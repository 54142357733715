import {
  actionTable,
  tablePageController, translate, useTranslate,
} from 'magner';
import { IInterview } from 'features/settings/interviews/interfaces';
import { ProfileLink } from 'features/profile/profile/types';
import ExportButton from 'features/settings/interviews/components/exportButton.vue';
import CopyButton from 'features/settings/interviews/components/copyButton.vue';
import EditButton from 'features/settings/interviews/components/editButton.vue';
import { interviewRead, interviewClone } from './requests';
import { formatDate } from '~/utils/format-date';

const interviewsConfig = tablePageController<IInterview>({
  request: interviewRead,
  header: {
    title: translate('gaz.interview.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'interview', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('gaz.interview.filters.create_new'),
        },
      },
    ],
  },

  filters: {
    filtersInSeparatePanel: false,
    filtersData: {
      name: '',
    },
    submitEvent: 'input',
    pagination: {
      items: 25,
      page: 1,
    },
    actions: [],
    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: translate('gaz.interview.filters.name'),
          inputDelay: 250,
        },
      },
    ],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'name',
        label: translate('gaz.interview.table.name'),
      },
      {
        prop: 'description',
        label: translate('gaz.interview.table.description'),
      },
      {
        prop: 'createdAt',
        label: translate('gaz.interview.table.createdAt'),
        view: {
          type: 'text',
          formatter: (_, row) => formatDate(row.createdAt as unknown as Date),
        },
      },
      {
        prop: 'published',
        label: translate('gaz.interview.table.published'),
        view: {
          type: 'tags',
          formatter: (publish) => {
            const { customT } = useTranslate();

            return [{
              text: publish
                ? customT(translate('gaz.interview.table.is_published'))
                : customT(translate('gaz.interview.table.is_not_published')),
              backgroundColor: publish ? '#32cd32' : '#b0c4de',
              textColor: '#FFFFFF',
            }];
          },
        },
      },
      {
        prop: 'editBtton',
        label: '',
        width: 40,
        view: {
          type: 'custom',
          component: () => EditButton,
        },
      },
      {
        prop: 'copyBtton',
        label: '',
        width: 40,
        view: {
          type: 'custom',
          component: () => CopyButton,
        },
      },
      {
        prop: 'pollResults',
        label: '',
        width: 40,
        view: {
          type: 'custom',
          component: () => ExportButton,
        },
      },
    ],
  },
});

export default interviewsConfig;
