import { loginPageController, translate } from 'magner';
import loginRequest from './requests';

const loginPageConfig = loginPageController({
  title: translate('hubstr.login.title'),
  request: loginRequest,
  logo: '/logo/milliard/logo.svg',
  form: {
    submitOnEnter: true,
    fullDataOnUpdate: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.login.submitButton'),
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: [{
        type: 'column',
        props: {},
        fields: [
          {
            type: 'input-phone',
            name: 'login',
            label: translate('hubstr.login.fields.phone.label'),
            props: {
              autocomplete: 'on',
              preferredCountries: ['ru', 'kz', 'uz', 'am'],
              initialCountry: 'uz',
              hasWithoutMaskOption: true,
            },
            validation: {
              type: 'empty',
              trigger: 'blur',
            },
          },
          {
            type: 'input',
            name: 'password',
            label: translate('hubstr.login.fields.password.label'),
            props: {
              type: 'password',
              autocomplete: 'on',
              placeholder: translate('hubstr.login.fields.password.placeholder'),
              prefixIcon: () => import('./lock.svg'),
            },
            /* validation: {
              type: 'password',
              trigger: 'blur',
            }, */
          },
        ],
      }],
    },
  },
});

export default loginPageConfig;
