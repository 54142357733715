import {
  actionCard, openModal,
  tableController, translate, useTranslate,
} from 'magner';
import { CustomNotification } from 'features/settings/notification/types/hubstr';
import notificationItemConfig from 'features/settings/notification/item/hubstr';
import { NOTIFICATION_STATUS } from 'features/settings/notification/constants';
import { formatDate } from '~/utils/format-date';

const notificationTable = tableController<CustomNotification>({
  // tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.notification.table.not_found'),
  columns: [{
    prop: 'caption',
    label: translate('hubstr.notification.table.caption'),
    minWidth: 280,
  }, {
    prop: 'createAt',
    label: translate('hubstr.notification.table.createAt'),
    minWidth: 150,
    view: {
      type: 'text',
      formatter: (_, row) => formatDate(`${row.createAt.date}Z`),
    },
  }, {
    prop: 'notifyAt',
    label: translate('hubstr.notification.table.notifyAt'),
    sortable: true,
    minWidth: 150,
    view: {
      type: 'text',
      formatter: (_, row) => formatDate(`${row.notifyAt.date}Z`),
    },
  }, {
    prop: 'status',
    label: translate('hubstr.notification.table.published'),
    minWidth: 150,
    view: {
      type: 'tags',
      formatter: (status) => {
        const { customT } = useTranslate();

        return [{
          text: customT(translate(NOTIFICATION_STATUS[status as string].text)),
          backgroundColor: NOTIFICATION_STATUS[status as string].color,
          textColor: '#FFFFFF',
        }];
      },
    },
  }, {
    prop: 'id',
    label: translate('hubstr.notification.table.actions'),
    width: 200,
    view: {
      type: 'actions',
      actions: [
        {
          type: 'action',
          emits: 'update-table',
          action: actionCard<CustomNotification>(async (data) => {
            try {
              await openModal<any>({
                type: 'card',
                config: notificationItemConfig,
                handleBeforeClose: true,
                customClass: 'add-notification-modal',
                props: {
                  emptyCard: false,
                  entityId: data.data.row.id as string,
                },
              });
              return false;
            } catch (e) {
              return translate('hubstr.notification.form.failed_action');
            }
          }),
          props: {
            text: translate('hubstr.notification.table.edit_action'),
            type: 'primary',
            plain: true,
          },
        },
      ],
    },
  }],
});

export default notificationTable;
