<template>
  <div class="container text-section">
    <div class="draggable_icon">
      <el-icon class="text-section__move-icon"><DCaret /></el-icon>
    </div>
    <div class="type">
      <el-form-item :label="translateText('hubstr.editor.fields.field_type')">
        <el-input v-if="state.text === 'data.department'" :value="translateText('hubstr.editor.fields.departments')" disabled />
        <el-input v-else :value="translateText(`hubstr.editor.fields.${state.type}`)" disabled />
      </el-form-item>
    </div>
    <div class="label">
      <el-form-item :label="translateText('hubstr.editor.fields.label')">
        <el-input v-if="state.type === 'text_section_widget'" disabled :value="matchingField?.description || TranslatesList[state.title.replace('lang.', '')] || state.title" />
        <el-input v-if="state.type === 'array_section_widget'" disabled :value="matchingField?.description || TranslatesList[state.title.replace('lang.', '')] || state.title" />
        <el-input v-if="state.type === 'info_line_action_widget'" disabled :value="TranslatesList[state.title.replace('lang.', '')] || state.title" />
        <el-input v-if="state.type === 'list_line_widget'" disabled :value="matchingField?.description || TranslatesList[state.leftText.replace('lang.', '')] || state.leftText" />
        <el-input v-if="state.type === 'list_line_raw_action_widget'" disabled :value="matchingField?.description || TranslatesList[state.leftText.replace('lang.', '')] || state.leftText" />
      </el-form-item>
    </div>
    <div class="required_info">
      <div v-if="matchingField?.isAdminRequired"><span>* </span>{{ translateText('hubstr.editor.fields.required_admin') }}</div>
      <div v-if="matchingField?.isUserRequired"><span>* </span>{{ translateText('hubstr.editor.fields.required_app') }}</div>
      <div v-if="data?.hasPrivacy"><span>* </span>{{ translateText('hubstr.editor.fields.has_privacy') }}</div>
    </div>
    <div class="action_btn">
      <el-button
        type="primary"
        link
        :icon="EditPen"
        @click="editModal"
      />
      <el-popconfirm
        :title="translateText('hubstr.editor.warnings.remove_field_confirm')"
        :width="300"
        @confirm="removeField(state.id)"
      >
        <template #reference>
          <el-button
            type="danger"
            link
            :icon="Delete"
          />
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType, reactive,
} from 'vue';
import { AdditionalField, WidgetBody } from 'features/settings/mobile-editor/interfaces';
import { DCaret, Delete, EditPen } from '@element-plus/icons-vue';
import { AdditionsList, TranslatesList } from 'features/settings/mobile-editor/requests';
import { magnerConfirm, translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'Field',
  components: {
    DCaret,
  },
  props: {
    data: {
      type: Object as PropType<WidgetBody>,
      required: true,
    },
  },
  emits: ['remove', 'edit'],
  setup (props, { emit }) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    const FIELD_TYPE_TO_NAME = {
      list_line_widget: translateText('hubstr.editor.modal.type.list_line'),
      text_section_widget: translateText('hubstr.editor.modal.type.text'),
      array_section_widget: 'Массив Секция',
      company_list_widget: translateText('hubstr.editor.modal.type.company'),
      info_line_action_widget: translateText('hubstr.editor.modal.type.phone'),
      list_line_raw_action_widget: translateText('hubstr.editor.modal.type.list_line_raw'),
    } as const;

    const state = reactive(props.data);
    const isBirthday = computed(() => state.text === 'data.formattedBirthday.value' || state.text === 'data.birthday');

    const removeConfirmModal = (id: string) => {
      magnerConfirm({
        title: 'Внимание',
        message: 'Удаление этого поля поля может привести к потере данных. Вы уверены, что хотите продолжить?',
        type: 'warning',
      }).then(() => {
        emit('remove', id);
      });
    };

    const removeField = (id: string) => {
      if (isBirthday.value) {
        removeConfirmModal(id);
        return;
      }
      emit('remove', id);
    };

    const editModal = (id: string) => {
      emit('edit', id);
    };

    const matchingField = Object.values(AdditionsList).find((field) => Object.keys(props.data).some((key) => {
      if (props.data[key] && typeof props.data[key] === 'string') {
        return props.data[key] === field.formCode;
      }
    })) as AdditionalField;

    return {
      state,
      matchingField,

      editModal,
      removeField,
      translateText,

      Delete,
      EditPen,

      FIELD_TYPE_TO_NAME,
      TranslatesList,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-section {
  &__move-icon {
    cursor: move;
  }
}

.container {
  background-color: white;
  border: 1px #f5f5f5 solid;
  border-radius: 5px;
  padding: 15px;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 0.1fr 1fr 1fr 0.1fr;
  grid-template-rows: auto auto;
  gap: 10px;
  grid-auto-flow: row;
  align-items: stretch;
  grid-template-areas:
    "draggable_icon label type action_btn"
    "draggable_icon required_info required_info action_btn";

  .el-form-item {
    margin-bottom: 0 !important;
  }
}

.draggable_icon {
  margin-top: 30px;
  grid-area: draggable_icon;
}

.action_btn {
  margin-top: 20px;
  grid-area: action_btn;
  display: flex;
  gap: 10px;
}

.required_info {
  color: var(--el-text-color-secondary);
  font-weight: lighter;
  grid-area: required_info;

  span {
    color: var(--el-color-danger)
  }
}

.type { grid-area: type; }

.label { grid-area: label; }

</style>
