import { tableController, translate } from 'magner';
import type { ResidentList } from 'features/residents/types/hubstr';
import { getColumns } from 'features/residents/requests/hubstr';

const residentsTable = tableController<ResidentList>({
  getColumnsRequest: getColumns,
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.resident.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [],
});

export default residentsTable;
