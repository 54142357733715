import {
  actionCard, openModal, tableController, tablePageController, translate,
} from 'magner';
import breakingFilters from 'features/profile/safety/breaking/filters';
import { IBreaking } from 'features/profile/safety/breaking/types';
import { breakingRead } from 'features/profile/safety/breaking/requests';
import BreakingDetail from 'features/profile/safety/breaking/card.vue';
import { formatDate } from '~/utils/format-date';

const breakingConfig = tablePageController<IBreaking>({
  header: {
    title: translate('gaz.safety.table.title'),
    actions: [],
    tabs: [
      {
        label: translate('gaz.safety.tabs.suggestions'),
        link: { name: 'suggestions' },
        active: false,
      },
      {
        label: translate('gaz.safety.tabs.breaking'),
        link: { name: 'breaking' },
        active: true,
      },
    ],
  },
  request: breakingRead,
  table: tableController<IBreaking>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('gaz.safety.table.not_found'),
    columns: [{
      prop: 'fio',
      label: translate('gaz.safety.table.fio'),
      className: 'suggestions-fio-cell',
      width: 150,
      fixed: 'left',
    }, {
      prop: 'email',
      label: translate('gaz.safety.table.email'),
      width: 250,
    }, {
      prop: 'organization',
      label: translate('gaz.safety.table.organization'),
      width: 200,
    }, {
      prop: 'place',
      label: translate('gaz.safety.table.place'),
      width: 200,
    }, {
      prop: 'text',
      label: translate('gaz.safety.table.text_breaking'),
      minWidth: 300,
    }, {
      prop: 'createdAt',
      label: translate('gaz.safety.table.createdAt'),
      view: {
        type: 'text',
        formatter: (_, row) => formatDate(row.createdAt),
      },
      width: 160,
    }, {
      prop: 'id',
      label: translate('gaz.safety.table.action_more'),
      width: 200,
      view: {
        type: 'actions',
        actions: [
          {
            type: 'action',
            action: actionCard<any>(async (data) => {
              try {
                await openModal<any>({
                  type: 'custom',
                  handleBeforeClose: false,
                  component: () => BreakingDetail,
                  props: {
                    breaking: data.data.row,
                  },
                });
                return false;
              } catch (e) {
                return false;
              }
            }),
            props: {
              text: translate('gaz.safety.table.action_more'),
              type: 'primary',
              plain: true,
            },
          },
        ],
      },
    }],
  }),
  filters: breakingFilters,
});

export default breakingConfig;
