import {
  actionTable, openModal, tablePageController, translate, useTranslate,
} from 'magner';
import { profileRead } from 'features/profile/profile/requests';
import { ProfileLink } from 'features/profile/profile/types';
import ProfileDepartmentsEditCard from './card.vue';

const profileConfig = tablePageController<ProfileLink>({
  header: {
    title: translate('gaz.profile.table.title'),
    actions: [],
  },
  request: profileRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 400,
        label: translate('gaz.profile.table.name'),
      },
      {
        prop: 'departments',
        width: 'auto',
        label: translate('gaz.profile.table.departments'),
        view: {
          type: 'text',
          formatter: (_, row) => {
            const { customT } = useTranslate();

            if (row.departments.length === 0) {
              return customT(translate('gaz.profile.table.nothing'));
            }

            return row.departments.map((department) => department.name).join(', ');
          },
        },
      },
      {
        prop: 'id',
        label: translate('gaz.profile.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<ProfileLink>(async ({ data }) => {
                const profileLink = (data as any).row as ProfileLink;
                try {
                  await openModal<any>({
                    type: 'custom',
                    component: () => ProfileDepartmentsEditCard,
                    handleBeforeClose: true,
                    props: {
                      id: profileLink.id,
                      title: profileLink.title,
                      selectedDepartments: profileLink.departments,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('gaz.profile.form.failed_edition');
                }
              }),
              props: {
                text: translate('gaz.profile.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
    pagination: {
      items: 25,
      page: 1,
    },
  },
});

export default profileConfig;
