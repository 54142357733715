import { tablePageController, translate } from 'magner';
import { eventRead } from 'features/events/requests/hubstr';
import eventsTable from 'features/events/table/hubstr';
import eventsFilters from 'features/events/filters/hubstr';
import { Event } from 'features/events/types/hubstr';

const eventsConfig = tablePageController<Event>({
  header: {
    title: translate('hubstr.event.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'event', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('hubstr.event.filters.create_new'),
        },
      },
    ],
  },
  request: eventRead,
  table: eventsTable,
  filters: eventsFilters,
});

export default eventsConfig;
