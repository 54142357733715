import {
  tablePageController, translate, actionCard, openModal, actionTable,
} from 'magner';
import { sportsSectionsRead } from 'features/profile/sports/sections/requests';
import { SportsSection } from 'features/profile/sports/sections/types';
import sportsSectionConfig from 'features/profile/sports/sections/card';

const sportsSectionsConfig = tablePageController<SportsSection>({
  request: sportsSectionsRead,
  header: {
    title: translate('gaz.sports.sections.table.title'),
    tabs: [
      {
        label: translate('gaz.sports.sections.tabs.title'),
        link: { name: 'sports-sections' },
        active: true,
      },
      {
        label: translate('gaz.sports.tourney.tabs.title'),
        link: { name: 'sports-tourney' },
        active: false,
      },
      {
        label: translate('gaz.sports.gto.tabs.title'),
        link: { name: 'sports-gto' },
        active: false,
      },
    ],
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('gaz.sports.sections.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<SportsSection>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: sportsSectionConfig,
              handleBeforeClose: true,
              customClass: 'modal-max-width modal-max-width__500',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('gaz.sports.sections.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('gaz.sports.sections.table.name'),
      },
      {
        prop: 'id',
        label: translate('gaz.sports.sections.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<SportsSection>(async ({ data }) => {
                const role = (data as any).row as SportsSection;
                try {
                  await openModal<any>({
                    type: 'card',
                    config: {
                      ...sportsSectionConfig,
                      header: {
                        title: translate('gaz.sports.sections.form.title_edit'),
                      },
                    },
                    customClass: 'modal-max-width modal-max-width__500',
                    handleBeforeClose: true,
                    props: {
                      emptyCard: false,
                      entityId: role.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('gaz.sports.sections.form.failed_edition');
                }
              }),
              props: {
                text: translate('gaz.sports.sections.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default sportsSectionsConfig;
