import { tableController, translate } from 'magner';
import {
  emailField, firstNameField, lastNameField, phoneField, statusField, telegramField, updatedField,
  titleIconColumn, paymentField, amountField,
} from 'features/events/guests/table/general';
import { EventGuest } from 'features/events/guests/types/hubstr';
import { APP_CILICIA, SUB_DOMEN } from '~/constants';

const eventGuestsTable = tableController<EventGuest>({
  emptyText: translate('hubstr.event.guests.table.not_found'),
  rowLink: (row) => ({ name: 'resident', params: { id: row.id } }),
  columns: [
    updatedField,
    firstNameField,
    lastNameField,
    emailField,
    phoneField,
    telegramField,
    statusField,
    paymentField,
    amountField,
  ],
});

// только для CILICIA
if (SUB_DOMEN === APP_CILICIA) {
  eventGuestsTable.columns.unshift(titleIconColumn);
}
export default eventGuestsTable;
