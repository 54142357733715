<template>
  <div class="container text-section-widget">
    <el-row
      align="middle"
      :gutter="10"
      style="margin: 0"
    >
      <el-col :span="1" :md="2" :lg="1">
        <el-icon
          class="text-section-widget__move-icon"
          style="cursor: move"
          color="var(--el-text-color-secondary)"
        >
          <BarsSVG />
        </el-icon>
      </el-col>

      <el-col
        :span="10"
        :md="22"
        :lg="10"
        class="text-section-widget__label"
      >
        <el-form-item :label="translateText('hubstr.editor.fields.label')">
          <el-input
            v-model="titleProxy"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col
        :span="9"
        :lg="9"
        :md="16"
        class="text-section-widget__type"
      >
        <el-form-item :label="translateText('hubstr.editor.fields.field_type')">
          <el-input
            :value="translateText('hubstr.editor.modal.type.text')"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col
        :span="2"
        :lg="2"
        :md="4"
      >
        <el-button
          type="primary"
          plain
          style="margin: -3px 0 0 0; width: auto;"
          :icon="EditPen"
          @click="editHandler(state)"
        />
      </el-col>
      <el-col
        :span="2"
        :lg="2"
        :md="4"
      >
        <el-popconfirm
          :title="translateText('hubstr.editor.warnings.remove_field_confirm')"
          :width="300"
          @confirm="removeHandler(state.id)"
        >
          <template #reference>
            <el-button
              type="danger"
              plain
              style="margin: -3px 0 0 0; width: auto;"
              :icon="Delete"
            />
          </template>
        </el-popconfirm>
      </el-col>
    </el-row>

    <div class="required_info">
      <div v-if="state.additions?.isAdminRequired">
        <span>* </span>{{ translateText('hubstr.editor.fields.required_admin') }}
      </div>
      <div v-if="state.additions?.isUserRequired">
        <span>* </span>{{ translateText('hubstr.editor.fields.required_app') }}
      </div>
      <div v-if="state.hasPrivacy">
        <span>* </span>{{ translateText('hubstr.editor.fields.has_privacy') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';
import { EditPen, Delete } from '@element-plus/icons-vue';
import { ITextSectionWidget }
  from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/interfaces';
import { translate, useTranslate } from 'magner';
import BarsSVG from 'features/settings/editor/assets/icons/bars.svg';
import { TranslatesAssociationList as t } from 'features/settings/editor/association-editor/requests';

interface Props {
  data: ITextSectionWidget;
}
const props = defineProps<Props>();
const emit = defineEmits(['edit', 'remove']);

const state = reactive(props.data);
const titleProxy = computed(() => t[state.title?.replace(/lang\.mobile(_ml)?\.?/g, '')] || state.title);

const editHandler = (formData: ITextSectionWidget) => {
  emit('edit', formData);
};
const removeHandler = (id: string) => {
  emit('remove', id);
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  border: 1px #f5f5f5 solid;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
}
.required_info {
  margin-left: 30px;
  font-weight: 100;
  font-size: 12px;
  color: var(--el-text-color-secondary);
  span {
    color: var(--el-color-danger)
  }
}
</style>
