import { initMagner } from 'magner';

import project from 'configs/index';
import dayjs from 'dayjs';

import 'magner/style';
import 'features/all.css';

dayjs.Ls.en.weekStart = 1; // Календарь начинается с понедельника

initMagner(project);
