<template>
  <el-input
    :model-value="modelValue"
    :maxlength="long ? 500 : 50"
    :placeholder="translateText('gaz.interview.preview.form.enter_text')"
    type="textarea"
    show-word-limit
    class="interview-preview-field-textarea"
    @input="$emit('update:modelValue', $event)"
  />
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';

import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'IntreviewPreviewFieldTextarea',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    long: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup () {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    return {
      translateText,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-textarea {
  margin-top: 12px;

  textarea {
    min-height: 179px !important;
    resize: none;
    background-color: transparent;
    padding: 0;
    font-size: 17px;
  }

  .el-input__count {
    bottom: -16px !important;
    right: 0 !important;
  }
}
</style>
