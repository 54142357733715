import { Link, LinkGroup } from 'features/settings/link-groups/types/hubstr';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const linkGroupsRead = request.table<LinkGroup>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination.page || 1}`;

  const res = await api.get<{ list: LinkGroup[], pager: Pager }>(
    `/api/admin/links_group/paginated_list${pagination}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const linksRead = request.table<Link>(async ({ api, data, router }) => {
  const code = router.currentRoute.value.params.id;
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination.page || 1}`;

  const res = await api.get<{ list: Link[], pager: Pager }>(
    `/api/admin/link/paginated_list${pagination}&filters[2][id]=groupCode&filters[2][value]=${code}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
  // Меняем заголовок на странице /links
  document.getElementsByClassName('magner-page-header_title')[0].innerHTML = res.data?.list[0]?.group.name || 'Ссылки';

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const linkGroupGet = request.card<Link>(async ({ api, data, parseError }) => {
  const res = await api.get<{ link: Link }>(`/api/admin/link/get?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.link };
});

export const linkGroupUpdate = request.card<Link>(async ({ api, parseError, data }) => {
  const body = { ...data.data, link: data.id };
  const res = await api.patch<{ link: Link }>('/api/admin/link/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.link };
});
