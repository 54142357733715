import { v4 as uuidv4 } from 'uuid';
import { magnerMessage } from 'magner';
import { fileUpload } from 'features/common/file-request';
import type {
  Category,
  Event,
  EventCreate,
  EventReceive, OrganizerResident,
} from 'features/events/types/hubstr';
import { IInterview } from 'features/settings/interviews/interfaces';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { ElLoading } from 'magner/element-plus';
import { request } from '~/utils/request';

import { API_URL, APP_ID, META_APP } from '~/constants';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('Europe/Moscow');

/**
 * Получить список мероприятий
 */
export const eventRead = request.table<Event>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination.page}`;
  const sort = '&sort[0][id]=dateStart&sort[0][value]=DESC';
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';
  const status = data.filters.status ? `&filters[1][id]=status&filters[1][value]=${data.filters.status}` : '';

  let dateFilter = '';
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    const formattedDate = `${d.getMonth() + 1}.${d.getFullYear()}`;
    dateFilter = `&filters[2][id]=onDate&filters[2][value]=${formattedDate}`;
  }

  let categories = '';
  if (data.filters.categories?.length) {
    const arr = data.filters.categories.map((id: string) => `filters[3][value][]=${id}`) as string[];
    categories = `&filters[3][id]=categories&${arr.join('&')}`;
  }

  const isPublished = data.filters.isPublished ? `&filters[4][id]=isPublished&filters[4][value]=${data.filters.isPublished}` : '';

  const res = await api.get<{ list: Event[], pager: Pager }>(
    `/api/admin/event/list?${pagination}${sort}${search}${dateFilter}${categories}${status}${isPublished}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  const list = res.data.list.map((item) => {
    const dateStart = dayjs(item.dateStart).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');
    const dateEnd = dayjs(item.dateEnd).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');

    return {
      ...item,
      dateStart,
      dateEnd,
    };
  });

  return {
    data: {
      rows: list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});
/**
 * Получить детальную информацию о мероприятии
 */
export const eventGet = request.card<EventReceive, EventCreate>(async ({ api, data, parseError }) => {
  const res = await api.get<{ event: Event }>(`/api/admin/event?event=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  /**
   * на бэке дата и время хранятся в UTC, но работаем мы всегда с GMT+3 (мск)
   * Поэтому какими бы странными эти манимуляции не какзались - они нужны
   */
  const dateStart = dayjs(res.data?.event.dateStart).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');
  const dateEnd = dayjs(res.data?.event.dateEnd).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');
  const registrationDateStart = dayjs(res.data?.event.registration.start).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');
  const registrationDateEnd = dayjs(res.data?.event.registration.end).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss');

  const page = document.querySelector('.page');
  if (res.data?.event.dateEnd && new Date() > new Date(dateEnd)) {
    page?.classList.add('event-past');
  } else {
    page?.classList.remove('event-past');
  }

  return {
    data: {
      ...res.data?.event,
      pollLink: res.data?.event.poll ?? null,
      categories: res.data?.event.categories.map((category: Category) => category.id),
      owner: res.data?.event.owner ? res.data.event.owner.id : null,
      address: res.data?.event.address ? res.data.event.address.id : null,
      dateStart: [new Date(dateStart), new Date(dateEnd)] as unknown as Date,
      dateEnd: new Date(dateEnd),
      invitedDepartments: Object.values(res.data?.event.invitedDepartments || {}).map(([id]) => id),
      eventMaterialsLink: res.data?.event.additions?.eventMaterialsLink,
      publish: res.data?.event.id,
      isPublished: res.data?.event.isPublished,
      eventEventCode: res.data?.event?.additions?.eventEventCode,
      registrationDateStart: [new Date(registrationDateStart), new Date(registrationDateEnd)] as unknown as Date,
      registrationDateEnd: new Date(registrationDateEnd),
      registrationType: res.data?.event.registration.type,
      poll: res.data?.event.poll?.name,
    },
  };
});
/**
 * формирование запроса на создание/сохранение
 */
const getRequestBody = async (data: any, id: string) => {
  const body = { ...data.data, id };

  delete body.eventEventCode;

  if (data.data.dateStart && Array.isArray(data.data.dateStart) && data.data.dateStart.length > 1) {
    const date = data.data.dateStart as unknown as [Date, Date];
    /**
     * на бэке дата и время хранятся в UTC, но работаем мы всегда с GMT+3 (мск)
     * Поэтому какими бы странными эти манимуляции не какзались - они нужны
     */
    const dateStart = dayjs().tz('Europe/Moscow')
      .year(date?.[0].getFullYear())
      .month(date?.[0].getMonth())
      .date(date?.[0].getDate())
      .hour(date?.[0].getHours())
      .minute(date?.[0].getMinutes())
      .second(0);
    body.dateStart = dateStart.utc().format('YYYY-MM-DDTHH:mm:ss[.000Z]');

    const dateEnd = dayjs().tz('Europe/Moscow')
      .year(date?.[1].getFullYear())
      .month(date?.[1].getMonth())
      .date(date?.[1].getDate())
      .hour(date?.[1].getHours())
      .minute(date?.[1].getMinutes())
      .second(0);
    body.dateEnd = dateEnd.utc().format('YYYY-MM-DDTHH:mm:ss[.000Z]');
  }

  if (data.data.registrationDateStart && Array.isArray(data.data.registrationDateStart) && data.data.registrationDateStart.length > 1) {
    const date = data.data.registrationDateStart as unknown as [Date, Date];
    /**
     * на бэке дата и время хранятся в UTC, но работаем мы всегда с GMT+3 (мск)
     * Поэтому какими бы странными эти манимуляции не какзались - они нужны
     */
    const registrationDateStart = dayjs().tz('Europe/Moscow')
      .year(date?.[0].getFullYear())
      .month(date?.[0].getMonth())
      .date(date?.[0].getDate())
      .hour(date?.[0].getHours())
      .minute(date?.[0].getMinutes())
      .second(0);

    const registrationDateEnd = dayjs().tz('Europe/Moscow')
      .year(date?.[1].getFullYear())
      .month(date?.[1].getMonth())
      .date(date?.[1].getDate())
      .hour(date?.[1].getHours())
      .minute(date?.[1].getMinutes())
      .second(0);

    body.registrationStart = registrationDateStart.utc().format('YYYY-MM-DDTHH:mm:ss[.000Z]');
    body.registrationEnd = registrationDateEnd.utc().format('YYYY-MM-DDTHH:mm:ss[.000Z]');

    delete body.registrationDateStart;
  }

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'event',
      'event_logo',
      'eventLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  if (data.data.organizers?.length) {
    const owner = data.data.organizers.find((org) => org.type === 'resident' && org.id) as OrganizerResident;
    body.owner = '';
    if (owner) {
      body.owner = owner.id;
    } else {
      const userStorage = localStorage.getItem('magner-hubstr');
      if (userStorage && 'id' in JSON.parse(userStorage)) {
        body.owner = JSON.parse(userStorage).id;
      }
    }
  }

  if (data.data.categories?.length) {
    body.categories = data.data.categories
      .map((category) => (typeof category === 'object' ? (category as any)?.id : category));
  }

  if (typeof data.data.address === 'object') body.address = (body.address as any)?.id || body.address;

  if (data.data.poll?.length === 0) {
    body.poll = null;
  }

  return body;
};
/**
 * Создать мероприятие
 */
export const eventCreate = request.card<Event, EventCreate>(async ({
  api, parseError, data, router,
}) => {
  const body = await getRequestBody(data, uuidv4());

  body.additions = {};

  if (data.data.eventEventCode) {
    body.additions.eventEventCode = data.data.eventEventCode;
  }

  const res = await api.post<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'event', params: { id: res.data?.event.id } });
  // жуткий костыль, но кнопки формы не реактивны в магнере
  window.location.reload();
  return { data: res.data?.event };
});
/**
 * Обновить детальную информацию мероприятия
 */
export const eventUpdate = request.card<Event, EventCreate>(async ({ api, parseError, data }) => {
  const body = await getRequestBody(data, data.id as string);

  body.additions = {
    eventMaterialsLink: data.data.eventMaterialsLink?.length ? data.data.eventMaterialsLink : '',
  };

  if (data.data.eventEventCode) {
    body.additions.eventEventCode = data.data.eventEventCode;
  }

  const res = await api.patch<{ event: Event }>('/api/admin/event', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.event };
});
/**
 * Удалить мероприятие
 */
export const eventDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`/api/admin/event?event=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'events' });
  return { data: 'ok' };
});
/**
 *
 */
export const getEventReportRating = request.card(async (
  {
    data,
    lstorage,
  },
) => {
  const token = lstorage.read('token');

  // @ts-ignore
  fetch(`${API_URL}/api/admin/event/report/rating?event=${data.id}&format=${data.format}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // @ts-ignore
      a.download = `Статистика_оценок_${data.form.name}.${data.format}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return { data: 'ok' };
});
/**
 * опубликовать мероприятие
 */
export const publishEvent = request.custom(async ({
  api, data, parseError, router,
}) => {
  const loading = ElLoading.service({
    lock: true,
    background: 'rgba(0, 0, 0, 0.7)',
  });

  const res = await api.patch<{ event: Event }>('/api/admin/event/publish', { id: data.data.id });

  if (res.error) {
    loading.close();

    return { error: parseError?.(res.error) };
  }

  loading.close();

  magnerMessage({
    type: 'success',
    message: 'Мероприятие успешно опубликовано',
  });

  window.location.reload();

  return { data: res?.data?.event };
});
/**
 * Получение списка опросников, не привязанных ни к какому мероприятию
 */
export const getPollList = request.table<IInterview>(async ({ api, data }) => {
  try {
    const res = await api.get<{ list: Event[] }>(
      '/api/admin/poll/list?count=1000&page=1',
    );

    if (res.error) {
      return {
        data: {
          rows: [],
          pagination: null,
        },
      };
    }

    return {
      data: {
        rows: res.data.list,
        pagination: null,
      },
    };
  } catch (e) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }
});

/**
 * Получить значения по умолчанию для новой рассылки
 */
export const eventNewGet = request.card<any>(async ({ api, data, parseError }) => {
  const departments = await autocompleteDepartment('');

  if (APP_ID === META_APP) {
    return {
      data: {
        invitedDepartments: [departments.data?.rows[0].id],
      },
    };
  }

  return { data: {} };
});
