import { request } from '~/utils/request';

export const getFaqLayout = request.card<{faqLayout: any}>(async ({ api, data, parseError }) => {
  try {
    const res = await api.get<any>('/api/admin/view?code=faq');

    return {
      data: {
        faqLayout: res.data.data,
      },
    };
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const updateFaqLayout = request.custom(async ({ api, data, parseError }) => {
  const res = await api.put('/api/admin/view', data.data.faqLayout);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});
