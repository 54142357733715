import * as uuid from 'uuid';
import { SupportedLanguages } from 'configs/translation';
import { IAdditions, IAView, IAViewWidgets } from 'features/settings/editor/association-editor/interfaces';
import parseForAdmin from 'features/settings/editor/association-editor/utils/parse-for-admin';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import parseForMobile from 'features/settings/editor/association-editor/utils/parse-for-mobile';
import { defaultAssociation } from 'features/settings/editor/association-editor/default-association';
import { request } from '~/utils/request';

export let TranslatesAssociationList: Record<string, string> = {};
export let AdditionsAssociationList: IAdditions[] = [];

/**
 * Получение всех существующих additional полей
 * */
export const additionsGet = request.custom<IAdditions[]>(async ({ api }) => {
  const res = await api.get<{ data: IAdditions[] }>('/api/admin/additional_fields/list?entityType=association');
  if (res.error) {
    return { error: res.error };
  }

  AdditionsAssociationList = res.data.data;
  return { data: res.data.data };
});

/**
 * Получение переводов
 * */
export const translatesGet = request.custom<Record<string, string>>(async ({ api, data }) => {
  const query = data as typeof SupportedLanguages[keyof typeof SupportedLanguages] || 'ru';
  const [res, resMl] = await Promise.all([
    api.get<any>(`/api/admin/translations/list?locale=${query}&domain=mobile`),
    api.get<any>(`/api/admin/translations/list?locale=${query}&domain=mobile_ml`),
  ]);

  if (res.error || resMl.error) {
    return { error: res.error };
  }

  TranslatesAssociationList = { ...res.data.data, ...resMl.data.data };
  return { data: { ...res.data.data, ...resMl.data.data } };
});

/**
 * Получение view(мп)
 * */
const viewGet = request.custom<IAView>(async ({ api }) => {
  try {
    const res = await api.get<{ data: IAView }>(
      '/api/admin/view?code=association',
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Создание нового дефолтного view(мп)
 * */
const defaultProfileCreate = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post<{ data: IAView }>(
      '/api/admin/view',
      { ...data },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

export const associationViewGet = request.card<any>(async ({ api, data, parseError }) => {
  const concatData = (data: { data: IAView }, additions: { data: IAdditions[] }) => data.data.widgets.body.forEach((group) => {
    group.widgets.forEach((widget) => {
      const foundAddition = additions?.data.find((a) => Object.keys(widget).some((key) => widget[key] === a.formCode));
      if (!widget.id) {
        widget.id = uuid.v4();
      }
      if (foundAddition) {
        widget.additions = foundAddition;
      }
    });
  });

  const [view, translate, additions] = await Promise.all([
    // Получаем view
    await viewGet(''),
    // Получаем переводы
    await translatesGet(''),
    // Получаем доп. поля
    await additionsGet(''),
  ]);

  if (view?.error || translate?.error || additions?.error) {
    return { error: 'error' };
  }

  if (!view?.data?.id) {
    const resCreate = await defaultProfileCreate(defaultAssociation);
    if (resCreate.error) { return { error: resCreate.error }; }
    concatData(resCreate, additions);
    return {
      data: {
        associationEditorLayout: {
          id: resCreate.data.id,
          ...resCreate.data?.widgets,
        },
      },
    };
  }

  concatData(view, additions);

  return {
    data: {
      associationEditorLayout: {
        id: view.data.id,
        ...view.data.widgets,
      },
    },
  };
});

/**
 * Сохранение view(мп)
 * */
const saveView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.put(
      '/api/admin/view',
      {
        id: data.id,
        code: 'association',
        title: 'Организация',
        widgets: {
          ...data,
          // ...defaultAssociation.widgets,
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение admin-view
 * */
const saveAdminView = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'association',
        json: {
          ...parseForAdmin(data),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Сохранение представления для редактирования в мп
 * */
const saveMobileEdit = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post(
      '/api/admin/admin-view',
      {
        code: 'mp_association_form',
        json: {
          ...parseForMobile(data),
        },
      },
    );

    return {
      data: res.data?.data,
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Создание нового additional поля
 * */
export const createNewField = request.custom<IAdditions, IAdditions>(async ({ api, data, parseError }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/additional_fields/create',
      { ...data },
    );

    // Обновление списока всех доп.полей
    // const additionalFields = await additionsGet('');
    // if (additionalFields.error) {
    //   return { error: additionalFields.error };
    // }

    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление additional поля
 */
export const updateAdditionalField = request.custom<IAdditions, Partial<IAdditions>>(async ({ api, data }) => {
  try {
    const res = await api.patch<IAdditions>(
      '/api/admin/additional_fields/update',
      { ...data },
    );
    return {
      data: { res },
    };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Добавление поля в автокомплит
 * */
export const createAutocompleteField = request.custom(async ({ api, data }) => {
  try {
    const res = await api.post<any>(
      '/api/admin/autocomplete/values/create',
      { ...data },
    );

    return { data: res };
  } catch (err) {
    return { error: err };
  }
});

/**
 * Обновление поля userBadge.
 * Тк он находится отдельно (в header)
 * */
const saveBadge = async (data: IAViewWidgets) => {
  const badgeLabel = data.header.find((i) => i.type === 'header_badge_widget')?.label;
  const badgeDisc = AdditionsAssociationList.find((i) => i.code === 'associationUserPosition')?.description;

  if (badgeLabel && badgeLabel !== badgeDisc) {
    try {
      return await updateAdditionalField({
        ...AdditionsAssociationList.find((i) => i.code === 'associationUserPosition'),
        description: badgeLabel,
      });
    } catch (err) {
      return { error: err };
    }
  }

  return { data: 'ok' };
};

// Обновление всего
export const associationViewUpdate = request.custom<{ data: { associationEditorLayout: IAViewWidgets } }>(async ({ data }) => {
  const store = useEditorStore();
  const deepCopyPayload = JSON.parse(JSON.stringify(data.data.associationEditorLayout)) as IAViewWidgets;

  // Собираем массив с отредактированными доп.полями
  const listForUpdate = deepCopyPayload.body.flatMap((group) => group.widgets.map((widget) => {
    if ('additions' in widget && widget?.touch && !widget?.isNew) {
      return widget.additions;
    }
  }).filter(Boolean));

  // Собираем массив с новыми доп.полями
  const listForCreate = deepCopyPayload.body.flatMap((group) => group.widgets.map((widget) => {
    if ('additions' in widget && widget?.isNew) {
      return widget.additions;
    }
  }).filter(Boolean));

  // Обновляем доп.поля
  if (listForUpdate.length) {
    await Promise.all(listForUpdate.map(async (addition) => {
      await updateAdditionalField(addition);
    }));
  }
  // Создаем доп.поля
  if (listForCreate.length) {
    await Promise.all(listForCreate.map(async (addition) => {
      await createNewField(addition);
    }));
  }

  // Обновляем Автокомплиты
  const updatedWidgets = [...listForCreate, ...listForUpdate];
  const updatedCodes = updatedWidgets.map((w) => w?.code);
  await Promise.all(store.autocompleteList.value.map(async (autocompleteData: {field: string; values: { id: string; value: string }[]}) => {
    if (updatedCodes.includes(autocompleteData.field)) {
      await createAutocompleteField({
        field: autocompleteData.field,
        values: autocompleteData.values.map((v) => v.value),
      });
    }
  }));

  // Удаляем лишнее
  const outView = {
    ...deepCopyPayload,
    body: deepCopyPayload.body.map((group) => ({
      ...group,
      widgets: group.widgets.map(({
        isNew, touch, additions, ...widget
      }) => widget),
    })),
  };

  // Сохраняем представления
  const res = await Promise.all([
    // Сохраняем view
    await saveView(outView),
    // Сохраняем admin-view
    await saveAdminView(deepCopyPayload),
    // Сохраняем редактирование в мобилке
    await saveMobileEdit(deepCopyPayload),
    // Отдельно обновляем Badge в Additional листе
    await saveBadge(deepCopyPayload),

  ]).then(([view]) => ({
    view,
  }));

  if (res.view.error) {
    return { error: 'Ошибка' };
  }

  window.location.reload();
  return { data: res.view };
});
