import { BansList } from 'features/settings/bans/types/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/ignore/';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const bansHead = request.table<BansList>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;

  const res = await api.get<{ list: BansList[], pager: Pager }>(`${BASE_URL}list?${pagination}`);
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const banDelete = request.custom(async ({
  api, data, parseError, router,
}) => {
  const res = await api.post(`${BASE_URL}remove`,
    {
      ignoreId: data,
    });

  if (res.error) {
    throw res.error;
  }

  await window.location.reload();
  return { data: 'ok' };
});
