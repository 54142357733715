<template>
  <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
    <template #content>
      {{ translateText('gaz.interview.form.copy_button') }}
    </template>

    <div class="table-action-icon">
      <icon-copy @click="onClick(row.id)" />
    </div>
  </el-tooltip>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { useRouter } from 'magner/vue-router';
import { interviewClone } from '../requests';

import IconCopy from '~/assets/icons/copy.svg';

export default defineComponent({
  name: 'CopyButton',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    const { customT } = useTranslate();
    const router = useRouter();

    const onClick = async (id: string) => {
      const result = await interviewClone(id);

      if (!result.data) {
        return;
      }

      await router.push({ name: 'interview', params: { id: result.data } });
    };

    const translateText = (code: string) => customT(translate(code));

    return {
      onClick,
      translateText,
    };
  },

  components: {
    IconCopy,
  },
});
</script>
