<template>
  <el-tooltip
    :content="text"
    effect="light"
    placement="bottom"
    popper-class="generic-form_item_tooltip"
  >
    <el-icon size="15" class="icon">
      <component :is="Question" />
    </el-icon>
  </el-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Question from 'features/settings/mobile-editor/assets/icons/question.svg';

export default defineComponent({
  name: 'QuestionTooltip',
  components: { Question },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  setup () {
    return {
      Question,
    };
  },
});
</script>

<style scoped>
.icon {
  margin-left: 5px;
  color: var(--el-color-primary);
}
</style>
