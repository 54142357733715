const enCustom = {
  dynamic: {} as any,
  hubstr: {
    system: {
      sidebar: {
        group_title: 'System',
      },
    },
    settings: {
      sidebar: {
        group_title: 'Settings',
      },
    },
    moderation: {
      sidebar: {
        group_title: 'Moderation',
      },
    },
    address: {
      sidebar: {
        table_title: 'Addresses',
        card_title: 'New address',
        group_title: 'Addresses',
      },
      form: {
        title: 'Address',

        submit_button: 'Save',
        cancel_button: 'Back',
        remove_button: 'Delete',

        name: { label: 'Name', placeholder: 'Office' },
        address: { label: 'Address', placeholder: '4 Krasnoarmeyskaya st., Moscow' },
        point: { label: 'Point on the map', placeholder: '55.797397, 37.549390' },
        description: { label: 'Description', placeholder: 'The entrance is on the right side' },
      },
      table: {
        title: 'Addresses',
        not_found: 'Addresses not found',

        address: 'Address',
        name: 'Name',
        description: 'Description',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name',
      },
    },
    event: {
      sidebar: {
        table_title: 'Events',
        card_title: 'New event',
        group_title: 'Events',
      },
      table: {
        title: 'Events',
        not_found: 'Events not found',

        id: 'ID',
        name: 'Name',
        category: 'Category',
        date_start: 'Start Date',
        date_end: 'End Date',
        status: 'Status',
        isPublished: 'Publication',

        published: 'published',
        not_published: 'not published',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name',
        status: 'Status',
        planned_only: 'Planned',
        is_canceled: 'Canceled',
        registration_closed: 'Registration closed',
        categories: 'Categories',
        date: 'Month',
        is_published: {
          title: 'Is published',
          options: {
            published: 'Published',
            not_published: 'Not published',
          },
        },
      },
      tabs: {
        form: 'Card',
        guests: 'Guests',
      },
      alerts: {
        remove_with_interview: { title: 'Attention!', message: 'Cannot delete because the event is published and a survey is attached.' },
      },
      form: {
        title: 'Event',
        edit_button: 'Edit',
        add_button: 'Add',
        submit_button: 'Save',
        submit_button_publish: 'Publish',
        cancel_button: 'Back',
        remove_button: 'Delete',
        or_divider: 'or',

        name: {
          label: 'Name',
          placeholder: 'Business training',
          hint: 'Max 128 characters including spaces',
        },
        description: {
          label: 'Description',
          placeholder: 'At this business training we will learn how to manage team effectively',
        },
        date: {
          label: 'Start and end date',
          start_placeholder: '20.01.2022 at 14:00',
          end_placeholder: '21.01.2022 at 15:30',
        },
        registration_date: {
          label: 'Start and end date of registration',
        },
        image: {
          label: 'Image',
          hint: 'Recommended size - at least 800 pixels wide,'
            + '<br/>visible area in the proportions of 2:1. JPG, JPEG, PNG format. File size not more than 8 MB',
        },
        department: { label: 'Invite residents from departments', placeholder: '' },
        categories: { label: 'Categories', placeholder: 'Business, Human Resources' },
        organizer: { label: 'Organizers', placeholder: 'Ivan Ivanov' },
        publish: { label: 'Event publication' },
        address: { label: 'Address', placeholder: 'Moscow' },
        residentsLimit: {
          label: 'Max number of participants',
          placeholder: '10',
          hint: 'The limit is not taken into account if the limit is set in the attached questionnaire.',
        },
        access: {
          label: 'Access',
          placeholder: 'Private',
          opt_closed: 'Private',
          opt_half_open: 'Half-open event',
          opt_open: 'Open',
          opt_family: 'Family',
          opt_external: 'External',
        },
        type: {
          label: 'Type',
          placeholder: 'General',
          opt_main: 'General',
          opt_additional: 'Additional',
          opt_clubInClub: 'Club inside club',
          opt_open: 'Open',
        },
        isRegistrationOpen: {
          label: 'State',
          placeholder: '',
          opt_main: 'Open',
          opt_additional: 'Closed',
          hint: '"Closed" – registration is closed, the event will be forcibly closed and will not open upon the release of limits or any other conditions. It will not be possible to sign up for the event.',
        },
        status: {
          label: 'Status',
          hint: '<em>Planned</em> – the event will be held within the specified time frame. <br><em>Canceled</em> – the event will not take place. <br><em>Registration is closed</em> – the event will be held, but registration of new participants has been stopped.',
          placeholder: 'Planned',
          opt_planned: 'Planned',
          opt_canceled: 'Canceled',
          opt_closed: 'Registration is closed',
        },
        report: { label: 'Report', placeholder: 'The event was successful' },
        broadcast_link: { label: 'Link to view', placeholder: 'https://example.com' },
        materials_link: { label: 'Link to meeting materials', placeholder: 'https://example.com' },
        rating_statistics: { label: 'Statistics on given ratings:', xls: 'XLS', csv: 'CSV' },
        poll: {
          label: 'Attach survey',
          placeholder: 'Select a survey from the list',
        },
        titles: {
          registrations: 'Registration management',
        },
      },
      guests: {
        sidebar: {
          table_title: 'Participants',
          card_title: 'Event participants',
        },
        statuses: {
          willGo: 'Will go',
          favorite: 'In Favorites',
          noAnswer: 'No answer',
          new: 'Invited',
          willNotGo: 'Will not go',
          read: 'Viewed',
        },
        table: {
          title: 'Event participants',
          not_found: 'Participants not found',
          id: 'ID',
          name: 'Name',
          surname: 'Surname',
          email: 'Email',
          phone: 'Phone number',
          telegram: 'Telegram',
          status: 'Status',
          payment: 'Payment',
          amount: 'Amount',
          updatedAt: 'Update time',
        },
        filters: {
          name: 'Name or surname',
          status: 'Status',
        },
      },
      organizer: {
        title: {
          placeholder: 'Name of the organizing partner',
        },
        link: {
          placeholder: 'Link',
        },
        choose_organizer: {
          placeholder: 'Select an organizer from residents',
        },
      },
    },
    login: {
      title: 'Log in',
      submitButton: 'Log in',
      fields: {
        phone: { placeholder: '7-900-123-45-67', label: 'Phone number' },
        password: { placeholder: '******', label: 'Password' },
      },
      sidebar: {
        group_title: 'Directories',
      },
    },
    resident: {
      sidebar: {
        table_title: 'Residents',
        card_title: 'New resident',
        group_title: 'Residents',
        companies_title: 'Companies',
        company_title: 'Company',
      },
      tabs: {
        resident: 'Resident',
        companies: 'Companies',
        create: 'Create',
      },
      table: {
        title: 'Residents',
        not_found: 'Residents not found',
        id: 'ID',
        fio: 'Name',
        first_name: 'Name',
        last_name: 'Surname',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        tags: 'Role at the club',
        avatar: 'Avatar',
        status: 'Status',
        authMethods: 'Authorization type',
        set_password: 'Set Password',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'Name or surname',
        phone: 'Phone number',
        positionTags: 'Role at the club',
        status: 'Status',
      },
      form: {
        title: 'Resident',
        cancel_button: 'Back',
        edit_button: 'Edit',
        submit_button: 'Save',
        remove_button: 'Remove',
        confirm_delete: 'Are you sure you want to remove this resident?',
        failed_password_change: 'Password update failure',

        about_me: 'About myself',
        about_business: 'About business',
        first_name: { label: 'Name', placeholder: 'Ivan' },
        last_name: { label: 'Surname', placeholder: 'Ivanov' },
        phone: { label: 'Phone number', placeholder: '+998 (XX) XXX-XX-XX' },
        email: { label: 'Email', placeholder: 'example{\'@\'}domain.com' },
        password: { label: 'Password for admin panel', placeholder: '' },
        roles: {
          label: 'Access settings',
          placeholder: 'Administrator',
          hint: 'The field affects access to the administrative panel. It also regulates some permissions within the mobile application. For example, the role of "Administrator" gives the right to delete other people`s comments',
          admin: 'Administrator',
          resident: 'Resident',
          community_manager: 'Community manager',
          buddy_partner: 'Buddy Partner',
        },
        birthday: { label: 'Date of birth', placeholder: '26.06.1980' },
        avatar: { label: 'Avatar' },
        badge: { label: 'Badge', placeholder: 'CEO of Atom LLC' },
        recommendation_user: { label: 'Joined on the recommendation', placeholder: 'Petr Petrov' },
        entry: { label: 'Member since', placeholder: '15.10.2021' },
        tag: { label: 'Tags', placeholder: 'Tag' },
        forum_group: { label: 'Forum Group', placeholder: 'Group' },
        position_tag: { label: 'Role at the club', placeholder: 'Moderator' },
        department: { label: 'Department', placeholder: 'Choose' },
        atlas: { label: 'Atlases', placeholder: '10' },
        city: { label: 'City', placeholder: 'Ulyanovsk' },
        business_start_year: { label: 'Seniority', placeholder: '01.03.2000' },
        business_start_year_display: { option: 'Hide section' },
        turnover_per_year: { label: 'Annual turnover, rubles', placeholder: '6 000 000 000' },
        turnover_per_year_display: { option: 'Hide section' },
        number_of_employees: { label: 'Number of employees', placeholder: '41' },
        number_of_employees_display: { option: 'Hide section' },
        business_sectors: { label: 'Business sectors', placeholder: 'IT, telecom' },
        gender: {
          label: 'Sex',
          option_m: 'Male',
          option_f: 'Female',
        },
        visibility: {
          show: 'Show',
          hide: 'Hide',
          range: 'Range',
        },
        telegram: { label: 'Telegram nickname', placeholder: '{\'@\'}butterfly' },
        telegramId: { label: 'Telegram ID', placeholder: '' },
        vk: { label: 'Vkontakte', placeholder: 'https:/vk.com/butterfly' },
        facebook: { label: 'Facebook', placeholder: 'https://fb.com/butterfly' },
        instagram: { label: 'Instagram', placeholder: '{\'@\'}butterfly' },
        education: { label: 'Education', placeholder: 'МГУ, 2014-2018' },
        family_status: { label: 'Marital status', placeholder: 'Single' },
        interests: { label: 'Interests, hobbies', placeholder: 'Swimming, horseback riding' },
        sports: { label: 'Sports', placeholder: 'Aikido, running' },
        waste_types: { label: 'Types of recyclable waste', placeholder: 'Choose a value' },
        facts_about_me: { label: 'Facts about me', placeholder: 'When I was four years old, I was selling lemonade near my house.' },
        personal_www: { label: 'Personal site', placeholder: 'domain.com' },
        video_presentation: { label: 'Video business card', placeholder: 'https://youtube.com/some-video' },
        accept_rules: { label: 'Accepted agreement' },
        profile_complete: { label: 'Fully completed profile', hint: 'After fully completing the profile (required fields), the resident proceeds further into the application. Sees other residents and events' },
        created_at: { label: 'Creation date' },
        updated_at: { label: 'Update date' },
        change_password_at: { label: 'Password update date' },
        last_activity: { label: 'Last app login date' },
        booleans: { yes: 'Yes', no: 'No' },
        not_found: 'Not found',

        useful: 'What can I do',
        expertise: { label: 'Expertise', placeholder: 'I am good at chemistry.' },
        achievements: { label: 'Achievements', placeholder: 'Certificate "Medvezhonok", first place' },
        look_resources: { label: 'Looking for resources', placeholder: 'Calcium' },

        searching: 'What I am looking for',
        provide_resources: { label: 'Can provide resources', placeholder: 'Places in the office in Kaluga' },
        year_target: { label: 'Goal for the year', placeholder: 'Obtain an investment of $100 million' },
        request: { label: 'Request', placeholder: 'Looking for a business partner' },

        children: { label: 'Children', placeholder: 'A boy of 6 and a girl of 8' },
        contacts: { label: 'Contact info' },
        companies: {
          title: 'Company',
          submit_button: 'Save',
          remove_button: 'Delete',
          edit_button: 'Edit',
          add_button: 'Add',

          label: 'Companies',
          user: { label: 'Resident', placeholder: '' },
          name: { label: 'Company name', placeholder: 'Atom LLC' },
          logo: { label: 'Logo' },
          areas: { label: 'Sphere of business', placeholder: 'Telecom' },
          position: { label: 'Role in the company', placeholder: 'CEO' },
          geography: { label: 'Geography', placeholder: 'Central Russia' },
          site: { label: 'Website', placeholder: 'example.com' },
          telegram: { label: 'Company Telegram Channel', placeholder: '{\'@\'}company' },
          wasteTypes: { label: 'Types of recyclable waste', placeholder: '' },
        },
        status: {
          label: 'Status',
          active: 'Active',
          blocked: 'Blocked',
          left: 'Left the club',
          not_paid: 'Not paid',
        },
        newPassword: {
          label: 'Permanent password',
          placeholder: 'Enter your password',
        },
        authMethods: {
          label: 'Type of authorization',
          placeholder: 'Select at least one type of authorization',
        },
        externalId: {
          label: 'User ID in GetCourse',
          placeholder: 'Enter ID',
        },
        description: {
          label: 'Characteristics of a resident as a buddy-partner',
          placeholder: 'Description',
        },
      },
      companies: {
        table: {
          title: 'Resident companies',
          not_found: 'Companies not found',
          name: 'Name',
          logo: 'Logo',
          user_position: 'Position in the company',
          areas: 'Spheres of business',
          error: 'Failed to load the company',
        },
        filters: {
          name: 'Name',
        },
      },
    },
    link_groups: {
      sidebar: {
        table_title: 'Link groups',
      },
      table: {
        title: 'Link groups',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      form: {
        title_edit: 'Edit link',
        submit_button: 'Save',
        name: { label: 'Name', placeholder: 'Link name' },
        position: { label: 'Position', placeholder: 'Position number' },
        visible: 'Visible',
        failed_edition: 'Failed to edit link',
      },
      links: {
        table: {
          title: 'Links',
        },
      },
    },
    club_role: {
      sidebar: {
        table_title: 'Roles at the club',
      },
      table: {
        title: 'Roles at the club',
        not_found: 'Roles not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New role',
        title_edit: 'Role editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a role',
        failed_edition: 'Failed to edit a role',
        name: { label: 'Name', placeholder: 'Headman' },
        color: { label: 'Color', placeholder: '#FFFFFF' },
      },
    },
    mobile_profile: {
      sidebar: {
        title: 'Fields editor',
      },
      form: {
        title: 'Fields editor',
      },
    },
    change_log: {
      form: {
        title: 'Changelog',
      },
    },
    preferences: {
      sidebar: {
        title: 'Settings',
      },
      tabs: {
        tab_1: 'Notifications',
        tab_2: 'General Settings',
        tab_3: 'Notification to residents about new versions',
      },
      versions: {
        title: 'Notification to residents about new versions',
        version: { label: 'Installing the minimum working version of the mobile application in the stores' },
        type: { label: 'Type of window message in the mobile application', hint: 'Soft - once a day, can skip the message and continue using the application. Hard - cannot use the application until the user updates it.' },
        require_update: { label: 'Should a message about a new version in the store be displayed?' },
        appLinks: { title: 'Links to the application' },
      },
    },
    reports: {
      sidebar: {
        title: 'Reports',

        user_activity_title: 'User Activity',
        events_title: 'Events',
      },
      table: {
        events: {
          alert: 'Please, select events',
        },
        certificate: {
          alert: 'Please, select departments',
        },
        partners: {
          alert: 'Please, select departments',
        },
      },
    },
    event_category: {
      sidebar: {
        group_title: 'Event categories',
        table_title: 'Event categories',
      },
      table: {
        title: 'Event categories',
        not_found: 'Categories not found',
        edit_action: 'Edit',
        id: 'ID',
        name: 'Name',
        remove: 'Delete',
        actions: '',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title: 'New category',
        failed_creation: 'Failed to create category',
        failed_edition: 'Failed to edit category',
        submit_button: 'Save',
        remove_button: 'Delete',
        name: { label: 'Name', placeholder: 'Meeting' },
      },
    },
    partner: {
      sidebar: {
        table_title: 'Partners',
      },
      table: {
        title: 'Partners',
        not_found: 'Partners not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New partner',
        title_edit: 'Partner editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a partner',
        failed_edition: 'Failed to edit partner',
        name: { label: 'Name', placeholder: 'Company LLC' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/company' },
        group: { label: 'Links group', placeholder: 'Выбрать' },
        image: { label: 'Image' },
      },
    },
    club_in_club: {
      sidebar: {
        table_title: 'Clubs inside the club',
      },
      table: {
        title: 'Clubs inside the club',
        not_found: 'Clubs not found',
        id: 'ID',
        name: 'Name',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit the club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    complaints: {
      sidebar: {
        table_title: 'Complaints and blocks',
      },
      table: {
        title: 'Complaints',
        complaintAuthor: 'Who complained',
        complaintText: 'Text of the complaint',
        complaintObject: 'Subject of the complaint',
        commentary: 'Author of the comment/publication',
        complaintTime: 'Time of complaint',
        complaintActions: 'Controllers',
        complaintObserve: 'View complaint',
      },
      modal: {
        complaint_author: 'Who complained',
        complaint_text: 'Text of the complaint',
        complaint_object: 'Subject of the complaint',
        commentary: 'Author of the comment/publication',
        complaint_time: 'Time of complaint',
        complaint_published_time: 'Time of publication',
        complaint_published_text: 'Text of the publication',
        complaint_published_author: 'Author of the publication',
        complaint_comment_time: 'Time of the comment',
        complaint_comment_text: 'Text of the comment',
        complaint_comment_author: 'Author of the comment',
        complaint_event_time: 'Time of the event',
        complaint_event_name: 'Title of the event',
        cancel_button: 'Close',
      },
      filters: {
        complaints: 'Complaints',
        bans: 'Blocks',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit the club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    bans: {
      sidebar: {
        table_title: 'Blocks',
      },
      table: {
        ignoring: 'Jury',
        ignored: 'Defendant',
        title: 'Blocks',
        company: 'Company',
        author: 'Creator\'s name',
        created: 'Creation date',
        actions: 'Controllers',
        remove: 'Unblock',
      },
      filters: {
        complaints: 'Complaints',
        bans: 'Blocks',
      },
      form: {
        title_create: 'New club inside the club',
        title_edit: 'Club editing',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create a club',
        failed_edition: 'Failed to edit club',
        name: { label: 'Name', placeholder: 'Car enthusiasts' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Links group', placeholder: 'Select' },
        image: { label: 'Image' },
      },
    },
    community_manager: {
      sidebar: {
        table_title: 'Community Managers',
      },
      table: {
        title: 'Community Managers',
        not_found: 'Community managers not found',
        fio: 'Name',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        avatar: 'Avatar',
        community_manager: 'Community',
        is_community_manager: 'Is the community manager',
        change_community_manager: 'Change manager',
        status: 'Status',
      },
      form: {
        title: 'Link to community manager',
        failed_action: 'Failed to link to community manager',
        submit_button: 'Save',
      },
      filters: {
        community_manager: 'Community Managers',
        name: 'Search by residents',
      },
      layout: {
        title: 'Link to community manager',
        sub_title: 'residents selected',
        placeholder: 'Select manager',
        loading: 'Loading',
        link: 'Unlink',
        unlink: 'Link',
      },
    },
    buddy_partner: {
      sidebar: {
        table_title: 'Buddy partners',
      },
      table: {
        title: 'Buddy partners',
        not_found: 'Buddy partners not found',
        fio: 'Name',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Date of birth',
        avatar: 'Avatar',
        buddy_partner: 'Buddy',
        is_buddy_partner: 'is buddy partner',
        change_buddy_partner: 'Change buddy partner',
        status: 'Status',
      },
      form: {
        title: 'Link to buddy partner',
        failed_action: 'Failed to link to buddy partner',
        submit_button: 'Save',
      },
      filters: {
        buddy_partner: 'Buddy partner',
        name: 'Search by residents',
      },
    },
    notification: {
      sidebar: {
        table_title: 'Mailings',
      },
      table: {
        title: 'Mailings',
        caption: 'Title',
        actions: 'Actions',
        edit_action: 'Edit',
        createAt: 'Date of creation',
        notifyAt: 'Date of mailing',
        published: 'Post',
      },
      filters: {
        create_new: 'New mailing',
      },
      form: {
        title: 'Mailing',
        cancel_button: 'Cancel',
        submit_button: 'Save',
        remove_button: 'Delete',
        confirm_delete: 'Are you sure you want to delete the mailing?',
        caption: {
          label: 'Message title',
          placeholder: 'Type text',
        },
        text: {
          label: 'Message body',
          placeholder: 'Type text',
        },
        date: {
          label: 'Date and time of sending',
        },
        status: 'Mailing status',
        draft: 'Draft',
        types: {
          all: 'All',
          users: 'List of residents',
          group: 'Role in the club',
        },
        users_list_type: 'Mailing recipients',
        users: {
          label: 'Select residents',
          placeholder: 'Start typing the name...',
        },
        groupId: {
          label: 'Role in the club',
          placeholder: 'Select Role',
        },
        deeplink: {
          label: 'Deeplink',
          placeholder: 'Select link',
        },
        events: {
          label: 'Event',
          placeholder: 'Select event',
        },
        posts: {
          label: 'Posts',
          placeholder: 'Select post',
        },
      },
      status: {
        draft: 'Draft',
        planned: 'Scheduled',
        completed: 'Сompleted',
        failed: 'Error',
      },
    },
    auth: {
      sidebar: {
        table_title: 'List of author codes',
      },
      table: {
        title: 'List of recent authorization codes',
        notfound: 'List is empty',

        fio: 'Full name',
        phone: 'Phone',
        code: 'Code',
        validTill: 'Valid until',
        activateAt: 'Login status',
      },
    },
    editor: {
      main: {
        header: {
          title: 'Profile Header',
          widgets: {
            avatar: 'Avatar',
            role: 'Role in the Club',
            badge: 'Badge',
            name: 'Title',
            group_btns: 'Button Group',
          },
          hints: {
            tags: 'In the mobile application, a maximum of 3 values assigned through the admin panel in the resident details are displayed.',
            group_btns: 'You can add a maximum of 4 buttons.',
          },
        },
        body: {
          title: 'Profile Fields',
        },
        footer: {
          title: 'Footer',
          forum_group: 'Forum-group',
          member_s: 'Member since',
          member_since: 'Member since January 01, 2000',
        },
      },
      modal: {
        title: {
          add: 'Add Field',
          edit: 'Edit Field',
        },
        select_field: 'Select Field Type',
        field_view: 'Field View',
        type: {
          text: 'Text',
          list_line: 'Short Single-Line Text',
          list_line_raw: 'Value without a Header',
          phone: 'Phone',
          company: 'Company',
          block_reason: 'Additional Information',
        },
        field_name: 'Name',
        data_type: {
          label: 'Data Type',
          text: 'String',
          link: 'Link',
          date_time: 'Date',
          number: 'Number',
        },
        hint_list_line_raw: 'Name is only visible in the form',
        hint_telegram: 'A single field to fill out, but it can be displayed in different places',
        icon: 'Icon',
        new_value: 'Enter New Value',
        show: 'Show All Fields',
        hide: 'Hide All Fields',
        long_text: 'Long Text',
        add_autocomplete: 'Add a list of selectable values when filling',
        allow_search: 'Participates in the search',
      },
      fields: {
        field_type: 'Field Type',
        label: 'Label',
        required_admin: 'Required for Admin Panel',
        required_app: 'Required for Mobile App',
        has_privacy: 'Has Privacy Settings',
        departments: 'Departments',
        text_section_widget: 'Text',
        list_line_widget: 'Short Single-Line Text',
        list_line_raw_action_widget: 'Value without a Header',
      },
      groups: {
        select_group_type: 'Select Group Type',
        group_type: 'Group Type',
        header: 'Header',
        types: {
          group: 'Group',
          companies: 'Community/Company Group',
          socials: 'Social Networks',
          contacts: 'Contacts',
        },
      },
      add: 'Add',
      cancel: 'Cancel',
      remove: 'Remove',
      apply: 'Apply',
      done: 'Done',
      add_field: 'Add Field',
      add_group: 'Add Group',
      save_exit: 'Save and Exit',
      exit_no_save: 'Exit Without Saving',
      saving: 'Saving...',
      errors: {
        create_field: 'Error in Field Creation',
        required_field: 'Required Field',
        name_first: 'Please enter a field name first',
        has_name: 'This value already exists in the list',
        fill_all: 'Fill in all fields',
        saving: 'Error saving data. Please try again.',
      },
      warnings: {
        remove_group_confirm: 'Are you sure you want to delete the group?',
        remove_field_confirm: 'Are you sure you want to delete the field?',
        save: 'Save changes?',
        attention: 'Attention!',
      },
      success: {
        save: 'Successfully saved',
      },
      phone_preview: {
        text: 'Text',
        privacy: 'Privacy',
        show: 'show',
        privacy_birthday: 'mouth and day only',
        action_btns: {
          thanks: 'Say Thanks',
          to_contact: 'To contacts',
          link: 'Link',
          call: 'Call',
          radar: 'Radar',
        },
        view_content: {
          name: 'Name Surname',
          badge: 'BADGE',
          team: 'CLUB TEAM',
          no_name: 'NO NAME',
        },
        edit_content: {
          name: { label: 'Your Name', placeholder: 'John Smith' },
          surname: { label: 'Your Surname' },
          badge: { label: 'Badge' },
        },
      },
    },
    appearance: {
      sidebar: {
        title: 'Appearance',
      },
      form: {
        title: 'Appearance',
        cancel_button: 'Back',
        submit_button: 'Save',
        tab1: 'General',
        tab2: 'Menu',
        name: { label: 'Community name' },
        logo: { label: 'Upload club logo', hint: 'Image should have a 1:1 aspect ratio. If the size is different, it may display incorrectly.' },
        menu: {
          label: 'Menu animation',
          default: 'No animation (default)',
        },
      },
    },
    cabinet_sections: {
      sidebar: {
        title: 'Cabinet sections',
      },
      filters: {
        create_new: 'Create',
      },
      table: {
        title: 'Cabinet sections',
        actions: '',
        edit_action: 'Edit',

        type: 'Type',
        name: 'Name',
        image: 'Image',
      },
      form: {
        title_create: 'New Link',
        title_edit: 'Edit Link',
        submit_button: 'Save',
        failed_edition: 'Failed to edit link',
        failed_creation: 'Failed to create link',
        type: { label: 'Link Type', placeholder: '' },
        name: { label: 'Name', placeholder: '' },
        description: { label: 'Description', placeholder: '' },
        url: { label: 'Link', placeholder: 'https://' },
        code: { label: 'Code', placeholder: '' },
        image: { label: 'Image', placeholder: '' },
      },
      deep_links: {
        sidebar: {
          title: 'Cabinet Sections',
        },
      },
    },
  },
  gaz: {
    import: {
      button: 'Import',
      title: 'Import residents',
      error: 'Error',
      label: 'Import',
      success_text: 'Successfully imported:',
    },
    interview: {
      sidebar: {
        table_title: 'Surveys',
        card_title: 'Survey',
        preview_title: 'Preview',
      },
      table: {
        title: 'Surveys',
        not_found: 'No surveys found',
        id: 'ID',
        name: 'Name',
        description: 'Description',
        createdAt: 'Creation date',
        actions: 'Actions',
        published: 'Publication',
        is_published: 'Published',
        is_not_published: 'Not published',
      },
      filters: {
        create_new: 'Create',
        submit: 'Submit',
        name: 'By name',
      },
      form: {
        title: 'Survey',
        preview_link: 'Preview',
        failed_creation: 'Failed to create a survey',
        submit_button: 'Save',
        cancel_button: 'Cancel',
        remove_button: 'Delete',
        edit_button: 'Edit',
        copy_button: 'Copy',
        name: { label: 'Name', placeholder: 'Event Evaluation' },
      },
      preview: {
        title: 'Preview',
        error: 'At least one of the answers to questions with two asterisks (**) must be non-zero.',
      },
      components: {
        condition: {
          question_select: {
            placeholder: 'Select step and question',
            step_label: 'Step',
            question_label: 'Add question',
          },
          add_condition_btn: 'Add condition',
          add_block_btn: 'Add block',
          confirm: {
            message: 'Do you really want to remove this condition?',
            title: 'Attention',
            confirm: 'Yes, remove',
            cancel: 'Cancel',
          },
        },
      },
      constants: {
        question_type: {
          stars: 'Rating',
          number: 'Number field',
          plain_answer: 'Text field',
          list: 'List',
          date: 'Date/Time',
          file: 'Download',
        },
        data_type: {
          string: 'Row',
          number: 'Number',
        },
        length_options: {
          short: 'Short text',
          long: 'Long text',
        },
        date_type: {
          date_time: 'Date',
          time: 'Time',
        },
        file_type: {
          photo: 'Select photo from gallery',
          camera: 'Take a photo',
          document: 'Upload from documents',
        },
      },
      layout: {
        name: 'Survey name',
        global_limit: 'Global limit',
        global_limit_hint: 'The limit has a higher priority than in the Event. When applied, the limit in the Event will be ignored.\n',
        description: 'Survey description',
        price: 'Additional cost',
        step: 'Step',
        question: 'Question',
        confirm_question: {
          message: 'Do you really want to delete this question?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_step: {
          message: 'Do you really want to delete this step?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_move: {
          message: 'If you move this step, ALL of the display conditions in the survey will be removed. Continue?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_move_question: {
          message: 'If you move this question, ALL of the display conditions in the survey will be removed. Continue?',
          title: 'Attention',
          confirm: 'Yes, move',
          cancel: 'Cancel',
        },
        confirm_price: {
          message: 'All cost adjustments will be reset. Continue?',
          title: 'Attention',
          confirm: 'Yes',
          cancel: 'Cancel',
        },
        modal_question: {
          title: 'How to add a question?',
          label: 'Question type',
          cancel: 'Cancel',
          ok: 'Select',
        },
        modal_price: {
          title: 'Cost adjustment',
          free: 'Free event',
          paid: 'Paid event',
          total_sum: 'Final price',
          base_price: 'Base price',
          question: 'Question',
          term: 'Summary',
        },
      },
      question: {
        title: 'Question',
        placeholder_question: 'Frame a question',
        placeholder_description: 'Type a brief description',
        label: 'Required question (≥0)',
        hint: {
          required_not_null: 'The respondent must answer, otherwise he will not be able to complete the survey. Automatically activated if "Cannot enter 0" is selected',
          required_null: 'A question with this condition cannot contain zero in the answer',
          not_null_sum: 'The sum of answers in a group of questions with this condition cannot be equal to zero',
        },
      },
      steps: {
        step_limit: {
          title: 'Step limit',
          hint: '<p>The maximum sum of responses from all respondents to&nbsp;questions of the type &laquo;Enter a number&raquo;, which are included in&nbsp;this step. Useful for managing tickets when the number of participants is limited.</p>\n'
            + '<p>Set the limit through the step limit if the final distribution of the total limit by&nbsp;ticket types is not important; otherwise, use the question limit.</p>\n'
            + '<p>The step limit cannot be changed if question limits within it are already set; the sum of already set question limits is displayed here for reference. If a limit needs to be set at&nbsp;the step level, first disable the limits of all questions.</p>',
        },
      },
      list_question: {
        confirm_delete: {
          message: 'Do you really want to delete this answer?',
          title: 'Attention',
          confirm: 'Yes, delete',
          cancel: 'Cancel',
        },
        title: 'Data type',
        self_answer: 'Resident can enter his answer',
        multi_select: 'Resident can choose several answers',
        total_limit: 'Total limit',
        total_limit_hint: 'The general limit for all participants of the event in relation to a specific issue.',
        option_placeholder: 'Type the answer',
        add_button: 'Add',
      },
      plain_answer_question: {
        title: 'Data type',
        text_length: 'Text length',
        int_limit: 'Limit',
        int_limit_hint: 'The limit limits the maximum number of the selected option (such as tickets) for a single user taking the survey.',
        total_limit: 'Total limit',
        total_limit_hint: 'The total limit limits the sum of values entered by all users relative to the selected question.',
      },
      file_question: {
        max_size: 'Maximum file size in MB, limit only for files',
        warning_1: 'Only one file can be attached to a single question using the method described above.',
        warning_2: 'Attention! Files are stored on the server for 1 year from the moment of addition',
      },
    },
    profile: {
      sidebar: {
        title: 'Content',
        table_title: 'Distribution by department',
        card_title: 'New subsection',
        group_title: 'Content',
      },
      table: {
        title: 'Distribution by department',
        not_found: 'Subsections not found',
        id: 'ID',
        name: 'Subsection',
        departments: 'Departments',
        actions: '',
        edit_action: 'Edit',
      },
      filters: {
        create_new: 'Create',
      },
      form: {
        title_create: 'New subsection',
        title_edit: 'Edit subsection',
        submit_button: 'Save',
        remove_button: 'Delete',
        failed_creation: 'Failed to create subsection',
        failed_edition: 'Failed to edit subsection',
      },
    },
    feedback: {
      sidebar: {
        table_title: 'Electronic reception',
      },
      table: {
        title: 'Electronic legal liaison',
        not_found: 'Not found',

        fio: 'Name',
        email: 'E-mail',
        text: 'Text of the resident\'s message',
        createdAt: 'Creation date',
        action_more: 'Learn more',
      },
      tabs: {
        suggestions: 'Suggestions for changes',
        breaking: 'Claims on violations',
      },
      filters: {
        text: 'Search by row',
      },
    },
    boss_word: {
      sidebar: {
        card_title: 'Word of the Chairman',
      },
      form: {
        submit_button: 'Save',
        cancel_button: 'Back',

        image: {
          label: 'Image',
          hint: 'Size up to 100 MB',
        },
        title: {
          label: 'Title',
          placeholder: 'type in the title text',
        },
        text: {
          label: 'Main text',
          placeholder: 'type text',
        },
      },
    },
    safety: {
      sidebar: {
        table_title: 'Occupational Health & Safety',
      },
      table: {
        title: 'Occupational Health & Safety',
        not_found: 'No suggestions found',

        fio: 'Name',
        email: 'E-mail',
        organization: 'Company',
        regulation_act: 'Local regulatory act',
        point: 'Item number of the act',
        text: 'Your suggestions',
        text_breaking: 'Description of violation',
        createdAt: 'Creation date',
        place: 'Place where the violation was detected',
        action_more: 'Learn more',
      },
      tabs: {
        suggestions: 'Suggestions for changes',
        breaking: 'Claims on violations',
      },
      filters: {
        text: 'Search by row',
      },
    },
  },
};

export type CustomTranslationSchema = typeof enCustom;
export default enCustom;
