import {
  reactive,
} from 'vue';
import {
  AutocompleteData,
} from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/components/form-autocomplete.vue';
import { withState } from './helper';
// type ValueOf<T> = T[keyof T];

type State = {
  associationAdditionalCodes: string[];
  residentAdditionalCodes: string[];
  removedResidentFieldIdsList: string[];
  autocompleteList: { field: string, values: AutocompleteData[] }[];
};
type Mutations = {
  setAssociation: (value: any) => void;
  setAssociationAdditionalCodes: (value: any) => void;
  setResidentAdditionalCodes: (value: any) => void;
  setRemovedResidentFieldIdsList: (value: any) => void;
  pushToAutocompleteList: (value: any) => void;
};
type Actions = {
};

// State
const state = reactive<State>({
  associationAdditionalCodes: [],
  autocompleteList: [],

  residentAdditionalCodes: [],
  removedResidentFieldIdsList: [],
});

// Mutation
const setAssociationAdditionalCodes = (value: any) => {
  state.associationAdditionalCodes = value;
};
const setResidentAdditionalCodes = (value: any) => {
  state.residentAdditionalCodes = value;
};

const setRemovedResidentFieldIdsList = (value: any) => {
  state.removedResidentFieldIdsList.push(value);
};

const pushToAutocompleteList = (value: { field: string, values: AutocompleteData[] }) => {
  const item = state.autocompleteList.find((a) => a.field === value.field);
  if (item) {
    item.field = value.field;
    item.values = value.values;
  } else {
    state.autocompleteList.push(value);
  }
};
// Actions

export default (): State & Mutations & Actions => withState(
  {
    setAssociationAdditionalCodes,
    setResidentAdditionalCodes,
    setRemovedResidentFieldIdsList,
    pushToAutocompleteList,
  },
  state,
);
