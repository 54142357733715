<template>
  <div class="body-module">
    <draggable
      :list="state"
      group="views"
      item-key="widgets"
      class="drag-area"
    >
      <template #item="{element, index}">
        <CollapseWrap>
          <template #title>
            <el-icon :size="15" class="item-icon">
              <BarsSVG />
            </el-icon>
            <EditableTextInput
              v-model="element.title"
            />
            <el-popconfirm
              :title="translateText('hubstr.editor.warnings.remove_group_confirm')"
              :width="300"
              :hide-after="0"
              @confirm="removeGroupSubmitHandler(index)"
            >
              <template #reference>
                <el-button
                  type="danger"
                  class="delete-btn"
                  :icon="Delete"
                  link
                  @click.stop
                />
              </template>
            </el-popconfirm>
          </template>
          <template #default>
            <component
              :is="setComponent(element.adminGroupType)"
              :key="element"
              :data="element"
              @edit="editWidgetModalHandler(index, $event)"
              @remove="removeWidgetSubmitHandler(index, $event)"
              @update:data="updateSocialOrContacts(index, $event)"
            />
            <el-button
              v-if="element.adminGroupType === ADMIN_GROUP_TYPES.GROUP"
              type="primary"
              plain
              style="margin-top: 10px"
              @click="addWidgetModalHandler(index)"
            >
              {{ translateText('hubstr.editor.add_field') }}
            </el-button>
          </template>
        </CollapseWrap>
      </template>
    </draggable>
    <el-button type="primary" plain @click="isOpenDialogAddGroup = true">
      {{ translateText('hubstr.editor.add_group') }}
    </el-button>
  </div>

  <AddGroupModal
    :key="isOpenDialogAddGroup"
    v-model="isOpenDialogAddGroup"
    :groups="state.map((i) => i.adminGroupType)"
    @change="addGroupSubmitHandler"
  />

  <AddWidgetModal
    :key="isOpenDialogCreate"
    v-model="isOpenDialogCreate"
    :data="data.body"
    :enable-block-reason-field="enableBlockReasonField"
    @update:formData="addWidgetSubmitHandler"
  />
  <EditWidgetModal
    :key="isOpenDialogEdit"
    v-model="isOpenDialogEdit"
    :data="formDataForEdit"
    :group-index="currentGroupIndex"
    @update:formData="editWidgetSubmitHandler"
  />
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable-es';
import {
  type Component, reactive, ref,
} from 'vue';
import { IViewWidgets, IViewWidgetsBody, ValueOf } from 'features/settings/editor/residents-editor/interfaces';
import {
  IWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import CollapseWrap from 'features/settings/editor/residents-editor/ui/components/left-side/components/collapse-wrap.vue';
import { ADMIN_GROUP_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/types/constants';
import { translate, useTranslate } from 'magner';
import AddWidgetModal from 'features/settings/editor/residents-editor/ui/components/left-side/modals/add-widget-modal.vue';
import EditWidgetModal from 'features/settings/editor/residents-editor/ui/components/left-side/modals/edit-widget-modal.vue';
import DefaultGroup from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/default-group.vue';
import CompaniesGroup from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/companies-group.vue';
import UnknownGroup from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/unknown-group.vue';
import EditableTextInput
  from 'features/settings/editor/residents-editor/ui/components/left-side/components/editable-text-input.vue';
import { Delete } from '@element-plus/icons-vue';
import BarsSVG from 'features/settings/editor/assets/icons/bars.svg';
import { updateAdditionalField } from 'features/settings/editor/residents-editor/requests';
import AddGroupModal from 'features/settings/editor/residents-editor/ui/components/left-side/modals/add-group-modal.vue';
import ContactsGroup
  from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/contacts-group.vue';
import SocialsGroup
  from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/socials-group.vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';

interface Props {
  data: IViewWidgets,
  enableBlockReasonField: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  enableBlockReasonField: false,
});

const emit = defineEmits(['edit', 'remove']);
const store = useEditorStore();
const state = reactive<IViewWidgetsBody[]>(props.data.body);

const isOpenDialogCreate = ref(false);
const isOpenDialogEdit = ref(false);
const isOpenDialogAddGroup = ref(false);
const currentGroupIndex = ref();
const formDataForEdit = ref();

const setComponent = (adminGroupType: ValueOf<typeof ADMIN_GROUP_TYPES>): Component => {
  switch (adminGroupType) {
    case ADMIN_GROUP_TYPES.GROUP:
      return DefaultGroup;
    case ADMIN_GROUP_TYPES.COMPANIES:
      return CompaniesGroup;
    case ADMIN_GROUP_TYPES.CONTACTS:
      return ContactsGroup;
    case ADMIN_GROUP_TYPES.SOCIALS:
      return SocialsGroup;
    default:
      return UnknownGroup;
  }
};

// Modal Actions
const addWidgetModalHandler = (groupIndex: number) => {
  currentGroupIndex.value = groupIndex;
  isOpenDialogCreate.value = true;
};
const editWidgetModalHandler = (groupIndex: number, formData: IWidget) => {
  currentGroupIndex.value = groupIndex;
  formDataForEdit.value = formData;
  isOpenDialogEdit.value = true;
};

// Widget Actions
const addGroupSubmitHandler = (newGroup: IViewWidgetsBody) => {
  state.push(newGroup);
};
const addWidgetSubmitHandler = (formData: IWidget) => {
  state[currentGroupIndex.value].widgets?.push(formData as never);
  isOpenDialogCreate.value = false;
};
const editWidgetSubmitHandler = (formData: IWidget) => {
  const widgetIndex = state[currentGroupIndex.value].widgets?.findIndex((w) => w.id === formData.id);
  state[currentGroupIndex.value].widgets?.splice(widgetIndex, 1, formData as never);
  isOpenDialogEdit.value = false;
};
const removeWidgetSubmitHandler = async (groupIndex: number, widgetId: string) => {
  const widget = state[groupIndex].widgets?.find((w) => w.id === widgetId) as IWidget;

  /**
   * Прежде чем отвязывать виджет от формы - надо сделать поле необязательным
   */
  if ('additions' in widget && !widget?.isNew && widget?.additions?.id) {
    store.setRemovedResidentFieldIdsList(widget?.additions?.id);
    // await updateAdditionalField({
    //   id: widget.additions.id,
    //   isUserRequired: false,
    //   isAdminRequired: false,
    // });

    console.log(store.removedResidentFieldIdsList.value);
  }

  const widgetIndex = state[groupIndex].widgets?.findIndex((w) => w.id === widgetId);
  state[groupIndex].widgets?.splice(widgetIndex, 1);
};
const removeGroupSubmitHandler = (groupIndex: number) => {
  Promise.all(state[groupIndex].widgets.map(async (w) => {
    await removeWidgetSubmitHandler(groupIndex, w.id);
  })).then(() => {
    state.splice(groupIndex, 1);
  });
};

const updateSocialOrContacts = (indexGroup: number, event: any) => {
  /**
   * Присвоение пустого массива или другого массива (event) ведет к станной работе реактивности
   * Кароч все ломается, поэтому очищаем и заполняем заново
   */
  while (state[indexGroup].widgets.length > 0) {
    state[indexGroup].widgets.pop();
  }
  event.map((w: any) => {
    if (!state[indexGroup].widgets.map((wi) => wi.name).includes(w.name)) {
      state[indexGroup].widgets.push(w);
    }

    return w;
  });
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style scoped>
.drag-area {
  background: white;
}
.item-icon {
  margin-right: 10px;
  color: var(--el-text-color-secondary);
  cursor: move;
}
.delete-btn {
  width: auto !important;
  margin-bottom: 0 !important;
  margin-left: 5px !important;
  margin-right: 15px;
}
</style>
