<template>
  <draggable
    :key="state.widgets"
    :list="state.widgets"
    :group="state.widgets"
    item-key="id"
    class="group"
  >
    <template #item="{element}">
      <Field
        :key="AdditionsList"
        class="field"
        :data="element"
        @remove="removeField"
        @edit="editField(element)"
      />
    </template>
  </draggable>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive } from 'vue';
import Draggable from 'vuedraggable-es';
import { Body, WidgetBody } from 'features/settings/mobile-editor/interfaces';
import Field from 'features/settings/mobile-editor/components/left-side/components/body/fields/field.vue';
import { v4 as uuidv4 } from 'uuid';
import { AdditionsList } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'Group',
  components: {
    Field,
    Draggable,
  },
  props: {
    data: {
      type: Object as PropType<Body>,
      required: true,
    },
  },
  emits: ['remove', 'edit'],
  setup (props, { emit }) {
    const state = reactive<Body>(props.data);
    state.widgets.map((group: WidgetBody) => group.id = uuidv4());

    const removeField = (id: string) => {
      emit('remove', id);
    };
    const editField = (fieldData: string) => {
      emit('edit', fieldData);
    };

    return {
      state,
      removeField,
      editField,

      AdditionsList,
    };
  },
});
</script>

<style lang="scss" scoped>
.group {
  padding: 0 5px;

  &__item {
    box-shadow: var(--el-box-shadow-lighter);
    padding: 10px;
    border-radius: var(--el-card-border-radius);
    background: #ffffff;
  }
}
</style>
