// @ts-ignore
import { cardPageController, translate } from 'magner';
import EditMobileProfileLayout from 'features/settings/mobile-editor/layout/index.vue';
import {
  mobileProfileGet, mobileProfileUpdate,
} from './requests';
import {
  APP_CILICIA, APP_COMMON, APP_ID, META_APP, SUB_DOMEN,
} from '~/constants';

const mobileEditorConfig = cardPageController({
  getRequest: mobileProfileGet,
  createRequest: '' as never,
  updateRequest: mobileProfileUpdate,
  deleteRequest: '' as never,

  header: {
    title: translate('hubstr.mobile_profile.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'editMobileProfileLayout',
        component: () => EditMobileProfileLayout,
        props: {},
      },
    ],
  },
});

if (APP_ID === APP_COMMON || APP_ID === META_APP) {
  mobileEditorConfig.header.tabs = [
    {
      label: 'Резиденты',
      active: true,
      link: { name: 'mobile-editor' },
    },
    {
      label: 'Ассоциации',
      active: false,
      link: { name: 'associations-editor' },
    },
  ];
}

export default mobileEditorConfig;
