import { cardPageController, translate } from 'magner';
import { SportsSectionFull } from 'features/profile/sports/sections/types';
import {
  sportsSectionCreate,
  sportsSectionDelete,
  sportsSectionGet,
  sportsSectionUpdate,
} from 'features/profile/sports/sections/requests';

const sportsSectionConfig = cardPageController<SportsSectionFull>({
  getRequest: sportsSectionGet,
  createRequest: sportsSectionCreate,
  updateRequest: sportsSectionUpdate,
  deleteRequest: sportsSectionDelete,

  header: {
    title: translate('gaz.sports.sections.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.sports.sections.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('gaz.sports.sections.form.remove_button'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('gaz.sports.sections.form.name.label'),
          props: {
            required: true,
            placeholder: translate('gaz.sports.sections.form.name.placeholder'),
            maxLength: 50,
            showLetterLimit: true,
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'textarea',
          name: 'description',
          label: translate('gaz.sports.sections.form.description.label'),
          props: {
            required: true,
            placeholder: translate('gaz.sports.sections.form.description.placeholder'),
            maxLength: 3000,
            showLetterLimit: true,
            rows: 5,
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'dropzone',
          name: 'image',
          dataType: 'number',
          label: translate('gaz.sports.sections.form.image.label'),
          props: {
            valueKey: 'id',
            srcKey: 'image',
            removable: true,
          },
        },
      ],
    },
  },
});

export default sportsSectionConfig;
