import { ADMIN_FIELD_TYPES, ADMIN_DATA_TYPE } from 'features/settings/mobile-editor/factories/constants';
import { AdditionalField, WidgetBody } from 'features/settings/mobile-editor/interfaces';

export default class AdminFieldEntity {
  widget: WidgetBody;
  additional: AdditionalField;
  translates: Record<string, string>;
  outField: {
    type: typeof ADMIN_FIELD_TYPES[keyof typeof ADMIN_FIELD_TYPES] | 'textarea' | '',
    required: boolean,
    name: string,
    label: string,
    placeholder: string,
    dataType: typeof ADMIN_DATA_TYPE[keyof typeof ADMIN_DATA_TYPE] | '',
    validation?: any,
    remoteMethodSettings?: any,
    propsFormat?: string,
    propsType?: string,
    propsMask?: any,
  };

  constructor (widget: WidgetBody, additional: AdditionalField, translates: Record<string, string>) {
    this.widget = widget;
    this.additional = additional;
    this.translates = translates;

    this.outField = {
      type: '',
      required: false,
      name: '',
      label: '',
      placeholder: '',
      dataType: '',
      validation: [],
    };
  }

  setType () {
    if (this.widget.hasAutoComplete) {
      this.outField.type = 'select';
    } else if (this.additional.isMultiline) {
      this.outField.type = 'textarea';
    } else {
      this.outField.type = ADMIN_FIELD_TYPES[this.additional.variableType];
    }
  }
  setRequired () {
    this.outField.required = this.additional.isAdminRequired;
  }
  setName () {
    this.outField.name = this.additional.code;
  }
  setLabel () {
    this.outField.label = this.additional.description || this.translates[this.additional.code] || `lang.${this.additional.code}`;
  }
  setPlaceholder () {
    switch (this.additional.variableType) {
      case 'text':
        this.outField.placeholder = 'Введите информацию';
        break;
      case 'number':
        this.outField.placeholder = '10000';
        break;
      case 'link':
        this.outField.placeholder = 'https://www.site.com';
        break;
      case 'datetime':
        this.outField.placeholder = '01.03.2000';
        break;
      default:
        this.outField.placeholder = 'Введите информацию';
    }
  }
  setValidator () {
    if (this.additional.isAdminRequired) {
      this.outField.validation.push({
        type: 'empty-required',
        trigger: 'blur',
      });
    }
    if (this.additional.variableType === 'number') {
      this.outField.validation.push({
        type: 'is-integer',
        trigger: 'blur',
      });
    }
  }
  setRemoteMethodSettings () {
    if (this.widget.hasAutoComplete) {
      this.outField.remoteMethodSettings = {
        filterable: true,
        allowCreate: true,
        url: '/api/form/autocomplete',
        parameter: 'result',
        type: this.additional.code,
        multiple: true,
        // valueKey: 'code',
        // labelKey: 'title',
      };
    }
  }
  setDataType () {
    this.outField.dataType = ADMIN_DATA_TYPE[this.additional.variableType];
    if (this.additional.variableType === 'datetime') {
      this.outField.propsFormat = 'DD.MM.YYYY';
      this.outField.propsType = 'date';
    }
  }

  setMask () {
    if (this.additional.variableType === 'number') {
      this.outField.propsMask = {
        mask: '#*',
      };
    }
  }

  create () {
    this.setType();
    this.setRequired();
    this.setName();
    this.setLabel();
    this.setPlaceholder();
    this.setValidator();
    this.setRemoteMethodSettings();
    this.setDataType();
    this.setMask();

    return this.outField;
  }
}
