import { ComputedRef, reactive } from 'vue';
import { Department } from 'features/residents/types/hubstr';
import { DefaultLogo } from 'features/settings/appearance/interfaces';
import { PreferenceItem } from 'features/settings/preferences/interfaces';
import { withState } from '~/store/helper';

type ValueOf<T> = T[keyof T];

type State = {
  departments: Department[],
  defaultLogo: DefaultLogo | null,
  preferences: PreferenceItem[],
}
type Mutations = {
  setDepartments: (value: Department[]) => void
  setAppLogo: (value: DefaultLogo) => void
}
type Actions = {
}

// State
const state = reactive<State>({
  departments: [],
  defaultLogo: null,
  preferences: [],
});

// Mutation
const setDepartments = (value: Department[]) => state.departments = value;
const setAppLogo = (value: DefaultLogo) => state.defaultLogo = value;
const setPreferences = (value: PreferenceItem[]) => state.preferences = value;

// Actions

export default (): State & Mutations & Actions => withState({
  setDepartments,
  setAppLogo,
  setPreferences,
}, state);
