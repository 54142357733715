<template>
  <div>
    <h4>ФИО:</h4>
    <p>{{ s.fio }}</p>

    <el-divider />

    <h4>E-mail:</h4>
    <p>{{ s.email }}</p>

    <el-divider />

    <h4>Организация:</h4>
    <p>{{ s.organization }}</p>

    <el-divider />

    <h4>Локальный нормативный акт:</h4>
    <p>{{ s.regulation_act }}</p>

    <el-divider />

    <h4>Номер пункта акта:</h4>
    <p>{{ s.point }}</p>

    <el-divider />

    <h4>Ваши предложения:</h4>
    <p>{{ s.text }}</p>

    <el-divider />

    <h4>Дата создания:</h4>
    <p>{{ s.formattedDate }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ISuggestion } from 'features/profile/safety/suggestions/types';
import { formatDate } from '~/utils/format-date';

export default defineComponent({
  name: 'SuggestionDetail',
  props: {
    suggestion: {
      type: Object as PropType<ISuggestion>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props) {
    return {
      s: {
        ...props.suggestion,
        formattedDate: formatDate(props.suggestion.createdAt),
      },
    };
  },
});
</script>
