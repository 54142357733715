<template>
  <el-collapse
    class="collapse-wrapper"
  >
    <el-collapse-item>
      <template #title>
        <slot
          name="title"
        />
      </template>
      <div class="collapse-content">
        <slot />
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CollapseWrap',
});
</script>

<style lang="scss">
.collapse-wrapper {
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;
  background: #ffffff;
}
.collapse-wrapper {
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__header {
    border-bottom: none !important;
  }
}
.collapse-content {
  padding: 5px;
}
</style>
