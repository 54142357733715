import {
  filtersFormController, tableController, tablePageController, translate,
} from 'magner';
import { communityRead } from 'features/residents/communities/requests';
import { Community } from 'features/residents/communities/types';

const communitiesConfig = tablePageController<Community>({
  header: {
    title: translate('lc.resident.communities.table.title'),
    tabs: [
      {
        label: translate('hubstr.resident.tabs.resident'),
        link: { name: 'resident' },
        active: false,
      },
      {
        label: translate('lc.resident.tabs.communities'),
        link: { name: 'resident-communities' },
        active: true,
      },
    ],
    actions: [{
      type: 'link',
      to: (route) => ({ name: 'community', params: { id: 'new' }, query: { resident: route.params.id } }),
      props: {
        type: 'primary',
        text: translate('hubstr.resident.tabs.create'),
      },
    }],
  },
  request: communityRead,
  table: tableController<Community>({
    emptyText: translate('hubstr.resident.companies.table.not_found'),
    rowLink: (row) => {
      const residentId = window.location.pathname.replace('/residents/', '').replace(/\/communities.*/, '');
      return { name: 'community', params: { id: row.id }, query: { resident: residentId } };
    },
    columns: [
      {
        prop: 'logo',
        label: translate('hubstr.resident.companies.table.logo'),
        view: {
          // @ts-ignore
          type: 'image',
          // @ts-ignore
          nestedKey: 'big',
        },
      },
      {
        prop: 'name',
        label: translate('hubstr.resident.companies.table.name'),
        width: 200,
      },
      {
        prop: 'roleInto',
        label: translate('lc.resident.form.communities.roleInto.label'),
      },
    ],
  }),
  filters: filtersFormController<Community>({
    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {},
    sort: {},

    layout: [],
  }),
});

export default communitiesConfig;
