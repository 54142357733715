import { tablePageController, translate } from 'magner';
import { residentRead } from 'features/residents/requests/udschool';
import residentsTable from 'features/residents/table/udschool';
import residentsFilters from 'features/residents/filters/hubstr';
import { Resident } from 'features/residents/types/udschool';

const residentsConfig = tablePageController<Resident>({
  header: {
    title: translate('hubstr.resident.table.title'),
  },
  request: residentRead,
  table: residentsTable as any,
  filters: residentsFilters,
});

export default residentsConfig;
