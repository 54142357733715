import { request } from '~/utils/request';

interface DefaultLogo {
  big: string
  thumb_100: string
  upload_down_scale: string
}
interface AppInfo {
  id: string
  appId: string
  title: string
  isMeta: string
  domain: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  defaultLogo: DefaultLogo
}

const appInfoRequest = request.custom(async ({ api, parseError }) => {
  const res = await api.get<{ data: AppInfo[] }>('/api/admin/apps/list');

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data[0],
  };
});

export default appInfoRequest;
