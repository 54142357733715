import { AppItem, PreferenceItem, Version } from 'features/settings/preferences/interfaces';
import { request } from '~/utils/request';

interface GetReference {
  data: {
    list: Array<PreferenceItem>
  }
}

export const preferenceGet = request.card(async ({ api, appStore }) => {
  try {
    const [resPreference, resVersions, resApps] = await Promise.all([
      api.get<GetReference>('/api/admin/setting/list?page=1&count=150'),
      api.get<{ version: Version | null }>('/api/admin/client-version'),
      api.get<{ data: AppItem[] }>('/api/admin/apps/list'),
    ]);

    appStore.setPreferences(resPreference.data?.data.list);

    if (resPreference.data && resVersions.data && resApps.data) {
      const responseList = resPreference.data.data?.list ?? [];
      const preferencesList: Record<string, Record<string, PreferenceItem>> = {};
      responseList.map((item) => {
        preferencesList[item.code] = item;

        return item;
      });
      const defaultValue = { value: null };

      const out = {
        ...preferencesList,
        version: resVersions.data.version?.minVersion || '1.0.00',

        requireUpdate: resVersions.data.version?.requireUpdate || false,

        type: resVersions.data.version?.typeUpdate || 'SOFT',

        iosLink: resApps.data.data[0].iosLink || null,
        androidLink: resApps.data.data[0].androidLink || null,
        huaweiLink: resApps.data.data[0].huaweiLink || null,
        ruStoreLink: resApps.data.data[0].ruStoreLink || null,
      } as any;

      return {
        data: {
          preferenceLayout: out,
        },
      };
    }
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const preferenceUpdate = request.custom(async ({ api, data, parseError }) => {
  const {
    version, type, requireUpdate,
    iosLink, androidLink, huaweiLink, ruStoreLink,
    ...preferenceBody
  } = data.data.preferenceLayout;

  const [preference, versions, appLinks] = await Promise.all([
    api.put('/api/admin/setting/multi', { settings: preferenceBody }),
    api.post(`/api/admin/client-version/update?version=${version}&requireUpdate=${requireUpdate}&type=${type}`, {}),
    api.patch('/api/admin/apps/update_partial', {
      iosLink: iosLink || null, androidLink: androidLink || null, huaweiLink: huaweiLink || null, ruStoreLink: ruStoreLink || null,
    }),
  ]);

  if (preference.error) {
    return { error: parseError(preference.error) };
  }
  if (versions.error) {
    return { error: parseError(versions.error) };
  }
  if (appLinks.error) {
    return { error: parseError(appLinks.error) };
  }

  return { data: 'ok' };
});
