import { cardPageController, translate } from 'magner';
import {
  eventCategoryCreate, eventCategoryGet, eventCategoryUpdate, eventCategoryDelete,
} from 'features/settings/event-categories/requests/hubstr';

const eventCategoryConfig = cardPageController({
  getRequest: eventCategoryGet,
  createRequest: eventCategoryCreate,
  updateRequest: eventCategoryUpdate,
  deleteRequest: eventCategoryDelete,

  header: {
    title: translate('hubstr.event_category.form.title'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.event_category.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('hubstr.event_category.form.remove_button'),
        },
      },
    ],
    layout: [
      {
        type: 'input',
        name: 'name',
        label: translate('hubstr.event_category.form.name.label'),
        props: {
          required: true,
          placeholder: translate('hubstr.event_category.form.name.placeholder'),
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      },
    ],
  },
});

export default eventCategoryConfig;
