import { autocompleteRole } from 'features/residents/requests/hubstr';
import { UserRoles } from 'features/residents/types/hubstr';

/**
 * Возвращяет список роле в виде [{ 'roleId': 'ROLE_NAME }];
 * */
export const getRolesIdName = async (): Promise<Record<string, UserRoles>> => {
  const rolesList = {};
  const autocompleteRoles = await autocompleteRole('');

  autocompleteRoles.data?.rows.forEach((obj) => {
    rolesList[obj.id] = obj.name;
  });

  return rolesList;
};
