import { cardPageController, translate } from 'magner';
import {
  addressGet, addressCreate, addressUpdate, addressDelete,
} from 'features/addresses/requests/hubstr';
import type { Address } from 'features/addresses/types/hubstr';
import {
  actionsButtons, addressField, descriptionField, nameField,
} from 'features/addresses/address/general';

const addressConfig = cardPageController<Address>({
  header: {
    title: translate('hubstr.address.form.title'),
  },

  getRequest: addressGet,
  createRequest: addressCreate,
  updateRequest: addressUpdate,
  deleteRequest: addressDelete,

  confirmDelete: true,

  form: {
    actions: actionsButtons,

    layout: {
      type: 'column',
      props: {},
      fields: [
        nameField,
        addressField,
        descriptionField,
      ],
    },
  },
});

export default addressConfig;
