import { cardPageController, translate } from 'magner';

import { BlankFull } from 'features/profile/blanks/types';
import {
  blankCreate, blankDelete, blankGet, blankUpdate,
} from 'features/profile/blanks/requests';

const blankConfig = cardPageController<BlankFull>({
  getRequest: blankGet,
  createRequest: blankCreate,
  updateRequest: blankUpdate,
  deleteRequest: blankDelete,

  header: {
    title: translate('gaz.blanks.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.blanks.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('gaz.blanks.form.remove_button'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('gaz.blanks.form.name.label'),
          props: {
            required: true,
            placeholder: translate('gaz.blanks.form.name.placeholder'),
            maxLength: 50,
            showLetterLimit: true,
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },

        {
          type: 'input',
          name: 'url',
          label: translate('gaz.blanks.form.url.label'),
          props: {
            required: true,
            placeholder: translate('gaz.blanks.form.url.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }, {
            type: 'url' as 'empty',
            trigger: 'blur',
          }],
        },
      ],
    },
  },
});

export default blankConfig;
