/**
 * Типы вопросов
 */
export const QUESTION_TYPE = {
  STARS: 'type_stars',
  PLAIN_ANSWER: 'type_plain_answer',
  LIST: 'type_list',
  DATE: 'type_date',
  FILE: 'type_file',

  PLAIN_ANSWER_NUMBER: 'type_plain_answer_number',
};
/**
 * Список типов вопросов для селекта
 */
export const QUESTION_TYPE_OPTIONS = [
  {
    id: QUESTION_TYPE.STARS,
    name: 'gaz.interview.constants.question_type.stars',
  },
  {
    id: QUESTION_TYPE.PLAIN_ANSWER_NUMBER,
    name: 'gaz.interview.constants.question_type.number',
  },
  {
    id: QUESTION_TYPE.PLAIN_ANSWER,
    name: 'gaz.interview.constants.question_type.plain_answer',
  },
  {
    id: QUESTION_TYPE.LIST,
    name: 'gaz.interview.constants.question_type.list',
  },
  {
    id: QUESTION_TYPE.DATE,
    name: 'gaz.interview.constants.question_type.date',
  },
  {
    id: QUESTION_TYPE.FILE,
    name: 'gaz.interview.constants.question_type.file',
  },
];
/**
 * Типы данных (строка, число)
 */
export const DATA_TYPE = {
  STRING: 'type_string',
  NUMBER: 'type_int',
};
/**
 * Список типов данных (строка, число) для селека
 */
export const DATA_TYPE_OPTIONS = [
  {
    label: 'gaz.interview.constants.data_type.string',
    value: DATA_TYPE.STRING,
  },
  {
    label: 'gaz.interview.constants.data_type.number',
    value: DATA_TYPE.NUMBER,
  },
];
/**
 * Типы длинны текстовых полей
 */
export const TEXT_LENGTH = {
  SHORT: 'short',
  LONG: 'long',
};
/**
 * Список типов длинны
 */
export const TEXT_LENGTH_OPTIONS = [
  {
    label: 'gaz.interview.constants.length_options.short',
    value: TEXT_LENGTH.SHORT,
  },
  {
    label: 'gaz.interview.constants.length_options.long',
    value: TEXT_LENGTH.LONG,
  },
];
/**
 * Типы данных (дата, время)
 */
export const DATE_DATA_TYPE = {
  DATETIME: 'type_datetime',
  TIME: 'type_time',
};
/**
 * Список типов данных (дата, время) для селекта
 */
export const DATE_DATA_TYPE_OPTIONS = [
  {
    label: 'gaz.interview.constants.date_type.date_time',
    value: DATE_DATA_TYPE.DATETIME,
  },
  {
    label: 'gaz.interview.constants.date_type.time',
    value: DATE_DATA_TYPE.TIME,
  },
];
/**
 * Тип данных для загружаемых файлов
 */
export const FILE_TYPE = {
  PHOTO: 'photoGallery',
  DOCUMENT: 'document',
  AUDIO: 'audio',
  VIDEO: 'video',
  CAMERA: 'photoCamera',
};
export const FILE_TYPE_OPTIONS = [
  {
    label: 'gaz.interview.constants.file_type.camera',
    value: FILE_TYPE.CAMERA,
  },
  {
    label: 'gaz.interview.constants.file_type.photo',
    value: FILE_TYPE.PHOTO,
  },
  {
    label: 'gaz.interview.constants.file_type.document',
    value: FILE_TYPE.DOCUMENT,
  },
];
