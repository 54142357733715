<template>
  <div class="loyalty__layout">
    <el-tree
      :data="state.data"
      :expand-on-click-node="false"
      node-key="id"
      default-expand-all
    >
      <template #default="{ node, data }">
        <div class="loyalty__tree-node">
          <p class="loyalty__tree-node-label">{{ node.label }}</p>
          <div style="display: inline-block;">
            <el-button v-if="data.type === NODE_TYPES.FOLDER" style="width: auto;margin: 0;" @click="initAddFolder(data)">
              {{ translateText('gaz.loyalty.form.add_folder') }}
            </el-button>
            <el-button v-if="data.type === NODE_TYPES.FOLDER" style="width: auto;margin: 0 0 0 8px" @click="initAddFile(data)">
              {{ translateText('gaz.loyalty.form.add_file') }}
            </el-button>

            <el-button
              v-if="data.id !== 'root'"
              style="width: auto;margin: 0 0 0 8px"
              type="primary"
              plain
              @click="data.type === NODE_TYPES.FOLDER ? initEditFolder(data) : initEditFile(data)"
            >
              {{ translateText('gaz.loyalty.form.edit_button') }}
            </el-button>

            <el-button
              v-if="data.id !== 'root'"
              style="width: auto;margin: 0 0 0 8px"
              type="danger"
              :icon="Delete"
              @click="removeNode(node)"
            />
          </div>
        </div>
      </template>
    </el-tree>
  </div>

  <el-dialog
    v-if="state.folder.modalVisible"
    v-model="state.folder.modalVisible"
    :title="state.folder.isEdit
      ? translateText('gaz.loyalty.form.modals.edit_folder.title')
      : translateText('gaz.loyalty.form.modals.add_folder.title')"
  >
    <el-input v-model="state.folder.newFolderName" placeholder="Введите название каталога" />

    <div style="margin-top: 20px;">
      <magner-form-dropzone v-model="state.folder.image" :field="dropZoneField" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button style="width: auto;margin-right: 10px;" @click="state.folder.modalVisible = false">
          {{ translateText('gaz.loyalty.form.modals.add_folder.cancel') }}
        </el-button>
        <el-button
          :disabled="state.folder.newFolderName.length === 0"
          style="width: auto;"
          type="primary"
          @click="saveFolder"
        >
          {{ translateText('gaz.loyalty.form.modals.add_folder.ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="state.file.modalVisible"
    v-model="state.file.modalVisible"
    :title="state.file.isEdit
      ? translateText('gaz.loyalty.form.modals.edit_file.title')
      : translateText('gaz.loyalty.form.modals.add_file.title')"
  >
    <el-input v-model="state.file.newFileName" placeholder="Введите название файла" />

    <div style="margin-top: 20px;">
      <magner-form-dropzone v-model="state.file.image" :field="dropZoneField" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button style="width: auto;margin-right: 10px;" @click="state.file.modalVisible = false">
          {{ translateText('gaz.loyalty.form.modals.add_file.cancel') }}
        </el-button>
        <el-button
          :disabled="state.file.newFileName.length === 0"
          style="width: auto;"
          type="primary"
          @click="saveFile"
        >
          {{ translateText('gaz.loyalty.form.modals.add_file.ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import {
  reactive, defineProps, defineEmits, PropType, onMounted, watch, ref,
} from 'vue';
import {
  translate, useTranslate, MagnerFormDropzone, magnerConfirm,
} from 'magner';
import { fileUpload } from 'features/common/file-request';
import { v4 as uuidv4 } from 'uuid';
import {
  Delete,
} from '@element-plus/icons-vue';

const NODE_TYPES = {
  FOLDER: 'folder_widget',
  FILE: 'external_document_widget',
};
interface TreeNode {
  id: string;
  label: string;
  type: typeof NODE_TYPES[keyof typeof NODE_TYPES];
  image: any;
  children?: TreeNode[];
}

const dropZoneField = {
  type: 'dropzone',
  name: 'image',
  dataType: 'number',
  label: translate('gaz.loyalty.form.image.label'),
  props: {
    valueKey: 'id',
    srcKey: 'image',
    removable: true,
  },
};

const emits = defineEmits(['update:modelValue']);

const state: {
  id: string;
  code: string;
  title: string;
  folder: {
    isEdit: boolean;
    modalVisible: boolean;
    context: TreeNode;
    newFolderName: string;
    newFolderId: string;
    image: any;
  },
  file: {
    isEdit: boolean;
    modalVisible: boolean;
    context: TreeNode;
    newFileName: string;
    newFileId: string;
    image: any;
  },
  data: TreeNode[];
} = reactive({
  id: '',
  code: '',
  title: '',
  folder: {
    isEdit: false,
    modalVisible: false,
    context: {} as any,
    newFolderName: '',
    newFolderId: '',
    image: null,
  },
  file: {
    isEdit: false,
    modalVisible: false,
    context: {} as any,
    newFileName: '',
    newFileId: '',
    image: null,
  },
  data: [{
    id: 'root',
    label: 'Корневой каталог',
    type: NODE_TYPES.FOLDER,
    image: null,
    children: [],
  }],
});

const props = defineProps({
  modelValue: {
    type: Object as PropType<any>,
    required: true,
  },
});

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
const uploadFile = async (data: any, id: string) => {
  const fileUploadRes = await fileUpload(
    id as string,
    (data as File[])[0],
    'view',
    'view_file',
    'default',
    true,
  );

  if (fileUploadRes.error) {
    return false;
  }

  return fileUploadRes.data;
};
const parseJson = (node) => {
  switch (node.type) {
    case 'separated_group_widget': {
      return node.widgets.map((w) => parseJson(w));
    }
    case NODE_TYPES.FOLDER: {
      let widgets = node.widgets;
      if (node.widgets.length === 1 && node.widgets[0].type === 'separated_group_widget') {
        widgets = node.widgets[0].widgets;
      }

      return {
        id: uuidv4(),
        label: node.name,
        image: { image: node.image } ?? null,
        type: NODE_TYPES.FOLDER,
        children: widgets.map((w) => parseJson(w)) ?? [],
      };
    }
    case NODE_TYPES.FILE: {
      return {
        id: uuidv4(),
        label: node.name,
        image: { image: node.link } ?? null,
        type: NODE_TYPES.FILE,
      };
    }
    default: return '';
  }
};
/**
 *
 */
const convertToJson = (node: TreeNode) => {
  if (node.type === NODE_TYPES.FILE) {
    return {
      type: NODE_TYPES.FILE,
      icon: 'folder_document',
      name: node.label,
      link: node.image?.image ?? '',
    };
  }

  return {
    type: NODE_TYPES.FOLDER,
    name: node.label,
    image: node.image?.image ?? '',
    widgets: [{
      type: 'separated_group_widget',
      widgets: node.children?.map((c: TreeNode) => convertToJson(c)) ?? [],
    }],
  };
};
/**
 * Метод формирует JSON-массив и отдает его наверх
 */
const emitData = () => {
  const out = {
    id: state.id,
    code: state.code,
    title: state.title,
    widgets: {
      header: [],
      body: [
        {
          text: 'Для всех членов Профсоюза доступны эксклюзивные',
          type: 'text_widget',
          fontWeight: 400,
          fontSize: 16,
        },
        {
          type: 'vertical_gap_widget',
          size: 20,
        },
        {
          type: 'separated_group_widget',
          widgets: state.data[0].children?.map((c) => convertToJson(c)),
        },
      ],
      footer: [],
      actions: [],
    },
  };
  emits('update:modelValue', out);
};
/**
 * Метод открывает модалку добавления каталога
 */
const initAddFolder = (data: any) => {
  state.folder.isEdit = false;
  state.folder.newFolderId = uuidv4();
  state.folder.context = data;
  state.folder.newFolderName = '';
  state.folder.image = null;
  state.folder.modalVisible = true;
};
const initEditFolder = (data: any) => {
  state.folder.isEdit = true;
  state.folder.newFolderId = data.id;
  state.folder.context = data;
  state.folder.newFolderName = data.label;
  state.folder.image = { ...data.image };
  state.folder.modalVisible = true;
};
/**
 * Метод добавляет подкаталог в указанный каталог (указывается в initAddFolder)
 */
const saveFolder = async () => {
  /**
   * если это массив - то был выбран новый файл и его надо загрузить
   */
  if (state.folder.image && Array.isArray(state.folder.image)) {
    const resImage = await uploadFile(state.folder.image, state.folder.newFolderId);
    state.folder.image = { ...resImage };
  }

  if (state.folder.isEdit) {
    state.folder.context.label = state.folder.newFolderName;
    state.folder.context.image = { ...state.folder.image };
  } else {
    state.folder.context.children?.push({
      id: state.folder.newFolderId,
      label: state.folder.newFolderName,
      type: NODE_TYPES.FOLDER,
      image: { ...state.folder.image },
      children: [],
    });
  }

  state.folder.modalVisible = false;
  emitData();
};
/**
 * Метод открывает модалку добавления файла
 */
const initAddFile = (data: any) => {
  state.file.isEdit = false;
  state.file.newFileId = uuidv4();
  state.file.context = data;
  state.file.newFileName = '';
  state.file.image = null;
  state.file.modalVisible = true;
};
const initEditFile = (data: any) => {
  state.file.isEdit = true;
  state.file.newFileId = data.id;
  state.file.context = data;
  state.file.newFileName = data.label;
  state.file.image = { ...data.image };
  state.file.modalVisible = true;
};
/**
 * Метод добавляет файл в указанный каталог
 */
const saveFile = async () => {
  /**
   * если это массив - то был выбран новый файл и его надо загрузить
   */
  if (state.file.image && Array.isArray(state.file.image)) {
    const resImage = await uploadFile(state.file.image, state.file.newFileId);
    state.file.image = { ...resImage };
  }

  if (state.file.isEdit) {
    state.file.context.label = state.file.newFileName;
    state.file.context.image = { ...state.file.image };
  } else {
    state.file.context.children?.push({
      id: state.file.newFileId,
      label: state.file.newFileName,
      type: NODE_TYPES.FILE,
      image: { ...state.file.image },
    });
  }

  state.file.modalVisible = false;
  emitData();
};
/**
 * Метод удаляет
 */
const removeNode = async (node: any) => {
  magnerConfirm({
    message: customT(node.data.type === NODE_TYPES.FOLDER
      ? translate('gaz.loyalty.form.confirm.folder.message')
      : translate('gaz.loyalty.form.confirm.file.message')),
    title: customT(translate('gaz.loyalty.form.confirm.folder.title')),
    confirmButtonText: customT(translate('gaz.loyalty.form.confirm.folder.confirm')),
    cancelButtonText: customT(translate('gaz.loyalty.form.confirm.folder.cancel')),
    type: 'warning',
  }).then(() => {
    node.parent.data.children = [...node.parent.data.children.filter((f: TreeNode) => f.id !== node.data.id)];

    emitData();
  });
};
/**
 *
 */
onMounted(() => {
  state.id = props.modelValue.id;
  state.code = props.modelValue.code;
  state.title = props.modelValue.title;
  state.data = [{
    id: 'root',
    label: 'Корневой каталог',
    type: NODE_TYPES.FOLDER,
    image: null,
    children: props.modelValue.widgets.body[2] && props.modelValue.widgets.body[2]?.widgets?.length ? [...parseJson(props.modelValue.widgets.body[2])] : [],
  }];
});
</script>

<style lang="scss" scoped>
.loyalty{
  &__layout{
    width: 100%;
    min-height: 500px;

    :deep(.el-tree-node__content) {
      height: 50px;
      box-sizing: border-box;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  &__tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    &-label {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
