<template>
  <draggable
    :list="buttons"
    :group="buttons.type"
    item-key="name"
    @end="emitData"
  >
    <template #item="{element}">
      <div class="button-block">
        <div class="button-block__title">
          <el-icon :size="15" class="button-block__title-icon">
            <DCaret />
          </el-icon>
          {{ EnumHeaderButtons[element.name] || '???' }}
          <el-tooltip
            v-if="element.name === 'telegram'"
            placement="top"
            :content="translateText('hubstr.editor.modal.hint_telegram')"
          >
            <el-icon :size="15" color="#007AFF" class="button-block__tooltip">
              <Question />
            </el-icon>
          </el-tooltip>
        </div>
        <div class="button-block__check">
          <el-switch
            v-model="element.visible"
            size="small"
            @change="emitData"
          />
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts" setup>
import {
  reactive, watch,
} from 'vue';
import Draggable from 'vuedraggable-es';
import { DCaret } from '@element-plus/icons-vue';
import Question from 'features/settings/editor/assets/icons/question.svg';
import { translate, useTranslate } from 'magner';

interface Button {
  type: 'social_button_widget',
  name: string,
  link: string | null,
  visible?: boolean,
}

// eslint-disable-next-line no-shadow
enum EnumHeaderButtons {
  telegram = 'Telegram',
  phone = 'Позвонить',
  thanks= 'Поблагодарить',
  add_to_contact = 'В контакты',
  link = 'Ссылка',
  vk = 'VK',
  instagram = 'Instagram',
  facebook = 'Facebook',
  radar = 'Радар',
}

interface Props {
  data: { widgets: Button[] }
}

const props = defineProps<Props>();
const emit = defineEmits(['update:data']);

const buttons = reactive([
  {
    type: 'social_button_widget',
    name: 'vk',
    link: 'data.contacts.vk',
    visible: false,
  },
  {
    type: 'social_button_widget',
    name: 'telegram',
    link: 'data.contacts.telegram',
    visible: false,
  },
  {
    type: 'social_button_widget',
    name: 'instagram',
    link: 'data.contacts.instagram',
    visible: false,
  },
  {
    type: 'social_button_widget',
    name: 'facebook',
    link: 'data.contacts.facebook',
    visible: false,
  },
] as Button[]);

watch(() => props.data, () => {
  const visibleButtons = props.data.widgets?.map((w: Button) => (w.name));
  buttons.map((i) => {
    i.visible = visibleButtons.includes(i.name);
    return i;
  });
}, { deep: true, immediate: true });

/**
     * Выбирает где visible:true, удаляет visible, эмитит
     * */
const emitData = () => {
  const out = buttons.filter((i) => i.visible).map(({ type, name, link }) => ({ type, name, link }));

  emit('update:data', out);
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;

  &__title {
    display: flex;
    align-items: center;
  }
  &__title-icon {
    cursor: move;
    margin-right: 5px;
  }
  &__tooltip {
    margin-left: 5px;
  }
}
</style>
