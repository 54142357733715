import { IBreaking } from 'features/profile/safety/breaking/types';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const breakingRead = request.table<IBreaking>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  const search = data.filters.text ? `&filters[0][id]=search&filters[0][value]=${data.filters.text}` : '';

  const res = await api.get<{ list: IBreaking[], pager: Pager }>(
    `/api/admin/feedback/breaking${pagination}${search}`,
  );
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((s: IBreaking) => ({
        ...s,
        fio: s.user.name,
        organization: s.additionalData.organization,
        place: s.additionalData.place,
      })),
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});
