import { cardPageController, translate } from 'magner';
import OnboardingEditorLayout from 'features/settings/onboarding-editor/components/layout/index.vue';
import { onboardingGet, onboardingUpdate } from 'features/settings/onboarding-editor/requests';

const onboardingEditorConfig = cardPageController({
  getRequest: onboardingGet,
  createRequest: '' as never,
  updateRequest: onboardingUpdate,
  deleteRequest: '' as never,

  header: {
    title: translate('hubstr.onboarding_editor.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'onboardingEditorLayout',
        component: () => OnboardingEditorLayout,
        props: {},
      },
    ],
  },
});

export default onboardingEditorConfig;
