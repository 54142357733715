<template>
  <div class="screen">
    <div class="screen__header">
      <div style="display: flex; align-items: center">
        <div class="bars-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
        <h3 class="screen__title"> {{ state.name }}</h3>
      </div>
      <div>
        <el-divider direction="vertical" />
        <el-popconfirm
          confirm-button-text="Да"
          cancel-button-text="Отмена"
          :icon="InfoFilled"
          width="300"
          title="Уверены что хотите удалить слайд?"
          @confirm="removeSlideHandler(state.id)"
        >
          <template #reference>
            <el-button
              type="text"
              size="small"
              style="width: auto; margin: 0; color: var(--el-color-danger)"
            >
              Удалить
            </el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>

    <div class="screen__content">
      <DefaultSlide v-if="!refHasWidgets" :data="state" />
      <WidgetsSlide v-else :data="state" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IOnboardScreen } from 'features/settings/onboarding-editor/interfaces';
import { reactive, ref } from 'vue';
import DefaultSlide from 'features/settings/onboarding-editor/components/slides/components/default-slide.vue';
import WidgetsSlide from 'features/settings/onboarding-editor/components/slides/components/widgets-slide.vue';
import { InfoFilled } from '@element-plus/icons-vue';

interface Props {
  data: IOnboardScreen,
  hasWidgets: boolean,
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(['remove']);

const state = reactive<IOnboardScreen>(props.data);
const refHasWidgets = ref(props.hasWidgets);

const removeSlideHandler = (id: string) => {
  emit('remove', id);
};
</script>

<style lang="scss" scoped>
.screen {
  padding: 20px;
  border-radius: var(--el-border-radius-base);
  box-shadow: var(--el-box-shadow-light);
  background: #FFF;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    margin: 15px 0;
  }

  .bars-icon {
    width: 14px;
    color: var(--el-text-color-secondary);
    cursor: move;
    margin-right: 10px;
  }
}
</style>
