<template>
  <el-form
    ref="formRef"
    :model="formData"
    @input="updateHandler(formRef)"
    @change="updateHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="title"
      :rules="[{ required: true, message: translateText('hubstr.editor.errors.required_field'), trigger: 'blur' },
               { validator: uniqueValidator, message: 'Поле с таким именем уже было создано' }]"
    >
      <el-input
        v-model="titleProxy"
        :maxlength="40"
        @focusin="warningChangeTextWithAutoComplete"
      />
    </el-form-item>
    <el-form-item label="Тип поля">
      <el-radio-group
        v-model="formData.additions.variableType"
        :disabled="isEdit && !formData.isNew"
      >
        <el-radio label="text" :disabled="formData.hasAutoComplete">{{ translateText('hubstr.editor.modal.data_type.text') }}</el-radio>
        <el-radio label="link" :disabled="formData.hasAutoComplete">{{ translateText('hubstr.editor.modal.data_type.link') }}</el-radio>
        <el-radio label="datetime" :disabled="formData.hasAutoComplete">{{ translateText('hubstr.editor.modal.data_type.date_time') }}</el-radio>
        <el-radio label="array" :style="[formData.additions.variableType !== 'array' && {display: 'none'}]">Список</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-checkbox
        v-if="formData.additions.variableType === 'text' || formData.additions.variableType === 'array'"
        v-model="formData.additions.isMultiline"
        :disabled="formData.hasAutoComplete"
        :label="translateText('hubstr.editor.modal.long_text')"
      />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.isUserRequired" :label="translateText('hubstr.editor.fields.required_app')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.isAdminRequired" :label="translateText('hubstr.editor.fields.required_admin')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasPrivacy" :label="translateText('hubstr.editor.fields.has_privacy')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.allowSearch" :label="translateText('hubstr.editor.modal.allow_search')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasAutoComplete" :label="translateText('hubstr.editor.modal.add_autocomplete')" />
    </el-form-item>
  </el-form>
  <FormAutocomplete
    v-if="formData.hasAutoComplete"
    :key="formData.additions.code"
    :field-code="formData.additions.code"
    @update:values="[
      updateHandler(formRef),
      emitAutoComplete($event),
    ]"
  />
</template>

<script setup lang="ts">
import {
  reactive, watch, ref,
} from 'vue';
import translit from 'features/settings/editor/utils/translit';
import { v4 as uuidV4 } from 'uuid';
import { WIDGET_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import { ITextSectionWidget } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import { FormInstance } from 'element-plus';
import {
  magnerAlert, translate, useTranslate,
} from 'magner';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import {
  MOBILE_VALIDATORS,
} from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/types/constants';
import FormAutocomplete
, {
  AutocompleteData,
} from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/components/form-autocomplete.vue';

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

interface Props {
  data?: ITextSectionWidget;
  isEdit?: boolean;
  isNew?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:formData', 'input:error', 'update:autocomplete']);
const store = useEditorStore();
const state = (props.data && JSON.parse(JSON.stringify(props.data))) || undefined;

const formRef = ref<FormInstance>();
const warningChangeTextWithAutoCompleteOk = ref(false);

const formData = reactive<ITextSectionWidget>(
  state || {
    id: uuidV4(),
    type: WIDGET_TYPES.TEXT_SECTION,
    title: '',
    name: null,
    text: '',
    isMultiline: true,
    dataProvider: null,
    hasAutoComplete: false,
    hasPrivacy: false,
    additions: {
      code: '',
      variableType: 'text',
      allowSearch: false,
      isUserRequired: false,
      isAdminRequired: false,
      glType: 'user',
      entityType: 'user',
      isAutocompletedField: false,
      format: 'DEFAULT',
      hasDisplayType: false,
      formCode: '',
      description: null,
      isMultiline: false,
      mobileValidator: {},
    },
  } as ITextSectionWidget,
);

const titleProxy = ref(t[formData.title?.replace('lang.', '')] || formData.title);

const uniqueValidator = (rule: any, value: any, callback: any) => {
  if (!props.isEdit && store.residentAdditionalCodes.value.includes(formData.additions.code)) {
    callback(new Error());
  } else {
    callback();
  }
};

const fieldBuilder = () => {
  if (!props.isEdit || formData.isNew) {
    // "code" меняется только при создании нового поля(или редактировании нового(!) поля)
    const code = `${formData.additions.entityType}${translit(formData.title, 'pascal')}`;
    formData.additions.code = code;
    formData.additions.formCode = `data.additions.${code}`;
    formData.text = `data.additions.${code}`;
  }
  // если есть перевод оставляем код, если нет то свое значение.
  if (titleProxy.value?.toLowerCase() !== t[formData.additions.code]?.toLowerCase()) {
    formData.title = titleProxy.value.trim();
    formData.additions.description = titleProxy.value.trim();
  } else {
    formData.title = `lang.${formData.additions.code}`;
    formData.additions.description = null;
  }

  if (formData.additions.variableType === 'link') {
    formData.name = 'link';
  }
  if (formData.additions.variableType === 'text') {
    formData.additions.mobileValidator = MOBILE_VALIDATORS.TEXT;
  }

  if (!formData.hasAutoComplete && formData.additions.variableType === 'array') {
    formData.additions.variableType = 'text';
    formData.additions.isAutocompletedField = false;
    formData.additions.mobileValidator = MOBILE_VALIDATORS.TEXT;
  }
  if (formData.hasAutoComplete && formData.additions.variableType !== 'array') {
    formData.additions.variableType = 'array';
    formData.additions.isAutocompletedField = true;
    formData.additions.isMultiline = false;
    formData.additions.mobileValidator = MOBILE_VALIDATORS.AUTOCOMPLETE;
  }

  if (props.isNew) {
    formData.isNew = true; // переменная определяет новое ли поле
  }
  formData.touch = true; // переменная определяет изменилась ли formData
};

const updateHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  fieldBuilder();

  formEl.validate((valid) => {
    if (valid) {
      // submit
      emit('input:error', false);
      emit('update:formData', formData);
    } else {
      emit('input:error', true);
    }
  });
};

const emitAutoComplete = (event: { field: string; values: AutocompleteData[] }) => {
  emit('update:autocomplete', { field: formData.additions.code, values: event.values });
};

// TODO: сделать что бы список автокомплита не зависел от кода поля
const warningChangeTextWithAutoComplete = () => {
  if (formData.isNew && formData.hasAutoComplete && formData.title !== '' && !warningChangeTextWithAutoCompleteOk.value) {
    magnerAlert({
      type: 'warning',
      title: 'Внимание',
      message: 'При изменении названия поля, весь прогресс заполнения автокомплита будет сброшен. Продолжить?',
    }).then(() => warningChangeTextWithAutoCompleteOk.value = true);
  }
};

/**
 * Принудительно с большой буквы
 * */
watch(() => titleProxy.value, (newValue) => {
  titleProxy.value = newValue?.charAt(0).toUpperCase() + newValue.slice(1);
});

watch(
  () => formData,
  () => {
    updateHandler(formRef.value);
  },
  { deep: true },
);

</script>

<style scoped>
.el-checkbox {
  font-weight: lighter;
}
</style>
