<template>
  <el-form
    ref="formRef"
    :model="formData"
    @input="updateHandler(formRef)"
    @change="updateHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="label"
      :rules="[
          { required: true, message: translateText('hubstr.editor.errors.required_field'), },
        ]"
    >
      <el-input
        v-model="labelProxy"
        :maxlength="40"
      />
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, reactive, ref, watchEffect,
} from 'vue';
import {translate, useTranslate} from "magner";
import { TranslatesList as t } from 'features/settings/mobile-editor/requests';
import { AdditionsList } from "features/settings/mobile-editor/requests";
import {FormInstance} from "element-plus";

export default defineComponent({
  name: 'StatusIcon',
  props: {

  },
  emits: ['input:error', 'update:formData'],
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const formData = reactive<any>({
      type: 'header_title_with_icon_widget',
      value: 'data.fullName',
      name: 'data.additions.userTitleIcon',
      label: 'Статус',
    });

    const labelProxy = ref(t[formData.label?.replace('lang.', '')] || formData.label);

    watchEffect(() => {
      formData.label = labelProxy.value.trim();
    })

    const updateHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);
          emit('update:formData', formData);
        } else {
          emit('input:error', true);
        }
      });
    };

    onMounted(() => {
      emit('update:formData', formData);
    })

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      formRef,
      formData,
      labelProxy,
      updateHandler,
      translateText,
    };
  },
});
</script>

<style scoped>

</style>
