import { AdditionalField, Widgets } from 'features/settings/mobile-editor/interfaces';
import { MetaHubstrFieldsGroup } from 'features/residents/layouts/meta-hubstr';
import { TranslatesList } from 'features/settings/mobile-editor/requests';
import { ADMIN_GROUP_TYPES } from 'features/settings/mobile-editor/constants';
import AdminFieldEntity from 'features/settings/mobile-editor/factories/admin-field-entity';
import { findMatchingField } from 'features/settings/mobile-editor/utils/find-matching-field';

/**
 * @param mobileView
 * @param additionsList
 * */
const parseForAdmin = (mobileView: Widgets, additionsList: AdditionalField[]) => {
  const body: MetaHubstrFieldsGroup[] = [];

  // Группы
  mobileView.body
    .filter((group) => ![ADMIN_GROUP_TYPES.SOCIAL, ADMIN_GROUP_TYPES.CONTACTS, ADMIN_GROUP_TYPES.COMMUNITIES].includes(group.adminGroupType))
    .forEach((group) => {
      const fieldsGroup: MetaHubstrFieldsGroup = {
        title: group.title,
        fields: [],
      };

      // Виджеты
      group.widgets
        .filter((widget) => widget.text !== 'data.additions.userBadge' && widget.text !== 'data.additions.userCity')
        .forEach((widget) => {
          // Ищем соответствующее(виджету) поле в списке доп. полей
          const matchingField = findMatchingField(widget, additionsList);

          if (matchingField) {
            // Генерируем поле
            const fieldFactoryInit = new AdminFieldEntity(widget, matchingField, TranslatesList);
            fieldsGroup.fields.push(fieldFactoryInit.create() as never);
          }
        });

      // Фильтруем пустые группы
      if (fieldsGroup.fields.length) {
        body.push(fieldsGroup);
      }
    });

  const statusIcon = mobileView.header.find((w) => w.type === 'header_title_with_icon_widget');
  if (statusIcon) {
    body.unshift({
      title: '',
      fields: [{
        type: 'radio',
        name: 'userTitleIcon',
        label: statusIcon.label || 'Статус',
        options: [
          {
            label: 'Да',
            value: 'crown',
          },
          {
            label: 'Нет',
            value: 'null',
          },
        ],
      }],
    } as any);
  }

  return body;
};

export default parseForAdmin;
