import {
  tablePageController, translate, actionCard, openModal, actionTable,
} from 'magner';
import blankConfig from 'features/profile/blanks/blank';
import { Blank } from 'features/profile/blanks/types';
import { blanksRead } from 'features/profile/blanks/requests';

const blanksConfig = tablePageController<Blank>({
  request: blanksRead,
  header: {
    title: translate('gaz.blanks.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('gaz.blanks.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<Blank>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: blankConfig,
              handleBeforeClose: true,
              customClass: 'modal-max-width modal-max-width__400',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('gaz.blanks.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('gaz.blanks.table.name'),
      },
      {
        prop: 'id',
        label: translate('hubstr.partner.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<Blank>(async ({ data }) => {
                const role = (data as any).row as Blank;
                try {
                  await openModal<any>({
                    type: 'card',
                    config: {
                      ...blankConfig,
                      header: {
                        title: translate('gaz.blanks.form.title_edit'),
                      },
                    },
                    handleBeforeClose: true,
                    props: {
                      emptyCard: false,
                      entityId: role.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('gaz.blanks.form.failed_edition');
                }
              }),
              props: {
                text: translate('gaz.blanks.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default blanksConfig;
