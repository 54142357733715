import { cardPageController, translate } from 'magner';
import { BossWord } from 'features/profile/bossword/types';
import { getBossWord, updateBossWord } from 'features/profile/bossword/requests';
import { ActionButton } from 'magner/dist/types/utils/actions';

const bossWordConfig = cardPageController<BossWord>({
  header: {
    title: translate('gaz.boss_word.sidebar.card_title'),
  },

  getRequest: getBossWord,
  createRequest: null,
  updateRequest: updateBossWord,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.boss_word.form.cancel_button'),
        },
      } as ActionButton<'cancel'>,
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('gaz.boss_word.form.submit_button'),
          type: 'primary',
        },
      } as ActionButton<'submit'>,
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'dropzone',
          name: 'image',
          label: translate('gaz.boss_word.form.image.label'),
          hint: translate('gaz.boss_word.form.image.hint'),
          props: {
            required: true,
            formats: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
            removable: true,
            valueKey: 'id',
            srcKey: 'image',
            maxSize: 8,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
        {
          type: 'input',
          name: 'title',
          label: translate('gaz.boss_word.form.title.label'),
          props: {
            required: true,
            placeholder: translate('gaz.boss_word.form.title.placeholder'),
            maxLength: 300,
            showLetterLimit: true,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
        {
          type: 'textarea',
          name: 'text',
          label: translate('gaz.boss_word.form.text.label'),
          props: {
            required: true,
            placeholder: translate('gaz.boss_word.form.text.placeholder'),
            maxLength: 10000,
            showLetterLimit: true,
            rows: 2,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});

export default bossWordConfig;
