import type { EventCategory } from 'features/settings/event-categories/types/hubstr';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const eventCategoryRead = request.table<EventCategory>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination.page || 1}`;
  const sort = '&sort[0][id]=name&sort[0][value]=ASC';
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';

  const res = await api.get<{ list: EventCategory[], pager: Pager }>(
    `/api/admin/event/category/list${pagination}${sort}${search}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const eventCategoriesSearch = request.custom<{ rows: EventCategory[] }, string>(async ({ api, data }) => {
  const sort = '&sort[0][id]=name&sort[0][value]=ASC';
  const search = data?.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';

  const res = await api.get<{ list: EventCategory[] }>(
    `/api/admin/event/category/list?page=1&count=40${sort}${search}`,
  );
  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data.list },
  };
});

export const eventCategoryGet = request.card<EventCategory, EventCategory>(async ({ api, data, parseError }) => {
  const res = await api.get<{ eventCategory: EventCategory }>(`/api/admin/event/category?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.eventCategory };
});

export const eventCategoryCreate = request.card<Event, EventCategory>(async ({ api, parseError, data }) => {
  const res = await api.post<{ event: Event }>('/api/admin/event/category', data.data);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  window.location.reload();
  return { data: res.data?.event };
});

export const eventCategoryUpdate = request.card<EventCategory, EventCategory>(async ({ api, parseError, data }) => {
  const body = { ...data.data, id: data.id };
  const res = await api.patch<{ event: EventCategory }>('/api/admin/event/category', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.event };
});

export const eventCategoryDelete = request.card(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/event/category?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
