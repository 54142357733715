<template>
  <div v-for="i in sections" :key="i.type" class="header-items">
    <div
      class="header-items__item"
    >
      <div class="header-items__item-content">
        <div class="header-items__item-title">
          <div style="width: max-content">
            <EditableText
              v-if="i.editable"
              v-model="userBadgeLabel"
              class="header-items__item-editable"
              :disabled="!i.visible"
            />
            <span v-else>
              {{ HeaderModules[i.type] }}
            </span>
          </div>
          <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
        </div>
        <el-checkbox
          v-if="i.required"
          v-model="userBadgeReq"
          :disabled="!i.visible"
          size="small"
          :label="translateText('hubstr.editor.fields.required_app')"
        />
      </div>
      <el-switch
        v-if="i.switched"
        v-model="i.visible"
        size="small"
        @change="onChecked($event, i)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted, reactive, ref, watch,
} from 'vue';
import QuestionTooltip from 'features/settings/editor/association-editor/ui/components/left-side/components/question-tooltip.vue';
import { magnerMessage, translate, useTranslate } from 'magner';
import EditableText from 'features/settings/editor/association-editor/ui/components/left-side/components/editable-text-input.vue';
import { AdditionsAssociationList, updateAdditionalField } from 'features/settings/editor/association-editor/requests';
import { IAViewWidgets, IAViewWidgetsHeader } from 'features/settings/editor/association-editor/interfaces';

interface Items {
  type: string,
  switched: boolean,
  questionIcon: boolean,
  textTooltip?: string,
  visible?: boolean,
  value?: string,
  tags?: string,
  editable?: boolean,
  label?: string,
  required?: boolean,
}

interface Props {
  data: IAViewWidgets,
}

const props = defineProps<Props>();
const emit = defineEmits(['update:data']);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const copy = props.data.header.slice();

const state = reactive(props.data.header) as IAViewWidgetsHeader[];

const HeaderModules = {
  header_avatar_widget: translateText('hubstr.association_editor.main.header.widgets.logo'),
  header_tags_widget: translateText('hubstr.editor.main.header.widgets.role'),
  header_badge_widget: translateText('hubstr.association_editor.main.header.widgets.badge'),
  header_title_widget: translateText('hubstr.association_editor.main.header.widgets.name'),
  header_group_buttons_widget: translateText('hubstr.editor.main.header.widgets.group_btns'),
} as const;

const badge = props.data.header.find((w) => w.type === 'header_badge_widget') as IAViewWidgetsHeader;
const userBadgeLabel = ref(AdditionsAssociationList.find((i) => i.code === 'associationUserPosition')?.description || HeaderModules.header_badge_widget);
const userBadgeReq = ref(AdditionsAssociationList.find((i) => i.code === 'associationUserPosition')?.isUserRequired);

const sections = reactive([
  {
    type: 'header_avatar_widget',
    switched: false,
    questionIcon: false,
    value: 'data.avatar',
  },
  {
    type: 'header_title_widget',
    switched: false,
    questionIcon: false,
    value: 'data.fullName',
  },
  {
    type: 'header_badge_widget',
    switched: true,
    questionIcon: false,
    visible: false,
    value: 'data.additions.associationUserPosition',
    editable: true,
    label: userBadgeLabel.value,
    required: true,
  },
]) as Items[];

watch(() => userBadgeLabel.value, (newValue) => {
  badge.label = newValue;
  sections.find((w) => w.type === 'header_badge_widget').label = newValue;
});

watch(() => userBadgeReq.value, async (newValue) => {
  badge.required = newValue;

  const res = await updateAdditionalField({
    ...AdditionsAssociationList.find((i) => i.code === 'associationUserPosition'),
    isUserRequired: newValue,
  });
  if (res.error) {
    magnerMessage({
      type: 'error',
      message: 'Ошибка',
    });
    return { error: res.error };
  }

  return true;
});

/**
 * Добавляет или удаляет элемент
 * */
const onChecked = (event: boolean, item: Items) => {
  if (event) {
    const index = copy.map((i) => i.type).indexOf(item.type);
    const out = {
      type: item.type,
    } as {type: string, value: string, tags: string, label: string};

    item?.value ? out.value = item.value : '';
    item.tags ? out.tags = item.tags : '';
    item.label ? out.label = item.label : '';

    state.splice(index, 0, out);
  } else {
    const index = state.map((i) => i.type).indexOf(item.type);
    if (item.required) {
      userBadgeReq.value = false;
    }
    state.splice(index, 1);
  }
};

/**
 * Проходит по пропсу и если секция с таким типом найдена в Sections
 * Включает флаг visible в Items
 * */
onMounted(() => {
  sections.map((item: Items) => {
    state.map((h) => {
      if (item.type === h.type) {
        item.visible = true;
      }
      return true;
    });
    return true;
  });
});

</script>

<style lang="scss" scoped>
.header-items {
  &__item {
    min-height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--el-box-shadow-lighter);
    padding: 10px 20px;
    border-radius: var(--el-card-border-radius);
    margin-bottom: 10px;
  }
  &__item-title {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--el-font-size-base);
  }
  &__item-content {
    width: 100%;
  }
}
.el-checkbox {
  font-weight: lighter;
}
</style>
