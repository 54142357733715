import { ChangeLog } from 'features/change-log/interfaces';

export const log = [
  {
    version: '1.0.26',
    date: '02.10.2023',
    text: {
      whatsNew: [
        'Добавили раздел "Отчеты". Аналитика по: <ul><li>активности пользователей</li><li>мероприятиям</li></ul>',
        'В "Системное" раздел "Редактор полей". Дает возможность редактировать внешний вид профиля резидента: название полей, расположение, создание своих полей.',
        'В "Системное" раздел "Настройки" с общими настройками для управления приложениям.<ul><li>Несколько типов уведомлений.</li><li>Управление кол-вом одновременно залогинненых устройств в рамках одного аккаунта.</li><li>Включение/выключение сбора аналитики.</li><li>Включение/выключение фичи запиненных постов.</li></ul>',
      ],
      improvements: [
        'Справочники -> "Роли в клубе". Добавили палитру для выбора цвета роли.',
      ],
    },
  },
] as ChangeLog[];
