<template>
  <div class="interview-preview-field-date">
    <div class="interview-preview-field-date__label">
      <div>{{ translateText('gaz.interview.preview.form.date') }}</div>

      <div class="interview-preview-field-date__value">
        {{ formattedValue || translateText('gaz.interview.preview.form.select') }}

        <el-icon>
          <ArrowRight />
        </el-icon>
      </div>
    </div>

    <el-date-picker
      v-model="value"
      type="date"
      @change="$emit('update:modelValue', $event)"
    />
  </div>
</template>
    
<script lang="ts">
import { 
  computed, 
  defineComponent, 
  ref, 
  watch,
} from 'vue';

import { translate, useTranslate } from 'magner';

import { ArrowRight } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'IntreviewPreviewFieldDate',

  components: {
    ArrowRight,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup (props) {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    const value = ref(props.modelValue);

    const formattedValue = computed(() => {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      return value.value ? new Date(value?.value)?.toLocaleString('ru-Ru', options) : '';
    });

    watch(() => props.modelValue, () => { value.value = props.modelValue; });

    return {
      value,

      formattedValue,

      translateText,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-date {
  position: relative;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(200, 199, 204, 1);
    pointer-events: none;
  }

  &__value {
    display: flex;
    align-content: center;
    color: rgba(200, 199, 204, 1);

    svg {
      margin-left: 8px;
    }

    svg path {
      fill: rgba(200, 199, 204, 1);
    }
  }

  .el-date-editor {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    cursor: pointer;
    z-index: 2;

    input {
      cursor: pointer;
    }

    .el-input__wrapper {
      padding: 0;
    }
  }

  .clear-icon {
    display: none !important;
  }
}
</style>
