import { LoyaltyProgram } from 'features/profile/loyalty/main/types';
import { request } from '~/utils/request';

let loyaltyProgramList = {} as LoyaltyProgram;

export const getLoyaltyCategories = request.card<{loyaltyLayout: any}>(async ({ api, data, parseError }) => {
  try {
    const res = await api.get<any>('/api/admin/view?code=loyalty_program');

    loyaltyProgramList = res.data?.data;
    return {
      data: {
        loyaltyLayout: res.data.data,
      },
    };
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const updateLoyaltyCategories = request.custom(async ({ api, data, parseError }) => {
  data.data.loyaltyLayout.widgets.body[0].text = loyaltyProgramList.widgets.body[0].text;
  const res = await api.put('/api/admin/view', data.data.loyaltyLayout);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});
