<template>
  <el-input
    v-model="value"
    size="large"
    class="app-color-picker"
    :readonly="readOnly"
    @change="$emit('update:modelValue', $event)"
  >
    <template #prefix>
      <el-color-picker v-model="value" color-format="hex" @change="$emit('update:modelValue', $event)" />
    </template>
  </el-input>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, ref, watch, 
} from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const value = ref(props.modelValue);

const changeVal = (newVal: any) => {
  value.value = newVal;

  emit('update:modelValue', value.value);
};

watch(() => props.modelValue, () => {
  value.value = props.modelValue;
});
</script>

<style lang="scss">
.app-color-picker {
  input[type='color'] {
      -webkit-appearance: none;
      border: none;
      width: 20px;
      height: 20px;
      background: transparent;
      cursor: pointer;
  }
  
  input[type='color']::-webkit-color-swatch {
      border: none;
      border-radius: 100%;
  }
  input[type='color']::-webkit-color-swatch-wrapper {
      padding: 0;
  }

  .el-color-picker__trigger {
      border: none !important;
      padding: 0 !important;
      width: 25px !important;
      height: 25px !important;
  }
  .el-color-picker__color {
      border: 1px rgba(211, 211, 211, 0.3) solid !important;
      border-radius: 100% !important;
  }
  .el-color-picker__color-inner {
      border-radius: 100% !important;
  }
  .el-color-svpanel {
      width: 100% !important;
  }
  .el-color-hue-slider.is-vertical {
      z-index: 2;
  }
}
</style>
