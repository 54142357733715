import {
  tablePageController, translate,
} from 'magner';
import { linkGroupsRead } from 'features/settings/link-groups/requests/hubstr';
import { Link, LinkGroup } from 'features/settings/link-groups/types/hubstr';

const linkGroupsConfig = tablePageController<LinkGroup>({
  request: linkGroupsRead,
  header: {
    title: translate('hubstr.link_groups.table.title'),
  },
  filters: {
    fieldsShowAmount: 5,
    pagination: {
      items: 25,
      page: 1,
    },
    filtersData: {},
    actions: [],
    layout: [],
  },
  filtersInSeparatePanel: true,
  table: {
    // @ts-ignore
    rowLink: (row) => ({
      name: 'links',
      params: { id: row.code },
    }),
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('hubstr.link_groups.table.name'),
      },
    ],
  },
});

export default linkGroupsConfig;
