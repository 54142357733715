import metaHubstrAssociationLayout from 'features/residents/companies/layouts/meta-hubstr';
import { Company, CompanyData } from 'features/residents/companies/types/hubstr';
import { fileUpload } from 'features/common/file-request';
import { AdditionalField } from 'features/residents/types/hubstr';
import { v4 as uuidv4 } from 'uuid';
import { request } from '~/utils/request';

/**
 * Метод получения layout
 * */
export const associationGetLayout = request.custom(async ({ api }) => {
  const res = await api.get('/api/admin/admin-view?code=association');

  if (res.error) {
    return {
      error: res.error,
    };
  }

  const columns = res.data?.data.json;

  return {
    ...metaHubstrAssociationLayout(columns),
  };
});

export const companyCreate = request.card<Company, CompanyData>(async ({
  data, api, parseError, router,
}) => {
  const id = uuidv4();
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';

  const body: any = { id, additions: {}, userId: residentId };

  /**
   * Список вложенных ключей (вложенных в additions) нам так же надо получить с бэка
   */
  const resAdditionalFields = await api.get<{data: AdditionalField[]}>('/api/admin/additional_fields/list?entityType=association');
  const nestedKeys = resAdditionalFields.data?.data.map(({ code, variableType }) => ({ code, variableType }));
  Object.entries(data.data).forEach(([key, val]) => {
    const nestedKey = nestedKeys?.find(({ code }) => code === key);
    if (nestedKey) {
      if (nestedKey.variableType === 'array' && val === '') {
        body.additions[key] = null;
      } else {
        body.additions[key] = val;
      }
    } else {
      body[key] = val;
    }
  });

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'association',
      'company_logo',
      'companyLogo',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  } else {
    body.logo = null;
  }

  const res = await api.post<{ company: Company }>('/api/admin/user/association/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-companies', params: { id: residentId } });
  return { data: res.data?.company };
});

export const companyUpdate = request.card<Company, CompanyData>(async ({
  api, parseError, data, router,
}) => {
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';
  const body: any = {
    id: data.id, additions: {}, userId: residentId,
  };

  /**
   * Список вложенных ключей (вложенных в additions) нам так же надо получить с бэка
   */
  const resAdditionalFields = await api.get<{data: AdditionalField[]}>('/api/admin/additional_fields/list?entityType=association');
  const nestedKeys = resAdditionalFields.data?.data.map(({ code, variableType }) => ({ code, variableType }));
  Object.entries(data.data).forEach(([key, val]) => {
    const nestedKey = nestedKeys?.find(({ code }) => code === key);
    if (nestedKey) {
      if (nestedKey.variableType === 'array' && val === '') {
        body.additions[key] = null;
      } else {
        body.additions[key] = val;
      }
    } else {
      body[key] = val;
    }
  });

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.logo as File[])[0],
      'association',
      'company_logo',
      'companyLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  const res = await api.patch<{ company: Company }>('/api/admin/user/association/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-companies', params: { id: residentId } });
  return { data: res.data?.company };
});
