<template>
  <el-image
    v-if="row?.image"
    class="image"
    :src="row?.image.thumbnail"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { CabinetSections } from 'features/settings/cabinet-sections/interfaces';

export default defineComponent({
  name: 'TableImage',
  props: {
    row: {
      type: Object as PropType<CabinetSections>,
      default: () => {},
    },
  },
  setup () {
    /** Нормальный доступ к кнопке 'редактировать' в таблице есть только отсюда :(
     *  Эта конструкция предотвращает всплытие события
     *  при котором при нажатии на row в таблице мы переходим на другую страницу
     */
    onMounted(() => {
      const userSelection = document.getElementsByClassName('update-btn-on-table-row');
      for (let i = 0; i < userSelection.length; i++) {
        (function (index) {
          const btn = userSelection[index].getElementsByClassName('el-button')[0];
          btn?.addEventListener('click', (evt) => {
            evt.preventDefault();
          });
        }(i));
      }
    });
  },
});
</script>

<style scoped>
.image {
  width: 35px;
  border-radius: 3px;
}
</style>
