<template>
  <div class="autocomplete-form">
    <div class="autocomplete-form__form">
      <el-input v-model="autoCompleteForm" :placeholder="translateText('hubstr.editor.modal.new_value')" />
      <el-button
        type="primary"
        plain
        :loading="loadAddAutocompleteField"
        :disabled="!autoCompleteForm.trim().length"
        @click="addAutoCompleteData"
        @keyup.enter="addAutoCompleteData"
      >
        {{ translateText('hubstr.editor.add') }}
      </el-button>
    </div>
    <el-collapse v-model="activeCollapse" v-loading="loadAutocompleteFields">
      <el-collapse-item
        name="1"
        :title="activeCollapse.length ? translateText('hubstr.editor.modal.hide') : translateText('hubstr.editor.modal.show')"
      >
        <div class="autocomplete-form__list">
          <div v-for="(item, index) in state.values" :key="item.id" class="autocomplete-form__list-item">
            <div>
              {{ item.value }}
            </div>
            <div>
              <el-button
                type="danger"
                size="small"
                plain
                @click="remoteAutoCompleteData(item.id, index)"
              >
                {{ translateText('hubstr.editor.remove') }}
              </el-button>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  onMounted, reactive, ref, watch, watchEffect,
} from 'vue';
import {
  getAutocompleteFields,
  removeAutocompleteField,
} from 'features/settings/mobile-editor/requests';
import { magnerMessage, translate, useTranslate } from 'magner';
import useEditorStore from 'features/settings/editor/store/useEditorStore';

export interface AutocompleteData {
  id: string,
  value: string,
}

interface Props {
  fieldCode: string,
}

const props = defineProps<Props>();
const emit = defineEmits(['update:autocomplete']);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const store = useEditorStore();
const loadAutocompleteFields = ref(false);
const loadAddAutocompleteField = ref<boolean>(false);
const autoCompleteForm = ref<string>('');
const activeCollapse = ref(['1']);

const state = reactive<any>({
  field: props.fieldCode,
  values: [],
});

const hasCode = () => {
  if (!props.fieldCode) {
    magnerMessage({
      type: 'error',
      message: translateText('hubstr.editor.errors.name_first'),
    });
    loadAddAutocompleteField.value = false;
    return false;
  }
  return true;
};

// Добавление автокомплитов
const addAutoCompleteData = async () => {
  if (!hasCode()) return;

  if (state.values.map((v) => v.value.toLowerCase()).includes(autoCompleteForm.value.trim().toLowerCase())) {
    magnerMessage({
      type: 'error',
      message: translateText('hubstr.editor.errors.has_name'),
    });
    return;
  }

  state.values.unshift({
    id: '',
    value: autoCompleteForm.value.trim(),
  });

  autoCompleteForm.value = '';
  // store.pushToAutocompleteList(state);

  emit('update:autocomplete', state);
};

// Удаление
const remoteAutoCompleteData = (id: string, index: number) => {
  if (id) {
    loadAutocompleteFields.value = true;
    removeAutocompleteField(id)
      .then(() => {
        state.values.splice(state.values.findIndex((a) => a.id === id), 1);
        loadAutocompleteFields.value = false;
      })
      .catch((e) => false);
  } else {
    state.values.splice(index, 1);
  }
};

const initAutocompleteForm = async () => {
  const autocompleteItemsInStore = computed(() => store.autocompleteList.value.find((a) => a.field === props.fieldCode));

  if (!autocompleteItemsInStore.value) {
    loadAutocompleteFields.value = true;
    const autoComplete = await getAutocompleteFields(props.fieldCode);
    if (autoComplete.error) {
      loadAutocompleteFields.value = false;
      return;
    }

    state.values = autoComplete.data?.res.data.list || [];
    emit('update:autocomplete', state);
    loadAutocompleteFields.value = false;
  } else {
    state.values = autocompleteItemsInStore.value?.values || [];
  }
};

// Подгрузка созданых полей
onMounted(async () => {
  await initAutocompleteForm();
});

watchEffect(async () => {
  state.field = props.fieldCode;
  await initAutocompleteForm();
});

// Принудительно с большой буквы
watch(() => autoCompleteForm.value, (newValue) => {
  autoCompleteForm.value = newValue.charAt(0).toUpperCase() + newValue.slice(1);
});
</script>

<style lang="scss" scoped>
.autocomplete-form {
  .autocomplete-form {
    &__form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px #eaeaea solid;

    .el-button {
      margin: 0;
    }
  }
}
</style>
