import {
  actionCard, openModal, tableController, tablePageController, translate,
} from 'magner';
import FeedbackDetail from 'features/profile/feedback/card.vue';
import { feedbackRead } from 'features/profile/feedback/requests';
import { IFeedBack } from 'features/profile/feedback/types';
import feedbackFilters from 'features/profile/feedback/filters';
import { formatDate } from '~/utils/format-date';

const feedbackConfig = tablePageController<IFeedBack>({
  header: {
    title: translate('gaz.feedback.table.title'),
    actions: [],
  },
  request: feedbackRead,
  table: tableController<IFeedBack>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('gaz.feedback.table.not_found'),
    columns: [{
      prop: 'fio',
      label: translate('gaz.feedback.table.fio'),
      width: 150,
      fixed: 'left',
    }, {
      prop: 'email',
      label: translate('gaz.feedback.table.email'),
      width: 250,
    }, {
      prop: 'text',
      label: translate('gaz.feedback.table.text'),
      minWidth: 300,
    }, {
      prop: 'createdAt',
      label: translate('gaz.feedback.table.createdAt'),
      view: {
        type: 'text',
        formatter: (_, row) => formatDate(row.createdAt),
      },
      width: 160,
    }, {
      prop: 'id',
      label: translate('gaz.feedback.table.action_more'),
      width: 200,
      view: {
        type: 'actions',
        actions: [
          {
            type: 'action',
            action: actionCard<any>(async (data) => {
              try {
                await openModal<any>({
                  type: 'custom',
                  handleBeforeClose: false,
                  component: () => FeedbackDetail,
                  props: {
                    feedback: data.data.row,
                  },
                });
                return false;
              } catch (e) {
                return false;
              }
            }),
            props: {
              text: translate('gaz.feedback.table.action_more'),
              type: 'primary',
              plain: true,
            },
          },
        ],
      },
    }],
  }),
  filters: feedbackFilters,
});

export default feedbackConfig;
