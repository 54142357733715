import {
  residentRead as HubstrResidentRead,
  residentSearch as HubstrResidentSearch,
} from 'features/residents/requests/hubstr';
import { ResidentPassword, Resident } from 'features/residents/types/udschool';
import { request } from '~/utils/request';
import { formatShortDate } from '~/utils/format-date';

const BASE_URL = '/api/admin/user';

export const residentRead = HubstrResidentRead;

export const residentSearch = HubstrResidentSearch;

export const residentGet = request.card<Resident>(async ({ api, data, parseError }) => {
  const res = await api.get<{ user: Resident }>(`${BASE_URL}/read?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.user,
      birthday: res.data?.user.birthday ? formatShortDate(new Date(res.data?.user.birthday)) : '',
      positionTags: res.data?.user.positionTags.length
        ? res.data?.user.positionTags?.reduce(
          (prev, next) => `${prev.length ? ` ,${prev}` : prev} ${next.name}`, '',
        )
        : '',
      status: res.data?.user.status ? res.data?.user.status.title : '',
      authMethod: res.data?.user.authMethods?.length ? res.data?.user.authMethods[0] : '',
      oldAuthMethod: res.data?.user.authMethods?.length ? res.data?.user.authMethods[0] : '',
    },
  };
});

export const residentUpdate = request.card<Resident, ResidentPassword>(async ({ api, parseError, data }) => {
  const body: any = {
    id: data.id,
    externalId: data.data.externalId,
  };

  if (data.data.authMethod !== data.data.oldAuthMethod) {
    body.authMethods = [data.data.authMethod];
  }

  if (data.data.password?.length) {
    body.password = data.data.password;
  }

  const res = await api.patch<{ user: Resident }>(`${BASE_URL}/update`, body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.user };
});
