<template>
  <el-button
    type="text"
    @click="clickButton"
  >
    {{ row.eventsRatingCount }}
  </el-button>

  <teleport to="body">
    <el-dialog
      v-if="visible"
      v-model="visible"
      :title="translateText('hubstr.reports.table.user_activity.eventsRatingCount')"
    >
      <el-descriptions v-for="rating in row.eventsRating" :key="rating.eventId" :title="rating.name">
        <el-descriptions-item label="Дата">{{ formatDate(rating.startDate) }}</el-descriptions-item>
        <el-descriptions-item label="Оценка">{{ rating.stars }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </teleport>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { API_URL } from '~/constants';
import { formatDate } from '../../../utils/format-date';

export default defineComponent({
  name: 'EventsRatingCountLink',
  methods: { formatDate },
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));
    const visible = ref(false);

    const clickButton = () => {
      visible.value = true;
    };

    return {
      translateText,
      clickButton,
      visible,
    };
  },
});
</script>
