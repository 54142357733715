<template>
  <div class="list-line">
    <div class="list-line__title">
      <component :is="ICONS[data.leftIcon]" class="list-line__icon" />
    </div>
    <div class="list-line__text">
      {{ data.hasAutoComplete
        ? `${translateText('hubstr.editor.phone_preview.text')} 1, ${translateText('hubstr.editor.phone_preview.text')} 2`
        : translateText('hubstr.editor.phone_preview.text')
      }}
    </div>
  </div>
  <el-divider />
</template>

<script lang="ts" setup>
import { translate, useTranslate } from 'magner';
import {
  IListLineRawActionWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import People from 'features/settings/editor/assets/icons/people.svg';
import Peoples from 'features/settings/editor/assets/icons/peoples.svg';
import Pie_chart from 'features/settings/editor/assets/icons/pie_chart.svg';
import Suitcase from 'features/settings/editor/assets/icons/suitcase.svg';
import Folder from 'features/settings/editor/assets/icons/folder.svg';
import Folder_full from 'features/settings/editor/assets/icons/folder_full.svg';

interface Props {
  data: IListLineRawActionWidget
}

defineProps<Props>();

const ICONS = {
  people: People,
  peoples: Peoples,
  pie_chart: Pie_chart,
  suitcase: Suitcase,
  folder: Folder,
  folder_full: Folder_full,
} as const;

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style lang="scss" scoped>
.list-line {
  display: flex;
  align-items: center;
  &__title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__icon {
    width: 20px;
    margin-right: 10px;
  }
}
.el-divider--horizontal {
  margin: 15px 0 15px 0;
}
</style>
