<template>
  <div class="appearance-layout">
    <el-tabs v-model="activeName" class="demo-tabs">
      <!--      general Tab -->
      <el-tab-pane :label="translateText('hubstr.appearance.form.tab1')" name="general">
        <GeneralTab :data="state" />
      </el-tab-pane>
      <!--      menu Tab -->
      <el-tab-pane :label="translateText('hubstr.appearance.form.tab2')" name="menu">
        <MenuTab :data="state" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import {
  translate, useTranslate,
} from 'magner';
import { AppInfo, Theme } from 'features/settings/appearance/interfaces';
import GeneralTab from 'features/settings/appearance/modules/general-tab.vue';
import MenuTab from 'features/settings/appearance/modules/menu-tab.vue';

export default defineComponent({
  name: 'AppearanceLayout',
  components: { MenuTab, GeneralTab },
  props: {
    modelValue: {
      type: Object as PropType<AppInfo & {theme: Theme}>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup (props) {
    const { customT } = useTranslate();
    const activeName = ref('general');

    const state = ref<AppInfo & {theme: Theme}>(props.modelValue);

    const translateText = (code: string) => customT(translate(code));
    return {
      activeName,
      state,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.appearance-layout {
  width: 100%;

  .el-tab-pane {
    padding: 5px;
  }
}
</style>
