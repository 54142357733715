<template>
  <draggable
    :list="state"
    group="views"
    item-key="widgets"
    class="drag-area"
    :move="checkMove"
  >
    <template #item="{element, index}">
      <CollapseWrap>
        <template #title>
          <el-icon :size="15" class="item-icon">
            <DCaret />
          </el-icon>
          <EditableTextInput
            v-model="element.title"
          />
          <el-popconfirm
            :title="translateText('hubstr.editor.warnings.remove_group_confirm')"
            :width="300"
            :hide-after="0"
            @confirm="removeGroup(index)"
          >
            <template #reference>
              <el-button
                type="danger"
                class="delete-btn"
                :icon="Delete"
                link
                @click.stop
              />
            </template>
          </el-popconfirm>
        </template>
        <template #default>
          <component
            :is="TYPE_GROUPS_TO_COMPONENTS[element.adminGroupType]"
            :key="forceRerenderKey"
            class="group"
            :data="element"
            @remove="removeField($event, index)"
            @edit="editField($event)"
            @update:data="updateSocialOrContacts(index, $event)"
          />
          <el-button
            v-if="element.adminGroupType === ADMIN_GROUP_TYPES.GROUP"
            type="primary"
            plain
            @click="openAddFieldHandler(index)"
          >
            {{ translateText('hubstr.editor.add_field') }}
          </el-button>
        </template>
      </CollapseWrap>
    </template>
  </draggable>
  <el-button type="primary" plain @click="openAddGroupModal = true">
    {{ translateText('hubstr.editor.add_group') }}
  </el-button>

  <AddGroupModal
    :key="openAddGroupModal"
    v-model="openAddGroupModal"
    :groups="state.map((i) => i.adminGroupType)"
    @change="addGroup"
  />

  <AddFieldModal
    :key="openAddFieldModal"
    v-model="openAddFieldModal"
    :data="data.body"
    :current-index-group="currentGroupIndex"
    @change="addField"
  />

  <EditFieldModal
    v-if="fieldData"
    :key="openEditFieldModal"
    v-model="openEditFieldModal"
    :field-id="fieldId"
    :data="fieldData"
    @change="forceRerender"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  reactive,
  ref,
} from 'vue';
import Draggable from 'vuedraggable-es';
import CollapseWrap from 'features/settings/mobile-editor/components/left-side/components/collapse-wrap.vue';
import { DCaret, Delete } from '@element-plus/icons-vue';
import EditableTextInput
  from 'features/settings/mobile-editor/components/left-side/components/editable-text-input.vue';
import AddGroupModal from 'features/settings/mobile-editor/components/left-side/modals/add-group-modal.vue';
import GroupButton from 'features/settings/mobile-editor/components/left-side/components/body/groups/group-button.vue';
import Group from 'features/settings/mobile-editor/components/left-side/components/body/groups/group.vue';
import GroupSocials from 'features/settings/mobile-editor/components/left-side/components/body/groups/group-socials.vue';
import GroupContacts
  from 'features/settings/mobile-editor/components/left-side/components/body/groups/group-contacts.vue';
import AddFieldModal from 'features/settings/mobile-editor/components/left-side/modals/add-field-modal.vue';
import { WidgetBody, Body, Widgets } from 'features/settings/mobile-editor/interfaces';
import EditFieldModal from 'features/settings/mobile-editor/components/left-side/modals/edit-field-modal.vue';
import { ADMIN_GROUP_TYPES, GROUP_TYPES, TYPE_GROUPS_TO_COMPONENTS } from 'features/settings/mobile-editor/constants';
import {
  AdditionsList,
  additionsGet,
  updateAdditionalField,
} from 'features/settings/mobile-editor/requests';
import { translate, useTranslate } from 'magner';

interface WidgetState extends WidgetBody {
  id?: string;
}

export default defineComponent({
  name: 'BodyModule',
  components: {
    EditFieldModal,
    AddFieldModal,
    AddGroupModal,
    DCaret,
    CollapseWrap,
    Draggable,
    EditableTextInput,
    Group,
    GroupButton,
    GroupSocials,
    GroupContacts,
  },
  props: {
    data: {
      type: Object as PropType<Widgets>,
      required: true,
    },
  },
  setup (props, { emit }) {
    const forceRerenderKey = ref(0);
    const currentGroupIndex = ref();
    const fieldId = ref();
    const fieldData = ref();
    const openAddGroupModal = ref(false);
    const openAddFieldModal = ref(false);
    const openEditFieldModal = ref(false);

    const state = reactive<Body[]>(props.data.body);

    /**
     * Запрещает перетаскивание элемента
     * */
    const checkMove = (event: any) => (event.draggedContext.element.type !== 'group_footer');

    const openAddFieldHandler = (index: number) => {
      currentGroupIndex.value = index;
      openAddFieldModal.value = true;
    };

    const addField = (event: { indexGroup: number, formData: WidgetState}) => {
      state[event.indexGroup].widgets?.push(event.formData as never);
    };

    const removeField = async (field_Id: string, indexGroup: number) => {
      const widget = state[indexGroup].widgets?.find((w: WidgetState) => w.id === field_Id);

      /**
       * Прежде чем отвязывать виджет от формы - надо сделать поле необязательным
       */
      const additionsFields = await additionsGet('');
      additionsFields.data.map(async (af) => {
        if (af.formCode === widget?.text) {
          await updateAdditionalField({
            id: af.id,
            isUserRequired: false,
            isAdminRequired: false,
          });
        }
      });

      const fieldIndex = state[indexGroup].widgets?.findIndex((w: WidgetState) => w.id === field_Id);
      state[indexGroup].widgets?.splice(fieldIndex, 1);
    };

    const editField = (event: string) => {
      openEditFieldModal.value = true;
      fieldData.value = event;
    };

    const addGroup = (event: Body) => {
      state.push(event);
    };
    const removeGroup = (indexGroup: number) => {
      Promise.all(state[indexGroup].widgets.map(async (w) => {
        await removeField(w.id, indexGroup);
      })).then(() => {
        state.splice(indexGroup, 1);
      });
    };

    const updateSocialOrContacts = (indexGroup: number, event: any) => {
      /**
       * Присвоение пустого массива или другого массива (event) ведет к станной работе реактивности
       * Кароч все ломается, поэтому очищаем и заполняем заново
       */
      while (state[indexGroup].widgets.length > 0) {
        state[indexGroup].widgets.pop();
      }
      event.map((w: any) => {
        if (!state[indexGroup].widgets.map((wi) => wi.name).includes(w.name)) {
          state[indexGroup].widgets.push(w);
        }

        return w;
      });
    };

    /**
     * Обновление ключа для ре-рендера компонента
     * */
    const forceRerender = () => {
      forceRerenderKey.value += 1;
    };

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      Delete,
      state,
      forceRerenderKey,
      fieldId,
      fieldData,
      currentGroupIndex,
      openAddGroupModal,
      openAddFieldModal,
      openEditFieldModal,
      openAddFieldHandler,
      checkMove,
      addGroup,
      removeGroup,
      addField,
      removeField,
      editField,
      updateSocialOrContacts,
      translateText,
      forceRerender,

      TYPE_GROUPS_TO_COMPONENTS,
      ADMIN_GROUP_TYPES,
      GROUP_TYPES,
      AdditionsList,
    };
  },
});
</script>

<style lang="scss" scoped>
.drag-area {
  background: white;
}
.item-icon {
  margin-right: 5px;
  cursor: move;
}
.delete-btn {
  width: auto !important;
  margin-bottom: 0 !important;
  margin-left: 5px !important;
}
</style>
