import {
  tableController, translate, actionCard, openModal,
} from 'magner';
import {
  complaintAuthor, complaintText, complaintObject, complaintCommentary, complaintDate, complaintActions,
} from 'features/settings/complaints/table/general';
import ObserveComplaint from 'features/settings/complaints/layout/hubstr.vue';
import { ComplaintsList } from 'features/settings/complaints/types/hubstr';

const complaintsTable = tableController<ComplaintsList>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.complaints.table.not_found'),
  rowLink: () => ({ name: 'complaints' }),
  columns: [
    complaintAuthor,
    complaintCommentary,
    complaintDate,
    complaintObject,
    complaintText,
    complaintActions,
  ],
});

export default complaintsTable;
