import {
  actionCard, actionTable, magnerMessage, openModal, tablePageController, translate,
} from 'magner';
import { cabinetSectionsRead } from 'features/settings/cabinet-sections/requests';
import { CabinetSections } from 'features/settings/cabinet-sections/interfaces';
import cabinetSectionConfig from 'features/settings/cabinet-sections/section/common';
import { LINK_TYPES } from 'features/settings/cabinet-sections/constants';
import TableImage from 'features/settings/cabinet-sections/components/table-image.vue';

const cabinetSectionsConfig = tablePageController<CabinetSections>({
  request: cabinetSectionsRead,
  header: {
    title: translate('hubstr.cabinet_sections.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('hubstr.cabinet_sections.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<CabinetSections>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: cabinetSectionConfig,
              handleBeforeClose: true,
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('hubstr.club_role.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    pagination: {
      items: 25,
      page: 1,
    },
    filtersData: {},
    actions: [],
    layout: [],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    rowLink: (row) => (row.type === LINK_TYPES.DEEP ? ({ name: 'deep_links_sections', params: { id: row.code } }) : {}),
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.name'),
      },
      {
        prop: 'type',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.type'),
        view: {
          type: 'text',
          formatter: (_, row) => (row.type === 'deepLink'
            ? 'Внутреняя'
            : row.type === 'externalLink'
              ? 'Внешняя'
              : 'Веб вью'),
        },
      },
      {
        prop: 'image',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.image'),
        align: 'center',
        view: {
          type: 'custom',
          component: () => TableImage,
        },
      },
      {
        prop: 'id',
        label: translate('hubstr.cabinet_sections.table.actions'),
        width: 200,
        className: 'update-btn-on-table-row',
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<CabinetSections>(async ({ data }) => {
                const link = (data as any).row as CabinetSections;
                // if (link.code === 'privacy_policy') {
                //   await magnerMessage({
                //     type: 'error',
                //     message: 'Нельзя редактировать',
                //   });
                //   return;
                // }
                try {
                  await openModal<any>({
                    type: 'card',
                    config: {
                      ...cabinetSectionConfig,
                      header: {
                        title: translate('hubstr.cabinet_sections.form.title_edit'),
                      },
                    },
                    handleBeforeClose: true,
                    props: {
                      emptyCard: false,
                      entityId: link.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('hubstr.cabinet_sections.form.failed_edition');
                }
              }),
              props: {
                text: translate('hubstr.cabinet_sections.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default cabinetSectionsConfig;
