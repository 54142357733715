import {
  actionTable, openModal, tableController, translate,
} from 'magner';
import {
  avatarColumn, birthdayColumn, emailColumn, fioColumn, phoneColumn, positionTagsColumn, statusColumn,
} from 'features/residents/table/general';
import residentConfig from 'features/residents/resident/udschool';
import type { ResidentList as UdResidentList, Resident } from 'features/residents/types/udschool';
import { AUTH_METHODS } from 'features/residents/types/udschool';

const residentsTable = tableController<UdResidentList>({
  tableLayout: 'auto',
  emptyText: translate('hubstr.resident.table.not_found'),
  columns: [
    // @ts-ignore
    avatarColumn,
    // @ts-ignore
    fioColumn,
    // @ts-ignore
    phoneColumn,
    // @ts-ignore
    emailColumn,
    // @ts-ignore
    birthdayColumn,
    // @ts-ignore
    {
      ...positionTagsColumn,
      width: 200,
    },
    // @ts-ignore
    statusColumn,
    {
      prop: 'authMethods',
      label: translate('hubstr.resident.table.authMethods'),
      view: {
        type: 'tags',
        formatter: (cellValue) => (cellValue as string[]).map(
          (item) => AUTH_METHODS.filter((method) => method.id === item)[0]?.name,
        ),
      },
      width: 200,
    },
    {
      prop: 'id',
      label: translate('hubstr.resident.table.set_password'),
      width: 200,
      view: {
        type: 'actions',
        actions: [
          {
            type: 'action',
            props: {
              type: 'primary',
              text: translate('hubstr.resident.form.edit_button'),
            },
            emits: 'update-table',
            action: actionTable<Resident>(async ({ data }) => {
              try {
                await openModal<any>({
                  type: 'card',
                  config: residentConfig,
                  handleBeforeClose: true,
                  props: {
                    emptyCard: false,
                    entityId: ((data as any).row as Resident).id,
                  },
                });
                return false;
              } catch (e) {
                return translate('hubstr.resident.form.failed_password_change');
              }
            }),
          },
        ],
      },
    },
  ],
});

export default residentsTable;
