<template>
  <el-form
    ref="formRef"
    :model="formData"
    @input="updateHandler(formRef)"
    @change="updateHandler(formRef)"
  >
    <el-form-item
      prop="leftText"
      :rules="[{ required: true, message: translateText('hubstr.editor.errors.required_field'), trigger: 'blur' },
               { validator: uniqueValidator, message: 'Поле с таким именем уже было создано' }]"
    >
      <template #label>
        {{ translateText('hubstr.editor.modal.field_name') }}
        <question-tooltip :text="translateText('hubstr.editor.modal.hint_list_line_raw')" />
      </template>
      <el-input
        v-model="titleProxy"
        :maxlength="40"
        @focusin="warningChangeTextWithAutoComplete"
      />
    </el-form-item>
    <el-form-item :label="translateText('hubstr.editor.modal.icon')">
      <el-select v-model="formData.leftIcon">
        <el-option
          v-for="option in icons"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        >
          <div class="option">
            <component :is="option.icon" class="list-line__icon" />
            <span>{{ option.label }}</span>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.isUserRequired" :label="translateText('hubstr.editor.fields.required_app')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.isAdminRequired" :label="translateText('hubstr.editor.fields.required_admin')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasPrivacy" :label="translateText('hubstr.editor.fields.has_privacy')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.additions.allowSearch" :label="translateText('hubstr.editor.modal.allow_search')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasAutoComplete" :label="translateText('hubstr.editor.modal.add_autocomplete')" />
    </el-form-item>
  </el-form>
  <FormAutocomplete
    v-if="formData.hasAutoComplete"
    :key="formData.additions.code"
    :field-code="formData.additions.code"
    @update:values="[
      updateHandler(formRef),
      emitAutoComplete($event),
    ]"
  />
</template>

<script setup lang="ts">
import { reactive, watch, ref } from 'vue';
import translit from 'features/settings/editor/utils/translit';
import { v4 as uuidV4 } from 'uuid';
import People from 'features/settings/editor/assets/icons/people.svg';
import Peoples from 'features/settings/editor/assets/icons/peoples.svg';
import PieChart from 'features/settings/editor/assets/icons/pie_chart.svg';
import Suitcase from 'features/settings/editor/assets/icons/suitcase.svg';
import Folder from 'features/settings/editor/assets/icons/folder.svg';
import FolderFull from 'features/settings/editor/assets/icons/folder_full.svg';
import { WIDGET_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import {
  IListLineRawActionWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import { FormInstance } from 'element-plus';
import { magnerAlert, translate, useTranslate } from 'magner';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import QuestionTooltip from 'features/settings/editor/residents-editor/ui/components/left-side/components/question-tooltip.vue';
import {
  MOBILE_VALIDATORS,
} from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/types/constants';
import FormAutocomplete
, {
  AutocompleteData,
} from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/components/form-autocomplete.vue';

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

interface Props {
  data?: IListLineRawActionWidget;
  isEdit?: boolean;
  isNew?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits(['update:formData', 'input:error', 'update:autocomplete']);
const store = useEditorStore();
const state = (props.data && JSON.parse(JSON.stringify(props.data))) || undefined;
const warningChangeTextWithAutoCompleteOk = ref(false);

const formRef = ref<FormInstance>();

const formData = reactive<IListLineRawActionWidget>(
  state || {
    id: uuidV4(),
    type: WIDGET_TYPES.LIST_LINE_RAW,
    leftText: '',
    name: null,
    rightText: '',
    leftIcon: 'suitcase',
    rightIcon: null,
    isMultiline: true,
    dataProvider: null,
    hasAutoComplete: false,
    hasPrivacy: false,
    additions: {
      code: '',
      variableType: 'text',
      allowSearch: false,
      isUserRequired: false,
      isAdminRequired: false,
      glType: 'user',
      entityType: 'user',
      isAutocompletedField: false,
      format: 'DEFAULT',
      hasDisplayType: false,
      formCode: '',
      description: null,
      isMultiline: false,
      mobileValidator: {},
    },
  } as IListLineRawActionWidget,
);

const titleProxy = ref(t[formData.leftText?.replace('lang.', '')] || formData.leftText);

const icons = [
  { label: 'People', value: 'people', icon: People },
  { label: 'Peoples', value: 'peoples', icon: Peoples },
  { label: 'Pie chart', value: 'pie_chart', icon: PieChart },
  { label: 'Suitcase', value: 'suitcase', icon: Suitcase },
  { label: 'Folder', value: 'folder', icon: Folder },
  { label: 'Folder full', value: 'folder_full', icon: FolderFull },
];

const uniqueValidator = (rule: any, value: any, callback: any) => {
  if (!props.isEdit && store.residentAdditionalCodes.value.includes(formData.additions.code)) {
    callback(new Error());
  } else {
    callback();
  }
};

const fieldBuilder = () => {
  if (!props.isEdit || formData.isNew) {
    // "code" меняется только при создании нового поля(или редактировании нового(!) поля)
    const code = `${formData.additions.entityType}${translit(formData.leftText, 'pascal')}`;
    formData.additions.code = code;
    formData.additions.formCode = `data.additions.${code}`;
    formData.rightText = `data.additions.${code}`;
  }
  // если есть перевод оставляем код, если нет то свое значение
  if (titleProxy.value?.toLowerCase() !== t[formData.additions.code]?.toLowerCase()) {
    formData.leftText = titleProxy.value.trim();
    formData.additions.description = titleProxy.value.trim();
  } else {
    formData.leftText = `lang.${formData.additions.code}`;
    formData.additions.description = null;
  }

  if (formData.additions.variableType === 'link') {
    formData.name = 'site';
  }
  if (formData.additions.variableType === 'text') {
    formData.additions.mobileValidator = MOBILE_VALIDATORS.TEXT;
  }

  if (!formData.hasAutoComplete && formData.additions.variableType === 'array') {
    formData.additions.variableType = 'text';
    formData.additions.isAutocompletedField = false;
    formData.additions.mobileValidator = MOBILE_VALIDATORS.TEXT;
  }
  if (formData.hasAutoComplete && formData.additions.variableType !== 'array') {
    formData.additions.variableType = 'array';
    formData.additions.isAutocompletedField = true;
    formData.additions.isMultiline = false;
    formData.additions.mobileValidator = MOBILE_VALIDATORS.AUTOCOMPLETE;
  }

  if (props.isNew) {
    formData.isNew = true; // переменная определяет новое ли поле
  }
  formData.touch = true; // переменная определяет изменилась ли formData
};

const updateHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  fieldBuilder();

  formEl.validate((valid) => {
    if (valid) {
      // submit
      emit('input:error', false);
      emit('update:formData', formData);
    } else {
      emit('input:error', true);
    }
  });
};

const emitAutoComplete = (event: { field: string; values: AutocompleteData[] }) => {
  emit('update:autocomplete', { field: formData.additions.code, values: event.values });
};

// TODO: сделать что бы список автокомплита не зависел от кода поля
const warningChangeTextWithAutoComplete = () => {
  if (formData.isNew && formData.hasAutoComplete && formData.leftText !== '' && !warningChangeTextWithAutoCompleteOk.value) {
    magnerAlert({
      type: 'warning',
      title: 'Внимание',
      message: 'При изменении названия поля, весь прогресс заполнения автокомплита будет сброшен. Продолжить?',
    }).then(() => warningChangeTextWithAutoCompleteOk.value = true);
  }
};

/**
 * Принудительно с большой буквы
 * */
watch(() => titleProxy.value, (newValue) => {
  titleProxy.value = newValue?.charAt(0).toUpperCase() + newValue.slice(1);
});

watch(
  () => formData,
  () => {
    updateHandler(formRef.value);
  },
  { deep: true },
);

</script>

<style scoped>
.list-line__icon {
  width: 20px;
}
.option {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.el-checkbox {
  font-weight: lighter;
}
</style>
