<template>
  <el-form
    ref="formRef"
    :model="formData"
    @change="updateHandler(formRef)"
    @input="updateHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="title"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <el-input
        v-model="titleProxy"
        :maxlength="40"
      />
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { FormInstance } from 'element-plus';
import {
  onMounted, reactive, ref, watch,
} from 'vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import {
  IBlockReasonWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import { v4 as uuidV4 } from 'uuid';
import {
  WIDGET_TYPES,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import { translate, useTranslate } from 'magner';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import translit from 'features/settings/editor/utils/translit';

interface Props {
  data?: IBlockReasonWidget,
  isEdit?: boolean,
}
const props = defineProps<Props>();
const emit = defineEmits(['update:formData', 'input:error', 'update:autocomplete']);
const store = useEditorStore();
const state = (props.data && JSON.parse(JSON.stringify(props.data))) || undefined;

const formRef = ref<FormInstance>();

const formData = reactive<IBlockReasonWidget>(
  state || {
    id: uuidV4(),
    type: WIDGET_TYPES.TEXT_SECTION,
    title: 'lang.userBlockReason',
    text: 'data.additions.userBlockReason',
    additions: {
      code: 'userBlockReason',
      variableType: 'text',
      allowSearch: false,
      isUserRequired: false,
      isAdminRequired: false,
      glType: 'user',
      entityType: 'user',
      isAutocompletedField: false,
      format: 'DEFAULT',
      hasDisplayType: false,
      formCode: 'data.additions.userBlockReason',
      description: null,
      isMultiline: true,
      mobileValidator: {
        type: 'text',
        maxValue: 4000,
      },
    },
  } as IBlockReasonWidget,
);

const titleProxy = ref(t[formData.title?.replace('lang.', '')] || formData.title);

const fieldBuilder = () => {
  // если есть перевод оставляем код, если нет то свое значение.
  if (titleProxy.value?.toLowerCase() !== t[formData.additions.code]?.toLowerCase()) {
    formData.title = titleProxy.value.trim();
    formData.additions.description = titleProxy.value.trim();
  } else {
    formData.title = `lang.${formData.additions.code}`;
    formData.additions.description = null;
  }
};
const updateHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  fieldBuilder();

  formEl.validate((valid) => {
    if (valid) {
      // submit
      emit('input:error', false);
      emit('update:formData', formData);
    } else {
      emit('input:error', true);
    }
  });
};

onMounted(() => updateHandler(formRef.value));

watch(
  () => formData,
  () => {
    updateHandler(formRef.value);
  },
  { deep: true },
);

/**
 * Принудительно с большой буквы
 * */
watch(() => titleProxy.value, (newValue) => {
  titleProxy.value = newValue?.charAt(0).toUpperCase() + newValue.slice(1);
});

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style scoped>

</style>
