import { User } from 'features/login/requests';
import { PreferenceItem } from 'features/settings/preferences/interfaces';
import { request } from '~/utils/request';
import { APP_ID, META_APP } from '~/constants';

const profileRequest = request.profile(async ({
  api, parseError, lstorage, router, appStore,
}) => {
  const res = await api.get<{ result: User, token: string }>('/api/admin/profile');
  if (res.error) {
    // Авторизация по токену в query параметрах
    if (APP_ID === META_APP) {
      setTimeout(() => {
        const token = router.currentRoute.value.query?.token;
        if (token) {
          lstorage.put('token', token);
          window.location.reload();
        }
      });
    }

    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return { error: parseError(res.error) };
  }

  lstorage.put('id', res.data?.result.id);

  // Получение настроек приложения
  const preferencesRes = await api.get<{ data: { list: Array<PreferenceItem> } }>('/api/admin/setting/list?page=1&count=150');
  if (preferencesRes.error) {
    return { error: parseError(preferencesRes.error) };
  }
  appStore.setPreferences(preferencesRes.data?.data.list);

  return {
    data: {
      user: res.data?.result,
      role: res.data?.result.roles?.[0],
    },
  };
});

export default profileRequest;
