import { BossWord } from 'features/profile/bossword/types';
import { request } from '~/utils/request';

export const getBossWord = request.card<BossWord, BossWord>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: BossWord }>('/api/admin/boss-word');
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data.data };
});

const save = (body: BossWord) => {
  const fileCreate = request.custom<any, BossWord>(async ({ api, data }) => await api
    .instance
    .url('/api/admin/boss-word')
    .formData({
      ...data,
    })
    .post()
    .json((response) => (response.code === 200 ? { data: response.data } : { error: response.errors }))
    .catch(({ text }) => {
      const error = JSON.parse(text);

      return {
        error: error.result.errors,
      };
    }) as any);

  return fileCreate(body);
};

export const updateBossWord = request.card(async ({ api, parseError, data }) => {
  const body: Partial<BossWord> = {};

  if (data.data.text) {
    body.text = data.data.text;
  }

  if (data.data.title) {
    body.title = data.data.title;
  }

  if (data.data.image && Array.isArray(data.data.image)) {
    body.image = (data.data.image as File[])[0];
  }

  const res = await save(body as BossWord);

  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  return res;
});

// export const updateBossWord = request.card(async ({ api, parseError, data }) => {
//   console.log('data', data);
//   const body = {
//     title: data.data.title,
//     text: data.data.text,
//   };
//
//   if (data.data.image) {
//     const fileCreate = request.custom<any, File>(async ({ api, data }) => await api
//       .instance
//       .url('/api/admin/boss-word')
//       .formData({
//         image: data,
//         ...body,
//       })
//       .post()
//       .json((response) => {
//         const d = (response as { result: { id: string } })?.result;
//         return d?.id ? { data: d } : { error: 'Не удалось загрузить файл' };
//       })
//       .catch(() => ({
//         error: 'Не удалось загрузить файл',
//       })) as any);
//
//     const res = fileCreate((data.data.image as File[])[0]);
//
//     if (res.error) {
//       return {
//         error: {
//           message: '',
//           fields: { image: res.error as unknown as string },
//         },
//       };
//     }
//     return { data: res.data.data };
//   }
//
//   const res = await api.post<{ data: BossWord }>('/api/admin/boss-word', body);
//   if (res.error) {
//     return { error: parseError?.(res.error) };
//   }
//   return { data: res.data.data };
// });
