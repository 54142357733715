import { v4 as uuidv4 } from 'uuid';
import { Address } from 'features/addresses/types/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/address';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const addressRead = request.table<Address>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  const sort = '&sort[0][id]=name&sort[0][value]=ASC';
  const search = data.filters.name ? `&filters[0][id]=search&filters[0][value]=${data.filters.name}` : '';
  const res = await api.get<{ list: Address[], pager: Pager }>(
    `${BASE_URL}/list?${pagination}${sort}${search}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const addressSearch = request.custom<{ rows: { id: string, title: string }[] }, string>(async ({ api, data }) => {
 const search = data.search ? `?search=${data.search}` : '';
  const res = await api.get<{data: { list: { id: string, title: string }[] }}>(`/api/admin/event/address/autocomplete${search}`);

  if (res.error) {
    return {
      data: { rows: [] },
    };
  }

  return {
    data: { rows: res.data?.data.list },
  };
});

export const addressGet = request.card<Address, Address>(async ({ api, data, parseError }) => {
  const res = await api.get<{ address: Address }>(`${BASE_URL}?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data.address };
});

export const addressCreate = request.card<Address, Address>(async ({
  api, parseError, data, router,
}) => {
  const res = await api.post<{ address: Address }>(`${BASE_URL}`, {
    ...data.data,
    id: uuidv4(),
  });
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'address', params: { id: res.data?.address.id } });
  return { data: res.data.address };
});

export const addressUpdate = request.card(async ({ api, parseError, data }) => {
  const res = await api.patch<{ address: Address }>(`${BASE_URL}`, {
    ...data.data,
    id: data.id,
  });
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data.address };
});

export const addressDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const res = await api.delete(`${BASE_URL}?id=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'addresses' });
  return { data: 'ok' };
});
