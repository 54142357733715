import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import {
  Company, CompanyData,
} from 'features/residents/companies/types/hubstr';
import { Resident } from 'features/residents/types/hubstr';
import { translate, useTranslate } from 'magner';
import { request } from '~/utils/request';

export const companyRead = request.table<Company>(async ({ api, data }) => {
  const { customT } = useTranslate();
  const residentId = window.location.pathname.replace('/admin/residents/', '').replace('/companies', '');
  const user = `?id=${residentId}`;

  const [resCompanies, resResident] = await Promise.all([
    api.get<{ data: Company[] }>(
      `/api/admin/user/association/list${user}`,
    ),
    api.get<{ user: Resident }>(`/api/admin/user/read?id=${residentId}`),
  ]);
  if (resCompanies.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  const headerEl = document.querySelector('.page .magner-page-header_title');
  if (headerEl && resResident.data) {
    const name = `${resResident.data.user.firstName} ${resResident.data.user.lastName}`;
    headerEl.textContent = `${customT(translate('hubstr.resident.companies.table.title'))} ${name}`;
  }

  return {
    data: {
      rows: resCompanies.data?.data.map((item) => ({
        ...item,
        ...item.additions,
      })),
      pagination: null,
    },
  };
});

export const companyGet = request.card<Company, CompanyData>(async ({ api, data, parseError }) => {
  const { customT } = useTranslate();

  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';

  const [resCompany, resResident] = await Promise.all([
    api.get<{ data: Company }>(`/api/admin/user/association/read/${data.id}`),
    api.get<{ user: Resident }>(`/api/admin/user/read?id=${residentId}`),
  ]);

  if (resCompany.error || resResident.error) {
    return { error: { message: customT(translate('hubstr.resident.companies.table.error')), fields: {} } };
  }

  return {
    data: {
      ...resCompany.data.data,
      ...resCompany.data.data.additions,
      userId: resResident.data.user as any,
    },
  };
});

const createCompanyNestedKeys = ['associationTurnoverPerYear', 'associationAreas', 'associationUserPosition', 'associationNumberOfEmployees', 'associationTelegram', 'associationGeography', 'associationWasteTypes', 'associationSite'];
export const companyCreate = request.card<Company, CompanyData>(async ({
  data, api, parseError, router,
}) => {
  const id = uuidv4();
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';

  const body: any = { id, additions: {}, userId: residentId };
  Object.entries(data.data).forEach(([key, val]) => {
    if (createCompanyNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });

  if (data.data.associationAreas) body.additions.associationAreas = data.data.associationAreas.join(',');

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      id as string,
      (data.data.logo as File[])[0],
      'association',
      'company_logo',
      'companyLogo',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  } else {
    body.logo = null;
  }

  const res = await api.post<{ company: Company }>('/api/admin/user/association/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-companies', params: { id: residentId } });
  return { data: res.data?.company };
});

export const companyUpdate = request.card<Company, CompanyData>(async ({
  api, parseError, data, router,
}) => {
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';
  const body: any = {
    id: data.id, additions: {}, userId: residentId,
  };

  Object.entries(data.data).forEach(([key, val]) => {
    if (createCompanyNestedKeys.includes(key)) {
      body.additions[key] = val;
    } else {
      body[key] = val;
    }
  });

  if (data.data.associationAreas) body.additions.associationAreas = data.data.associationAreas.join(',');

  if (data.data.logo) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.logo as File[])[0],
      'association',
      'company_logo',
      'companyLogo',
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { logo: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.logo = fileUploadRes.data?.id;
  }

  const res = await api.patch<{ company: Company }>('/api/admin/user/association/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  await router.push({ name: 'resident-companies', params: { id: residentId } });
  return { data: res.data?.company };
});

export const companyDelete = request.card(async ({
  api, data, parseError, router,
}) => {
  const residentId = window.location.search.match(/\?resident=([^&]+)/)?.[1] || '';

  const res = await api.delete(`/api/admin/user/association/delete/${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'resident-companies', params: { id: residentId } });
  return { data: 'ok' };
});
