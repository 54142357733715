import { Gto } from 'features/profile/sports/gto/types';
import { request } from '~/utils/request';

export const getGto = request.card<Gto, Gto>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Gto }>('/api/admin/sports/gto');
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data.data };
});

const save = (body: Gto) => {
  const fileCreate = request.custom<any, Gto>(async ({ api, data }) => await api
    .instance
    .url('/api/admin/sports/gto')
    .formData({
      ...data,
    })
    .post()
    .json((response) => (response.code === 200 ? { data: response.data } : { error: response.errors }))
    .catch(({ text }) => {
      const error = JSON.parse(text);

      return {
        error: error.result.errors,
      };
    }) as any);

  return fileCreate(body);
};

export const updateGto = request.card(async ({ api, parseError, data }) => {
  const body: Partial<Gto> = {};

  if (data.data.text) {
    body.text = data.data.text;
  }

  if (data.data.title) {
    body.title = data.data.title;
  }

  if (data.data.image && Array.isArray(data.data.image)) {
    body.image = (data.data.image as File[])[0];
  }

  const res = await save(body as Gto);

  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  return res;
});
