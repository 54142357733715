import { IOnboardScreen } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import { magnerMessage } from 'magner';
import { request } from '~/utils/request';

export interface IOnboardAdapter {
  indicatorStyle: 'line' | 'dot',
  hasWidgets: boolean,
  slides: IOnboardScreen[],
}

interface IIndicatorStyle {
  category: 'onboarding',
  code: 'onboardingIndicatorStyle',
  value: 'line' | 'dot',
  updatedAt: string
}

export const indicatorStyleGet = request.custom<string>(async ({ api, parseError }) => {
  const res = await api.get<{ data: IIndicatorStyle }>('/api/admin/setting?code=onboardingIndicatorStyle');

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data.data?.value,
  };
});

export const onboardingGet = request.custom<{ onboardingEditorLayout: IOnboardAdapter }>(async ({ api, data, parseError }) => {
  const [indicatorStyle, onboardingData] = await Promise.all([
    await indicatorStyleGet(''),
    await api.get<{ data: IOnboardAdapter }>('/api/admin/onboarding/list'),
  ]);

  if (indicatorStyle.error || onboardingData.error) {
    return { error: indicatorStyle.error || onboardingData.error };
  }

  return {
    data: {
      onboardingEditorLayout: {
        indicatorStyle: indicatorStyle.data || 'line',
        hasWidgets: true,
        slides: onboardingData.data?.data.slides || [],
      },
    },
  };
});

export const publishImages = request.custom<any>(async ({ api, data, parseError }) => {
  const query = data ? `?${Object.keys(data).map((key) => `images[${key}]=${data[key]}`).join('&')}` : '';

  const res = await api.post<any>(
    `/api/image/mass_publish${query}`,
    {},
  );

  if (res.error) {
    return { error: res.error };
  }

  return {
    data: 'ok',
  };
});

export const onboardingUpdate = request.custom<IOnboardScreen[], { data: { onboardingEditorLayout: IOnboardAdapter } }>(async ({ api, data, parseError }) => {
  const images: string[] = [];

  if (!data.data.onboardingEditorLayout.hasWidgets) {
    data.data.onboardingEditorLayout.slides.forEach((slide) => slide.widgets = []);

    // Ищем изображения в слайдах
    data.data.onboardingEditorLayout.slides.forEach((slide) => {
      if (slide.imgId) {
        images.push(slide.imgId);
      }
    });
  } else {
    // Ищем изображения в виджетах
    data.data.onboardingEditorLayout.slides.forEach((slide) => {
      slide.widgets.forEach((widget) => {
        if (widget.type === WIDGET_TYPES.IMAGE && widget.imgId) {
          images.push(widget.imgId);
        }
      });
    });

    // Слайды с пустыми виджетами не сохраняем
    const isEmptySlides = data.data.onboardingEditorLayout.slides.find((slide) => !slide.widgets?.length);
    if (isEmptySlides) {
      magnerMessage({
        type: 'error',
        message: 'Нельзя сохранить пустой слайд',
      });
      return { error: 'Нельзя сохранить пустой слайд' };
    }
  }

  const { hasWidgets, ...body } = data.data.onboardingEditorLayout;

  const res = await api.post<{ data: IOnboardScreen[] }>(
    '/api/admin/onboarding/full-update',
    { ...body },
  );

  if (res.error) {
    return { error: parseError(res.error) };
  }

  // Публикуем изображения
  if (images.length) {
    const imagePublishRes = await publishImages(images);

    if (imagePublishRes.error) {
      return { error: imagePublishRes.error };
    }
  }

  return {
    data: res.data.data,
  };
});

export const onboardingSlideDelete = request.custom(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/onboarding/${data}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: 'ok',
  };
});
