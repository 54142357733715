<template>
  <div v-loading="isLoading">
    <el-row>
      <h1>Жалоба</h1>
    </el-row>
    <el-row>
      <ul class="complaint-list">
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_author') }}:</el-col>
          <el-col :span="16">
            <el-link type="primary" @click="clickLink(complaint?.author?.id)">{{ complaint?.author?.name }}</el-link>
          </el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.created_at) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_text') }}:</el-col>
          <el-col :span="16">{{ complaint?.text }}</el-col>
        </li>
      </ul>
      <ul
        v-if="complaint.target?.type === 'post'"
        class="complaint-list"
      >
        <el-divider />
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_object') }}:</el-col>
          <el-col :span="16">{{ complaint?.target?.title }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_published_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.target.published_at) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_published_text') }}:</el-col>
          <el-col :span="16">{{ complaint?.target.text }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_published_author') }}:</el-col>
          <el-col :span="16">
            <el-link type="primary" @click="clickLink(complaint?.target.creator?.id)">{{ complaint?.target.creator?.name }}</el-link>
          </el-col>
        </li>
      </ul>
      <ul
        v-if="complaint.target?.type === 'user'"
        class="complaint-list"
      >
        <el-divider />
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_object') }}:</el-col>
          <el-col :span="16">
            <el-link type="primary" @click="clickLink(complaint.target.id)">{{ complaint.target.title }}</el-link>
          </el-col>
        </li>
      </ul>
      <ul
        v-if="complaint.target?.type === 'comment' && !complaint.target?.date_start"
        class="complaint-list"
      >
        <el-divider />
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_object') }}:</el-col>
          <el-col :span="16">{{ complaint?.target?.title }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_published_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.target.published_at) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_published_text') }}:</el-col>
          <el-col :span="16">{{ complaint?.target.post_text }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.target.commented_at) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_text') }}:</el-col>
          <el-col :span="16">{{ complaint?.target.comment_text }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_author') }}:</el-col>
          <el-col :span="16">
            <el-link type="primary" @click="clickLink(complaint.target.creator?.id)">{{ complaint.target.creator?.name }}</el-link>
          </el-col>
        </li>
      </ul>
      <ul
        v-if="complaint.target?.type === 'comment' && complaint.target?.date_start"
        class="complaint-list"
      >
        <el-divider />
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_object') }}:</el-col>
          <el-col :span="16">{{ complaint?.target?.title }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_event_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.target.date_start) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_event_name') }}:</el-col>
          <el-col :span="16">{{ complaint?.target.event_title }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_time') }}:</el-col>
          <el-col :span="16">{{ formatDate(complaint?.target.commented_at) }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_text') }}:</el-col>
          <el-col :span="16">{{ complaint?.target.comment_text }}</el-col>
        </li>
        <li class="complaint-row">
          <el-col :span="8">{{ translateText('hubstr.complaints.modal.complaint_comment_author') }}:</el-col>
          <el-col :span="16">
            <el-link type="primary" @click="clickLink(complaint.target.creator?.id)">{{ complaint.target.creator?.name }}</el-link>
          </el-col>
        </li>
      </ul>
      <el-button @click="close">{{ translateText('hubstr.complaints.modal.cancel_button') }}</el-button>
    </el-row>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref,
} from 'vue';
import { Complaint } from 'features/settings/complaints/types/hubstr';
import { complaintGet } from 'features/settings/complaints/requests/hubstr';
import { useTranslate, translate } from 'magner';
import { formatDate } from '~/utils/format-date';

export default defineComponent({
  name: 'ObserveComplaint',
  props: {
    complaintId: {
      type: String,
      required: true,
    },
  },
  emits: ['success'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const isLoading = ref<Boolean>(false);
    const complaint = ref<Complaint>({});

    const getComplaint = async (id: string) => {
      isLoading.value = true;
      const res = await complaintGet(id);
      complaint.value = res.data as Complaint;
      isLoading.value = false;
    };

    const close = () => {
      emit('success');
    };

    const clickLink = (residentId: string | undefined) => {
      window.open(`/residents/${residentId}`, '_blank');
    };

    onMounted(() => {
      getComplaint(props.complaintId);
    });

    return {
      complaint,
      isLoading,
      close,
      formatDate,
      clickLink,
      translateText: (code: string) => customT(translate(code)),
    };
  },
  methods: { formatDate },
});
</script>

<style>
h4 {
  margin: 0;
}

.change-community-manager-modal {
  width: 700px !important;
}

.el-row {
  margin-bottom: 30px;
}

:deep(.select-error .el-input__inner) {
  box-shadow: 0 0 0 1px #ff3333 inset !important;
}

.complaint-list {
  width: 100%;
  padding: 0;
}

.complaint-row {
  width: 100%;
  padding: 0 5px;
  margin-bottom: 15px;
  min-width: 300px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.complaint-row > div:first-child {
  font-weight: bold;
}

</style>
