import { MetaHubstrFieldsGroup } from 'features/residents/layouts/meta-hubstr';
import { TranslatesAssociationList as t } from 'features/settings/editor/association-editor/requests';
import AdminFieldEntity from 'features/settings/mobile-editor/factories/admin-field-entity';
import { IAViewWidgets } from 'features/settings/editor/association-editor/interfaces';

/**
 * @param mobileView
 * */
const parseForAdmin = (mobileView: IAViewWidgets) => {
  const body: MetaHubstrFieldsGroup[] = [];

  // Группы
  mobileView.body
    .forEach((group) => {
      const fieldsGroup: MetaHubstrFieldsGroup = {
        title: group.title,
        fields: [],
      };

      // Виджеты
      group.widgets
        .forEach((widget) => {
          if ('additions' in widget) {
            // Генерируем поле
            const fieldFactoryInit = new AdminFieldEntity(widget, widget.additions, t);
            fieldsGroup.fields.push(fieldFactoryInit.create() as never);
          }
        });

      body.push(fieldsGroup);
    });

  return body;
};

export default parseForAdmin;
