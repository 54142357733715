import { cardPageController, translate } from 'magner';
import { hubstrLayout } from 'features/residents/companies/layouts/hubstr';
import type { CompanyData } from 'features/residents/companies/types/hubstr';
import {
  companyGet, companyCreate, companyUpdate, companyDelete,
} from 'features/residents/companies/requests/hubstr';

const companyConfig = cardPageController<CompanyData>({
  getRequest: companyGet,
  createRequest: companyCreate,
  updateRequest: companyUpdate,
  deleteRequest: companyDelete,

  confirmDelete: true,

  header: {
    title: translate('hubstr.resident.form.companies.title'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.resident.form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.resident.form.companies.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.resident.form.remove_button'),
          type: 'danger',
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: hubstrLayout as any,
    },
  },
});

export default companyConfig;
