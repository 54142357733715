import { v4 as uuidv4 } from 'uuid';
import { fileUpload } from 'features/common/file-request';
import { SportsTourney, SportsTourneyFull } from 'features/profile/sports/tourney/types';
import { request } from '~/utils/request';
import { imageUploadCode } from '~/constants';

export const sportsTourneysRead = request.table<SportsTourney>(async ({ api }) => {
  const res = await api.get<{ links: SportsTourney[] }>('/api/admin/link/list?groupCode=sports_tourney');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.links,
      pagination: null,
    },
  };
});

export const sportsTourneyGet = request.card<SportsTourneyFull, SportsTourneyFull>(
  async ({ api, data, parseError }) => {
    const res = await api.get<{ link: SportsTourneyFull }>(`/api/admin/link/get?link=${data.id}`);
    if (res.error) {
      return { error: parseError(res.error) };
    }
    return {
      data: {
        ...res.data?.link,
        group: res.data?.link.group?.id as any,
      },
    };
  },
);

export const sportsTourneyCreate = request.card<SportsTourneyFull, SportsTourneyFull>(
  async ({ api, parseError, data }) => {
    const id = uuidv4();

    const body = {
      ...data.data,
      id,
      type: 'bottomSheet',
      groupCode: 'sports_tourney',
    };

    if (data.data.image) {
      const fileUploadRes = await fileUpload(
      id as string,
      (data.data.image as File[])[0],
      'link',
      imageUploadCode,
      'default',
      true,
      );

      if (fileUploadRes.error) {
        return {
          error: {
            message: '',
            fields: { image: fileUploadRes.error as unknown as string },
          },
        };
      }
      body.image = fileUploadRes.data?.id;
    } else {
      body.image = null;
    }

    const res = await api.post<{ link: SportsTourneyFull }>('/api/admin/link/create', body);
    if (res.error) {
      return { error: parseError?.(res.error) };
    }
    return { data: res.data?.link };
  },
);

export const sportsTourneyUpdate = request.card<SportsTourneyFull, SportsTourneyFull>(
  async ({ api, parseError, data }) => {
    const body = { ...data.data, link: data.id };

    if (data.data.image) {
      const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.image as File[])[0],
      'link',
      imageUploadCode,
      'default',
      true,
      );

      if (fileUploadRes.error) {
        return {
          error: {
            message: '',
            fields: { image: fileUploadRes.error as unknown as string },
          },
        };
      }
      body.image = fileUploadRes.data?.id;
    }

    const res = await api.patch<{ link: SportsTourneyFull }>('/api/admin/link/update', body);
    if (res.error) {
      return { error: parseError?.(res.error) };
    }
    return { data: res.data?.link };
  },
);

export const sportsTourneyDelete = request.card(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/link/delete?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
