<template>
  <el-image
    v-if="logoUrl"
    style="width: 50px; height: inherit"
    :src="logoUrl"
    fit="scale-down"
  />
  <template v-else>
    <LogoHubstr />
  </template>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import LogoHubstr from 'assets/hubstr/icons/logo-collapsed.svg';
import useStore from '~/store/useStore';

export default defineComponent({
  name: 'LogoNotCollapsed',
  components: { LogoHubstr },
  setup () {
    const store = useStore();
    const logoUrl = ref('');
    const local = JSON.parse(window.localStorage.getItem('magner-hubstr') as string);

    onMounted(async () => {
      if (local.logo) {
        logoUrl.value = local.logo.thumb_100;
      }
    });

    watch(() => store.defaultLogo, () => {
      logoUrl.value = store.defaultLogo?.value.thumb_100;
    }, { deep: true });

    return {
      logoUrl,
    };
  },
});
</script>

<style scoped>

</style>
