import { cardPageController, translate } from 'magner';
import AppearanceLayout from 'features/settings/appearance/layout/index.vue';
import { allGet, allUpdate } from 'features/settings/appearance/requests';

const appearanceConfig = cardPageController({
  getRequest: allGet,
  updateRequest: allUpdate,
  deleteRequest: '',

  header: {
    title: translate('hubstr.appearance.form.title'),
  },
  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.appearance.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.appearance.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'appearanceLayout',
        component: () => AppearanceLayout,
        props: {},
        // validation: [{
        //   type: 'interview' as 'empty',
        //   trigger: 'blur',
        // }],
      },
    ],
  },
});

export default appearanceConfig;
