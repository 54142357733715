<template>
  <label for="">{{ t[data.title.replace('lang.', '')] || data.title }}</label>
  <p>
    {{ data.hasAutoComplete ?
      `${translateText('hubstr.editor.phone_preview.text')} 1, ${translateText('hubstr.editor.phone_preview.text')} 2`
      : translateText('hubstr.editor.phone_preview.text')
    }}
  </p>
  <el-divider />
</template>

<script lang="ts" setup>
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import { translate, useTranslate } from 'magner';
import { ITextSectionWidget } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';

interface Props {
  data: ITextSectionWidget
}

defineProps<Props>();

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style scoped>
label {
  color: #8A8A8F;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.016em;
}
.el-divider--horizontal {
  margin: 0 0 15px 0;
}
</style>
