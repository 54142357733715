import { cardPageController, translate } from 'magner';
import { ActionButton } from 'magner/dist/types/utils/actions';
import FaqLayout from 'features/profile/faq/components/layout/index.vue';
import { getFaqLayout, updateFaqLayout } from 'features/profile/faq/requests';

const faqConfig = cardPageController<any>({
  header: {
    title: translate('gaz.faq.sidebar.card_title'),
  },

  getRequest: getFaqLayout,
  createRequest: null,
  updateRequest: updateFaqLayout,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.faq.form.cancel_button'),
        },
      } as ActionButton<'cancel'>,
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('gaz.faq.form.submit_button'),
          type: 'primary',
        },
      } as ActionButton<'submit'>,
    ],

    layout: [
      {
        type: 'custom',
        name: 'faqLayout',
        component: () => FaqLayout,
        props: {},
      },
    ],
  },
});

export default faqConfig;
