<template>
  <div class="inline-text">
    <div class="inline-text__label el-form-item__label">
      {{ customT(field.label) }}:
    </div>
    <div class="inline-text__content">
      {{ customT(content)  }}
    </div>
    <el-tooltip
      v-if="field.hint"
      :content="customT(field.hint)"
      effect="light"
      placement="bottom"
      popper-class="generic-form_item_tooltip"
    >
      <el-icon size="15" class="icon">
        <component :is="Question" />
      </el-icon>
    </el-tooltip>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import Question from 'features/settings/mobile-editor/assets/icons/question.svg';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'ResidentInfo',
  components: { Question },
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const { customT } = useTranslate();

    const content = ref(props.modelValue);

    if (props.field.formatter) {
      content.value = props.field.formatter(props.modelValue);
    }

    return {
      content,
      Question,
      customT,
    };
  },
});
</script>

<style scoped>
.inline-text {
  display: flex;
  align-items: center;
  gap: 5px;
}
.icon {
  color: var(--el-color-primary);
}
</style>
