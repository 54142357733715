import { filtersFormController, translate } from 'magner';
import { IFeedBack } from 'features/profile/feedback/types';

const feedbackFilters = filtersFormController<IFeedBack>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },
  filtersInSeparatePanel: false,
  filtersData: {
    text: '',
  },
  sort: {},

  layout: [{
    type: 'input',
    name: 'text',
    props: {
      placeholder: translate('gaz.feedback.filters.text'),
      inputDelay: 250,
    },
  }],
});

export default feedbackFilters;
