<template>
  <div class="button-complete-slot">
    <el-form ref="formRef" :model="formData" @change="submitHandler(formRef)">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Текст кнопки"
            prop="title"
          >
            <MagnerFormInput
              v-model.number="formData.title"
              :field="titleField"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { IButtonCompleteWidget } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import { InputField, MagnerFormInput } from 'magner';
import { FormInstance } from 'element-plus';

interface Props {
  editData?: IButtonCompleteWidget;
}

const props = withDefaults(defineProps<Props>(), {
  editData: undefined,
});
const emit = defineEmits(['update:formData', 'error']);

const state = props.editData && JSON.parse(JSON.stringify(props.editData));
const formRef = ref<FormInstance>();

const formData = reactive<IButtonCompleteWidget>(
  state
  || {
    type: WIDGET_TYPES.BUTTON_COMPLETE,
    title: 'Продолжить',
  } as IButtonCompleteWidget,
);

const titleField: InputField<any> = {
  type: 'input',
  name: 'title',
  dataType: 'string',
  props: {
    disabled: true,
  },
};

const submitHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return;
  }

  formEl.validate((valid) => {
    if (valid) {
      emit('error', false);
      emit('update:formData', formData);
      return true;
    }

    emit('error', true);
    return false;
  });
};

onMounted(() => submitHandler(formRef.value));
</script>

<style lang="scss" scoped>

</style>
