import { filtersFormController } from 'magner';
import { CustomNotification } from 'features/settings/notification/types/hubstr';

const notificationFilters = filtersFormController<CustomNotification>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersData: {},
  sort: {},

  layout: [],
});

export default notificationFilters;
