<template>
  <template v-if="state.success">
    <p style="text-align: center">{{ `${translateText('gaz.import.success_text')} ${state.importedCount}` }}</p>
  </template>
  <template v-else>
    <magner-form-dropzone
      v-model="state.file"
      :field="dropZoneField"
      @update:modelValue="fileSelected"
    />

    <p v-if="state.errorText.length" style="color: #ff3333; word-break: normal">{{ state.errorText }}</p>
  </template>
</template>
<script setup lang="ts">
import { MagnerFormDropzone, translate, useTranslate } from 'magner';
import { reactive } from 'vue';
import { importResidentsFileUpload } from 'features/residents/requests/gaz';

const state: {
  file: any,
  uploading: boolean,
  errorText: string,
  success: boolean,
  importedCount: number,
} = reactive({
  file: null,
  uploading: false,
  errorText: '',
  success: false,
  importedCount: 0,
});

const dropZoneField = {
  type: 'dropzone',
  name: 'file',
  dataType: 'number',
  label: translate('gaz.import.label'),
  props: {
    valueKey: 'id',
    srcKey: 'file',
    removable: true,
    formats: ['xlsx'],
    maxSize: 100,
    inputAtts: {
      accept: '.xlsx',
    },
  },
};

const fileSelected = async () => {
  if (state.uploading) {
    return;
  }
  state.uploading = true;
  state.errorText = '';

  const fileUploadRes = await importResidentsFileUpload((state.file as File[])[0]);
  if (fileUploadRes.error) {
    state.errorText = fileUploadRes.error as any;
    state.file = null;
  } else {
    state.importedCount = fileUploadRes.data.countImported;
    state.success = true;
  }

  state.uploading = false;
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>
