export const MOBILE_VALIDATORS = {
  AUTOCOMPLETE: {
    type: 'textAutocomplete',
    maxValues: 15,
    minValues: 1,
    canAddUserElements: true,
  },
  TEXT: {
    type: 'text',
    maxValue: 4000,
  },
  INT: {
    type: 'integer',
    maxValue: 9223372036854776000,
  },
} as const;
