<template>
  <div class="interview-preview-field-rate">
    <div class="interview-preview-field-rate__container">
      <el-rate
        :model-value="modelValue"
        :max="maxValue"
        size="large"
        @change="$emit('update:modelValue', $event)"
      />
    </div>

    <div class="interview-preview-field-rate__counter">
      {{ modelValue || 0 }} / {{ maxValue }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'IntreviewPreviewFielRate',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 5,
    },
  },

  emits: ['update:modelValue'],

  setup (props) {
    const maxValue = computed(() => Math.min(20, props.max));

    return {
      maxValue,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-rate {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: rgba(248, 248, 248, 1);
    border-radius: 8px;
  }

  &__counter {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    color: rgba(151, 151, 151, 1);
    margin-top: 8px;
  }

  .el-icon, .el-icon svg {
    width: 24px;
    height: 24px;
  }

  .el-icon.is-active svg {
    width: 29px;
    height: 29px;
    transform: translateX(-2px);
  }

  .el-icon svg path {
    fill: rgba(151, 151, 151, 1);
  }

  .el-icon.is-active svg path {
    fill: rgba(0, 122, 255, 1);
  }

  .el-rate__item {
    display: inline-block;
    position: relative;
  }

  .el-rate__icon {
    margin-right: 0;
  }

  .el-rate {
    display: block;
  }

  .el-rate--large {
    height: auto;
  }
}
</style>
