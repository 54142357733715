export const NOTIFICATION_STATUS: Record<string, Record<string, string>> = {
  draft: {
    text: 'hubstr.notification.status.draft',
    color: '#c8c9cc',
  },
  planned: {
    text: 'hubstr.notification.status.planned',
    color: '#a0cfff',
  },
  completed: {
    text: 'hubstr.notification.status.completed',
    color: '#b3e19d',
  },
  failed: {
    text: 'hubstr.notification.status.failed',
    color: '#fab6b6',
  },
};
