import { cardPageController, translate } from 'magner';
import { ActionButton } from 'magner/dist/types/utils/actions';
import { getLoyaltyMain, updateLoyaltyMain } from 'features/profile/loyalty/main/requests';

const loyaltyMainConfig = cardPageController<any>({
  header: {
    title: translate('gaz.loyalty.sidebar.card_title'),
    tabs: [
      {
        label: translate('gaz.loyalty.tabs.main'),
        link: { name: 'loyalty' },
        active: true,
      },
      {
        label: translate('gaz.loyalty.tabs.categories'),
        link: { name: 'loyalty-categories' },
        active: false,
      },
    ],
  },

  getRequest: getLoyaltyMain,
  createRequest: null,
  updateRequest: updateLoyaltyMain,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('gaz.loyalty.form.submit_button'),
          type: 'primary',
        },
      } as ActionButton<'submit'>,
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'textarea',
          name: 'text',
          label: translate('gaz.loyalty.form.text.label'),
          props: {
            required: true,
            placeholder: translate('gaz.loyalty.form.text.placeholder'),
            maxLength: 10000,
            showLetterLimit: true,
            rows: 15,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});

export default loyaltyMainConfig;
