import { tablePageController, translate } from 'magner';
import complaintsTable from 'features/settings/complaints/table/hubstr';
import { Resident } from 'features/residents/types/hubstr';
import { complaintsHead } from 'features/settings/complaints/requests/hubstr';

const complaintsConfig: any = tablePageController<Resident>({
  header: {
    title: translate('hubstr.complaints.table.title'),
    actions: [],
  },
  request: complaintsHead,
  table: complaintsTable as any,
  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
    pagination: {
      items: 25,
      page: 1,
    },
  },
});

export default complaintsConfig;
