<template>
  <div class="onboarding-editor">
    <el-row :gutter="20" :style="[!state.slides.length && 'text-align: center']">
      <el-col :lg="16">
        <div class="onboarding-editor__contents">
          <div class="onboarding-editor__settings">
            <h3 style="margin-bottom: 20px">Общие настройки</h3>
            <el-row :gutter="20" style="margin: 0">
              <!--                <el-col :lg="12" :md="24">-->
              <!--                  <el-form-item label="Настройка слайдов">-->
              <!--                    <el-checkbox v-model="hasWidgets">Использовать виджеты</el-checkbox>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
              <el-col :lg="12" :md="24">
                <el-form-item label="Индикатор">
                  <el-radio-group v-model="indicatorStyle">
                    <el-radio label="dot">
                      Точки
                    </el-radio>
                    <el-radio label="line">
                      Линии
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <template v-if="state.slides.length">
            <draggable
              :key="hasWidgets"
              :list="state.slides"
              group="slides"
              item-key="id"
              class="onboarding-editor__slides"
              @end="changeIndexSlidePosition"
            >
              <template #item="{element, index}">
                <Slides
                  :key="element + hasWidgets"
                  :data="element"
                  :has-widgets="hasWidgets"
                  @remove="removeSlide(index, $event)"
                  @focusin="carousel?.setActiveItem(index)"
                />
              </template>
            </draggable>
          </template>
          <template v-else>
            <el-empty description="Нет welcome-экранов. Хотите создать?" />
          </template>
        </div>
        <el-button style="margin-top: 20px" :disabled="state.slides.length >= 9" @click="addSlide">+ Добавить слайд</el-button>
      </el-col>
      <el-col :lg="8">
        <div class="onboarding-editor__preview">
          <PhoneMock>
            <el-carousel
              ref="carousel"
              :class="indicatorStyle === 'dot' && 'onboarding-editor__preview--dot'"
              height="624px"
              :autoplay="false"
              :loop="false"
              arrow="never"
              trigger="click"
              :initial-index="initialIndex"
            >
              <el-carousel-item
                v-for="item in state.slides"
                :key="item + state.slides"
              >
                <PreviewSlides :key="hasWidgets" :has-widgets="hasWidgets" :data="item" />
              </el-carousel-item>
            </el-carousel>
          </PhoneMock>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup lang="ts">
import {
  reactive, ref, watch, watchEffect,
} from 'vue';
import Slides from 'features/settings/onboarding-editor/components/slides/index.vue';
import PreviewSlides from 'features/settings/onboarding-editor/components/preview/preview-slides.vue';
import * as uuid from 'uuid';
import Draggable from 'vuedraggable-es';
import { IOnboardAdapter } from 'features/settings/onboarding-editor/requests';
import PhoneMock from 'features/settings/onboarding-editor/components/preview/phone-mock.vue';

interface Props {
  modelValue: IOnboardAdapter;
}

const props = withDefaults(defineProps<Props>(), {});
const state = reactive(props.modelValue);
const carousel = ref<{ setActiveItem:(index: number) => void }>();
console.log(state);
// const hasWidgets = ref(props.modelValue.hasWidgets || false);
const hasWidgets = ref(true);
const indicatorStyle = ref(props.modelValue.indicatorStyle || 'line');

const initialIndex = ref(0);
const addSlide = () => {
  state.slides.push({
    id: uuid.v4(),
    name: `Слайд ${state.slides.length + 1}`,
    code: `slide${state.slides.length + 1}`,
    text: 'Ваш текст',
    backgroundColor: '#F2F2F2',
    textColor: '#000',
    iconColor: '#000',
    widgets: [],
    position: state.slides.length + 1,
    hasWidgets: false,
  } as never);
};

const removeSlide = (index: number, id?: string) => {
  if (id) {
    state.slides.splice(state.slides.findIndex((slide) => slide.id === id), 1);
  } else {
    state.slides.splice(index, 1);
  }
};

const changeIndexSlidePosition = () => {
  state.slides.forEach((slide, index) => {
    slide.position = index + 1;
    slide.name = `Слайд ${index + 1}`;
  });
};

watch(() => state.slides.length, (newValue, oldValue) => {
  if (newValue > oldValue) {
    initialIndex.value = newValue - 1;
  } else {
    initialIndex.value = 0;
  }
});

watchEffect(() => {
  state.hasWidgets = hasWidgets.value;
  state.indicatorStyle = indicatorStyle.value;
});
</script>

<style lang="scss" scoped>
.onboarding-editor {
  width: 100%;
  transition: 0.3s;

  &__settings {
    box-shadow: var(--el-box-shadow-light);
    padding: 20px;
    border-radius: var(--el-border-radius-base);
    margin-bottom: 10px;

    .el-checkbox {
      font-weight: 100;
    }
  }

  &__slides {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__preview {
    position: sticky;
    top: 30px;

    .el-carousel:deep(.el-carousel__indicators) {
      width: 100%;
      text-align: center;
      mix-blend-mode: difference;
      bottom: 10px;
    }
    .onboarding-editor__preview--dot:deep(.el-carousel__button) {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }

    @media (max-width: 1199px) {
      margin-top: 30px;
    }
  }
}
</style>
