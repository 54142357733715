<template>
  <div class="default-group">
    <draggable
      :key="state.widgets"
      :list="state.widgets"
      group="widgets"
      item-key="title"
      class="widgets"
    >
      <template #item="{element}">
        <div class="widget">
          <component
            :is="setComponent(element)"
            :key="element"
            :data="element"
            @edit="$emit('edit', $event)"
            @remove="$emit('remove', $event)"
          />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable-es';
import { type Component, reactive } from 'vue';
import {
  IWidget,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/interfaces';
import {
  WIDGET_TYPES,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/constants';
import TextSectionWidget
  from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/text-section-widget.vue';
import ListLineWidget
  from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/list-line-widget.vue';
import ListLineRawActionWidget
  from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/list-line-raw-action-widget.vue';
import UnknownWidget
  from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/unknown-widget.vue';

interface Props {
  data: any
}
const props = defineProps<Props>();
const emit = defineEmits(['edit', 'remove']);
const state = reactive<any>(props.data);

const setComponent = (widget: IWidget): Component => {
  switch (widget.type) {
    case WIDGET_TYPES.TEXT_SECTION:
      return TextSectionWidget;
    case WIDGET_TYPES.LIST_LINE:
      return ListLineWidget;
    case WIDGET_TYPES.LIST_LINE_RAW:
      return ListLineRawActionWidget;
    default:
      return {};
  }
};

</script>

<style scoped>

</style>
