<template>
  <el-row :gutter="30" class="mobile-editor">
    <el-col
      :xl="16"
      :lg="16"
      :md="15"
      :sm="24"
      :xs="24"
    >
      <CollapseWrap>
        <template #title>
          <div class="mobile-editor__collapse-title">
            {{ translateText('hubstr.association_editor.main.header.title') }}
          </div>
        </template>
        <template #default>
          <HeaderModule :data="state" />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          <div class="mobile-editor__collapse-title">
            {{ translateText('hubstr.association_editor.main.body.title') }}
          </div>
        </template>
        <template #default>
          <BodyModule :data="state" />
        </template>
      </CollapseWrap>
    </el-col>
    <el-col
      :xl="8"
      :lg="8"
      :md="9"
      :sm="24"
      :xs="24"
    >
      <div class="phone-wrap">
        <PhoneMock :data="state">
          <template #view-content="{ data }">
            <PhoneViewContent :data="data" />
          </template>
          <template #edit-content="{ data }">
            <PhoneEditContent :data="data" />
          </template>
        </PhoneMock>
      </div>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import {
  magnerConfirm, magnerMessage, translate, useTranslate, 
} from 'magner';
import {
  computed, onMounted, reactive, ref, watch, watchEffect, 
} from 'vue';
import { IAViewWidgets } from 'features/settings/editor/association-editor/interfaces';
import CollapseWrap
  from 'features/settings/editor/association-editor/ui/components/left-side/components/collapse-wrap.vue';
import HeaderModule
  from 'features/settings/editor/association-editor/ui/components/left-side/modules/header-module.vue';
import BodyModule from 'features/settings/editor/association-editor/ui/components/left-side/modules/body-module.vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import PhoneMock from 'features/settings/editor/components/phone/phone-mock.vue';
import PhoneViewContent
  from 'features/settings/editor/association-editor/ui/components/right-side/modules/phone-view-content.vue';
import PhoneEditContent
  from 'features/settings/editor/association-editor/ui/components/right-side/modules/phone-edit-content.vue';
import { onBeforeRouteLeave } from 'magner/vue-router';
import { AdditionsAssociationList, associationViewUpdate } from 'features/settings/editor/association-editor/requests';
import { AdditionalField } from 'features/settings/mobile-editor/interfaces';

interface Props {
  modelValue: IAViewWidgets;
}

const props = defineProps<Props>();
const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const state = reactive<IAViewWidgets>(props.modelValue);
const store = useEditorStore();
const copyState = ref<IAViewWidgets>();
const isViewChanged = ref(false);

const computedForWatcher = computed(() => JSON.stringify(props.modelValue));

onMounted(() => copyState.value = JSON.parse(JSON.stringify(state)));

watch(
  () => computedForWatcher.value,
  () => {
    if (copyState.value) {
      isViewChanged.value = JSON.stringify(copyState.value) !== JSON.stringify(state);
    }
  },
);

/**
 * Предупреждение при покидании страницы
 * */
onBeforeRouteLeave((to, from, next) => {
  if (!isViewChanged.value) {
    next();
    return;
  }

  magnerConfirm({
    message: translateText('hubstr.editor.warnings.save'),
    title: translateText('hubstr.editor.warnings.attention'),
    confirmButtonText: translateText('hubstr.editor.save_exit'),
    cancelButtonText: translateText('hubstr.editor.exit_no_save'),
    type: 'warning',
    closeOnClickModal: false,
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = translateText('hubstr.editor.saving');

        // сохранение
        associationViewUpdate({ data: { associationEditorLayout: state } } as any)
          .then((res) => {
            // error
            if (res.error) {
              magnerMessage({
                type: 'error',
                message: translateText('hubstr.editor.errors.saving'),
              });
              done();
              return;
            }
            // success
            magnerMessage({
              type: 'success',
              message: translateText('hubstr.editor.success.save'),
            });
            done();
            next();
          });
      } else {
        done();
        next();
      }
    },
  });
});

watch(() => state.body, () => {
  // пишем существующие коды полей в store (для последующей валидации уникальности кода добавляемого поля)
  store.setAssociationAdditionalCodes([
    ...AdditionsAssociationList.map((a) => a.formCode),
    ...props.modelValue.body.flatMap((g) => g.widgets.map((w) => w?.additions?.formCode)).filter(Boolean),
  ]);
}, { deep: true, immediate: true });
</script>

<style lang="scss" scoped>
.mobile-editor {
  width: 100%;

  &__collapse-title {
    font-size: var(--el-font-size-base);
  }
}

@media (min-width: 992px) {
  .phone-wrap {
    position: fixed;
    right: 40px;
    top: 47% !important;
    transform: translateY(calc(-50% + 24px));
  }
}

@media (max-width: 1400px) {
  .phone-wrap {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .phone-wrap {
    position: relative;
    margin-top: 40px;
    right: 0;
  }
  .mobile-editor {
    margin: 0 !important;
  }
}
</style>
