<template>
  <div v-for="i in sections" :key="i.type" class="header-items">
    <CollapseWrap
      v-if="i.type === 'header_group_buttons_widget'"
    >
      <template #title>
        {{ HeaderModules[i.type] }}
        <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
      </template>
      <template #default>
        <header-buttons-section
          :data="state"
          @update:data="updateHeaderGroupButtons"
        />
      </template>
    </CollapseWrap>

    <div
      v-else-if="i.type === 'header_title_with_icon_widget'"
      class="header-items__item"
    >
      <div class="header-items__item-content">
        <div class="header-items__item-title">
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
            <div>
              {{ i.label }}
            </div>
            <div>
              <el-button
                style="margin: 0;"
                :icon="Delete"
                type="danger"
                link
                @click="removeStatus"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="header-items__item"
    >
      <div class="header-items__item-content">
        <div class="header-items__item-title">
          <div style="width: max-content">
            <EditableText
              v-if="i.editable"
              v-model="userBadgeLabel"
              class="header-items__item-editable"
              :disabled="!i.visible"
            />
            <span v-else>
              {{ HeaderModules[i.type] }}
            </span>
          </div>
          <question-tooltip v-if="i.questionIcon" :text="i.textTooltip" />
        </div>
        <el-checkbox
          v-if="i.required"
          v-model="userBadgeReq"
          :disabled="!i.visible"
          size="small"
          :label="translateText('hubstr.editor.fields.required_app')"
        />
      </div>
      <el-switch
        v-if="i.switched"
        v-model="i.visible"
        size="small"
        @change="onChecked($event, i)"
      />
    </div>
  </div>
  <el-button
    plain
    type="primary"
    :disabled="state.find((w) => w.type === 'header_title_with_icon_widget')"
    @click="openAddHeaderFieldModal = true"
  >
    Добавить поле
  </el-button>
  <AddHeaderFieldModal
    v-model="openAddHeaderFieldModal"
    @change="addStatus"
  />
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import QuestionTooltip from 'features/settings/mobile-editor/components/left-side/components/question-tooltip.vue';
import CollapseWrap from 'features/settings/mobile-editor/components/left-side/components/collapse-wrap.vue';
import HeaderButtonsSection
  from 'features/settings/mobile-editor/components/left-side/components/header/header-buttons-section.vue';
import { Header, Widgets } from 'features/settings/mobile-editor/interfaces';
import { magnerMessage, translate, useTranslate } from 'magner';
import EditableText from 'features/settings/mobile-editor/components/left-side/components/editable-text-input.vue';
import { AdditionsList, createNewField, updateAdditionalField } from 'features/settings/mobile-editor/requests';
import AddHeaderFieldModal
  from 'features/settings/mobile-editor/components/left-side/modals/add-header-field-modal.vue';
import { Delete } from '@element-plus/icons-vue';

interface Items {
  type: string,
  switched: boolean,
  questionIcon: boolean,
  textTooltip?: string,
  visible?: boolean,
  value?: string,
  tags?: string,
  editable?: boolean,
  label?: string,
  required?: boolean,
}

export default defineComponent({
  name: 'HeaderModule',
  components: {
    AddHeaderFieldModal,
    EditableText,
    HeaderButtonsSection,
    CollapseWrap,
    QuestionTooltip,
    Delete,
  },
  props: {
    data: {
      type: Object as PropType<Widgets>,
      required: true,
    },
  },
  emits: ['update:data'],
  setup (props) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    const copy = props.data.header.slice();

    const state = reactive(props.data.header) as Header[];
    const openAddHeaderFieldModal = ref(false);

    const HeaderModules = {
      header_avatar_widget: translateText('hubstr.editor.main.header.widgets.avatar'),
      header_tags_widget: translateText('hubstr.editor.main.header.widgets.role'),
      header_badge_widget: translateText('hubstr.editor.main.header.widgets.badge'),
      header_title_widget: translateText('hubstr.editor.main.header.widgets.name'),
      header_title_with_icon_widget: translateText('hubstr.editor.main.header.widgets.title_with_icon'),
      header_group_buttons_widget: translateText('hubstr.editor.main.header.widgets.group_btns'),
    } as const;

    const badge = props.data.header.find((w) => w.type === 'header_badge_widget') as Header;
    const userBadgeLabel = ref(AdditionsList.find((i) => i.code === 'userBadge')?.description || HeaderModules.header_badge_widget);
    const userBadgeReq = ref(AdditionsList.find((i) => i.code === 'userBadge')?.isUserRequired);

    const sections = reactive([
      {
        type: 'header_avatar_widget',
        switched: false,
        questionIcon: false,
        value: 'data.avatar',
      },
      {
        type: 'header_tags_widget',
        switched: true,
        questionIcon: true,
        textTooltip: translateText('hubstr.editor.main.header.hints.tags'),
        visible: false,
        tags: 'data.tags',
      },
      {
        type: 'header_title_widget',
        switched: false,
        questionIcon: false,
        value: 'data.fullName',
      },
      {
        type: 'header_badge_widget',
        switched: true,
        questionIcon: false,
        visible: false,
        value: 'data.additions.userBadge',
        editable: true,
        label: userBadgeLabel.value,
        required: true,
      },
      {
        type: 'header_group_buttons_widget',
        switched: false,
        questionIcon: true,
        textTooltip: translateText('hubstr.editor.main.header.hints.group_btns'),
      },
    ]) as Items[];

    watch(() => userBadgeLabel.value, (newValue) => {
      badge.label = newValue;
      sections.find((w) => w.type === 'header_badge_widget').label = newValue;
    });

    watch(() => userBadgeReq.value, async (newValue) => {
      badge.required = newValue;

      const res = await updateAdditionalField({
        ...AdditionsList.find((i) => i.code === 'userBadge'),
        isUserRequired: newValue,
      });
      if (res.error) {
        magnerMessage({
          type: 'error',
          message: 'Ошибка',
        });
        return { error: res.error };
      }

      return true;
    });

    const addStatus = async (event) => {
      const hasAdditionalField = AdditionsList.find((i) => i.code === 'userTitleIcon');
      const index = state.findIndex((w) => w.type === 'header_title_widget');

      if (!hasAdditionalField) {
        // Если нет доп поля короны, создаем
        const res = await createNewField({
          code: 'userTitleIcon',
          variableType: 'text',
          allowSearch: false,
          isUserRequired: false,
          isAdminRequired: false,
          glType: 'user',
          entityType: 'user',
          isAutocompletedField: false,
          format: 'DEFAULT',
          hasDisplayType: false,
          formCode: 'data.additions.userTitleIcon',
          description: null,
          isMultiline: false,
          mobileValidator: [],
        });
        if (res.error) {
          return { error: res.error };
        }
      }

      state.splice(index, 1, event);
      sections.splice(index + 1, 0, event);
    };
    const removeStatus = () => {
      const stateIndex = state.findIndex((w) => w.type === 'header_title_with_icon_widget');
      const sectionsIndex = sections.findIndex((w) => w.type === 'header_title_with_icon_widget');
      state.splice(stateIndex, 1, {
        type: 'header_title_widget',
        value: 'data.fullName',
      });
      sections.splice(sectionsIndex, 1);
    };

    /**
     * Добавляет или удаляет элемент
     * */
    const onChecked = (event: boolean, item: Items) => {
      if (event) {
        const index = copy.map((i) => i.type).indexOf(item.type);
        const out = {
          type: item.type,
        } as {type: string, value: string, tags: string, label: string};

        item?.value ? out.value = item.value : '';
        item.tags ? out.tags = item.tags : '';
        item.label ? out.label = item.label : '';

        state.splice(index, 0, out);
      } else {
        const index = state.map((i) => i.type).indexOf(item.type);
        if (item.required) {
          userBadgeReq.value = false;
        }
        state.splice(index, 1);
      }
    };

    /**
     * Проходит по пропсу и если секция с таким типом найдена в Sections
     * Включает флаг visible в Items
     * */
    onMounted(() => {
      const status = state.find((w) => w.type === 'header_title_with_icon_widget');
      if (status) {
        sections.splice(3, 0, {
          type: 'header_title_with_icon_widget',
          value: 'data.fullName',
          name: 'data.additions.userTitleIcon',
          label: status.label || 'lang.userTitleIcon',
        });
      }

      sections.map((item: Items) => {
        state.map((h) => {
          if (item.type === h.type) {
            item.visible = true;
          }
          return true;
        });
        return true;
      });
    });

    /**
     * Обновляет группу кнопок
     * */
    const updateHeaderGroupButtons = (event: Header) => {
      if (!state.find((h) => h.type === 'header_group_buttons_widget')) {
        state.push({
          type: 'header_group_buttons_widget',
          title: '',
          widgets: [],
        });
      }

      state.find((h) => h.type === 'header_group_buttons_widget').widgets = event;
    };

    return {
      HeaderModules,
      openAddHeaderFieldModal,
      state,
      userBadgeLabel,
      userBadgeReq,
      sections,
      onChecked,
      updateHeaderGroupButtons,
      translateText,
      addStatus,
      removeStatus,

      Delete,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-items {
  &__item {
    min-height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--el-box-shadow-lighter);
    padding: 10px 20px;
    border-radius: var(--el-card-border-radius);
    margin-bottom: 10px;
  }
  &__item-title {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__item-content {
    width: 100%;
  }
}
.el-checkbox {
  font-weight: lighter;
}
</style>
