<template>
  <el-row :gutter="30" class="mobile-editor">
    <el-col
      :xl="16"
      :lg="16"
      :md="15"
      :sm="24"
      :xs="24"
    >
      <CollapseWrap>
        <template #title>
          {{ translateText('hubstr.editor.main.header.title') }}
        </template>
        <template #default>
          <HeaderModule :data="state" />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          {{ translateText('hubstr.editor.main.body.title') }}
        </template>
        <template #default>
          <BodyModule :data="state" />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          {{ translateText('hubstr.editor.main.footer.title') }}
        </template>
        <template #default>
          <FooterModule :data="state" />
        </template>
      </CollapseWrap>
    </el-col>
    <el-col
      :xl="8"
      :lg="8"
      :md="9"
      :sm="24"
      :xs="24"
    >
      <PhoneMock :data="state" />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import CollapseWrap from 'features/settings/mobile-editor/components/left-side/components/collapse-wrap.vue';
import HeaderModule from 'features/settings/mobile-editor/components/left-side/modules/header-module.vue';
import { Widgets } from 'features/settings/mobile-editor/interfaces';
import BodyModule from 'features/settings/mobile-editor/components/left-side/modules/body-module.vue';
import PhoneMock from 'features/settings/mobile-editor/components/right-side/phone-mock.vue';
import { onBeforeRouteLeave } from 'magner/vue-router';
import {
  magnerConfirm, magnerMessage, translate, useTranslate,
} from 'magner';
import { AdditionsList, mobileProfileUpdate } from 'features/settings/mobile-editor/requests';
import FooterModule from 'features/settings/mobile-editor/components/left-side/modules/footer-module.vue';

export default defineComponent({
  name: 'EditMobileProfileLayout',
  components: {
    FooterModule,
    PhoneMock,
    BodyModule,
    HeaderModule,
    CollapseWrap,
  },
  props: {
    modelValue: {
      type: Object as PropType<Widgets>,
      required: true,
    },
    field: {
      type: Object as PropType<any>,
      default: () => {},
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));
    const state = reactive(props.modelValue) as Widgets;

    const copyState = ref<Widgets>();
    const isViewChanged = ref(false);

    const computedForWatcher = computed(() => JSON.stringify(props.modelValue));

    onMounted(() => copyState.value = JSON.parse(JSON.stringify(state)));

    watch(
      () => computedForWatcher.value,
      () => {
        if (copyState.value) {
          isViewChanged.value = JSON.stringify(copyState.value) !== JSON.stringify(state);
        }
      },
    );

    /**
     * Предупреждение при покидании страницы
     * */
    onBeforeRouteLeave((to, from, next) => {
      if (!isViewChanged.value) {
        next();
        return;
      }

      magnerConfirm({
        message: translateText('hubstr.editor.warnings.save'),
        title: translateText('hubstr.editor.warnings.attention'),
        confirmButtonText: translateText('hubstr.editor.save_exit'),
        cancelButtonText: translateText('hubstr.editor.exit_no_save'),
        type: 'warning',
        closeOnClickModal: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = translateText('hubstr.editor.saving');

            // сохранение
            mobileProfileUpdate({ data: { editMobileProfileLayout: state } } as any)
              .then((res) => {
                // error
                if (res.error) {
                  magnerMessage({
                    type: 'error',
                    message: translateText('hubstr.editor.errors.saving'),
                  });
                  done();
                  return;
                }
                // success
                magnerMessage({
                  type: 'success',
                  message: translateText('hubstr.editor.success.save'),
                });
                done();
                next();
              });
          } else {
            done();
            next();
          }
        },
      });
    });

    return {
      state,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-editor {
  width: 100%;

  &__collapse-title {
    font-size: var(--el-font-size-base);
  }
}

@media (min-width: 992px) {
  .phone-wrap {
    position: fixed;
    right: 40px;
    top: 47% !important;
    transform: translateY(calc(-50% + 24px));
  }
}

@media (max-width: 1400px) {
  .phone-wrap {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .phone-wrap {
    position: relative;
    margin-top: 40px;
    right: 0;
  }
  .mobile-editor {
    margin: 0 !important;
  }
}

</style>
