import { tablePageController, translate } from 'magner';
import bansTable from 'features/settings/bans/table/hubstr';
import { bansHead } from 'features/settings/bans/requests/hubstr';

const bansConfig: any = tablePageController<any>({
  header: {
    title: translate('hubstr.bans.table.title'),
    actions: [],
  },
  request: bansHead,
  table: bansTable as any,
  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
    pagination: {
      items: 25,
      page: 1,
    },
  },
});

export default bansConfig;
