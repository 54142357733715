import { cardPageController, translate } from 'magner';
import { associationViewGet, associationViewUpdate } from 'features/settings/editor/association-editor/requests';
import AssociationEditorLayout from 'features/settings/editor/association-editor/ui/index.vue';

const associationEditorConfig = cardPageController({
  getRequest: associationViewGet,
  createRequest: '' as never,
  updateRequest: associationViewUpdate,
  deleteRequest: '' as never,

  header: {
    title: translate('hubstr.association_editor.main.title'),
    tabs: [
      {
        label: 'Резиденты',
        active: false,
        link: { name: 'mobile-editor' },
      },
      {
        label: 'Ассоциации',
        active: true,
        link: { name: 'associations-editor' },
      },
    ],
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'associationEditorLayout',
        component: () => AssociationEditorLayout,
        props: {},
      },
    ],
  },
});

export default associationEditorConfig;
