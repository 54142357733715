import { IFeedBack } from 'features/profile/feedback/types';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const feedbackRead = request.table<IFeedBack>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;
  const search = data.filters.text ? `&filters[0][id]=search&filters[0][value]=${data.filters.text}` : '';

  const res = await api.get<{ list: IFeedBack[], pager: Pager }>(
    `/api/admin/feedback${pagination}${search}`,
  );
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((s: IFeedBack) => ({
        ...s,
        fio: s.user.name,
      })),
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});
