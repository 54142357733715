export const defaultAssociation = {
  id: '',
  title: 'Ассоциации',
  code: 'association',
  widgets: {
    body: [
      {
        type: 'group_widget',
        title: 'lang.mobile.companyGeographyTitle',
        widgets: [
          {
            id: '576251da-4c86-413a-adcb-14ed06da7e7d',
            name: null,
            text: 'data.additions.associationGeography',
            type: 'text_section_widget',
            title: 'lang.mobile.associationGeography',
          },
        ],
      },
      {
        type: 'group_widget',
        title: 'lang.mobile.contactsInfoTitle',
        widgets: [
          {
            id: '6110dbf1-f11a-4242-b79f-25c434424d41',
            name: 'site',
            type: 'list_line_widget',
            leftText: 'lang.mobile.companyWebsiteTitle',
            rightIcon: 'next',
            rightText: 'data.additions.associationSite',
          },
        ],
      },
      {
        type: 'group_widget',
        title: 'lang.mobile.businessInfoTitle',
        widgets: [
          {
            id: '38014451-67ca-4aa6-8d59-f7d8db472fe4',
            type: 'list_line_widget',
            leftIcon: 'pie_chart',
            leftText: 'lang.mobile_ml.turnoverPerYearTitle',
            rightText: 'data.additions.associationTurnoverPerYear',
            hasPrivacy: true,
            hasAutoComplete: false,
          },
          {
            id: '85d61e4a-98f5-4a73-8c0b-27afb7e93861',
            type: 'list_line_widget',
            leftIcon: 'people',
            leftText: 'lang.mobile.numberOfEmployeesInputLabel',
            rightText: 'data.additions.associationNumberOfEmployees',
            hasPrivacy: true,
            hasAutoComplete: false,
          },
        ],
      },
      {
        type: 'group_widget',
        title: 'lang.mobile.companyAreaTitle',
        widgets: [
          {
            id: 'ff8cf225-3ed4-41d4-8a70-363741253f2d',
            name: null,
            text: 'data.additions.associationAreas',
            type: 'text_section_widget',
            title: 'lang.mobile.associationAreas',
            hasAutoComplete: true,
          },
        ],
      },
    ],
    footer: [],
    header: [
      {
        type: 'header_avatar_widget',
        value: 'data.logo.big',
      },
      {
        type: 'header_title_widget',
        value: 'data.name',
      },
      {
        type: 'header_badge_widget',
        label: 'Роль в компании',
        value: 'data.additions.associationUserPosition',
        required: false,
      },
    ],
    actions: [],
  },
};
