<template>
  <div v-loading="isLoading" class="widgets-slide">
    <el-form-item label="Цвет фона">
      <ColorPicker v-model="state.backgroundColor" />
    </el-form-item>
    <el-divider />
    <template v-if="state.widgets?.length">
      <draggable
        :list="state.widgets"
        group="views"
        item-key="widgets"
        class="widgets-slide__widgets"
      >
        <template #item="{element, index}">
          <component
            :is="setComponent(element)"
            class="widgets-slide__widget"
            :data="element"
            @remove="removeWidget(index)"
            @edit="openEditWidgetModal(element, index)"
          />
        </template>
      </draggable>
    </template>
    <template v-else>
      <el-empty :image-size="100" description="Пока нет виджетов" />
    </template>
  </div>

  <el-button @click="showAddWidgetModal = true">+ Добавить виджет</el-button>

  <AddEditWidgetModal
    :key="showAddWidgetModal"
    v-model="showAddWidgetModal"
    :edit-data="editWidgetData"
    @submit:add="submitAddWidget"
    @submit:edit="submitEditWidget"
    @update:model-value="editWidgetData = undefined"
  />
</template>

<script setup lang="ts">
import {
  IOnboardScreen, IWidget,
} from 'features/settings/onboarding-editor/interfaces';
import { type Component, reactive, ref } from 'vue';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import AddEditWidgetModal
  from 'features/settings/onboarding-editor/components/slides/components/modal/index.vue';
import TextWidget from 'features/settings/onboarding-editor/components/slides/components/widgets/text-widget.vue';
import ImageWidget from 'features/settings/onboarding-editor/components/slides/components/widgets/image-widget.vue';
import GapWidget from 'features/settings/onboarding-editor/components/slides/components/widgets/gap-widget.vue';
import LogoWidget from 'features/settings/onboarding-editor/components/slides/components/widgets/logo-widget.vue';
import { fileUpload } from 'features/common/file-request';
import { magnerMessage } from 'magner';
import Draggable from 'vuedraggable-es';
import ColorPicker from 'features/settings/onboarding-editor/components/color-picker.vue';
import ButtonLinkWidget
  from 'features/settings/onboarding-editor/components/slides/components/widgets/button-link-widget.vue';
import ButtonCompleteWidget
  from 'features/settings/onboarding-editor/components/slides/components/widgets/button-complete-widget.vue';
import ButtonChatKeeperWidget
  from 'features/settings/onboarding-editor/components/slides/components/widgets/button-chat-keeper-widget.vue';
import MarkdownWidget
  from 'features/settings/onboarding-editor/components/slides/components/widgets/markdown-widget.vue';

interface Props {
  data: IOnboardScreen
}

const props = withDefaults(defineProps<Props>(), {});
const state = reactive<IOnboardScreen>(props.data);
const showAddWidgetModal = ref(false);
const isLoading = ref(false);
const editWidgetData = ref<IWidget | undefined>();
const editWidgetIndex = ref<number>();

const setComponent = (widget: IWidget) => {
  if (widget.type === WIDGET_TYPES.IMAGE && widget.isLogo) {
    return LogoWidget;
  }

  switch (widget.type) {
    case WIDGET_TYPES.TEXT:
      return TextWidget;
    case WIDGET_TYPES.IMAGE:
      return ImageWidget;
    case WIDGET_TYPES.GAP:
      return GapWidget;
    case WIDGET_TYPES.BUTTON_LINK:
      return ButtonLinkWidget;
    case WIDGET_TYPES.BUTTON_COMPLETE:
      return ButtonCompleteWidget;
    case WIDGET_TYPES.BUTTON_CHAT_KEEPER:
      return ButtonChatKeeperWidget;
    case WIDGET_TYPES.MARKDOWN:
      return MarkdownWidget;
    default:
      return {} as Component;
  }
};

const submitAddWidget = async (event: IWidget) => {
  isLoading.value = true;
  if (event.type === WIDGET_TYPES.IMAGE && Array.isArray(event.image)) {
    const res = await fileUpload(
      state.id,
      (event.image as File[])[0],
      'onboarding',
      'onboarding_image',
      'default',
      false,
    );

    if (res?.error) {
      magnerMessage({
        type: 'error',
        message: 'Не удалось загрузить изображение',
      });
      return {
        error: res.error,
      };
    }

    state.widgets.push({ ...event, image: res.data.image, imgId: res.data.id });
    isLoading.value = false;
    return true;
  }

  state.widgets.push(event);
  isLoading.value = false;
};

const openEditWidgetModal = (widget: IWidget, index: number) => {
  editWidgetData.value = widget;
  editWidgetIndex.value = index;
  showAddWidgetModal.value = true;
};

const submitEditWidget = async (event: IWidget) => {
  if (event.type === WIDGET_TYPES.IMAGE && Array.isArray(event.image)) {
    const res = await fileUpload(
      state.id,
      (event.image as File[])[0],
      'onboarding',
      'onboarding_image',
      'default',
      false,
    );

    if (res?.error) {
      magnerMessage({
        type: 'error',
        message: 'Не удалось загрузить изображение',
      });
      return {
        error: res.error,
      };
    }

    state.widgets.splice(editWidgetIndex.value as number, 1, { ...event, image: res.data.image, imgId: res.data.id });

    return true;
  }

  state.widgets.splice(editWidgetIndex.value as number, 1, event);
};

const removeWidget = (index: number) => {
  state.widgets.splice(index, 1);
};
</script>

<style lang="scss" scoped>
.widgets-slide {
  &__widgets {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__widget {
    padding: 20px;
    border-radius: var(--el-border-radius-base);
    box-shadow: var(--el-box-shadow-light);
  }
}
</style>
