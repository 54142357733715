<template>
  <label for="">{{ TranslatesList[state.title.replace('lang.', '')] || data.title }}</label>
  <p>{{ data.hasAutoComplete ? `${translateText('hubstr.editor.phone_preview.text')} 1, ${translateText('hubstr.editor.phone_preview.text')} 2` : translateText('hubstr.editor.phone_preview.text') }}</p>
  <el-divider />
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive,
} from 'vue';
import { AdditionalField, TextSectionWidget } from 'features/settings/mobile-editor/interfaces';
import { AdditionsList, TranslatesList } from 'features/settings/mobile-editor/requests';
import { translate, useTranslate } from 'magner';
import { formatShortDate } from '~/utils/format-date';

export default defineComponent({
  name: 'TextSection',
  props: {
    data: {
      type: Object as PropType<TextSectionWidget>,
      required: true,
    },
  },
  setup (props) {
    const state = reactive({
      ...props.data,
      text: props.data.name === 'date' ? formatShortDate(props.data.value?.date) : props.data.text,
    });

    const matchingField = Object.values(AdditionsList).find((field) => Object.keys(props.data).some((key) => {
      if (props.data[key] && typeof props.data[key] === 'string') {
        return props.data[key] === field.formCode;
      }
    })) as AdditionalField;

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      state,
      matchingField,
      translateText,
      TranslatesList,
    };
  },
});
</script>

<style scoped>
label {
  color: #8A8A8F;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.016em;
}
.el-divider--horizontal {
  margin: 0 0 15px 0;
}
</style>
