<template>
  <el-form
    ref="formRef"
    :model="formData"
    @change="updateHandler(formRef)"
    @input="updateHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="title"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <el-input
        v-model="titleProxy"
        :maxlength="40"
      />
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { FormInstance } from 'element-plus';
import {
  onMounted,
  reactive, ref, watch,
} from 'vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import {
  IBirthdayWidget,
  IDepartmentWidget,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/interfaces';
import { v4 as uuidV4 } from 'uuid';
import {
  WIDGET_TYPES,
} from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import { translate, useTranslate } from 'magner';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';

interface Props {
  data?: IDepartmentWidget,
  isEdit?: boolean,
}
const props = defineProps<Props>();
const emit = defineEmits(['update:formData', 'input:error']);
const store = useEditorStore();
const state = (props.data && JSON.parse(JSON.stringify(props.data))) || undefined;

const formRef = ref<FormInstance>();

const formData = reactive<IBirthdayWidget>(
  state || {
    id: uuidV4(),
    name: null,
    type: WIDGET_TYPES.TEXT_SECTION,
    title: 'lang.birthday',
    text: 'data.formattedBirthday.value',
    required: true,
    hasPrivacy: true,
    value: null,
    dataProvider: null,
  } as IBirthdayWidget,
);

const titleProxy = ref(t[formData.title?.replace('lang.', '')] || formData?.title);

const formBuilder = () => {
  // если есть перевод оставляем код, если нет то свое значение
  formData.title = titleProxy.value?.toLowerCase() !== t?.birthday.toLowerCase()
    ? titleProxy.value?.trim()
    : 'lang.birthday';
};

const updateHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) return;

  formBuilder();

  formEl.validate((valid) => {
    if (valid) {
      // submit
      emit('input:error', false);
      emit('update:formData', formData);
    } else {
      emit('input:error', true);
    }
  });
};

onMounted(() => updateHandler(formRef.value));

watch(
  () => formData,
  () => {
    updateHandler(formRef.value);
  },
  { deep: true },
);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style scoped>

</style>
