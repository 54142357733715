<template>
  <div class="button-link-slot">
    <el-form ref="formRef" :model="formData" @change="submitHandler(formRef)">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="Текст кнопки"
            prop="title"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.title"
              :field="titleField"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Ссылка"
            prop="link"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.link"
              :field="linkField"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { IButtonLinkWidget } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import { InputField, MagnerFormInput } from 'magner';
import { FormInstance } from 'element-plus';

interface Props {
  editData?: IButtonLinkWidget;
}

const props = withDefaults(defineProps<Props>(), {
  editData: undefined,
});
const emit = defineEmits(['update:formData', 'error']);

const state = props.editData && JSON.parse(JSON.stringify(props.editData));
const formRef = ref<FormInstance>();

const formData = reactive<IButtonLinkWidget>(
  state
  || {
    type: WIDGET_TYPES.BUTTON_LINK,
    title: '',
    link: '',
  } as IButtonLinkWidget,
);

const titleField: InputField<any> = {
  type: 'input',
  name: 'title',
  dataType: 'string',
  props: {
    placeholder: 'Введите текст',
  },
};

const linkField: InputField<any> = {
  type: 'input',
  name: 'link',
  dataType: 'string',
  props: {
    placeholder: 'https://',
  },
};

const submitHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return;
  }

  formEl.validate((valid) => {
    if (valid) {
      emit('error', false);
      emit('update:formData', formData);
      return true;
    }

    emit('error', true);
    return false;
  });
};

onMounted(() => submitHandler(formRef.value));
</script>

<style lang="scss" scoped>
//.gap-slot {
//  :deep(.el-input-number .el-input__inner) {
//    text-align: start !important;
//  }
//}
</style>
