<template>
  <div
    class="markdown-widget"
    :style="[
      {'align-items': data.withCenter ? 'center' : 'flex-start'},
      {'height': data.heightPercent ? data.heightPercent + '%' : 'min-content'}
    ]"
  >
    <div
      class="markdown-widget__preview"
      :style="{'color': data.color}"
      v-html="resultantMarkdown"
    />
  </div>
</template>

<script setup lang="ts">
import { IMarkdownWidget } from 'features/settings/onboarding-editor/interfaces';
import { computed } from 'vue';
import { marked } from '../../../../../../../../public/scripts/marked.esm.js';

interface Props {
  data: IMarkdownWidget
}

const props = withDefaults(defineProps<Props>(), {});
const resultantMarkdown = computed(() => marked(props.data.text, { sanitize: true, breaks: true }));

</script>

<style lang="scss" scoped>
.markdown-widget {
  display: flex;
  padding: 0 15px;
  min-height: fit-content;
}
</style>
