import { tablePageController, translate, useTranslate } from 'magner';
import { listRead } from 'features/settings/authorization/requests';
import { formatDate, formatPhone } from '~/utils/format-date';

const authListConfig = tablePageController<any>({
  header: {
    title: translate('hubstr.auth.table.title'),
  },
  request: listRead,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.auth.table.not_found'),
    columns: [
      {
        prop: 'fio',
        label: translate('hubstr.auth.table.fio'),
        className: 'resident-fio-cell',
      },
      {
        prop: 'phone',
        label: translate('hubstr.auth.table.phone'),
        view: {
          type: 'text',
          formatter: (_, row) => formatPhone(row.phone),
        },
      },
      {
        prop: 'code',
        label: translate('hubstr.auth.table.code'),
      },
      {
        prop: 'validTill',
        label: translate('hubstr.auth.table.validTill'),
        view: {
          type: 'text',
          formatter: (_, row) => formatDate(row.validTill as Date),
        },
      },
      {
        prop: 'activateAt',
        label: translate('hubstr.auth.table.activateAt'),
        view: {
          type: 'tags',
          formatter: (_, row) => [{
            text: row.activateAt ? formatDate(row.activateAt as Date) : 'не входил(а)',
            backgroundColor: row.activateAt ? '#00C04D' : '#C60222',
            textColor: '#FFFFFF',
          }],
        },
      },
    ],
  },
  filters: {
    actions: [],

    saveToLocalStorage: true,

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      firstName: '',
      phone: '',
      positionTags: '',
      status: '',
    },
    sort: {},

    layout: [],
  },
});

export default authListConfig;
