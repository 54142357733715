import {
  CabinetSections, LinkCreate, LinkGroup,
} from 'features/settings/cabinet-sections/interfaces';
import translit from 'features/settings/mobile-editor/utils/translit';
import { LINK_TYPES } from 'features/settings/cabinet-sections/constants';
import { fileUpload } from 'features/common/file-request';
import { v4 as uuidv4 } from 'uuid';
import { request } from '~/utils/request';
import { imageUploadCode } from '~/constants';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

let currentLink = {} as CabinetSections;

export const cabinetSectionsRead = request.table<CabinetSections>(async ({ api, router }) => {
  const res = await api.get<{ list: CabinetSections[], pager: Pager }>('/api/admin/link/paginated_list?page=1&count=25&filters[2][id]=groupCode&filters[2][value]=profile_links');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const cabinetSectionGet = request.card<CabinetSections, CabinetSections>(async ({ api, data, parseError }) => {
  const res = await api.get<{ link: CabinetSections }>(`/api/admin/link/get?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  currentLink = res.data?.link;
  return { data: res.data?.link };
});

export const cabinetSectionCreateGroup = request.custom<LinkGroup, CabinetSections>(async ({ api, data, parseError }) => {
  const body = {
    id: data.id,
    name: data?.title,
    code: translit(data?.title, 'underscore'),
    roles: [],
    defaultLinksLogo: null,
  };

  const res = await api.post<{ group: LinkGroup }>('/api/admin/links_group/create', body);
  if (res.error) {
    return { error: res.error };
  }

  return { data: res.data?.group };
});

export const cabinetSectionUpdateGroup = request.custom<LinkGroup, {id: string, data: CabinetSections}>(async ({ api, data, parseError }) => {
  const body = {
    group: data.id,
    name: data.data?.title,
  };

  const res = await api.patch<{ group: LinkGroup }>('/api/admin/links_group/update', body);
  if (res.error) {
    return { error: res.error };
  }

  return { data: res.data?.group };
});

export const cabinetSectionCreate = request.card<CabinetSections>(async ({
  api, parseError, data, router, 
}) => {
  const id = uuidv4();
  const groupId = router.currentRoute.value.params?.id;

  const body = {
    ...data.data,
    id,
    groupCode: groupId || 'profile_links',
    code: translit(data.data?.title, 'underscore'),
    url: data.data.type === LINK_TYPES.EXTERNAL ? data.data.url : `/page?code=${translit(data.data.title, 'underscore')}`,
    position: 12,
    visible: 1,
    roles: [],
  } as LinkCreate;

  if (data.data.image) {
    const fileUploadRes = await fileUpload(
        id as string,
        (data.data.image as File[])[0],
        'link',
        imageUploadCode,
        'default',
        true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { image: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.image = fileUploadRes.data?.id;
  } else {
    body.image = null;
  }

  const res = await api.post<{ link: CabinetSections }>('/api/admin/link/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  if (data.data.type === LINK_TYPES.EXTERNAL) {
    return { data: res.data?.link };
  }
  if (data.data.type === LINK_TYPES.DEEP) {
    const resGroup = await cabinetSectionCreateGroup(res.data?.link);
    if (resGroup.error) {
      return { error: parseError?.(resGroup?.error) };
    }

    return 'ok';
  }
});

export const cabinetSectionUpdate = request.card<CabinetSections, CabinetSections>(async ({ api, parseError, data }) => {
  const body = { ...data.data, link: data.id };

  if (data.data.image) {
    const fileUploadRes = await fileUpload(
      data.id as string,
      (data.data.image as File[])[0],
      'link',
      imageUploadCode,
      'default',
      true,
    );

    if (fileUploadRes.error) {
      return {
        error: {
          message: '',
          fields: { image: fileUploadRes.error as unknown as string },
        },
      };
    }
    body.image = fileUploadRes.data?.id;
  }

  const res = await api.patch<{ link: CabinetSections }>('/api/admin/link/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }

  // Обновляем название группы, если ссылка внешняя
  if (currentLink.type === LINK_TYPES.DEEP && data.data?.title) {
    const resGroup = await cabinetSectionUpdateGroup(data as {id: string, data: CabinetSections});
    if (resGroup.error) {
      return { error: parseError?.(resGroup?.error) };
    }
  }

  return { data: res.data?.link };
});

export const deepLinksSectionRead = request.table<CabinetSections>(async ({ api, router }) => {
  const code = router.currentRoute.value.params.id;

  const res = await api.get<{ list: CabinetSections[], pager: Pager }>(`/api/admin/link/paginated_list?page=1&count=25&filters[2][id]=groupCode&filters[2][value]=${code}`);
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  // Меняем заголовок на странице
  document.getElementsByClassName('magner-page-header_title')[0].innerHTML = res.data?.list[0]?.group.name || 'Ссылки';

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const cabinetSectionDelete = request.card(async ({ api, parseError, data }) => {
  const res = await api.delete(`/api/admin/link/delete?link=${data.id}`);

  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: 'ok' };
});

export const linkTypeOptions = request.custom(({ router }) => {
  if (!router.currentRoute.value.params?.id) {
    return {
      data: {
        rows: [
          {
            label: 'Внутреняя',
            value: LINK_TYPES.DEEP,
          },
          {
            label: 'Внешняя',
            value: LINK_TYPES.EXTERNAL,
          },
        ],
      },
    };
  } 
  return {
    data: {
      rows: [
        {
          label: 'Внешняя',
          value: LINK_TYPES.EXTERNAL,
        },
      ],
    },
  };
});
