import type { EventGuest } from 'features/events/guests/types/hubstr';
import { translate, useTranslate } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import Crown from 'features/settings/mobile-editor/components/right-side/assets/icons/crown.svg?inline';
import { formatDate, formatPhone } from '~/utils/format-date';

const statusFormatter = (status: EventGuest['status']): string => {
  const { customT } = useTranslate();

  return customT(translate(`hubstr.event.guests.statuses.${status}`));
};

const statusPaymentColorFormatter = (payment: EventGuest['payment']): string => {
  if (!payment) return 'transparent';

  switch (payment?.status.value) {
    case 'PROCESSED':
      return '#ecf5ff';
    case null:
      return '#b0e0e6';
    default:
      return '#db7093';
  }
};

const statusPaymentTextColorFormatter = (payment: EventGuest['payment']): string => {
  if (!payment) return '#FFFFFF';
  if (payment.status.value === 'PROCESSED') return '#409EFF';
  return '#FFFFFF';
};

const amountFormatter = (payment: EventGuest['payment']): string => {
  if (!payment) return '';
  if (payment.amount === null) return '-';

  return payment.amount.toString();
};

export const titleIconColumn: TableColumn<EventGuest> = {
  prop: 'titleIcon',
  width: 50,
  align: 'center',
  view: {
    type: 'html',
    formatter: (_, row) => (row.titleIcon ? `<img src=${Crown} alt="crown">` : ''),
  },
};

export const firstNameField:TableColumn<EventGuest> = {
  prop: 'firstName',
  label: translate('hubstr.event.guests.table.name'),
  width: 150,
};

export const lastNameField:TableColumn<EventGuest> = {
  prop: 'lastName',
  label: translate('hubstr.event.guests.table.surname'),
  width: 150,
};

export const emailField:TableColumn<EventGuest> = {
  prop: 'email',
  label: translate('hubstr.event.guests.table.email'),
  width: 250,
};

export const phoneField:TableColumn<EventGuest> = {
  prop: 'phone',
  label: translate('hubstr.event.guests.table.phone'),
  width: 170,
  view: {
    type: 'text',
    formatter: (_, row) => formatPhone(row.phone),
  },
};

export const telegramField:TableColumn<EventGuest> = {
  prop: 'telegram',
  label: translate('hubstr.event.guests.table.telegram'),
  width: 150,
};

export const statusField:TableColumn<EventGuest> = {
  prop: 'status',
  label: translate('hubstr.event.guests.table.status'),
  view: {
    type: 'tags',
    formatter: (_, row) => (row.status ? row.status.label : ''),
  },
  width: 150,
};

export const updatedField:TableColumn<EventGuest> = {
  prop: 'updatedAt',
  label: translate('hubstr.event.guests.table.updatedAt'),
  view: {
    type: 'text',
    formatter: (_, row) => formatDate(row.updatedAt),
  },
  width: 150,
};

export const paymentField:TableColumn<EventGuest> = {
  prop: 'payment',
  label: translate('hubstr.event.guests.table.payment'),
  width: 150,
  view: {
    type: 'tags',
    formatter: (_, row) => [{
      text: row.payment ? row.payment.status.label : '',
      backgroundColor: statusPaymentColorFormatter(row.payment),
      textColor: statusPaymentTextColorFormatter(row.payment),
    }],
  },
};

export const amountField:TableColumn<EventGuest> = {
  prop: 'amount',
  label: translate('hubstr.event.guests.table.amount'),
  width: 150,
  view: {
    type: 'text',
    formatter: (_, row) => amountFormatter(row.payment),
  },
};
