import { ChangeLog } from 'features/change-log/interfaces';

export const log = [
  {
    version: '1.1.0',
    date: '01.11.2023',
    text: {
      whatsNew: [
        '<b>Админ-панель:</b><br>'
        + '<ul>'
        + '<li>Импорт пользователей с помощью загрузки таблицы;</li></ul>',

        '<b>Приложение:</b><br>'
        + '<ul>'
        + '<li>Блокировка кнопки «+» при достижении одного из лимитов (первого по приоритету);</li>'
        + '<li>Исправление работы счётчика на иконке приложения;</li></ul>',
      ],
    },
  },
  {
    version: '1.0.34',
    date: '10.10.2023',
    text: {
      whatsNew: [
        '<b>СБП:</b><br>'
        + '<ul>'
        + '<li>Добавлена новая настройка в приложениях, хранящая время в секундах отведённое на оплату;</li>'
        + '<li>Добавлен новый статус платежа "Не оплачен";</li></ul>',

        '<b>Приложение:</b><br>'
        + '<ul>'
        + '<li>На последнем экране перед оплатой удалены кнопки изменения количества;</li></ul>',

        '<b>Админ-панель:</b><br>'
        + '<ul>'
        + '<li>На детальной странице опросника добавлен предпросмотр для мобильного приложения. Теперь можно до публикации увидеть то, как будет выглядеть опрос для пользователя и пройти его прямо в предпросмотре;</li>'
        + '<li>В разделе «Справочники»-> «Роли в клубе» добавлена палитра для выбора цвета роли;</li>'
        + '<li>Добавлен раздел с новостями, где можно смотреть новое в системе;</li></ul>',
      ],
    },
  },
] as ChangeLog[];
