<template>
  <el-row class="plain-answer-question" style="width: 100%;padding: 20px 0">
    <el-col
      v-if="state.dataType === DATA_TYPE.STRING"
      :lg="12"
      :md="12"
      :xs="24"
    >
      <el-radio-group v-model="state.textLength" @update:modelValue="emitData">
        <el-radio
          v-for="option in TEXT_LENGTH_OPTIONS"
          :key="option.value"
          :label="option.value"
          :disabled="onlyMeta"
          border
        >
          {{ translateText(option.label) }}
        </el-radio>
      </el-radio-group>
    </el-col>
    <el-col
      v-if="state.dataType === DATA_TYPE.NUMBER"
      :lg="12"
      :md="12"
      :xs="24"
    >
      <el-row>
        <el-col :span="12">
          <p class="label">
            {{ `${translateText('gaz.interview.plain_answer_question.int_limit')}:` }}
            <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
              <svg-icon
                size="sm"
                class="generic-form_item_help"
                core="question"
              />
              <template #content>
                {{ translateText('gaz.interview.plain_answer_question.int_limit_hint') }}
              </template>
            </el-tooltip>
          </p>
          <el-input-number
            v-model="state.intLimit"
            :class="[{'is-error': errors.intLimit && showErrors}]"
            :min="state.intLimitMin"
            @update:modelValue="emitData"
          />
        </el-col>
        <el-col :span="12">
          <p class="label">
            {{ `${translateText('gaz.interview.plain_answer_question.total_limit')}:` }}
            <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
              <svg-icon
                size="sm"
                class="generic-form_item_help"
                core="question"
              />
              <template #content>
                {{ translateText('gaz.interview.plain_answer_question.total_limit_hint') }}
              </template>
            </el-tooltip>
          </p>
          <el-input-number
            v-model="state.totalLimit"
            :class="[{'is-error': errors.totalLimit && showErrors}]"
            :min="state.totalLimitMin"
            @update:modelValue="emitData"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-row v-if="state.dataType === DATA_TYPE.NUMBER && !price && !moneySettingsHidden">
    <el-col>
      <el-alert style="margin-top: 20px" title="К этому вопросу можно прикрепить оплату" type="success" />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import { IPlainAnswerQuestion } from 'features/settings/interviews/interfaces';
import { DATA_TYPE, DATA_TYPE_OPTIONS, TEXT_LENGTH_OPTIONS } from 'features/settings/interviews/constants';
import { translate, useTranslate } from 'magner';

interface IPlainAnswerQuestionState {
  dataType: string;
  textLength: string;
  intLimit: number;
  intLimitMin: number;
  totalLimit: number;
  totalLimitMin: number;
}

export default defineComponent({
  name: 'PlainAnswerQuestion',
  props: {
    data: {
      type: Object as PropType<IPlainAnswerQuestion>,
      required: true,
    },
    price: {
      type: Number,
      default: 0,
    },
    moneySettingsHidden: {
      type: Boolean,
      default: false,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: IPlainAnswerQuestionState = reactive({
      dataType: props.data.dataType ?? DATA_TYPE_OPTIONS[0].value,
      textLength: props.data.textLength ?? TEXT_LENGTH_OPTIONS[0].value,
      intLimit: props.data.intLimit ?? 0,
      intLimitMin: props.onlyMeta && props.data.intLimit ? props.data.intLimit : 0,
      totalLimit: props.data.totalLimit ?? 0,
      totalLimitMin: props.onlyMeta && props.data.totalLimit ? props.data.totalLimit : 0,
    });
    const errors: {
      intLimit: boolean,
      totalLimit: boolean
    } = reactive({
      intLimit: false,
      totalLimit: false,
    });
    /**
     * Метод следит за обновлением пропсов
     */
    watch(() => props.data, () => {
      state.dataType = props.data.dataType ?? DATA_TYPE_OPTIONS[0].value;
      state.textLength = props.data.textLength ?? TEXT_LENGTH_OPTIONS[0].value;
      state.intLimit = props.data.intLimit ?? 0;
      state.totalLimit = props.data.totalLimit ?? 0;
    });
    /**
     * Метод валидирует введенные данные
     */
    const validateData = () => {
      errors.intLimit = state.intLimit === 0 || state.intLimit > state.totalLimit;
      errors.totalLimit = state.totalLimit !== 0 && state.totalLimit < state.intLimit;
    };
    /**
     * Метод отправляет данные "наверх"
     */
    const emitData = () => {
      validateData();

      const out: Partial<IPlainAnswerQuestionState> = {
        dataType: state.dataType,
        textLength: state.textLength,
      };

      if (state.dataType === DATA_TYPE.STRING) {
        state.intLimit = 0;
        state.totalLimit = 0;

        emit('update:data', {
          data: out,
          errors: errors.intLimit || errors.totalLimit,
        });
        return;
      }

      out.intLimit = state.intLimit;
      if (state.totalLimit > 0) {
        out.totalLimit = state.totalLimit;
      }

      emit('update:data', { data: out, errors: errors.intLimit || errors.totalLimit });
    };
    /**
     *
     */
    onMounted(() => {
      setTimeout(() => {
        emitData();
      });
    });

    return {
      state,
      errors,

      emitData,
      translateText: (code: string) => customT(translate(code)),

      TEXT_LENGTH_OPTIONS,
      DATA_TYPE_OPTIONS,
      DATA_TYPE,
    };
  },
});
</script>

<style lang="scss" scoped>
.label {
  display: block;
  padding: 0 0 10px 0;
  margin: 0;
}
</style>
