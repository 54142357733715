<template>
  <div class="body-module">
    <draggable
      :list="state"
      group="views"
      item-key="widgets"
      class="drag-area"
    >
      <template #item="{element, index}">
        <CollapseWrap>
          <template #title>
            <el-icon :size="15" class="item-icon">
              <BarsSVG />
            </el-icon>
            <EditableTextInput
              v-model="element.title"
            />
            <el-popconfirm
              :title="translateText('hubstr.editor.warnings.remove_group_confirm')"
              :width="300"
              :hide-after="0"
              @confirm="removeGroupSubmitHandler(index)"
            >
              <template #reference>
                <el-button
                  type="danger"
                  class="delete-btn"
                  :icon="Delete"
                  link
                  @click.stop
                />
              </template>
            </el-popconfirm>
          </template>
          <template #default>
            <component
              :is="setComponent(element.type)"
              :key="element"
              :data="element"
              @edit="editWidgetModalHandler(index, $event)"
              @remove="removeWidgetSubmitHandler(index, $event)"
            />
            <el-button
              type="primary"
              plain
              style="margin-top: 10px"
              @click="addWidgetModalHandler(index)"
            >
              {{ translateText('hubstr.editor.add_field') }}
            </el-button>
          </template>
        </CollapseWrap>
      </template>
    </draggable>
    <el-button type="primary" plain @click="isOpenDialogAddGroup = true">
      {{ translateText('hubstr.editor.add_group') }}
    </el-button>
  </div>

  <AddGroupModal
    :key="isOpenDialogAddGroup"
    v-model="isOpenDialogAddGroup"
    @change="addGroupSubmitHandler"
  />

  <AddWidgetModal
    :key="isOpenDialogCreate"
    v-model="isOpenDialogCreate"
    :data="data.body"
    @update:formData="addWidgetSubmitHandler"
    @update:autocomplete="autocompleteHandler"
  />
  <EditWidgetModal
    :key="isOpenDialogEdit"
    v-model="isOpenDialogEdit"
    :data="formDataForEdit"
    :group-index="currentGroupIndex"
    @update:formData="editWidgetSubmitHandler"
    @update:autocomplete="autocompleteHandler"
  />
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable-es';
import {
  type Component, reactive, ref,
} from 'vue';
import CollapseWrap
  from 'features/settings/editor/association-editor/ui/components/left-side/components/collapse-wrap.vue';
import { IAViewWidgets, IAViewWidgetsBody, ValueOf } from 'features/settings/editor/association-editor/interfaces';
import {
  IWidget,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/interfaces';
import {
  GROUP_TYPES,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/groups/types/constants';
import { translate, useTranslate } from 'magner';
import AddGroupModal
  from 'features/settings/editor/association-editor/ui/components/left-side/modals/add-group-modal.vue';
import AddWidgetModal
  from 'features/settings/editor/association-editor/ui/components/left-side/modals/add-widget-modal.vue';
import EditWidgetModal
  from 'features/settings/editor/association-editor/ui/components/left-side/modals/edit-widget-modal.vue';
import DefaultGroup
  from 'features/settings/editor/association-editor/ui/components/left-side/components/groups/default-group.vue';
import UnknownGroup
  from 'features/settings/editor/association-editor/ui/components/left-side/components/groups/unknown-group.vue';
import EditableTextInput
  from 'features/settings/editor/association-editor/ui/components/left-side/components/editable-text-input.vue';
import { Delete } from '@element-plus/icons-vue';
import BarsSVG from 'features/settings/editor/assets/icons/bars.svg';
import { updateAdditionalField } from 'features/settings/editor/association-editor/requests';
import useEditorStore from 'features/settings/editor/store/useEditorStore';

interface Props {
  data: IAViewWidgets
}

const props = defineProps<Props>();
const emit = defineEmits(['edit', 'remove']);
const state = reactive<IAViewWidgetsBody[]>(props.data.body);

const store = useEditorStore();
const isOpenDialogCreate = ref(false);
const isOpenDialogEdit = ref(false);
const isOpenDialogAddGroup = ref(false);
const currentGroupIndex = ref();
const formDataForEdit = ref();

const setComponent = (groupType: ValueOf<typeof GROUP_TYPES>): Component => {
  switch (groupType) {
    case GROUP_TYPES.GROUP:
      return DefaultGroup;
    default:
      return UnknownGroup;
  }
};

// Modal Actions
const addWidgetModalHandler = (groupIndex: number) => {
  currentGroupIndex.value = groupIndex;
  isOpenDialogCreate.value = true;
};
const editWidgetModalHandler = (groupIndex: number, formData: IWidget) => {
  currentGroupIndex.value = groupIndex;
  formDataForEdit.value = formData;
  isOpenDialogEdit.value = true;
};

// Widget Actions
const addGroupSubmitHandler = (newGroup: IAViewWidgetsBody) => {
  state.push(newGroup);
};
const addWidgetSubmitHandler = (formData: IWidget) => {
  state[currentGroupIndex.value].widgets?.push(formData as never);
  isOpenDialogCreate.value = false;
};
const editWidgetSubmitHandler = (formData: IWidget) => {
  const widgetIndex = state[currentGroupIndex.value].widgets?.findIndex((w) => w.id === formData.id);
  state[currentGroupIndex.value].widgets?.splice(widgetIndex, 1, formData as never);
  isOpenDialogEdit.value = false;
};
const removeWidgetSubmitHandler = async (groupIndex: number, widgetId: string) => {
  const widget = state[groupIndex].widgets?.find((w) => w.id === widgetId) as IWidget;

  /**
   * Прежде чем отвязывать виджет от формы - надо сделать поле необязательным
   */
  if ('additions' in widget && !widget?.isNew && widget?.additions?.id) {
    await updateAdditionalField({
      id: widget.additions.id,
      isUserRequired: false,
      isAdminRequired: false,
    });
  }

  const widgetIndex = state[groupIndex].widgets?.findIndex((w) => w.id === widgetId);
  state[groupIndex].widgets?.splice(widgetIndex, 1);
};
const removeGroupSubmitHandler = (groupIndex: number) => {
  Promise.all(state[groupIndex].widgets.map(async (w) => {
    await removeWidgetSubmitHandler(groupIndex, w.id);
  })).then(() => {
    state.splice(groupIndex, 1);
  });
};

const autocompleteHandler = () => {
  isOpenDialogCreate.value = false;
  isOpenDialogEdit.value = false;
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));
</script>

<style scoped>
.drag-area {
  background: white;
}
.item-icon {
  margin-right: 10px;
  color: var(--el-text-color-secondary);
  cursor: move;
}
.delete-btn {
  width: auto !important;
  margin-bottom: 0 !important;
  margin-left: 5px !important;
  margin-right: 15px;
}
</style>
