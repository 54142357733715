<template>
  <el-tooltip v-if="row.published" :append-to-body="false" popper-class="generic-form_item_tooltip">
    <template #content>
      {{ translateText('gaz.interview.table.download') }}
    </template>
    
    <div class="table-action-icon">
      <icon-result @click="clickButton(row.id)" />
    </div>
  </el-tooltip>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { API_URL } from '~/constants';

import IconResult from '~/assets/icons/result.svg';

export default defineComponent({
  name: 'ExportButton',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup () {
    const { customT } = useTranslate();
    const ls = JSON.parse(localStorage.getItem('magner-hubstr') || '');
    const token = ls.token;
    // @ts-ignore
    const clickButton = (pollId: string) => {
      fetch(`${API_URL}/api/admin/analytics/export/poll/${pollId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.blob();
          }
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          // @ts-ignore
          a.download = `результаты_опроса_${pollId}.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    };

    const translateText = (code: string) => customT(translate(code));

    return {
      clickButton,
      translateText,
    };
  },

  components: {
    IconResult,
  },
});
</script>
