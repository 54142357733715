<template>
  <div class="m-notifications">
    <div class="m-notifications__title">
      Уведомления
    </div>
    <div class="m-notifications__content">
      <el-form>
        <el-form-item v-if="welcome" class="checkbox" label="Welcome сообщение в постах">
          <el-checkbox v-model="state.sendWelcomeMessage.value" />
        </el-form-item>
        
        <el-form-item class="checkbox" label="Присылать сообщения о новом резиденте">
          <el-checkbox v-model="state.sendNotificationResidentNew.value" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MNotifications',
  props: {
    data: {
      type: Object,
      default: null,
    },
    welcome: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const state = ref(props.data);

    return {
      state,
    };
  },
});
</script>

<style lang="scss" scoped>
.m-notifications {
  &__title {
    font-size: var(--el-font-size-large);
  }
  &__content {
    margin-top: 20px;
    & .checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row-reverse;
      width: max-content;
    }
  }
}
</style>