<template>
  <div class="preference-layout">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="Формы обратной связи" name="forms">
        <el-card>
          <MForms
            id="safety-claim"
            :data="state.feedbackGazBreaking"
            title="Охрана труда | Заявить о нарушении"
            :theme-short-codes="themeShortCodes"
            :template-short-codes="safetyClaimTemplateShortCodes"
          />
        </el-card>
        <el-card>
          <MForms
            id="legal-reception"
            :data="state.feedbackGazReception"
            title="Юридическая приемная"
            :theme-short-codes="themeShortCodes"
            :template-short-codes="legalReceptionTemplateShortCodes"
          />
        </el-card>
        <el-card>
          <MForms
            id="safety-offer"
            :data="state.feedbackGazSuggestions"
            title="Охрана труда | Предложение по изменению"
            :theme-short-codes="themeShortCodes"
            :template-short-codes="safetyOfferTemplateShortCodes"
          />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="Уведомления" name="notifications">
        <el-card>
          <MNotifications :data="state" />
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="Общие настройки" name="settings">
        <el-card>
          <MGeneralSettings :data="state" />
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import MGeneralSettings from 'features/settings/preferences/components/modules/m-general-settings.vue';
import MForms from 'features/settings/preferences/components/modules/m-forms.vue';
import MNotifications from 'features/settings/preferences/components/modules/m-notifications.vue';

export default defineComponent({
  name: 'PreferenceLayout',
  components: { MNotifications, MForms, MGeneralSettings },
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const activeName = ref('forms');
    const state = ref(props.modelValue);

    const themeShortCodes = [
      {
        value: 'number',
        label: 'Номер',
      },
    ];

    const defaultTemplateShortCodes = [
      {
        value: 'user.name',
        label: 'Пользователь',
      },
      {
        value: 'user.organization',
        label: 'Структурная организация',
      },
      {
        value: 'created_at',
        label: 'Дата и время',
      },
      {
        value: 'number',
        label: 'Номер',
      },
      {
        value: 'email',
        label: 'Почта',
      },
      {
        value: 'text',
        label: 'Текст',
      },
    ];

    const safetyClaimTemplateShortCodes = [
      ...defaultTemplateShortCodes,
      {
        value: 'organization',
        label: 'Организация',
      },
      {
        value: 'place',
        label: 'Место',
      },
    ];

    const legalReceptionTemplateShortCodes = [
      ...defaultTemplateShortCodes,
    ];

    const safetyOfferTemplateShortCodes = [
      ...defaultTemplateShortCodes,
      {
        value: 'organization',
        label: 'Организация',
      },
      {
        value: 'Регулирующий акт',
        label: 'Нормативный акт',
      },
      {
        value: 'point',
        label: 'Номер пункта',
      },
    ];

    return {
      activeName,
      state,
      themeShortCodes,

      safetyClaimTemplateShortCodes,
      legalReceptionTemplateShortCodes,
      safetyOfferTemplateShortCodes,
    };
  },
});
</script>

<style scoped>
.preference-layout {
  width: 100%;

  .el-tab-pane {
    padding: 5px;
  }
}
</style>
