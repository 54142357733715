export const ADMIN_GROUP_TYPES = {
  GROUP: 'group',
  COMPANIES: 'companies',
  CONTACTS: 'contacts',
  SOCIALS: 'socials',
} as const;

export const GROUP_TYPES = {
  GROUP: 'group_widget',
  COMMUNITIES: 'group_button_widget',
  SOCIAL: 'group_icons_widget',
  CONTACTS: 'admin_group_contacts_widget',
} as const;
