<template>
  <el-row class="plain-answer-question" style="width: 100%;padding: 20px 0">
    <el-col
      v-if="state.dataType === DATA_TYPE.STRING"
      :lg="12"
      :md="12"
      :xs="24"
    >
      <p class="label">{{ `${translateText('gaz.interview.plain_answer_question.text_length')}:` }}</p>
      <el-radio-group v-model="state.textLength" @update:modelValue="emitData">
        <el-radio
          v-for="option in TEXT_LENGTH_OPTIONS"
          :key="option.value"
          :label="option.value"
          :disabled="onlyMeta"
          border
        >
          {{ translateText(option.label) }}
        </el-radio>
      </el-radio-group>
    </el-col>
    <el-divider />
    <el-col
      v-if="state.dataType === DATA_TYPE.NUMBER"
      :lg="24"
      :md="24"
      :xs="24"
    >
      <div class="plain-answer-question__limits">
        <el-row style="padding-bottom: 20px">
          <h4>Лимиты</h4>
        </el-row>
        <div style="display: flex; flex-direction: column; gap: 20px">
          <el-row :gutter="20" align="middle">
            <el-col :span="10" :xs="12" style="height: 40px;">
              <div class="align-centred" :class="{'is-disabled': disableIntLimit}">
                <el-switch
                  v-model="state.enabledIntLimit"
                  :disabled="isPublished"
                  :active-text="translateText('gaz.interview.question.respondent_limit.label')"
                  @update:modelValue="emitData"
                />
                <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                  <svg-icon
                    size="sm"
                    class="generic-form_item_help"
                    core="question"
                  />
                  <template #content>
                    <div v-html="translateText('gaz.interview.question.respondent_limit.hint')" />
                  </template>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="14" :xs="12">
              <el-input-number
                v-if="state.enabledIntLimit"
                v-model="state.intLimit"
                :disabled="disableIntLimit"
                :class="[{'is-error': errors.intLimit && showErrors}]"
                :min="state.intLimitMin"
                @update:modelValue="emitData"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10" :xs="12" style="height: 40px;">
              <div class="align-centred" :class="{'is-disabled': disableTotalLimit}">
                <el-switch
                  v-model="state.enabledTotalLimit"
                  :disabled="isPublished"
                  :active-text="translateText('gaz.interview.question.question_limit.label')"
                  @update:modelValue="emitData"
                />
                <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                  <svg-icon
                    size="sm"
                    class="generic-form_item_help"
                    core="question"
                  />
                  <template #content>
                    <div v-html="translateText('gaz.interview.question.question_limit.hint')" />
                  </template>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="14" :xs="12">
              <el-input-number
                v-if="state.enabledTotalLimit"
                v-model="state.totalLimit"
                :disabled="disableTotalLimit"
                :class="[{'is-error': errors.totalLimit && showErrors}]"
                :min="state.totalLimitMin"
                @update:modelValue="emitData"
              />
            </el-col>
          </el-row>
          <el-row>
            <div class="align-centred" :class="{'is-disabled': disableIsStepLimit}">
              <el-checkbox
                v-model="state.notIncludeInLimit"
                :disabled="isPublished"
                :label="translateText('gaz.interview.question.not_taken.label')"
                @change="emitData"
              />
              <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                <svg-icon
                  size="sm"
                  class="generic-form_item_help"
                  core="question"
                />
                <template #content>
                  <div v-html="translateText('gaz.interview.question.not_taken.hint')" />
                </template>
              </el-tooltip>
            </div>
          </el-row>
        </div>
      </div>
      <el-divider />
      <div class="plain-answer-question__payment">
        <el-row :gutter="20">
          <el-col :span="10" :xs="12">
            <div class="align-centred">
              <el-switch
                v-model="state.enabledPrice"
                :disabled="isPublished"
                :active-text="translateText('gaz.interview.question.price.label') + ', ₽'"
                @change="emitData"
              />
              <el-tooltip :append-to-body="false" popper-class="generic-form_item_tooltip">
                <svg-icon
                  size="sm"
                  class="generic-form_item_help"
                  core="question"
                />
                <template #content>
                  <div v-html="translateText('gaz.interview.question.price.hint')" />
                </template>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="14" :xs="12">
            <el-input-number
              v-if="state.enabledPrice"
              v-model="state.price"
              :min="0"
              @update:modelValue="emitData"
            />
          </el-col>
        </el-row>
      </div>
      <el-divider />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { IPlainAnswerQuestion, IStep } from 'features/settings/interviews/interfaces';
import { DATA_TYPE, DATA_TYPE_OPTIONS, TEXT_LENGTH_OPTIONS } from 'features/settings/interviews/constants';
import { translate, useTranslate } from 'magner';

interface IPlainAnswerQuestionState {
  dataType: string;
  textLength: string;
  intLimit: number;
  intLimitMin: number;
  totalLimit: number;
  totalLimitMin: number;
  price: number;

  showErrors: Boolean,

  enabledIntLimit: boolean;
  enabledTotalLimit: boolean;
  enabledPrice: boolean;
  notIncludeInLimit: boolean;
}

export default defineComponent({
  name: 'PlainAnswerQuestionNumber',
  props: {
    data: {
      type: Object as PropType<IPlainAnswerQuestion>,
      required: true,
    },
    step: {
      type: Object as PropType<IStep>,
      required: true,
    },
    price: {
      type: Number,
      default: 0,
    },
    moneySettingsHidden: {
      type: Boolean,
      default: false,
    },
    showErrors: Boolean,
    onlyMeta: Boolean,
    isPublished: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: IPlainAnswerQuestionState = reactive({
      dataType: props.data.dataType ?? DATA_TYPE_OPTIONS[1].value,
      textLength: props.data.textLength ?? TEXT_LENGTH_OPTIONS[0].value,
      intLimit: props.data.intLimit ?? 0,
      intLimitMin: props.onlyMeta && props.data.intLimit ? props.data.intLimit : 0,
      totalLimit: props.data.totalLimit ?? 0,
      totalLimitMin: props.onlyMeta && props.data.totalLimit ? props.data.totalLimit : 0,
      price: props.price ?? 0,
      notIncludeInLimit: props.data.notIncludeInLimit || false,

      enabledIntLimit: !!props.data.intLimit || false,
      enabledTotalLimit: !!props.data.totalLimit || false,
      enabledPrice: !!props.price || false,
    });
    const errors: {
      intLimit: boolean,
      totalLimit: boolean
    } = reactive({
      intLimit: false,
      totalLimit: false,
    });

    const disableIntLimit = ref(false);
    const disableTotalLimit = computed(() => (props.step.hasStepLimit && !state.notIncludeInLimit) || false);
    const disableIsStepLimit = ref(false);
    /**
     * Метод следит за обновлением пропсов
     */
    watch(() => props.data, () => {
      state.dataType = props.data.dataType ?? DATA_TYPE_OPTIONS[0].value;
      state.textLength = props.data.textLength ?? TEXT_LENGTH_OPTIONS[0].value;
      state.intLimit = props.data.intLimit ?? 0;
      state.totalLimit = props.data.totalLimit ?? 0;
    });
    watch(() => props.step, () => {
      if (props.step.hasStepLimit && !state.notIncludeInLimit) {
        state.enabledTotalLimit = false;
      }
    }, { deep: true });
    /**
     * Метод валидирует введенные данные
     */
    const validateData = () => {
      errors.intLimit = state.intLimit === 0 || (state.enabledTotalLimit && state.intLimit > state.totalLimit);
      errors.totalLimit = state.totalLimit !== 0 && state.totalLimit < state.intLimit;
    };
    /**
     * Метод отправляет данные "наверх"
     */
    const emitData = () => {
      validateData();

      const out: Partial<IPlainAnswerQuestionState> = {
        dataType: state.dataType,
        textLength: state.textLength,
        enabledTotalLimit: state.enabledTotalLimit,
        enabledIntLimit: state.enabledIntLimit,
        price: state.price,
        notIncludeInLimit: state.notIncludeInLimit,
        enabledPrice: state.enabledPrice,
      };

      if (state.dataType === DATA_TYPE.STRING) {
        state.intLimit = 0;
        state.totalLimit = 0;

        emit('update:data', {
          data: out,
          errors: errors.intLimit || errors.totalLimit,
        });
        return;
      }

      out.intLimit = state.intLimit;
      if (state.totalLimit > 0) {
        out.totalLimit = state.totalLimit;
      }

      emit('update:data', { data: out, errors: errors.intLimit || errors.totalLimit });
    };
    /**
     * Следит за изменением включенности "Лимита вопроса",
     * тк он меняется реактивно от чекбокса "Не учитывать в лимите шага"
     * */
    watch(() => state.enabledTotalLimit, () => {
      emitData();
    });
    /**
     *
     */
    onMounted(() => {
      setTimeout(() => {
        emitData();
      });
    });

    return {
      state,
      errors,
      disableIntLimit,
      disableTotalLimit,
      disableIsStepLimit,

      emitData,
      translateText: (code: string) => customT(translate(code)),

      TEXT_LENGTH_OPTIONS,
      DATA_TYPE_OPTIONS,
      DATA_TYPE,
    };
  },
});
</script>

<style lang="scss" scoped>
.label {
  display: block;
  padding: 0 0 10px 0;
  margin: 0;
}

.align-centred {
  display: flex;
  align-items: center;
}
.icon-question {
  opacity: .4;
  margin-left: 7px !important;
}
.el-checkbox__label {
  font-weight: normal;
}
.is-disabled *:not(i){
  opacity: .4;
  pointer-events: none;
}
</style>
