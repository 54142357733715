import { Complaint, ComplaintsList } from 'features/settings/complaints/types/hubstr';
import { request } from '~/utils/request';
import { CustomNotification } from 'features/settings/notification/types/hubstr';
import { translate, useTranslate } from 'magner';
import { NOTIFICATION_STATUS } from 'features/settings/notification/constants';

const BASE_URL = '/api/admin/complaint/';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const complaintsHead = request.table<ComplaintsList>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}`;

  const res = await api.get<{ list: ComplaintsList[], pager: Pager }>(`${BASE_URL}list?${pagination}`);
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const complaintGet = request.custom<Complaint>(async ({ api, data, parseError }) => {
  const res = await api.get<{data: Complaint }>(`${BASE_URL}read?id=${data}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: res.data?.data,
  };
});
