<template>
  <el-row v-if="stepsValid.length" class="mobile-editor interview-preview">
    <el-col :span="24">
      <div class="phone-wrap">
        <div class="iphone-x">
          <div class="top-header">
            <i>Speaker</i>
            <b>Camera</b>
            <div class="brow">
              <s>{{ new Date().toLocaleTimeString().slice(0,5) }}</s>
            </div>
          </div>

          <div class="content interview-preview__content">
            <preview-header
              :current="step"
              :length="stepsValid.length"
            />

            <preview-body
              :step="steps[step - 1]"
            />

            <preview-footer
              v-model="step"
              :length="stepsValid.length"
              :next-disabled="nextDisabled"
              :is-valid-zero-conditions="hasAnyValueInStepZeroConditions"
            />
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
  ref,
  watch,
} from 'vue';

import { IInterviewState, IListQuestion } from 'features/settings/interviews/interfaces';

import PreviewHeader from '../preview/preview-header.vue';
import PreviewBody from '../preview/preview-body.vue';
import PreviewFooter from '../preview/preview-footer.vue';

interface ITypeListValue {
  value: string | string[];
  answer: string;
}

export default defineComponent({
  name: 'IntreviewPreviewLayout',

  components: {
    PreviewHeader,
    PreviewBody,
    PreviewFooter,
  },

  props: {
    modelValue: {
      type: Object as PropType<IInterviewState>,
      required: true,
    },
  },

  setup (props) {
    const setupSteps = (stepsQuestions?: any) => props.modelValue.steps.map((step) => ({
      ...step,
      questions: step.questions.map((question) => {
        // eslint-disable-next-line max-len
        let currentQuestion = stepsQuestions?.find((item: any) => item.uuid === question.uuid) || null;

        if (
          currentQuestion?.type !== question?.type
          || currentQuestion?.dataType !== question?.dataType
          || currentQuestion?.multiselect !== (question as IListQuestion)?.multiselect
          || currentQuestion?.selfAnswer !== (question as IListQuestion)?.selfAnswer
        ) {
          currentQuestion = null;
        }

        let value: any = currentQuestion?.value || '';

        if (question.type === 'type_list' && !(question as IListQuestion).multiselect) {
          value = currentQuestion?.value || {
            value: '',
            answer: '',
          };
        }

        if (question.type === 'type_list' && (question as IListQuestion).multiselect) {
          value = currentQuestion?.value || {
            value: [],
            answer: '',
          };
        }

        if (question.type === 'type_list' && question.dataType !== 'type_string') {
          value = currentQuestion?.value || {
            value: '',
            answer: '',
          };
        }

        if (question.type === 'type_file') {
          value = currentQuestion?.value || [];
        }

        return {
          ...question,
          value,
        };
      }),
    }));

    const steps = ref(setupSteps());
    const step = ref(1);

    // eslint-disable-next-line max-len
    const hasAnyValueInStepZeroConditions = computed(() => steps.value.some((item) => item.questions.some((question) => question.inZeroCondition && question.value > 0)));

    // eslint-disable-next-line max-len
    const stepsValid = computed(() => steps.value.filter((item) => item.questions.find((question) => question.question)));

    const stepsQuestions = computed(() => steps.value.map((item) => item.questions.map((question) => question)).flat());

    const nextDisabled = computed(() => {
      const questions = steps.value[step.value - 1].questions;

      const requiredQuestions = questions.filter((question) => question.required && question.question);

      const validQuestions = questions.filter((question) => {
        if (question.type === 'type_list') {
          // eslint-disable-next-line max-len
          return question.required && !!((question.value as ITypeListValue)?.value?.length || (question.value as ITypeListValue)?.answer);
        }

        if (question.type === 'type_file') {
          return question.required && (question.value as File[]).length;
        }

        return question.required && !!question.value;
      });

      return validQuestions.length !== requiredQuestions.length;
    });

    watch(() => props.modelValue.steps, () => {
      steps.value = setupSteps(stepsQuestions.value);
    }, { deep: true });

    watch(() => props.modelValue.steps.length, () => {
      step.value = 1;
    }, { deep: true });

    return {
      steps,
      step,
      stepsValid,
      hasAnyValueInStepZeroConditions,

      nextDisabled,
    };
  },
});
</script>

<style lang="scss" scoped>
.mobile-editor {
  width: 100%;
}

.phone-wrap {
  position: sticky;
  top: 20px;
}

.interview-preview {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
