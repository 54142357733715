import { Resident } from 'features/residents/types/hubstr';
import { request } from '~/utils/request';

const BASE_URL = '/api/admin/user';

export const communityManagersListGet = request.custom<{ rows: Resident[] }, string>(
  // @ts-ignore
  async ({ api, data }) => {
    const search = data?.search ? `&filters[0][id]=search&filters[0][value]=${data.search}` : '';
    const communityManager = '&filters[1][id]=isCommunityManager&filters[1][value]=1';
    const sort = '&sort[0][id]=lastName&sort[0][value]=ASC';
    const res = await api.get<{ list: Resident[] }>(
      `${BASE_URL}/list?page=1${sort}${search}${communityManager}`,
    );
    if (res.error) {
      return {
        data: { rows: [] },
      };
    }

    const processedList: {id: string, fio: string}[] = res.data.list.map((item: Resident) => ({
      id: item.id,
      fio: `${item.firstName} ${item.lastName}`,
    }));

    processedList.unshift({
      id: '',
      fio: 'Все резиденты',
    }, {
      id: '-1',
      fio: 'Комьюнити не назначен',
    });

    return {
      data: {
        rows: processedList,
      },
    };
  },
);

export const communityManagerChange = request.custom(async ({ api, data, parseError }) => {
  const res = await api.patch(`${BASE_URL}/update/manager`, {
    communityManager: data.communityManager,
    users: data.residentsList.map((item: Resident) => item.id),
  });
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
