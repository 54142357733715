<template>
  <div class="preference-layout">
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane :label="translateText('hubstr.preferences.tabs.tab_1')" name="notifications">
        <el-card>
          <MNotifications :data="state" welcome />
        </el-card>
      </el-tab-pane>
      <el-tab-pane :label="translateText('hubstr.preferences.tabs.tab_2')" name="settings">
        <el-card>
          <MGeneralSettings :data="state" analytics :manager-preference="field?.managerPreference" />
        </el-card>
      </el-tab-pane>
      <el-tab-pane :label="translateText('hubstr.preferences.tabs.tab_3')" name="versions">
        <el-card>
          <MVersions :data="state" version />
        </el-card>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import MGeneralSettings from 'features/settings/preferences/components/modules/m-general-settings.vue';
import MNotifications from 'features/settings/preferences/components/modules/m-notifications.vue';
import MVersions from 'features/settings/preferences/components/modules/m-versions.vue';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'PreferenceLayout',
  components: { MVersions, MNotifications, MGeneralSettings },
  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },
    field: {
      type: Object as PropType<{managerPreference: boolean}>,
      default: () => ({ managerPreference: false }),
    },
  },
  setup (props) {
    const activeName = ref('notifications');
    const state = ref(props.modelValue);

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));
    return {
      activeName,
      state,
      translateText,
    };
  },
});
</script>

<style scoped>
.preference-layout {
  width: 100%;

  .el-tab-pane {
    padding: 5px;
  }
}
</style>
