import { cardPageController, translate } from 'magner';
import { Gto } from 'features/profile/sports/gto/types';
import { getGto, updateGto } from 'features/profile/sports/gto/requests';
import { ActionButton } from 'magner/dist/types/utils/actions';

const sportsGtoConfig = cardPageController<Gto>({
  header: {
    title: translate('gaz.sports.gto.card.title'),
    tabs: [
      {
        label: translate('gaz.sports.sections.tabs.title'),
        link: { name: 'sports-sections' },
        active: false,
      },
      {
        label: translate('gaz.sports.tourney.tabs.title'),
        link: { name: 'sports-tourney' },
        active: false,
      },
      {
        label: translate('gaz.sports.gto.tabs.title'),
        link: { name: 'sports-gto' },
        active: true,
      },
    ],
  },

  getRequest: getGto,
  createRequest: null,
  updateRequest: updateGto,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.sports.gto.form.cancel_button'),
        },
      } as ActionButton<'cancel'>,
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('gaz.sports.gto.form.submit_button'),
          type: 'primary',
        },
      } as ActionButton<'submit'>,
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('gaz.sports.gto.form.title.label'),
          props: {
            required: true,
            placeholder: translate('gaz.sports.gto.form.title.placeholder'),
            maxLength: 300,
            showLetterLimit: true,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
        {
          type: 'textarea',
          name: 'text',
          label: translate('gaz.sports.gto.form.text.label'),
          props: {
            required: true,
            placeholder: translate('gaz.sports.gto.form.text.placeholder'),
            maxLength: 10000,
            showLetterLimit: true,
            rows: 2,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
      ],
    },
  },
});

export default sportsGtoConfig;
