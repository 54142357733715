<template>
  <draggable
    :list="buttonsList"
    :group="buttonsList.type"
    item-key="name"
    @end="emitData"
  >
    <template #item="{element, index}">
      <div class="button-block">
        <div class="button-block__title">
          <el-icon :size="15" class="button-block__title-icon">
            <DCaret />
          </el-icon>
          {{ EnumHeaderButtons[element.name] || '???' }}
        </div>
        <div class="button-block__check">
          <el-switch
            v-model="element.visible"
            size="small"
            :disabled="countSelected === 4 && !element.visible"
            @change="emitData"
          />
        </div>
        <div v-if="element.name === 'thanks'" class="button-block__icons">
          <el-radio-group
            v-model="buttonsList[index].icon"
            :disabled="!element.visible"
            @change="emitData"
          >
            <el-radio label="hands" size="large">
              <Thanks class="icon" />
            </el-radio>
            <el-radio label="thank_you_heart" size="large">
              <Heart class="icon" />
            </el-radio>
            <el-radio v-if="SUB_DOMEN === APP_ETALON" label="etalon" size="large">
              <Etalon class="icon" />
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import {
  computed, ComputedRef,
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { Header } from 'features/settings/mobile-editor/interfaces';
import { EnumHeaderButtons } from 'features/settings/mobile-editor/constants';
import Draggable from 'vuedraggable-es';
import { DCaret } from '@element-plus/icons-vue';
import Thanks from '../../../right-side/assets/icons/thanks.svg';
import Heart from '../../../right-side/assets/icons/heart.svg';
import Etalon from '../../../right-side/assets/icons/etalon.svg';
import {
  APP_DP, APP_ETALON, SUB_DOMEN,
} from '~/constants';
import useStore from '~/store/useStore';

interface Button {
  type: string,
  name: 'thanks' | 'telegram' | 'phone' | 'add_to_contact' | 'vk' | 'instagram' | 'radar',
  link: string | null,
  icon?: 'hands' | 'thank_you_heart' | 'etalon' | null,
  visible?: boolean,
  entityId?: string,
  actions?: [Object],
  size?: string,
  enabled?: boolean,
}

export default defineComponent({
  name: 'HeaderButtonsSection',
  components: {
    Draggable, DCaret, Thanks, Heart, Etalon,
  },
  props: {
    data: {
      type: Object as PropType<Header[]>,
      required: true,
    },
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const store = useStore();
    const state = reactive<Button[]>(props.data.find((i) => i.type === 'header_group_buttons_widget')?.widgets || []);

    const buttonsList = reactive<Button[]>([
      {
        type: 'header_social_button_widget',
        name: 'thanks',
        link: 'data.thanks',
        visible: false,
        entityId: 'data.id',
        icon: null,
        size: '22',
      },
      {
        type: 'header_social_button_widget',
        name: 'telegram',
        link: 'data.contacts.telegram',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'phone',
        link: 'data.contacts.phone',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'add_to_contact',
        link: 'data.contacts.phone',
        visible: false,
        actions: [
          {
            type: 'add_to_contacts_action',
            contactsData: {
              badge: 'data.additions.userBadge',
              email: 'data.contacts.email',
              phone: 'data.contacts.phone',
              avatar: 'data.avatar',
              avatarSmall: 'data.avatar_small',
              userId: 'data.id',
              birthday: 'data.birthday',
              lastName: 'data.lastName',
              firstName: 'data.firstName',
              companyName: 'data.firstAssociationName',
            },
          },
        ],
      },
      {
        type: 'header_social_button_widget',
        name: 'vk',
        link: 'data.contacts.vk',
        visible: false,
      },
      {
        type: 'header_social_button_widget',
        name: 'instagram',
        link: 'data.contacts.instagram',
        visible: false,
      },
    ]);

    // TODO что отправляем во вьюху?
    // Если в настройках включен радар. Добавляем его в список
    if (store.preferences.value.find((i) => i.code === 'radarEnabled')?.value) {
      buttonsList.push({
        type: 'header_social_button_widget',
        name: 'radar',
        link: 'data.id',
        enabled: true,
        visible: false,
      });
    }

    /**
     * Проходит по пропсу и если кнопка с таким именем найдена в Buttons
     * Включает флаг visible в Buttons
     * */
    onMounted(() => {
      buttonsList.map((item: Button) => {
        state.map((h: Button) => {
          if (item.name === h.name) {
            item.visible = true;
          }
          return true;
        });
        return true;
      });
    });

    /**
     * Считает кол-во выбранных кнопок
     * */
    const countSelected = computed(() => buttonsList.filter((i) => i.visible).length);

    /**
     * Выбирает где visible:true, удаляет visible, эмитит
     * */
    const emitData = () => {
      const out = buttonsList.filter((i) => i.visible).map(({ visible, ...button }) => ({
        ...button,
        // переопределяем значение icon в null :(
        ...(button?.icon === 'hands' && { icon: null }),
      }));

      emit('update:data', out);
    };

    return {
      SUB_DOMEN,
      APP_DP,
      APP_ETALON,
      EnumHeaderButtons,
      state,
      buttonsList,
      countSelected,
      emitData,
    };
  },
});
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;
  flex-wrap: wrap;

  &__title {
    display: flex;
    align-items: center;
    width: 90%;
    @media (max-width: 1199px) {
      width: unset;
    }
  }
  &__title-icon {
    cursor: move;
    margin-right: 5px;
  }
  &__icons {
    margin-top: 10px;
  }
}
.icon {
  height: 30px;
  width: 30px;
  background: var(--el-color-primary);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
</style>
