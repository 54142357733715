import { translate } from 'magner';
import {
  searchEvents,
  searchPositionTags,
  searchPosts,
  searchResidents,
} from 'features/settings/notification/requests/hubstr';
import { CustomNotification } from 'features/settings/notification/types/hubstr';
import { roundToTwoSigns } from '~/utils/format-date';

const DeepLinks = {
  event: {
    value: 'event',
    label: 'Мероприятие',
    link: '/page?code=event&id=',
    withId: true,
  },
  post: {
    value: 'post',
    label: 'Пост',
    link: '/page?code=post&id=',
    withId: true,
  },
  resident: {
    value: 'resident',
    label: 'Резидент',
    link: '/page?code=resident&id=',
    withId: true,
  },
  residents_list: {
    value: 'residents_list',
    label: 'Список резидентов',
    link: '/page?code=resident_list',
    withId: false,
  },
};

export const hubstrLayout = [{
  type: 'column',
  props: {
    span: 24,
  },
  layout: [{
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, style: { margin: '0' } },
    fields: [
      { type: 'input', name: 'id', props: { hidden: true } },
      {
        type: 'input',
        name: 'caption',
        label: translate('hubstr.notification.form.caption.label'),
        props: {
          required: true,
          placeholder: translate('hubstr.notification.form.caption.placeholder'),
          maxLength: 30,
          showLetterLimit: true,
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      },
    ],
  },
  {
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, style: { margin: '0' } },
    fields: [
      {
        type: 'textarea',
        name: 'text',
        label: translate('hubstr.notification.form.text.label'),
        props: {
          required: true,
          placeholder: translate('hubstr.notification.form.text.placeholder'),
          maxLength: 160,
          showLetterLimit: true,
          rows: 3,
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      },
    ],
  },
  {
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, style: { margin: '0' } },
    layout: [{
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-right': '12px' },
      },
      fields: [{
        type: 'select',
        name: 'userListType',
        label: translate('hubstr.notification.form.users_list_type'),
        hint: '<b>"Все"</b> - все резиденты;<br>' +
          '<b>"Список резидентов"</b> - множественный выбор;<br>' +
          '<b>"Роль в клубе"</b> - значение из раздела <a href="/catalogs/club-roles">"Справочник"</a>;',
        options: [
          {
            value: 'all',
            label: translate('hubstr.notification.form.types.all'),
          },
          {
            value: 'users',
            label: translate('hubstr.notification.form.types.users'),
          },
          {
            value: 'group',
            label: translate('hubstr.notification.form.types.group'),
          },
        ],
        props: {
          clearable: false,
          required: true,
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      }, {
        type: 'input',
        name: 'status',
        props: {
          hidden: true,
          disabled: true,
        },
      }],
    }, {
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-left': '12px' },
      },
      fields: [{
        type: 'select',
        name: 'users',
        dataType: 'array',
        options: [],
        label: translate('hubstr.notification.form.users.label'),
        props: {
          required: true,
          multiple: true,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchResidents,
          placeholder: translate('hubstr.notification.form.users.placeholder'),
          hidden: (data: {state: CustomNotification}) => data.state.userListType !== 'users',
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
      }, {
        type: 'select',
        name: 'groupId',
        dataType: 'array',
        options: [],
        label: translate('hubstr.notification.form.groupId.label'),
        props: {
          required: true,
          multiple: false,
          filterable: false,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'name',
          remoteMethod: searchPositionTags,
          placeholder: translate('hubstr.notification.form.groupId.placeholder'),
          hidden: (data: {state: CustomNotification}) => data.state.userListType !== 'group',
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
      }],
    }],
  },
  {
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, style: { margin: '0' } },
    layout: [{
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-right': '12px' },
      },
      fields: [{
        type: 'select',
        name: 'deeplinkTemplate',
        label: translate('hubstr.notification.form.deeplink.label'),
        options: Object.values(DeepLinks),
        hint: 'Это ссылка (раздел) в приложении, куда будет осуществляться переход при тапе на пуш.<br><br> При выборе "Пост", "Мероприятие" и "Резидент" необходимо выбрать соответствующую сущность для перехода в приложении.' +
          '"Список резидентов" - вкладка в приложении.',
        props: {
          placeholder: translate('hubstr.notification.form.deeplink.placeholder'),
          clearable: true,
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        changeAction: ({ form }) => {
          form.deeplinkEntityId = '';
        },
      }],
    }, {
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-left': '12px' },
      },
      fields: [{
        type: 'select',
        name: 'deeplinkEntityId',
        dataType: 'array',
        options: [],
        label: translate('hubstr.notification.form.events.label'),
        props: {
          required: true,
          multiple: false,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchEvents,
          placeholder: translate('hubstr.notification.form.events.placeholder'),
          hidden: (data: {state: CustomNotification}) => data.state.deeplinkTemplate !== 'event',
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      }, {
        type: 'select',
        name: 'deeplinkEntityId',
        dataType: 'array',
        options: [],
        label: translate('hubstr.notification.form.posts.label'),
        props: {
          required: true,
          multiple: false,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchPosts,
          placeholder: translate('hubstr.notification.form.posts.placeholder'),
          hidden: (data: {state: CustomNotification}) => data.state.deeplinkTemplate !== 'post',
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      }, {
        type: 'select',
        name: 'deeplinkEntityId',
        dataType: 'array',
        options: [],
        label: translate('hubstr.notification.form.users.label'),
        props: {
          required: true,
          multiple: false,
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: searchResidents,
          placeholder: translate('hubstr.notification.form.users.placeholder'),
          hidden: (data: {state: CustomNotification}) => data.state.deeplinkTemplate !== 'resident',
          disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
        validation: [{
          type: 'empty',
          trigger: 'blur',
        }],
      }],
    }],
  },
  {
    type: 'row',
    props: { justify: 'space-between', elementsGrow: true, style: { margin: '0' } },
    layout: [{
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-right': '12px' },
      },
      fields: [
        {
          type: 'datetime',
          name: 'notifyAt',
          dataType: 'array',
          label: translate('hubstr.notification.form.date.label'),
          changeAction: ({ form }) => {
            const nowDate = new Date();
            const notifyAt = new Date(form.notifyAt);
            const today = `${nowDate.getFullYear()}-${roundToTwoSigns(nowDate.getMonth() + 1)}-${roundToTwoSigns(nowDate.getDate())}`;
            const notifyAtDate = `${notifyAt.getFullYear()}-${roundToTwoSigns(notifyAt.getMonth() + 1)}-${roundToTwoSigns(notifyAt.getDate())}`;

            if (today === notifyAtDate) {
              if (notifyAt.getTime() < nowDate.getTime()) {
                notifyAt.setHours(nowDate.getHours());
                notifyAt.setMinutes(nowDate.getMinutes() + 5);
                notifyAt.setSeconds(nowDate.getSeconds());
                form.notifyAt = notifyAt;
              }
            }
          },
          props: {
            required: true,
            type: 'datetime',
            format: 'DD.MM.YYYY HH:mm',
            startPlaceholder: translate('hubstr.notification.form.date.start_placeholder'),
            endPlaceholder: translate('hubstr.notification.form.date.end_placeholder'),
            disabledDate: (date: Date) => {
              const start = new Date();
              start.setHours(0, 0, 0, 0);
              return start.getTime() > date.getTime();
            },
            disabled: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },
      ],
    }, {
      type: 'column',
      props: {
        span: 12,
        xs: 24,
        styles: { 'padding-left': '12px' },
      },
      fields: [{
        type: 'checkbox',
        name: 'draft',
        label: translate('hubstr.notification.form.status'),
        dataType: 'array',
        options: [{
          label: translate('hubstr.notification.form.draft'),
          value: true,
        }],
        props: {
          hidden: (data: {state: {status: string}}) => data.state.status === 'failed' || data.state.status === 'completed',
        },
      }, {
        type: 'input',
        name: 'statusText',
        label: translate('hubstr.notification.form.status'),
        props: {
          hidden: (data: {state: {status: string}}) => !(data.state.status === 'failed' || data.state.status === 'completed'),
          disabled: true,
        },
      }],
    }],
  }],
}];
