import { cardPageController, translate } from 'magner';
import { AUTH_METHODS, Resident } from 'features/residents/types/udschool';
import {
  residentGet, residentUpdate,
} from 'features/residents/requests/udschool';

const residentConfig = cardPageController<Resident>({
  getRequest: residentGet,
  updateRequest: residentUpdate,
  createRequest: null,

  header: {
    title: translate('hubstr.resident.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.resident.form.submit_button'),
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: [{
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-right': '12px' },
        },
        layout: [{
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'lastName',
              label: translate('hubstr.resident.form.last_name.label'),
              dataType: 'string',
              props: {
                readOnly: true,
              },
            },
          ],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'email',
            label: translate('hubstr.resident.form.email.label'),
            props: {
              type: 'email',
              readOnly: true,
            },
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'datetime',
            dataType: 'date',
            name: 'birthday',
            label: translate('hubstr.resident.form.birthday.label'),
            props: {
              type: 'date',
              format: 'DD.MM.YYYY',
              readOnly: true,
            },
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'oldAuthMethod',
            props: {
              disabled: true,
              hidden: true,
            },
          }, {
            type: 'select',
            name: 'authMethod',
            options: AUTH_METHODS,
            label: translate('hubstr.resident.form.authMethods.label'),
            props: {
              required: true,
              placeholder: translate('hubstr.resident.form.authMethods.placeholder'),
              valueKey: 'id',
              labelKey: 'name',
              filterable: false,
              remote: false,
            },
            validation: [{
              type: 'empty-required' as 'empty',
              trigger: 'change',
            }],
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [
            {
              type: 'input',
              name: 'password',
              label: translate('hubstr.resident.form.newPassword.label'),
              props: {
                hidden: (data: { state: {authMethod: string} }) => data.state.authMethod !== 'form',
                required: false,
                placeholder: translate('hubstr.resident.form.newPassword.placeholder'),
              },
              validation: [{
                type: 'resident-password-empty' as 'empty',
                trigger: 'blur',
              }],
            },
          ],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'externalId',
            label: translate('hubstr.resident.form.externalId.label'),
            dataType: 'string',
            props: {
              placeholder: translate('hubstr.resident.form.externalId.placeholder'),
            },
          }],
        }],
      },
      /** RIGHT COLUMN */
      {
        type: 'column',
        props: {
          span: 12,
          xs: 24,
          styles: { 'padding-left': '12px' },
        },
        layout: [{
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'firstName',
            label: translate('hubstr.resident.form.first_name.label'),
            dataType: 'string',
            props: {
              readOnly: true,
            },
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'phone',
            label: translate('hubstr.resident.form.phone.label'),
            dataType: 'string',
            props: {
              readOnly: true,
              mask: {
                mask: '+#*',
              },
            },
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'positionTags',
            label: translate('hubstr.resident.form.position_tag.label'),
            dataType: 'string',
            props: {
              readOnly: true,
            },
          }],
        }, {
          type: 'row',
          props: { justify: 'space-between', elementsGrow: true },
          fields: [{
            type: 'input',
            name: 'status',
            label: translate('hubstr.resident.form.status.label'),
            dataType: 'string',
            props: {
              readOnly: true,
            },
          }],
        }],
      }],
    },
  },
});

export default residentConfig;
