<template>
  <div class="change-log">
    <el-row
      v-for="item in field?.row as ChangeLog[]"
      :key="item.version"
      :gutter="30"
      class="change-log_item"
    >
      <el-col class="change-log_item-version" :span="6">
        <h3 class="change-log_item-version-number">
          {{ item.version }}
        </h3>
        <div class="change-log_item-version-date">
          {{ item.date }}
        </div>
      </el-col>
      <el-col class="change-log_item-text" :span="18">
        <div v-if="item.text.whatsNew">
          <h3 class="change-log_item-text-title">Новое</h3>
          <div class="change-log_item-text-descs">
            <div v-for="whatsNewItem in item.text.whatsNew" class="change-log_item-text-desc" v-html="whatsNewItem" />
          </div>
        </div>

        <div v-if="item.text.improvements">
          <h3 class="change-log_item-text-title">Улучшения</h3>
          <div class="change-log_item-text-descs">
            <div v-for="improvement in item.text.improvements" class="change-log_item-text-desc" v-html="improvement" />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { ChangeLog } from 'features/change-log/interfaces';

export default {
  name: 'ChangeLog',
  props: {
    field: {
      type: Object as PropType<{ row: ChangeLog[] }>,
      required: true,
    },
  },
  computed: {
    ChangeLog () {
      return this.ChangeLog;
    },
  },
};
</script>

<style scoped>
.change-log {
  width: 100%
}
.change-log_item {
  position: relative;
  border-bottom: 1px var(--el-border-color-light) solid;
  padding-bottom: 20px;
}
.change-log_item-text-title {
  margin-bottom: 20px;
}
.change-log_item-text-descs {
  line-height: 1.5;
  margin-left: 15px;
}
.change-log_item-text-desc {
  margin-bottom: 15px;
  display: list-item;
  list-style: square;
}
.change-log_item-version {
  position: sticky;
  top: 10px;
  height: 100%;
}
.change-log_item-version-number {
  width: max-content;
  background-color: var(--el-color-info-light-7);
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.change-log_item-version-date {
  font-size: var(--el-font-size-small);
  color: var(--el-text-color-secondary);
}
</style>
