<template>
  <div class="list-line-raw-action">
    <InputFlat
      :label="additional?.description || TranslatesList[data.leftText.replace('lang.', '')] || data.leftText"
      :required="additional?.isUserRequired"
      :privacy="data?.hasPrivacy"
      :tags="data?.hasAutoComplete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import InputFlat from 'features/settings/mobile-editor/components/right-side/components/input-flat.vue';
import { AdditionsList, TranslatesList } from 'features/settings/mobile-editor/requests';
import { ListLineRawActionWidget } from 'features/settings/mobile-editor/interfaces';

export default defineComponent({
  name: 'ListLineRawAction',
  components: { InputFlat },
  props: {
    data: {
      type: Object as PropType<ListLineRawActionWidget>,
      required: true,
    },
  },
  setup (props) {
    const additional = AdditionsList.find((a) => props.data.rightText === a.formCode);

    return {
      additional,
      TranslatesList,
    };
  },
});
</script>

<style lang="scss" scoped>

</style>
