export const WIDGET_TYPES = {
  TEXT_SECTION: 'text_section_widget',
  LIST_LINE: 'list_line_widget',
  LIST_LINE_RAW: 'list_line_raw_action_widget',
  INFO_LINE_ACTION: 'info_line_action_widget',
  COMPANY_LIST: 'company_list_widget',
  ENTRY: 'entry_widget',

  DEPARTMENT: 'department_widget',
  BIRTHDAY: 'birthday_widget',
  BLOCK_REASON: 'block_reason_widget',
} as const;
