<template>
  <el-row v-loading="isLoading" :gutter="20">
    <el-col :span="7">
      <el-form-item label="Изображение" :error="imageErrors">
        <MagnerFormDropzone
          v-model="state.img"
          :field="{
            type: 'dropzone',
            name: 'img',
            props: {
              removable: true,
              formats: ['png', 'jpg', 'jpeg', 'svg'],
              maxSize: 1,
            }
          }"
          @text-errors="imageErrors = $event"
          @update:model-value="updateImage"
        />
      </el-form-item>
    </el-col>
    <el-col :span="17">
      <el-form-item label="Текст">
        <el-input v-model="state.text" type="textarea" />
      </el-form-item>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Цвет фона">
            <ColorPicker v-model="state.backgroundColor" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Цвет текста">
            <ColorPicker v-model="state.textColor" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import ColorPicker from 'features/settings/onboarding-editor/components/color-picker.vue';
import { MagnerFormDropzone, magnerMessage } from 'magner';
import { IOnboardScreen } from 'features/settings/onboarding-editor/interfaces';
import { reactive, ref, watch } from 'vue';
import { fileUpload } from 'features/common/file-request';

interface Props {
  data: IOnboardScreen
}

const props = withDefaults(defineProps<Props>(), {});
const state = reactive<IOnboardScreen>(props.data);

const isLoading = ref(false);
const imageErrors = ref();
const updateImage = async () => {
  if (state.img?.length && Array.isArray(state.img)) {
    isLoading.value = true;

    const res = await fileUpload(
      state.id,
      (state.img as File[])[0],
      'onboarding',
      'onboarding_image',
      'default',
      false,
    );

    if (res?.error) {
      magnerMessage({
        type: 'error',
        message: 'Не удалось загрузить изображение',
      });
      return {
        error: res.error,
      };
    }

    state.img = res.data.image;
    state.imgId = res.data.id;
    isLoading.value = false;
  }

  return true;
};

</script>

<style scoped>

</style>
