import { filtersFormController, translate } from 'magner';
import { IBreaking } from 'features/profile/safety/breaking/types';

const breakingFilters = filtersFormController<IBreaking>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },
  filtersInSeparatePanel: false,
  filtersData: {
    text: '',
  },
  sort: {},

  layout: [{
    type: 'input',
    name: 'text',
    props: {
      placeholder: translate('gaz.safety.filters.text'),
      inputDelay: 250,
    },
  }],
});

export default breakingFilters;
