<template>
  <el-dialog :width="isMobile && '95%'" style="padding: 10px" v-model="isOpen" @close="$emit('update:modelValue', false)">
    <template #title>
      <h2>{{ translateText('hubstr.editor.modal.select_field') }}</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item :label="translateText('hubstr.editor.modal.field_view')">
          <el-select v-model="selectedFieldType" class="m-2" :placeholder="translateText('hubstr.editor.modal.select_field')">
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <component
          :is="WIDGET_TYPES[selectedFieldType]"
          @update:formData="formData = $event"
        />
        </el-form>
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            type="primary"
            @click="addFieldHandler"
          >
            {{ translateText('hubstr.editor.add') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import {translate, useMobile, useTranslate} from "magner";
import {defineComponent, ref, watchEffect} from "vue";
import StatusIcon from "features/settings/mobile-editor/components/left-side/modals/slots/status-icon.vue";

export default defineComponent({
  name: 'AddHeaderFieldModal',
  components: { StatusIcon },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, {emit}) {
    const WIDGET_TYPES = {
      header_title_with_icon_widget: 'StatusIcon',
    }

    const isMobile = useMobile();
    const isOpen = ref<Boolean>(props.modelValue);
    const formData = ref();
    const selectedFieldType = ref('header_title_with_icon_widget');

    const types = [
      {
        label: 'Статус',
        value: 'header_title_with_icon_widget',
      }
    ]

    const addFieldHandler = () => {
      emit('change', formData.value);
      emit('update:modelValue', false);
    }

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));
    watchEffect(() => {
      isOpen.value = props.modelValue
    })
    return {
      isOpen,
      isMobile,
      types,
      formData,
      selectedFieldType,
      translateText,
      addFieldHandler,

      WIDGET_TYPES,
    };
  }
});

</script>
<style scoped lang="scss">

</style>