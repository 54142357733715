import { translate, filtersFormController } from 'magner';
import type { Address } from 'features/addresses/types/hubstr';

const addressesFilters = filtersFormController<Address>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersInSeparatePanel: true,
  filtersData: {
    name: '',
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: translate('hubstr.address.filters.name'),
        inputDelay: 250,
      },
    },
  ],
});

export default addressesFilters;
