<template>
  <el-form
    ref="formRef"
    :model="createData"
    @change="emitHandler(formRef)"
    @input="emitHandler(formRef)"
  >
    <el-form-item
      class="list-line-raw-action__title-input"
      prop="description"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <template #label>
        {{ translateText('hubstr.editor.modal.field_name') }}
        <question-tooltip :text="translateText('hubstr.editor.modal.hint_list_line_raw')" />
      </template>
      <el-input v-model="createData.description" :maxlength="40" />
    </el-form-item>
    <el-form-item :label="translateText('hubstr.editor.modal.icon')">
      <el-select v-model="formData.leftIcon">
        <el-option
          v-for="option in options"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        >
          <div class="option">
            <component :is="option.value" class="list-line-raw-action__icon" />
            <span>{{ option.label }}</span>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isUserRequired" :label="translateText('hubstr.editor.fields.required_app')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.isAdminRequired" :label="translateText('hubstr.editor.fields.required_admin')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="formData.hasPrivacy" :label="translateText('hubstr.editor.fields.has_privacy')" />
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="createData.allowSearch" :label="translateText('hubstr.editor.modal.allow_search')" />
    </el-form-item>
    <el-form-item v-if="createData.variableType === 'text'">
      <el-checkbox v-model="formData.hasAutoComplete" :label="translateText('hubstr.editor.modal.add_autocomplete')" />
    </el-form-item>
  </el-form>
  <form-autocomplete
    v-if="formData.hasAutoComplete"
    :field-code="createData.code"
    @update="emitHandler"
    @update:autocomplete="$emit('update:autocomplete', $event)"
  />
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref, watch,
} from 'vue';
import { CreateField } from 'features/settings/mobile-editor/interfaces';
import FormAutocomplete
  from 'features/settings/mobile-editor/components/left-side/modals/components/form-autocomplete.vue';
import QuestionTooltip from 'features/settings/mobile-editor/components/left-side/components/question-tooltip.vue';
import { TranslatesList } from 'features/settings/mobile-editor/requests';
import { FormInstance } from 'element-plus';
import { translate, useTranslate } from 'magner';
import { MOBILE_VALIDATORS } from 'features/settings/mobile-editor/constants';
import translit from '~/features/settings/mobile-editor/utils/translit';
import people from '../../../right-side/assets/icons/people.svg';
import peoples from '../../../right-side/assets/icons/peoples.svg';
import pie_chart from '../../../right-side/assets/icons/pie_chart.svg';
import suitcase from '../../../right-side/assets/icons/suitcase.svg';
import folder from '../../../right-side/assets/icons/folder.svg';
import folder_full from '../../../right-side/assets/icons/folder_full.svg';

interface TypeListLineRawAction {
  type: 'list_line_raw_action_widget',
  leftIcon: string,
  leftText: string,
  rightIcon?: any,
  rightText: string,
  id?: string,
  required: boolean,
  hasPrivacy: boolean,
  hasAutoComplete: boolean,
}

export default defineComponent({
  name: 'ListLineRawAction',
  components: {
    QuestionTooltip,
    FormAutocomplete,
    people,
    peoples,
    pie_chart,
    suitcase,
    folder,
    folder_full,
  },
  emits: ['input', 'input:createData', 'input:error', 'update:autocomplete'],
  props: {
    widgetData: {
      type: Object as PropType<TypeListLineRawAction>,
      default: () => {},
    },
    additionsData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const options = [
      { label: 'People', value: 'people' },
      { label: 'Peoples', value: 'peoples' },
      { label: 'Pie chart', value: 'pie_chart' },
      { label: 'Suitcase', value: 'suitcase' },
      { label: 'Folder', value: 'folder' },
      { label: 'Folder full', value: 'folder_full' },
    ];

    const formData = reactive<TypeListLineRawAction>(props.widgetData || {
      type: 'list_line_raw_action_widget',
      leftText: '',
      rightText: '',
      leftIcon: 'people',
      required: false,
      hasPrivacy: false,
      hasAutoComplete: false,
    });

    const createData = reactive<CreateField>(props.additionsData || {
      code: '',
      variableType: 'text',
      isUserRequired: false,
      isAdminRequired: false,
      format: 'DEFAULT',
      entityType: 'user',
      description: '',
      mobileValidator: [],
    });

    const fieldBuilder = () => {
      const descriptionTrim = createData.description.trim();

      if (!props.isEdit) {
        createData.code = `${createData.entityType}${translit(descriptionTrim, 'pascal')}`;
        formData.rightText = `data.additions.${createData.code}`;
      }

      formData.leftText = TranslatesList[createData.code] !== descriptionTrim ? descriptionTrim : `lang.${createData.code}`;
      formData.required = createData.isAdminRequired;

      if (createData.variableType === 'text') {
        createData.mobileValidator = MOBILE_VALIDATORS.TEXT;
      }
      if (createData.variableType === 'number') {
        createData.mobileValidator = MOBILE_VALIDATORS.INT;
      }
    };

    const emitHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);

          fieldBuilder();
          const outCreateData = reactive({
            ...createData,
            description: createData.description.trim(),
            variableType: formData.hasAutoComplete ? 'array' : createData.variableType,
            mobileValidator: formData.hasAutoComplete ? MOBILE_VALIDATORS.AUTOCOMPLETE : createData.mobileValidator,
          });

          emit('input', formData);
          emit('input:createData', outCreateData);
          return;
        }

        emit('input:error', true);
      });
    };

    /**
     * Принудительно с большой буквы
     * */
    watch(() => createData.description, (newValue) => {
      createData.description = newValue.charAt(0).toUpperCase() + newValue.slice(1);
    });

    onMounted(() => {
      if (createData.variableType === 'array') {
        createData.variableType = 'text';
      }

      // если нет description название берем из переводов
      if (props.isEdit && !createData.description) {
        createData.description = TranslatesList[createData.code];
      }
    });

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      formRef,
      formData,
      createData,
      options,
      emitHandler,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-line-raw-action__icon {
  width: 20px;
}

.option {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.el-checkbox {
  font-weight: lighter;
}
</style>
