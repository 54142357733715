import { request } from '~/utils/request';

interface FileUploadResponse {
  id: string,
  image: string,
  thumbnail: string,
}

export const fileUpload = (id: string, file: File, glType: string, code: string, context: string, publish?: boolean) => {
  const fileCreate = request.custom<FileUploadResponse, File>(async ({ api, data }) => await api
    .instance
    .url('/api/file/upload')
    .formData({
      file: data,
      glType,
      glId: id,
      code,
      context,
      publish: publish ?? false,
    })
    .post()
    .json((response) => {
      const d = (response as { result: { id: string } })?.result;
      return d?.id ? { data: d } : { error: 'Error uploading file' };
    })
    .catch(() => ({
      error: 'Error uploading file',
    })) as any);

  return fileCreate(file);
};
