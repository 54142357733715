import { cardPageController, translate } from 'magner';
import { linkGroupGet, linkGroupUpdate } from 'features/settings/link-groups/requests/hubstr';
import { Link } from 'features/settings/link-groups/types/hubstr';

const linkGroupConfig = cardPageController<Link>({
  getRequest: linkGroupGet,
  updateRequest: linkGroupUpdate,

  header: {
    title: translate('hubstr.link_groups.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.link_groups.form.submit_button'),
        },
      },
    ],
    layout: {
      // @ts-ignore
      type: 'column',
      props: {},
      // @ts-ignore
      fields: [
        {
          type: 'input',
          name: 'title',
          label: translate('hubstr.link_groups.form.name.label'),
          props: {
            required: true,
            disabled: (data: {state: {code: string}}) => data.state.code === 'privacy_policy',
            placeholder: translate('hubstr.link_groups.form.name.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },
        {
          type: 'input',
          name: 'position',
          label: translate('hubstr.link_groups.form.position.label'),
          props: {
            required: true,
            disabled: (data: {state: {code: string}}) => data.state.code === 'privacy_policy',
            placeholder: translate('hubstr.link_groups.form.position.placeholder'),
            mask: {
              mask: 'H*',
              tokens: {
                H: { pattern: /[0-9]/ },
              },
            },
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },
        {
          type: 'input',
          name: 'code',
          props: {
            hidden: true,
          },
        },
        {
          type: 'switch',
          name: 'visible',
          dataType: 'array',
          label: translate('hubstr.link_groups.form.visible'),
          props: {
            disabled: (data: {state: {code: string}}) => data.state.code === 'privacy_policy',
          },
        },
      ],
    },
  },
});

export default linkGroupConfig;
