import { filtersFormController } from 'magner';
import type { Company } from 'features/residents/companies/types/hubstr';

const companyFilters = filtersFormController<Company>({
  actions: [],

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersData: {},
  sort: {},

  layout: [],
});

export default companyFilters;
