import { cardPageController, translate } from 'magner';
import {
  residentCreate,
  residentDelete,
  residentGet, residentNew,
  residentUpdate,
} from 'features/residents/requests/hubstr';
import type { ResidentCreate } from 'features/residents/types/hubstr';
import { communityCenterLayout } from 'features/residents/layouts/community-center';

const residentConfig = cardPageController<ResidentCreate>({
  header: {
    title: translate('hubstr.resident.form.title'),
    tabs: [
      {
        label: translate('hubstr.resident.tabs.resident'),
        link: { name: 'resident-companies' },
        active: true,
      },
      {
        label: translate('hubstr.resident.tabs.communities'),
        link: { name: 'resident-communities' },
        active: false,
      },
    ],
  },

  getRequest: residentGet,
  getNewRequest: residentNew,
  createRequest: residentCreate,
  updateRequest: residentUpdate,
  deleteRequest: residentDelete,

  confirmDelete: translate('hubstr.resident.form.confirm_delete'),

  form: {
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.resident.form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('hubstr.resident.form.submit_button'),
          type: 'primary',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.resident.form.remove_button'),
          type: 'danger',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: communityCenterLayout as any,
    },
  },
});

export default residentConfig;
