import { cardPageController, translate } from 'magner';
import {
  cabinetSectionGet,
  cabinetSectionUpdate,
  cabinetSectionCreate,
  linkTypeOptions, cabinetSectionDelete,
} from 'features/settings/cabinet-sections/requests';
import { CabinetSections } from 'features/settings/cabinet-sections/interfaces';
import { LINK_TYPES } from 'features/settings/cabinet-sections/constants';

const cabinetSectionConfig = cardPageController<CabinetSections>({
  getRequest: cabinetSectionGet,
  createRequest: cabinetSectionCreate,
  updateRequest: cabinetSectionUpdate,
  deleteRequest: cabinetSectionDelete,

  header: {
    title: translate('hubstr.cabinet_sections.form.title_create'),
  },
  confirmDelete: 'Вы уверены что хотите удалить ссылку?',

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.cabinet_sections.form.submit_button'),
          hidden: ({ state }) => state.code === 'accept_rules',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.event.form.remove_button'),
          type: 'danger',
          class: 'remove',
          hidden: ({ state }) => state.code === 'terms_of_use' || state.code === 'accept_rules',
        },
      },
    ],
    layout: {
      // @ts-ignore
      type: 'column',
      props: {},
      // @ts-ignore
      fields: [
        {
          type: 'select',
          name: 'type',
          options: [],
          label: translate('hubstr.cabinet_sections.form.type.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.cabinet_sections.form.type.placeholder'),
            disabled: ({ isNew }) => !isNew,
            hidden: ({ isNew, state }) => state.type === LINK_TYPES.DEEP && !isNew,
            remote: true,
            remoteMethod: linkTypeOptions,
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'change',
          }],
        },
        {
          type: 'input',
          name: 'title',
          label: translate('hubstr.cabinet_sections.form.name.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.cabinet_sections.form.name.placeholder'),
          },
        },
        {
          type: 'textarea',
          name: 'description',
          label: translate('hubstr.cabinet_sections.form.description.label'),
          props: {
            placeholder: translate('hubstr.cabinet_sections.form.description.placeholder'),
            maxLength: 500,
            showLetterLimit: true,
            rows: 5,
            hidden: ({ isNew, state }) => state.type === LINK_TYPES.DEEP && !isNew,
          },
        },
        {
          type: 'input',
          name: 'url',
          label: translate('hubstr.cabinet_sections.form.url.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.cabinet_sections.form.url.placeholder'),
            hidden: ({ state }) => state.type === LINK_TYPES.DEEP,
            disabled: ({ state }) => state.code === 'privacy_policy',
          },
          validation: [{
            type: 'empty-required' as 'empty',
            trigger: 'blur',
          }, {
            type: 'url' as 'empty',
            trigger: 'blur',
          }],
        },
        {
          type: 'dropzone',
          name: 'image',
          dataType: 'number',
          label: translate('hubstr.cabinet_sections.form.image.label'),
          props: {
            valueKey: 'id',
            srcKey: 'image',
            removable: true,
            hidden: ({ isNew, state }) => state.type === LINK_TYPES.DEEP && !isNew,
          },
        },
        {
          type: 'input',
          name: 'code',
          props: {
            hidden: true,
          },
        },
        {
          type: 'input',
          name: 'type',
          props: {
            hidden: true,
          },
        },
      ],
    },
  },
});

export default cabinetSectionConfig;
