export const WIDGET_TYPES = {
  TEXT: 'onboarding_text',
  LOGO: 'onboarding_logo_widget',
  IMAGE: 'onboarding_image',
  GAP: 'vertical_gap_widget',
  BUTTON_LINK: 'onboarding_button_with_link_widget',
  BUTTON_COMPLETE: 'onboarding_complete_button_widget',
  BUTTON_CHAT_KEEPER: 'onboarding_chat_keeper_widget',
  MARKDOWN: 'onboarding_markdown_widget',
} as const;
