import { tableController, translate, useTranslate } from 'magner';
import type { Event } from 'features/events/types/hubstr';
import { formatDate } from '~/utils/format-date';

const statusFormatter = (status: Event['status']):
  [{ text: string; backgroundColor?: string, textColor?: string }] | string => {
  switch (status) {
    case 'planned':
      return [{ text: 'Запланировано', backgroundColor: '#b0e0e6', textColor: '#FFFFFF' }];
    case 'canceled':
      return [{ text: 'Отменено', backgroundColor: '#db7093', textColor: '#FFFFFF' }];
    case 'registrationClosed':
      return [{ text: 'Регистрация закрыта', backgroundColor: '#2f4f4f', textColor: '#FFFFFF' }];
    default:
      return '';
  }
};

const eventsTable = tableController<Event>({
  tableLayout: 'auto',
  scrollbarAlwaysOn: true,
  emptyText: translate('hubstr.event.table.not_found'),
  rowLink: (row) => ({ name: 'event', params: { id: row.id } }),
  columns: [
    {
      prop: 'name',
      label: translate('hubstr.event.table.name'),
      width: 280,
    },
    {
      prop: 'isPublished',
      label: translate('hubstr.event.table.isPublished'),
      width: 140,
      view: {
        type: 'text',
        formatter: (_, row) => {
          const { customT } = useTranslate();

          return row.isPublished
            ? customT(translate('hubstr.event.table.published'))
            : customT(translate('hubstr.event.table.not_published'));
        },
      },
    },
    {
      prop: 'dateStart',
      label: translate('hubstr.event.table.date_start'),
      width: 150,
      view: {
        type: 'text',
        formatter: (_, row) => formatDate(row.dateStart as Date),
      },
    },
    {
      prop: 'dateEnd',
      label: translate('hubstr.event.table.date_end'),
      width: 150,
      view: {
        type: 'text',
        formatter: (_, row) => formatDate(row.dateEnd as Date),
      },
    },
    {
      prop: 'categories',
      label: translate('hubstr.event.table.category'),
      view: {
        type: 'tags',
        formatter: (val) => (val as Event['categories'])?.map((category) => category.name),
      },
    },
    {
      prop: 'status',
      label: translate('hubstr.event.table.status'),
      width: 140,
      view: {
        type: 'tags',
        formatter: (val) => statusFormatter(val as Event['status']),
      },
    },
  ],
});

export default eventsTable;
