import { IUserActivityItem } from 'features/reports/user-activity/types';
import { request } from '~/utils/request';
import { roundToTwoSigns } from '~/utils/format-date';
import { API_URL } from '~/constants';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const reportsRead = request.table<IUserActivityItem>(async ({ api, data }) => {
  const pagination = `?count=${data.pagination.items || 25}&page=${data.pagination?.page || 1}&`;

  let filters = '';
  if (data.filters.department) {
    filters += filters.length ? '&' : '';
    filters += data.filters.department.map((u: string) => `filters[0][id]=departments&filters[0][value][]=${u}`).join('&');
  }
  if (data.filters.users) {
    filters += filters.length ? '&' : '';
    filters += data.filters.users.map((u: string) => `filters[1][id]=users&filters[1][value][]=${u}`).join('&');
  }
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    filters += `&filters[2][id]=dateStart&filters[2][value]=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }
  if (data.filters.dateEnd) {
    const d = new Date(data.filters.dateEnd);
    filters += `&filters[3][id]=dateEnd&filters[3][value]=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }

  const res = await api.get<{ list: IUserActivityItem[], pager: Pager }>(
    `/api/admin/analytics/report/users_activity${pagination}${filters}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((_) => ({ ..._, id: _.userId })),
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const downloadReport = request.custom(async (
  {
    data,
    lstorage,
  },
) => {
  const token = lstorage.read('token');

  let filters = '';
  if (data.filters.department) {
    filters += filters.length ? '&' : '';
    filters += data.filters.department.map((u: string) => `filters[0][id]=departments&filters[0][value][]=${u}`).join('&');
  }
  if (data.filters.users) {
    filters += filters.length ? '&' : '';
    filters += data.filters.users.map((u: string) => `filters[1][id]=users&filters[1][value][]=${u}`).join('&');
  }
  if (data.filters.dateStart) {
    const d = new Date(data.filters.dateStart);
    filters += `&filters[2][id]=dateStart&filters[2][value]=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }
  if (data.filters.dateEnd) {
    const d = new Date(data.filters.dateEnd);
    filters += `&filters[3][id]=dateEnd&filters[3][value]=${d.getFullYear()}-${roundToTwoSigns(d.getMonth() + 1)}-${roundToTwoSigns(d.getDate())}`;
  }

  // @ts-ignore
  fetch(`${API_URL}/api/admin/analytics/export/users_activity?${filters}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // @ts-ignore
      a.download = 'отчет_активность_пользователей.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return { data: 'ok' };
});
