import { v4 as uuidv4 } from 'uuid';
import { Blank, BlankFull } from 'features/profile/blanks/types';
import { request } from '~/utils/request';

export const blanksRead = request.table<Blank>(async ({ api }) => {
  const res = await api.get<{ links: Blank[] }>('/api/admin/link/list?groupCode=blanks_admin');
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data?.links,
      pagination: null,
    },
  };
});

export const blankGet = request.card<BlankFull, BlankFull>(async ({ api, data, parseError }) => {
  const res = await api.get<{ link: BlankFull }>(`/api/admin/link/get?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return {
    data: {
      ...res.data?.link,
      group: res.data?.link.group?.id as any,
    },
  };
});

export const blankCreate = request.card<BlankFull, BlankFull>(async ({ api, parseError, data }) => {
  const id = uuidv4();

  const body = {
    ...data.data,
    id,
    type: 'externalLink',
    groupCode: 'blanks_admin',
  };

  const res = await api.post<{ link: BlankFull }>('/api/admin/link/create', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.link };
});

export const blankUpdate = request.card<BlankFull, BlankFull>(async ({ api, parseError, data }) => {
  const body = { ...data.data, link: data.id };

  const res = await api.patch<{ link: BlankFull }>('/api/admin/link/update', body);
  if (res.error) {
    return { error: parseError?.(res.error) };
  }
  return { data: res.data?.link };
});

export const blankDelete = request.card(async ({ api, data, parseError }) => {
  const res = await api.delete(`/api/admin/link/delete?link=${data.id}`);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
