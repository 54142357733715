import { tablePageController, translate } from 'magner';
import companyTable from 'features/residents/companies/table/hubstr';
import companyFilters from 'features/residents/companies/filters/hubstr';
import { companyRead } from 'features/residents/companies/requests/hubstr';
import { Company } from 'features/residents/companies/types/hubstr';

const companiesConfig = tablePageController<Company>({
  header: {
    title: translate('hubstr.resident.companies.table.title'),
    tabs: [
      {
        label: translate('hubstr.resident.tabs.resident'),
        link: { name: 'resident' },
        active: false,
      },
      {
        label: translate('hubstr.resident.tabs.companies'),
        link: { name: 'resident-companies' },
        active: true,
      },
    ],
    actions: [{
      type: 'link',
      to: (route) => ({ name: 'company', params: { id: 'new' }, query: { resident: route.params.id } }),
      props: {
        type: 'primary',
        text: translate('hubstr.resident.tabs.create'),
      },
    }],
  },
  request: companyRead,
  table: companyTable,
  filters: companyFilters,
});

export default companiesConfig;
