<template>
  <div>
    <h4>ФИО:</h4>
    <p>{{ s.fio }}</p>

    <el-divider />

    <h4>E-mail:</h4>
    <p>{{ s.email }}</p>

    <el-divider />

    <h4>Организация:</h4>
    <p>{{ s.organization }}</p>

    <el-divider />

    <h4>Объект, где выявлено нарушение:</h4>
    <p>{{ s.place }}</p>

    <el-divider />

    <h4>Описание нарушения:</h4>
    <p>{{ s.text }}</p>

    <el-divider />

    <h4>Дата создания:</h4>
    <p>{{ s.formattedDate }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IBreaking } from 'features/profile/safety/breaking/types';
import { formatDate } from '~/utils/format-date';

export default defineComponent({
  name: 'BreakingDetail',
  props: {
    breaking: {
      type: Object as PropType<IBreaking>,
      required: true,
    },
  },
  emits: ['success'],
  setup (props) {
    return {
      s: {
        ...props.breaking,
        formattedDate: formatDate(props.breaking.createdAt),
      },
    };
  },
});
</script>
