import { tablePageController, translate } from 'magner';
import { addressRead } from 'features/addresses/requests/hubstr';
import addressesTable from 'features/addresses/table/hubstr';
import addressesFilters from 'features/addresses/filters/hubstr';
import { Address } from 'features/addresses/types/hubstr';

const addressesConfig = tablePageController<Address>({
  header: {
    title: translate('hubstr.address.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'address', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('hubstr.address.filters.create_new'),
        },
      },
    ],
  },
  request: addressRead,
  table: addressesTable,
  filters: addressesFilters,
});

export default addressesConfig;
