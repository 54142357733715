import { tablePageController, translate } from 'magner';
import eventGuestsTable from 'features/events/guests/table/hubstr';
import eventGuestsFilters from 'features/events/guests/filters/hubstr';
import { eventGuestsRead } from 'features/events/guests/requests/hubstr';
import { EventGuest } from 'features/events/guests/types/hubstr';

const eventGuestsConfig = tablePageController<EventGuest>({
  header: {
    title: translate('hubstr.event.guests.table.title'),
    tabs: [
      {
        label: translate('hubstr.event.tabs.form'),
        active: false,
        link: { name: 'event' },
      },
      {
        label: translate('hubstr.event.tabs.guests'),
        active: true,
        link: { name: 'event-guests' },
      },
    ],
  },
  request: eventGuestsRead,
  table: eventGuestsTable,
  filters: eventGuestsFilters,
});

export default eventGuestsConfig;
