import { request } from '~/utils/request';

export const importResidentsFileUpload = (file: File) => {
  const fileCreate = request.custom<File>(async ({ api, data, parseError }) => await api
    .instance
    .url('/api/admin/gaz/user/import')
    .formData({
      usersFile: data,
    })
    .post()
    .json((response) => {
      const d = (response as { data: { countImported: number } })?.data;
      return d?.countImported ? { data: d } : { error: 'Error uploading file' };
    })
    .catch((e) => ({
      error: JSON.parse(parseError(e).message).data.msg,
    })) as any);

  return fileCreate(file);
};
