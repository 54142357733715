<template>
  <div class="image-slot">
    <el-form
      ref="formRef"
      :model="formData"
      @change="submitHandler(formRef)"
    >
      <el-row :gutter="20">
        <el-col :span="7">
          <el-form-item
            label="Изображение"
            prop="image"
            :rules="[
              { required: true, message: 'Обязательное поле' },
            ]"
            :error="imageErrors"
          >
            <MagnerFormDropzone
              v-model="formData.image"
              :field="{
                type: 'dropzone',
                name: 'img',
                props: {
                  removable: true,
                  formats: ['png', 'jpg', 'jpeg', 'svg'],
                }
              }"
              @update:model-value="submitHandler(formRef)"
              @text-errors="imageErrors = $event"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Ширина, %"
            prop="widthPercent"
            :rules="[
              { required: true, message: 'Обязательное поле' },
            ]"
          >
            <MagnerFormInput
              v-model.number="formData.widthPercent"
              :field="widthPercentField"
              @update:model-value="maxIntMask($event,'widthPercent')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Высота, %"
            prop="heightPercent"
            :rules="[
              { required: true, message: 'Обязательное поле' },
            ]"
          >
            <MagnerFormInput
              v-model.number="formData.heightPercent"
              :field="heightPercentField"
              @update:model-value="maxIntMask($event,'heightPercent')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { IImageWidget } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import { InputField, MagnerFormDropzone, MagnerFormInput } from 'magner';
import { FormInstance } from 'element-plus';

interface Props {
  editData?: IImageWidget;
}

const props = withDefaults(defineProps<Props>(), {
  editData: undefined,
});
const emit = defineEmits(['update:formData', 'error']);
const state = props.editData && JSON.parse(JSON.stringify(props.editData));
const imageErrors = ref();
const formRef = ref<FormInstance>();

const formData = reactive<IImageWidget>(
  state
  || {
    type: WIDGET_TYPES.IMAGE,
    widthPercent: 80.0,
    heightPercent: 10.0,
    image: '',
  } as IImageWidget,
);

const widthPercentField: InputField<any> = {
  type: 'input',
  name: 'widthPercent',
  dataType: 'number',
  props: {
    mask: {
      mask: '###',
    },
  },
};

const heightPercentField: InputField<any> = {
  type: 'input',
  name: 'heightPercent',
  dataType: 'number',
  props: {
    mask: {
      mask: '###',
    },
  },
};

const maxIntMask = (value: number, field: keyof Omit<IImageWidget, 'type' | 'image'>) => {
  const regExp = /^0[0-9]/;

  if (value > 100) {
    formData[field] = 100;
  } else if (regExp.test(String(value))) {
    formData[field] = 1;
  }
};

const submitHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) return;

  formEl.validate((valid) => {
    if (valid) {
      emit('error', false);
      emit('update:formData', formData);
      return true;
    }

    emit('error', true);
    return false;
  });
};

onMounted(() => submitHandler(formRef.value));
</script>

<style lang="scss" scoped>

</style>
