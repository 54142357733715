import { translate } from 'magner';
import ruCustom from 'configs/translation/ru';
import { autocompleteSearch } from 'features/residents/requests/hubstr';
import unpackField from '~/utils/unpack-field';

export interface MetaHubstrField {
  type: string,
  label: string,
  name: string,
  props: Object,
  layout: Object
}
export interface MetaHubstrFieldsGroup {
  title: string;
  fields: MetaHubstrField[];
}

const metaHubstrAssociationLayout = (groups: MetaHubstrFieldsGroup[]) => {
  const layout = {
    type: 'row',
    props: {},
    layout: [
      /** Lastname and Firstname */
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          {
            type: 'column',
            props: {
              span: 4,
              xs: 24,
              styles: { 'padding-right': '12px' },
            },
            layout: [unpackField({
              type: 'dropzone',
              required: false,
              name: 'logo',
              label: translate('hubstr.resident.form.companies.logo.label'),
              placeholder: '',
              // special
              formats: ['jpg', 'png', 'jpeg', 'heic'],
              valueKey: 'id',
              srcKey: 'big',
              removable: true,
            })],
          },
          {
            type: 'column',
            props: {
              span: 20,
              xs: 24,
              styles: { 'padding-left': '12px' },
            },
            layout: [
              unpackField({
                type: 'input',
                required: true,
                name: 'name',
                label: translate('hubstr.resident.form.companies.name.label'),
                placeholder: translate('hubstr.resident.form.companies.name.placeholder'),
                validation: [{
                  type: 'empty-required',
                  trigger: 'blur',
                }],
                // special
                dataType: 'string',
              }),
              unpackField({
                type: 'select',
                required: false,
                name: 'associationUserPosition',
                label: translate('hubstr.resident.form.companies.position.label'),
                placeholder: translate('hubstr.resident.form.companies.position.placeholder'),
                // special
                remoteMethodSettings: {
                  url: autocompleteSearch('associationUserPosition'),
                  multiple: true,
                  filterable: true,
                },
              })],
          },
        ],
      },
      {
        type: 'row',
        props: { justify: 'space-between' },
        layout: [
          // Динамические поля
          ...groups.map((group: MetaHubstrFieldsGroup) => ({
            type: 'column',
            title: (() => {
              if (!ruCustom.dynamic[group.title.replace(/lang\.(mobile\.)?/g, '')]) {
                return group.title;
              }
              return translate(`dynamic.${group.title.replace(/lang\.(mobile\.)?/g, '')}`);
            })(),
            props: { span: 24, isPaper: true, titleType: 'heading' },
            layout: group.fields.map((field: MetaHubstrField) => unpackField({
              ...field,
              label: (() => {
                if (!ruCustom.dynamic[field.name]) {
                  return field.label;
                }
                return translate(`dynamic.${field.name}`);
              })(),
            })),
          })),
        ],
      },
    ],
  };

  return {
    data: {
      actions: [
        {
          type: 'action',
          emits: 'cancel',
          props: {
            text: translate('hubstr.resident.form.cancel_button'),
          },
        },
        {
          type: 'action',
          emits: 'submit',
          props: {
            text: translate('hubstr.resident.form.submit_button'),
            type: 'primary',
          },
        },
        {
          type: 'action',
          emits: 'remove',
          props: {
            text: translate('hubstr.resident.form.remove_button'),
            type: 'danger',
          },
        },
      ],
      layout,
    } as any,
  };
};

export default metaHubstrAssociationLayout;
