import { RolesResponse } from 'features/residents/types/hubstr';
import { request } from '~/utils/request';

/**
 * Метод перерабатывает поле, полученное с бэка в поле, которое прожует магнер
 * @param field
 */
const unpackField = (field: any) => {
  const out: any = {
    type: field.type,
    name: field.name,
    label: field.label ?? '',
    hint: field.hint ?? '',
    props: {
      required: field.required,
      placeholder: field.placeholder ?? '',
      disabled: field.disabled,
    },
  };

  if (field.validation) {
    out.validation = field.validation;
  }

  if (field.propsType) {
    out.props.type = field.propsType;
  }

  if (field.propsMask) {
    out.props.mask = field.propsMask;
  }

  if (field.propsHidden) {
    out.props.hidden = field.propsHidden;
  }

  if (field.propsDisabled) {
    out.props.disabled = field.propsDisabled;
  }

  if (field.propsHiddenFunction) {
    // eslint-disable-next-line no-eval
    out.props.hidden = ((evalString) => (data: { state: {roles: string, roleCurrent: RolesResponse, rolesList: Record<string, string>} }) => eval(evalString))(field.propsHiddenFunction);
  }

  switch (field.type) {
    case 'input': {
      out.dataType = field.dataType;
      break;
    }
    case 'textarea': {
      out.props.rows = field.rows;
      break;
    }
    case 'checkbox': {
      out.options = field.options;
      break;
    }
    case 'select': {
      out.options = field.options ?? [];
      if (field.remoteMethodSettings) {
        out.options = [];
        out.props.remote = true;
        out.props.valueKey = field.remoteMethodSettings.valueKey;
        out.props.labelKey = field.remoteMethodSettings.labelKey;
        out.props.multiple = field.remoteMethodSettings.multiple ?? false;
        out.props.filterable = field.remoteMethodSettings.filterable ?? false;
        out.props.clearable = field.remoteMethodSettings.clearable ?? false;
        out.props.allowCreate = field.remoteMethodSettings.allowCreate ?? false;
        out.props.remoteMethod = (({ url, parameter, type }) => (typeof url !== 'string' ? url : request.custom<{rows: any}>(async ({ api, data }) => {
          let query = '';
          if (type?.length) {
            query += query.length ? '&' : '?';
            query += `field=${type}`;
          }
          if (data?.search) {
            query += query.length ? '&' : '?';
            query += `search=${data.search}`;
          }
          const res = await api.get<any>(`${url}${query}`);
          if (res.error) {
            return {
              data: { rows: [] },
            };
          }

          return {
            data: { rows: res.data[parameter] },
          };
        })))(
          field.remoteMethodSettings,
        );
      }
      break;
    }
    case 'dropzone': {
      out.props.valueKey = field.valueKey;
      out.props.srcKey = field.srcKey;
      out.props.removable = field.removable;
      out.props.formats = field.formats;
      break;
    }
    case 'datetime': {
      out.dataType = field.dataType;
      out.props.type = field.propsType;
      out.props.format = field.propsFormat;
      break;
    }
    case 'radio': {
      out.options = field.options;
      break;
    }
    case 'custom': {
      out.component = field.component;
      out.props = field.props;
      break;
    }
    // eslint-disable-next-line no-empty
    default: {}
  }

  return {
    type: 'row',
    props: { elementsGrow: true },
    fields: [out],
  };
};

export default unpackField;
