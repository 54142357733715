<template>
  <div class="interview-preview-field-file">
    <div>
      <div 
        v-for="item, index in modelValue" 
        :key="index"
        class="interview-preview-field-file__item"
      >
        <div class="interview-preview-field-file__item-header">
          <div class="interview-preview-field-file__item-title">
            <div class="interview-preview-field-file__item-name">
              {{ item.name }}
            </div>

            <span>{{ fileSize(item.size) }}</span>
          </div>

          <el-icon class="interview-preview-field-file__delete" @click="remove(index)">
            <Delete />
          </el-icon>
        </div>

        <el-progress :percentage="100" status="success" class="interview-preview-field-file__progress" />
      </div>
    </div>

    <el-upload
      :file-list="modelValue"
      :auto-upload="false"
      :show-file-list="false"
      class="interview-preview-field-file__upload"
      @change="$emit('update:modelValue', [...modelValue, $event])"
    >
      <el-button type="primary">
        <el-icon size="20" style="margin-right: 8px; color: white;">
          <Folder />
        </el-icon>

        {{ translateText('gaz.interview.preview.form.attach') }}
      </el-button>
    </el-upload>
  </div>
</template>
    
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { translate, useTranslate } from 'magner';

import { Folder, Delete } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'IntreviewPreviewFieldFile',

  components: {
    Folder,
    Delete,
  },

  props: {
    modelValue: {
      type: Array as PropType<File[]>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup (props, { emit }) {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    // @ts-ignore
    const remove = (index: number) => {
      const fileList = [...props.modelValue];

      fileList.splice(index, 1);

      emit('update:modelValue', fileList);
    };

    const fileSize = (size: number) => {
      const mb = 1000000;
      const kb = 1000;

      if (size / mb > 1) {
        return `${(size / mb).toFixed(2)}Mb`;
      }

      return `${(size / kb).toFixed(2)}Kb`;
    };

    return {
      remove,

      fileSize,

      translateText,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-file {
  &__item {
    span {
      color: rgba(179, 179, 179, 1) !important;
      font-size: 14px;
    }
  }
  
  &__item:not(:last-child) {
    margin-bottom: 32px;
  }

  &__item-header {
    display: flex;
    justify-content: space-between;

    width: 100%;

    font-size: 15px;
  }

  &__item-name {
    max-width: 224px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
  }

  &__item-title {
    display: flex;
    align-items: center;
  }

  &__progress {
    margin-top: 12px;

    .el-progress__text {
      display: none;
    }
  }

  &__upload {
    margin-top: 50px;
  }

  &__delete {
    cursor: pointer;

    svg path {
      fill: rgba(106, 106, 106, 1);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
