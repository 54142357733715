export const ADMIN_FIELD_TYPES = {
  text: 'input',
  number: 'input',
  link: 'input',
  datetime: 'datetime',
  array: 'select',
} as const;

export const MOBILE_FIELD_TYPES = {
  text: 'text',
  number: 'text',
  link: 'text',
  datetime: 'datePicker',
  array: 'textAutocomplete',
} as const;

export const ADMIN_DATA_TYPE = {
  text: 'string',
  number: 'number',
  link: 'string',
  datetime: 'date',
  array: 'array',
} as const;
