import { autocompleteSearch } from 'features/residents/requests/hubstr';
import { translate } from 'magner';
import {
  companiesGeography, companyAreasField,
  companyLogoField,
  companyNameField, companyPositionField, companySiteField, companyTelegramField,
  companyTurnOverField,
  numberOfEmployeesField,
} from 'features/residents/companies/layouts/general';

export const cloverLayout = [
  {
    type: 'column',
    props: {},
    fields: [
      /** Company name */
      companyNameField,
      /** Company turnover (money flow) per year */
      companyTurnOverField,
      /** Number of employees */
      numberOfEmployeesField,
      /** Company logo */
      companyLogoField,
      /** Company areas */
      companyAreasField,
      /** Company position */
      companyPositionField,
      /** Company's geography */
      companiesGeography,
    ],
  },

  /** Company's site, telegram */
  {
    type: 'row',
    props: {
      justify: 'space-between', elementsGrow: true, align: 'middle', styles: 'gap: 12px', 
    },
    fields: [
      companySiteField,
      companyTelegramField,
    ],
  },
];
