<template>
  <div class="companies-group">
    <el-alert
      title="Только просмотр"
      type="info"
      show-icon
      :closable="false"
    >
      <template #default>
        <div>
          Редактировать поля можно во вкладке
          <el-link
            type="primary"
            :underline="false"
            style="font-size: 12px; font-weight: 400; opacity: .8;"
            @click="router.push({name: 'associations-editor'})"
          >
            {{ `«${t[data.title?.replace('lang.', '')] || data.title}»` }}
          </el-link>
        </div>
      </template>
    </el-alert>
  </div>
</template>

<script setup lang="ts">
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import { useRouter } from 'magner/vue-router';

interface Props {
  data: any
}
const props = defineProps<Props>();
const router = useRouter();
</script>

<style scoped>

</style>
