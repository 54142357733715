import { cardPageController, translate } from 'magner';
import PreferenceLayout from 'features/settings/preferences/components/layout/hubstr.vue';
import { preferenceGet, preferenceUpdate } from 'features/settings/preferences/requests/hubstr';
import { APP_TERRA, SUB_DOMEN } from '~/constants';

const preferenceConfig = cardPageController({
  getRequest: preferenceGet,
  updateRequest: preferenceUpdate,
  deleteRequest: '',
  confirmDelete: true,

  header: {
    title: translate('hubstr.preferences.sidebar.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'preferenceLayout',
        component: () => PreferenceLayout,
        props: {},
      },
    ],
  },
});

export default preferenceConfig;
