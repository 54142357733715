<template>
  <div v-loading="loadAutocompleteFields" class="autocomplete-form">
    <div class="autocomplete-form__form">
      <el-input
        v-model="newAutocompleteFormValue"
        :placeholder="translateText('hubstr.editor.modal.new_value')"
        @keyup.enter="addNewValue"
      />
      <el-button
        type="primary"
        plain
        :disabled="!newAutocompleteFormValue.trim().length"
        @click="addNewValue"
      >
        {{ translateText('hubstr.editor.add') }}
      </el-button>
    </div>

    <div class="autocomplete-form__list">
      <template v-for="(item, index) in state.values" :key="item.id">
        <div v-if="item.value" class="autocomplete-form__list-item">
          <div>
            {{ item.value }}
          </div>
          <div>
            <el-button
              type="danger"
              size="small"
              plain
              @click="remoteValue(item.id, index)"
            >
              {{ translateText('hubstr.editor.remove') }}
            </el-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted, reactive, ref, watch,
} from 'vue';
import { getAutocompleteFields } from 'features/settings/editor/residents-editor/requests';
import { magnerMessage, translate, useTranslate } from 'magner';
import useEditorStore from 'features/settings/editor/store/useEditorStore';

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

export interface AutocompleteData {
  id: string,
  value: string,
}
interface Props {
  fieldCode: string,
}

const props = defineProps<Props>();
const emit = defineEmits(['update:values']);

const store = useEditorStore();

const state = reactive<any>({
  field: props.fieldCode,
  values: [] as AutocompleteData[],
});
const loadAutocompleteFields = ref(false);
const newAutocompleteFormValue = ref<string>('');

const hasCode = () => {
  if (!props.fieldCode.replace('user', '')) {
    magnerMessage({
      type: 'error',
      message: translateText('hubstr.editor.errors.name_first'),
    });
    return false;
  }
  return true;
};

const addNewValue = () => {
  if (!hasCode()) return;

  if (state.values.map((v: any) => v.value.toLowerCase()).includes(newAutocompleteFormValue.value.trim().toLowerCase())) {
    magnerMessage({
      type: 'error',
      message: translateText('hubstr.editor.errors.has_name'),
    });

    return;
  }

  state.values.unshift({
    id: '',
    value: newAutocompleteFormValue.value.trim(),
  });

  newAutocompleteFormValue.value = '';
  emit('update:values', state);
};

const remoteValue = (id: string | undefined, index: number) => {
  if (!id) {
    state.values.splice(index, 1);
    emit('update:values', state);
    return;
  }

  state.values[index].value = '';
  emit('update:values', state);
};

// Подгрузка созданых полей
onMounted(async () => {
  // Поиск в store. Если есть то берем из store, если нет то загружаем с API
  const autocompleteInStore = store.autocompleteList.value?.find((a) => a.field === props.fieldCode) || false;
  if (autocompleteInStore) {
    state.values = autocompleteInStore.values;
    return;
  }

  // Подгрузка с API
  loadAutocompleteFields.value = true;
  const autoComplete = await getAutocompleteFields(props.fieldCode);
  if (autoComplete.error) {
    loadAutocompleteFields.value = false;
    return;
  }
  state.values = autoComplete.data?.res.data.list;
  loadAutocompleteFields.value = false;
});

// Принудительно с большой буквы
watch(() => newAutocompleteFormValue.value, (newValue) => {
  newAutocompleteFormValue.value = newValue.charAt(0).toUpperCase() + newValue.slice(1);
});
</script>

<style lang="scss" scoped>
.autocomplete-form {
  .autocomplete-form {
    &__form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px #eaeaea solid;

    .el-button {
      margin: 0;
    }
  }
}
</style>
