import {
  action,
  actionCard, actionTable, magnerMessage, openModal, tablePageController, translate,
} from 'magner';
import { deepLinksSectionRead } from 'features/settings/cabinet-sections/requests';
import { CabinetSections } from 'features/settings/cabinet-sections/interfaces';
import dpCommunitySectionConfig from 'features/settings/cabinet-sections/section/common';
import { LINK_TYPES } from 'features/settings/cabinet-sections/constants';
import TableImage from 'features/settings/cabinet-sections/components/table-image.vue';

const deepLinksSectionConfig = tablePageController<CabinetSections>({
  request: deepLinksSectionRead,
  header: {
    title: translate('hubstr.cabinet_sections.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'text',
          text: 'Назад',
        },
        action: action(() => {
          window.history.back();
        }),
      },
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('hubstr.cabinet_sections.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<CabinetSections>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: dpCommunitySectionConfig,
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('hubstr.cabinet_sections.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    pagination: {
      items: 25,
      page: 1,
    },
    filtersData: {},
    actions: [],
    layout: [],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.name'),
      },
      {
        prop: 'type',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.type'),
        view: {
          type: 'text',
          formatter: (_, row) => (row.type === LINK_TYPES.DEEP
            ? 'Внутреняя'
            : row.type === LINK_TYPES.EXTERNAL
              ? 'Внешняя'
              : 'Веб вью'),
        },
      },
      {
        prop: 'image',
        width: 'auto',
        label: translate('hubstr.cabinet_sections.table.image'),
        align: 'center',
        view: {
          type: 'custom',
          component: () => TableImage,
        },
      },
      {
        prop: 'id',
        label: translate('hubstr.cabinet_sections.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<CabinetSections>(async ({ data }) => {
                const link = (data as any).row as CabinetSections;
                if (link.type === LINK_TYPES.EXTERNAL) {
                  try {
                    await openModal<any>({
                      type: 'card',
                      config: {
                        ...dpCommunitySectionConfig,
                        header: {
                          title: translate('hubstr.cabinet_sections.form.title_edit'),
                        },
                      },
                      // customClass: 'modal-max-width modal-max-width__400',
                      handleBeforeClose: true,
                      props: {
                        emptyCard: false,
                        entityId: link.id,
                      },
                    });
                    return false;
                  } catch (e) {
                    return translate('hubstr.cabinet_sections.form.failed_edition');
                  }
                }
                if (link.type === 'webView') {
                  await magnerMessage({
                    type: 'error',
                    message: 'Нельзя редактировать',
                  });
                }
              }),
              props: {
                text: translate('hubstr.cabinet_sections.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default deepLinksSectionConfig;
