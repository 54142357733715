import {
  tablePageController, translate, actionCard, openModal, actionTable,
} from 'magner';
import { clubRoleRead } from 'features/settings/club-roles/requests/hubstr';
import type { ClubRole } from 'features/settings/club-in-club/types/hubstr';
import clubRoleConfig from 'features/settings/club-roles/role/hubstr';

const clubRolesConfig = tablePageController<ClubRole>({
  request: clubRoleRead,
  header: {
    title: translate('hubstr.club_role.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('hubstr.club_role.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<ClubRole>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: clubRoleConfig,
              handleBeforeClose: true,
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('hubstr.club_role.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    fieldsShowAmount: 5,
    filtersData: {},
    actions: [],
    layout: [],
  },
  filtersInSeparatePanel: true,

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'name',
        width: 'auto',
        label: translate('hubstr.club_role.table.name'),
      },
      {
        prop: 'id',
        label: translate('hubstr.club_role.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<ClubRole>(async ({ data }) => {
                const role = (data as any).row as ClubRole;
                try {
                  await openModal<any>({
                    type: 'card',
                    config: {
                      ...clubRoleConfig,
                      header: {
                        title: translate('hubstr.club_role.form.title_edit'),
                      },
                    },
                    handleBeforeClose: true,
                    props: {
                      emptyCard: false,
                      entityId: role.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('hubstr.club_role.form.failed_edition');
                }
              }),
              props: {
                text: translate('hubstr.club_role.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default clubRolesConfig;
