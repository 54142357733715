<template>
  <div class="phone-header">
    <el-avatar
      class="phone-header__avatar"
      :size="100"
      :src="avatar"
    />
    <div
      v-if="data.header.find(i => i.type === 'header_tags_widget')"
      class="phone-header__tags"
    >
      {{ translateText('hubstr.editor.phone_preview.view_content.team') }}
    </div>
    <h1
      class="phone-header__name_widget"
    >
      {{ translateText('hubstr.editor.phone_preview.view_content.name') }}
      <component :is="Crown" v-if="hasCrown" />
    </h1>
    <div
      v-if="data.header.find(i => i.type === 'header_badge_widget')"
      class="phone-header__badge"
    >
      {{ data.header.find((i) => i.type === 'header_badge_widget').label || translateText('hubstr.editor.phone_preview.view_content.badge') }}
    </div>
  </div>
  <div class="phone-header__action-buttons">
    <ActionButtons :data="data.header" />
  </div>

  <div
    v-for="view in data.body"
    :key="view"
    class="phone-body"
  >
    <h3
      class="phone-body__title"
    >
      {{ t[view.title.replace('lang.', '')] || view.title }}
    </h3>
    <br>
    <template v-if="view.adminGroupType == ADMIN_GROUP_TYPES.SOCIALS">
      <SocialButtons :data="view" />
    </template>
    <template v-else>
      <div
        v-for="field in view.widgets"
        :key="field.title"
      >
        <component
          :is="setComponent(field.type)"
          :key="field.title"
          :data="field"
        />
      </div>
    </template>
  </div>
  <div class="phone-footer">
    <div
      v-if="data.footer.some((i) => i.type === 'entry_widget')"
      class="phone-footer__member"
    >
      {{ translateText('hubstr.editor.main.footer.member_since') }}
    </div>
    <div
      v-if="data.footer.some((i) => i.type === 'form_group_widget')"
      class="phone-footer__form-group"
    >
      {{ translateText('hubstr.editor.main.footer.forum_group') }}
    </div>
  </div>
  <br>
  <br>
</template>

<script lang="ts" setup>
import ActionButtons from 'features/settings/editor/residents-editor/ui/components/right-side/components/action-buttons.vue';
import SocialButtons from 'features/settings/editor/residents-editor/ui/components/right-side/components/social-buttons.vue';
import TextSection
  from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/text-section.vue';
import CompanyList
  from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/company-list.vue';
import ListLine
  from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/list-line.vue';
import InfoLineAction
  from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/info-line-action.vue';
import ListLineRawAction
  from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/list-line-raw-action.vue';
import Entry from 'features/settings/editor/residents-editor/ui/components/right-side/components/fields/phone-view-content/entry.vue';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';
import { translate, useTranslate } from 'magner';
import { IViewWidgets, IViewWidgetsHeader, ValueOf } from 'features/settings/editor/residents-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import { ADMIN_GROUP_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/groups/types/constants';
import { Header } from 'features/settings/mobile-editor/interfaces';
import Crown from 'features/settings/editor/assets/icons/crown.svg';

interface Props {
  data: IViewWidgets
}

const props = defineProps<Props>();

const hasCrown = props.data.header.find((i: Header) => i.type === 'header_title_with_icon_widget');

const setComponent = (type: ValueOf<typeof WIDGET_TYPES>) => {
  switch (type) {
    case WIDGET_TYPES.TEXT_SECTION:
      return TextSection;
    case WIDGET_TYPES.LIST_LINE:
      return ListLine;
    case WIDGET_TYPES.LIST_LINE_RAW:
      return ListLineRawAction;
    case WIDGET_TYPES.COMPANY_LIST:
      return CompanyList;
    case WIDGET_TYPES.INFO_LINE_ACTION:
      return InfoLineAction;
    case WIDGET_TYPES.ENTRY:
      return Entry;
    default:
      return {};
  }
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const avatar = props.data.header.find((i: IViewWidgetsHeader) => i.type === 'header_avatar_widget')?.value || '';
</script>

<style lang="scss" scoped>
.phone-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__name {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 26px;
    text-align: center;
  }
  &__tags {
    font-size: 10px;
    margin-top: 15px;
    color: var(--el-color-warning);
  }
  &__avatar {
    position: relative;
  }
  &__badge {
    color: var(--el-text-color-secondary);
    font-size: 10px;
    margin-top: 5px;
  }
  &__action-buttons {
    padding: 0 10px;
  }
}

.phone-body {
  &__title {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
  }
}

.phone-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  margin-top: 10px;

  &__member {
    color: var(--el-text-color-disabled);
  }
  &__form-group {
    color: var(--el-color-primary);
  }
}
</style>
