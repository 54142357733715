<template>
  <event-guests-link type="totalRegistered" :column="props.column" :row="props.row" />
</template>
<script setup lang="ts">
import EventGuestsLink from 'features/reports/events/eventGuestsLink.vue';
import { defineProps, PropType } from 'vue';

const props = defineProps({
  row: {
    type: Object as PropType<any>,
    required: true,
  },
  column: {
    type: Object as PropType<any>,
    required: true,
  },
});
</script>
