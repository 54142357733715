<template>
  <div class="editable-text">
    <div v-if="!isEdit" class="editable-text__text">
      <div class="editable-text__text text">{{ titleProxy }}</div>
      <div>
        <el-button
          type="primary"
          :icon="EditPen"
          :disabled="disabled"
          link
          @click.stop="isEdit = true"
        />
      </div>
    </div>
    <div
      v-else
      class="editable-text__input"
    >
      <el-input
        v-model="titleProxy"
        :disabled="disabled"
        maxlength="30"
        @click.stop
        @keydown.enter.stop="applyHandler"
        @keydown.space.prevent="stopPropagation"
      />
      <div>
        <el-button
          type="primary"
          :icon="Close"
          link
          @click.stop="applyHandler"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { EditPen, Close } from '@element-plus/icons-vue';
import { TranslatesList as t } from 'features/settings/editor/residents-editor/requests';

interface Props {
  modelValue: string;
  disabled?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

const isEdit = ref(false);
const state = ref(props.modelValue);

const titleProxy = ref(t[state.value?.replace('lang.', '')] || state.value);

const applyHandler = () => {
  if (!state.value) return;

  if (Object.values(t).includes(titleProxy.value.trim())) {
    emit('update:modelValue', state.value);
    isEdit.value = false;
    return;
  }

  emit('update:modelValue', titleProxy.value.trim());
  isEdit.value = false;
};

/**
     * Этот костыль для того, что бы обойти раскрытие коллапса при нажатии на пробел.
     * т.к. @keydown.space.stop не срабатывает на событие пробел
     */
const stopPropagation = () => {
  titleProxy.value += ' ';
};

</script>

<style scoped>
.editable-text {
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-size: var(--el-font-size-base);
  font-weight: 400;
  max-width: calc(100% - 85px);
}
.editable-text__text, .editable-text__input {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.editable-text .el-button.el-button--primary {
  padding-right: 10px;
}

.card-form .el-button {
  margin: 0;
}

.editable-text__text .text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
