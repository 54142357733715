import { translate, filtersFormController } from 'magner';
import type { Event } from 'features/events/types/hubstr';
import { eventCategoriesSearch } from 'features/settings/event-categories/requests/hubstr';

const eventsFilters = filtersFormController<Event>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersInSeparatePanel: true,
  filtersData: {
    name: '',
    status: '',
    dateStart: null,
    categories: null,
    isPublished: '',
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: translate('hubstr.event.filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'categories',
      dataType: 'array',
      options: [],
      props: {
        multiple: true,
        filterable: true,
        remote: true,
        collapseTags: false,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: eventCategoriesSearch,
        placeholder: translate('hubstr.event.filters.categories'),
      },
    },
    {
      type: 'datetime',
      name: 'dateStart',
      dataType: 'date',
      props: {
        type: 'month',
        format: 'MM.YYYY',
        placeholder: translate('hubstr.event.filters.date'),
      },
    },
    {
      type: 'select',
      name: 'status',
      props: {
        placeholder: translate('hubstr.event.filters.status'),
        clearable: true,
      },
      options: [
        {
          label: translate('hubstr.event.filters.planned_only'),
          value: 'planned',
        },
        {
          label: translate('hubstr.event.filters.is_canceled'),
          value: 'canceled',
        },
        {
          label: translate('hubstr.event.filters.registration_closed'),
          value: 'registrationClosed',
        },
      ],
    },
    {
      type: 'select',
      name: 'isPublished',
      dataType: 'boolean',
      props: {
        placeholder: translate('hubstr.event.filters.is_published.title'),
        clearable: true,
      },
      options: [
        {
          label: translate('hubstr.event.filters.is_published.options.published'),
          value: 'true',
        },
        {
          label: translate('hubstr.event.filters.is_published.options.not_published'),
          value: 'false',
        },
      ],
    },
  ],
});

export default eventsFilters;
