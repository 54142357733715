import { ProfileLink } from 'features/profile/profile/types';
import { request } from '~/utils/request';

interface Pager {
  currentPage: number,
  totalPages: number,
  totalItems: number,
}

export const profileRead = request.table<ProfileLink>(async ({ api, data }) => {
  const pagination = `count=${data.pagination.items || 25}&page=${data.pagination.page || 1}`;

  const res = await api.get<{ list: ProfileLink[], pager: Pager }>(
    `/api/admin/link/paginated_list?filters[0][id]=groupCode&filters[0][value]=profile_links&${pagination}`,
  );

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list,
      pagination: {
        currentPage: res.data.pager?.currentPage || 1,
        totalPages: res.data.pager?.totalPages || 1,
        totalItems: res.data.pager?.totalItems || 0,
      },
    },
  };
});

export const profileUpdate = request.custom(async ({ api, data, parseError }) => {
  const res = await api.patch('/api/admin/link/update', {
    link: data.linkId,
    departments: data.departments,
  });
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: 'ok' };
});
