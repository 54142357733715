<template>
  <div class="m-preferences-forms">
    <div class="m-preferences-forms__title">
      {{ title }}
    </div>
    <div class="m-preferences-forms__content">
      <el-form>
        <el-form-item>
          <label class="el-form-item__label">Адрес кому отправить</label>

          <el-icon color="var(--el-color-primary)" class="m-preferences-forms__tooltip">
            <el-tooltip
              popper-class="generic-form_item_tooltip"
              content="Значения добавляются через клавишу &quot;Enter&quot;"
            >
              <question-filled />
            </el-tooltip>
          </el-icon>

          <el-select
            v-model="state.email.value"
            filterable
            allow-create
            multiple
            default-first-option
            no-data-text="Введите адрес"
            @change="onChangeEmail"
          />
        </el-form-item>
        <el-form-item label="Тема письма">
          <el-select
            placeholder="Добавить shortcode"
            class="m-preferences-forms__tag-select"
            @change="addShortCode($event, {editorId: themeEditorId, editorValue: themeEditorValue})"
          >
            <el-option
              v-for="option in themeShortCodes"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </el-select>
          <MagnerFormEditor
            v-model="themeEditorValue"
            :field="{
              type: 'editor',
              name: '',
              props: {
                id: themeEditorId,
                tools: {}
              }
            }"
            @update:model-value="updateEditorValue($event, 'subject')"
          />
        </el-form-item>
        <el-form-item label="Шаблон письма">
          <el-select
            placeholder="Добавить shortcode"
            class="m-preferences-forms__tag-select"
            @change="addShortCode($event, {editorId: templateEditorId, editorValue: templateEditorValue})"
          >
            <el-option
              v-for="option in templateShortCodes"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </el-select>
          <MagnerFormEditor
            v-model="templateEditorValue"
            :field="{
              type: 'editor',
              name: '',
              props: {
                id: templateEditorId,
                tools: {}
              }
            }"
            @update:model-value="updateEditorValue($event, 'template')"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { MagnerFormEditor } from 'magner';
import { QuestionFilled } from '@element-plus/icons-vue';
import { emailRequired } from 'configs/development/validation';
import { ShortCode } from 'features/settings/preferences/interfaces';

export default defineComponent({
  name: 'MForms',

  components: {
    MagnerFormEditor,
    QuestionFilled,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    themeShortCodes: {
      type: Array as PropType<Array<ShortCode>>,
      required: true,
    },
    templateShortCodes: {
      type: Array as PropType<Array<ShortCode>>,
      required: true,
    },
  },
  setup (props) {
    const state = ref(props.data);

    const themeEditorId = `${props.id}-theme-editor`;
    const templateEditorId = `${props.id}-template-editor`;

    const replaceToEditorText = (value: string) => {
      const regex = /\{(.*?)\}/g;
      return value
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(regex, '<span style=\\\"color: var(--el-color-primary);\\\">$&</span>');
    };

    const getEditorBlocks = (htmlValue: string) => {
      if (htmlValue.includes('<p>')) {
        const regex = /<p>(.*?)<\/p>/g;
        const matches = htmlValue.matchAll(regex);

        const resultArray = [...matches].map((item) => `<p>${item[1]}</p>`);
        return resultArray.map((pTextValue) => `{"id":"${Math.random().toFixed(6)}","type":"paragraph","data":{"text":"${replaceToEditorText(pTextValue)}"}}`);
      }

      return `{"id":"${Math.random().toFixed(6)}","type":"paragraph","data":{"text":"${replaceToEditorText(htmlValue)}"}}`;
    };

    const getEditorInitValue = (value: string): string => `{"time":${new Date().getTime()},"blocks":[${getEditorBlocks(value)}],"version":"2.27.2"}`;

    const themeEditorValue = ref<string>(getEditorInitValue(state.value.subject.value));
    const templateEditorValue = ref<string>(getEditorInitValue(state.value.template.value));

    const onChangeEmail = (value: string[]): void => {
      state.value.email.value = value.filter((item) => !emailRequired(item));
    };

    const addShortCode = (
      tagValue: string,
      {
        editorId,
        editorValue,
      }: {
        editorId: string,
        editorValue: string
      },
    ) => {
      const editorInstance = Reflect.get(window, editorId);

      // TODO написать проверку на пустое значение
      if (!editorValue) {
        return;
      }

      const value = JSON.parse(editorValue);
      const lastParagraph = value.blocks[value.blocks.length - 1];
      lastParagraph.data.text = `${lastParagraph.data.text} <span style="color: var(--el-color-primary);">{${tagValue}}</span>&nbsp;`;
      editorInstance.blocks.update(lastParagraph.id, lastParagraph.data);
    };

    const updateEditorValue = (editorValue: string, updateKey: 'subject' | 'template') => {
      const valueToSendJSON = editorValue
        .replace(/&nbsp;/g, ' ')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/<span[^>]*>/gi, '')
        .replace(/<\/span>/gi, '');

      state.value[updateKey].value = JSON.parse(valueToSendJSON)
        .blocks
        .map((item: any) => {
          if (!(item.data.text.startsWith('<p>') && item.data.text.endsWith('</p>'))) {
            return `<p>${item.data.text}</p>`;
          }

          return item.data.text;
        }).join('');
    };

    return {
      state,

      themeEditorValue,
      templateEditorValue,

      themeEditorId,
      templateEditorId,

      addShortCode,
      onChangeEmail,

      updateEditorValue,
    };
  },
});
</script>

<style lang="scss">
.m-preferences-forms {
  &__title {
    font-size: var(--el-font-size-large);
  }

  .highlight {
    color: var(--el-color-primary);
  }

  &__content {
    margin-top: 20px;
  }

  &__tag-select {
    margin-bottom: 10px;
  }

  &__tooltip {
    margin-left: 4px;
  }

  .form-editor {
    min-width: 100%;

    .ce-toolbar, .ce-inline-toolbar {
      display: none !important;
    }

    .codex-editor__redactor {
      display: inline-block;
      padding: 11px !important;
      background-color: var(--color-light-gray);
      min-height: 40px;
      border-radius: var(--el-border-radius-base);
      width: 100%;
      font-weight: lighter;
    }

    .ce-block {
      &:not(&:last-child) {
        padding-bottom: 4px;
      }
    }

    .ce-block__content {
      margin: 0;
      min-width: 100%;
      border-bottom: none !important;
    }

    .ce-paragraph {
      line-height: 1.3;
      padding: 0;
    }
  }
}
</style>
