<template>
  <div class="interview-preview-field-questions">
    <template v-for="item in options" :key="item.uuid">
      <label v-if="item.value" class="interview-preview-field-questions__item">
        {{ item.value }}
  
        <input 
          v-model="value.value" 
          :value="item.uuid"
          :type="multiple && !number ? 'checkbox' : 'radio'" 
          class="interview-preview-field-questions__checkbox" 
          @change="onChange"
        >
  
        <el-icon size="16" class="interview-preview-field-questions__checked">
          <Check />
        </el-icon>
      </label>
    </template>
    
    <div v-if="answer && !number" class="interview-preview-field-questions__answer">
      <div class="interview-preview-field-questions__answer-label">
        {{ translateText('gaz.interview.preview.form.answer') }}
      </div>

      <field-input 
        v-model="(value.answer as string)" 
        class="interview-preview-field-questions__input" 
        @input="onChangeString"
      />
    </div>

    <div v-if="answer && number" class="interview-preview-field-questions__answer">
      <div class="interview-preview-field-questions__answer-label">
        {{ translateText('gaz.interview.preview.form.answer') }}
      </div>

      <field-number 
        v-model="(value.answer as number)" 
        :min="min"
        :max="max"
        class="interview-preview-field-questions__input" 
        @input="onChangeNumber"
      />
    </div>
  </div>
</template>
    
<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

import { Check } from '@element-plus/icons-vue';

import { translate, useTranslate } from 'magner';

import type { IInterviewPreviewOption } from '../../../interfaces';

import FieldInput from './field-input.vue';
import FieldNumber from './field-number.vue';

interface ModelValue {
  value: string[] | number[] | string | number;
  answer: string | number;
}
    
export default defineComponent({
  name: 'IntreviewPreviewFieldQuestions',

  components: {
    FieldInput,
    FieldNumber,

    Check,
  },

  props: {
    modelValue: {
      type: Object as PropType<ModelValue>,
      required: true,
    },
    options: {
      type: Array as PropType<IInterviewPreviewOption[]>,
      required: true,
    },
    answer: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 1,
    }, 
    max: {
      type: Number,
      default: 10,
    },
  },

  emits: ['update:modelValue'],

  setup (props, { emit }) {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    const value = ref(props.modelValue || []);

    const onChange = () => {
      if (props.answer && !props.multiple) {
        emit('update:modelValue', { ...value.value, answer: '' });

        return;
      }

      emit('update:modelValue', value.value);
    };

    const onChangeNumber = () => {
      emit('update:modelValue', { ...value.value, value: '' });
    };

    const onChangeString = () => {
      if (!props.multiple) {
        emit('update:modelValue', { ...value.value, value: '' });

        return;
      }

      emit('update:modelValue', value.value);
    };

    watch(() => props.modelValue, () => { value.value = props.modelValue; });

    return {
      value,

      onChange,
      onChangeNumber,
      onChangeString,

      translateText,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-questions {
  display: flex;
  flex-direction: column;
  
  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    font-size: 17px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(198, 198, 200, 1);
  }

  &__checkbox {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    cursor: pointer;

    &:checked + .interview-preview-field-questions__checked {
      opacity: 1;
    }
  }

  &__checked {
    transition: opacity .2s;
    margin-left: 8px;
    opacity: 0;
    pointer-events: none;

    svg path {
      fill: rgba(0, 122, 255, 1);
    }
  }

  &__answer {
    margin-top: 32px;
  }

  &__answer-label {
    font-size: 15px
  }

  .interview-preview-field-input {
    margin-top: 0;
  }
}
</style>
