<template>
  <div class="markdown-slot">
    <el-form ref="formRef" :model="formData" @change="submitHandler(formRef)">
      <el-row>
        <el-col :span="24">
          <MarkdownEditor
            v-model="formData.text"
            :field="titleField"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="Цвет текста"
            prop="color"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <ColorPicker v-model="formData.color" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Высота"
            prop="heightPercent"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.heightPercent"
              :field="heightPercentField"
              @update:model-value="maxIntMask($event,'heightPercent')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Позиция"
          >
            <el-select v-model.number="formData.withCenter">
              <el-option
                v-for="item in withCenterOptions"
                :key="item"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { IMarkdownWidget } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import {
  InputField, MagnerFormInput, TextareaField,
} from 'magner';
import { FormInstance } from 'element-plus';
import MarkdownEditor from 'features/events/components/markdown-editor/markdown-editor.vue';
import ColorPicker from 'features/settings/onboarding-editor/components/color-picker.vue';

interface Props {
  editData?: IMarkdownWidget;
}

const props = withDefaults(defineProps<Props>(), {
  editData: undefined,
});
const emit = defineEmits(['update:formData', 'error']);

const state = props.editData && JSON.parse(JSON.stringify(props.editData));
const formRef = ref<FormInstance>();

const formData = reactive<IMarkdownWidget>(
  state
  || {
    type: WIDGET_TYPES.MARKDOWN,
    text: '',
    color: '#000000',
    heightPercent: 0,
    withCenter: false,
  } as IMarkdownWidget,
);

const titleField: TextareaField<any> = {
  type: 'textarea',
  name: 'title',
  dataType: 'string',
  props: {
    rows: 8,
  },
};

const heightPercentField: InputField<any> = {
  type: 'input',
  name: '',
  dataType: 'number',
  props: {
    mask: {
      mask: '###',
    },
  },
};

const withCenterOptions = [
  { label: 'Слева', value: false },
  { label: 'По центру', value: true },
];

const maxIntMask = (value: number, field: keyof Omit<IMarkdownWidget, 'type' | 'text'>) => {
  const regExp = /^0[0-9]/;

  if (value > 100) {
    formData[field] = 100;
  } else if (regExp.test(String(value))) {
    formData[field] = 1;
  }
};

const submitHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return;
  }

  formEl.validate((valid) => {
    if (valid) {
      emit('error', false);
      emit('update:formData', formData);
      return true;
    }

    emit('error', true);
    return false;
  });
};

onMounted(() => submitHandler(formRef.value));
</script>

<style lang="scss" scoped>

</style>
