import { AdditionalField, WidgetBody } from 'features/settings/mobile-editor/interfaces';
import { MOBILE_FIELD_TYPES } from 'features/settings/mobile-editor/factories/constants';

export default class MobileFieldEntity {
  widget: WidgetBody
  additional: AdditionalField
  outField: any

  constructor (widget: WidgetBody, additional: AdditionalField) {
    this.widget = widget;
    this.additional = additional;

    this.outField = {
      type: '',
      value: '',
      title: '',
      name: `additions[${this.additional.code}]`,
      required: this.additional.isUserRequired,
      multiline: this.additional.isMultiline,
      placeholder: '',
    };
  }

  setType () {
    this.widget.hasAutoComplete
      ? this.outField.type = 'textAutocomplete'
      : this.outField.type = MOBILE_FIELD_TYPES[this.additional.variableType];
  }

  setPlaceholder () {
    this.widget.hasAutoComplete
      ? this.outField.placeholder = 'lang.choice_or_enter'
      : this.outField.placeholder = 'lang.enter_info';
  }

  setValue () {
    this.additional.hasDisplayType
      ? this.outField.value = `${this.additional.formCode}_raw`
      : this.outField.value = this.additional.formCode;
  }

  setTitle () {
    this.outField.title = this.widget.title || this.widget.leftText || this.additional.description;
  }

  setValidator () {
    if (!Array.isArray(this.additional.mobileValidator)) {
      this.outField.validator = this.additional.mobileValidator;
    }
    if (this.widget.hasAutoComplete) {
      this.outField.url = `/api/form/autocomplete?field=${this.additional.code}`;
      this.outField.validator = {
        type: 'textAutocomplete',
        minValues: 1,
        maxValues: 15,
        canAddUserElements: true,
      };
    }
    if (this.additional.format === 'EMPLOYEES' || this.additional.format === 'TURNOVER') {
      this.outField.validator = {
        type: 'integer',
        maxValue: 9223372036854775806,
      };
    }
  }

  create () {
    this.setType();
    this.setPlaceholder();
    this.setValue();
    this.setTitle();
    this.setValidator();

    return this.outField;
  }
}
