<template>
  <el-button
    v-if="modelValue"
    style="width: auto; margin-bottom: 0"
    type="text"
    @click="clickButton(modelValue)"
  >
    Перейти в опросник
  </el-button>
</template>

<script lang="ts">
import {
  defineComponent, PropType,
} from 'vue';
import { Organizer } from 'features/events/types/hubstr';
import { useRouter } from 'magner/vue-router';

export default defineComponent({
  name: 'PollLink',
  props: {
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array as PropType<any>,
      required: true,
    },
  },
  setup () {
    const router = useRouter();
    // @ts-ignore
    const clickButton = (poll) => {
      router.push({ name: 'interview', params: { id: poll.id } });
    };

    return {
      clickButton,
    };
  },
});
</script>
