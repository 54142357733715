import { APP_ID } from '~/constants';

export const appVersion = async (): Promise<string> => {
  try {
    const logConfig = await import(`features/change-log/${APP_ID}/log.ts`);
    return `v.${logConfig.log[0].version}`;
  } catch (e) {
    return 'v.1.0.0';
  }
};
