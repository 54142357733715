<template>
  <div class="footer-items">
    <div class="footer-items__item">
      <div class="item__title">
        {{ translateText('hubstr.editor.main.footer.member_s') }}
      </div>
      <div class="item__check">
        <el-switch
          v-model="entry"
          size="small"
          @change="emitData($event, TYPE_FOOTER_WIDGETS.ENTRY)"
        />
      </div>
    </div>
    <div class="footer-items__item">
      <div class="item__title">
        {{ translateText('hubstr.editor.main.footer.forum_group') }}
      </div>
      <div class="item__check">
        <el-switch
          v-model="formGroup"
          size="small"
          @change="emitData($event, TYPE_FOOTER_WIDGETS.FORM_GROUP)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive, ref, 
} from 'vue';
import { Widgets } from 'features/settings/mobile-editor/interfaces';
import { translate, useTranslate } from 'magner';
import { TYPE_FOOTER_WIDGETS } from 'features/settings/mobile-editor/constants';

export default defineComponent({
  name: 'FooterModule',
  props: {
    data: {
      type: Object as PropType<Widgets>,
      required: true,
    },
  },
  setup (props) {
    const state = reactive(props.data.footer);

    const formGroup = ref(state.some((i) => i.type === TYPE_FOOTER_WIDGETS.FORM_GROUP));
    const entry = ref(state.some((i) => i.type === TYPE_FOOTER_WIDGETS.ENTRY));

    const WIDGETS = {
      form_group_widget: {
        text: 'data.additions.userFormGroup',
        type: 'form_group_widget',
        empty: 'lang.looking_form_group',
      },
      entry_widget: {
        name: 'date',
        text: 'data.entry',
        type: 'entry_widget',
      },
    } as const;

    const emitData = (event: boolean, type: 'form_group_widget' | 'entry_widget') => {
      if (event) {
        state.push({
          ...WIDGETS[type],
        } as never);
      } else {
        const index = state.findIndex((i) => i.type === type);
        state.splice(index, 1);
      }
    };

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      formGroup,
      entry,
      emitData,
      translateText,

      TYPE_FOOTER_WIDGETS,
    };
  },
});
</script>

<style scoped>
.footer-items__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-lighter);
  padding: 10px 20px;
  border-radius: var(--el-card-border-radius);
  margin-bottom: 10px;
  flex-wrap: wrap;

  &__title {
    display: flex;
    align-items: center;
    width: 90%;
  }
  &__title-icon {
    cursor: move;
    margin-right: 5px;
  }
}

</style>
