import {
  APP_COMMON, APP_ID, COMMON_LIST, siteTitle, SUB_DOMEN, 
} from '~/constants';

/**
 * Устанавливает Title страницы
 * */
export const setAppTitle = () => {
  const local = JSON.parse(window.localStorage.getItem('magner-hubstr') as string);

  const titleList = document.querySelectorAll('.hubstr-admin-title');
  titleList.forEach((element) => {
    if (local?.title) {
      element.innerHTML = local.title;
      return;
    }

    element.innerHTML = APP_ID === APP_COMMON && COMMON_LIST.includes(SUB_DOMEN)
      ? siteTitle(SUB_DOMEN)
      : APP_ID === APP_COMMON && !COMMON_LIST.includes(SUB_DOMEN)
        ? 'Hubstr'
        : siteTitle(APP_ID);
  });
};
