<template>
  <el-form-item :label="translateText('hubstr.appearance.form.menu.label')">
    <el-radio-group v-model="state.theme.value" class="radio-tab">
      <el-radio label="default">{{ translateText('hubstr.appearance.form.menu.default') }}</el-radio>
      <el-image class="radio-tab_img" :src="defaultImg" />
      <el-radio label="bubbles">Bubbles</el-radio>
      <el-image class="radio-tab_img" :src="bubbleImg" />
      <el-radio label="notch">Notch</el-radio>
      <el-image class="radio-tab_img" :src="notchImg" />
    </el-radio-group>
  </el-form-item>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import notchImg from 'features/settings/appearance/assets/img/notch.gif';
import defaultImg from 'features/settings/appearance/assets/img/default.png';
import bubbleImg from 'features/settings/appearance/assets/img/bubble.gif';
import { Theme } from 'features/settings/appearance/interfaces';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'MenuTab',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const state = ref<{theme: Theme}>(props.data);

    const translateText = (code: string) => customT(translate(code));
    return {
      state,
      notchImg,
      defaultImg,
      bubbleImg,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.radio-tab {
  flex-direction: column;
  align-items: start;
  gap: 5px;

  &_img {
    height: 50px;
    /*width: 320px;*/
    border-radius: 5px;
  }
}
</style>
