import { cardPageController, translate } from 'magner';
import {
  residentDelete,
  residentNew,
} from 'features/residents/requests/hubstr';
import {
  residentGet, residentGetLayout, residentCreate, residentUpdate,
} from 'features/residents/requests/meta-hubstr';
import type { ResidentCreate } from 'features/residents/types/hubstr';
import hubstrConfig from 'features/residents/layouts/meta-hubstr';

const residentConfig = cardPageController<ResidentCreate>({
  header: {
    title: translate('hubstr.resident.form.title'),
    tabs: [
      {
        label: translate('hubstr.resident.tabs.resident'),
        link: { name: 'resident-companies' },
        active: true,
        hideIfNew: true,
      },
      {
        label: translate('hubstr.resident.tabs.companies'),
        link: { name: 'resident-companies' },
        active: false,
        hideIfNew: true,
      },
    ],
  },

  getLayoutRequest: residentGetLayout,
  getRequest: residentGet,
  getNewRequest: residentNew,
  createRequest: residentCreate,
  updateRequest: residentUpdate,
  deleteRequest: residentDelete,

  confirmDelete: translate('hubstr.resident.form.confirm_delete'),

  form: {
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.resident.form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('hubstr.resident.form.submit_button'),
          type: 'primary',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.resident.form.remove_button'),
          type: 'danger',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: hubstrConfig as any,
    },
  },
});

export default residentConfig;
