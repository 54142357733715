<template>
  <el-dialog v-model="isOpen" @close="$emit('update:modelValue', false)">
    <template #title>
      <h2>{{ translateText('hubstr.editor.groups.select_group_type') }}</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item :label="translateText('hubstr.editor.groups.group_type')">
          <el-select
            v-model="formData.type"
            class="m-2"
            :placeholder="translateText('hubstr.editor.groups.select_group_type')"
          >
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="translateText('hubstr.editor.groups.header')">
          <el-input v-model="formData.title" maxlength="30" />
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" @click="addGroupHandler">
            {{ translateText('hubstr.editor.add') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import {
  computed,
  reactive,
  ref,
  watch,
} from 'vue';
import { magnerMessage, translate, useTranslate } from 'magner';
import { IAViewWidgetsBody } from 'features/settings/editor/association-editor/interfaces';
import { GROUP_TYPES }
  from 'features/settings/editor/association-editor/ui/components/left-side/components/groups/types/constants';

interface Props {
  modelValue: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
});
const emit = defineEmits(['update:modelValue', 'change']);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const isOpen = ref(props.modelValue);

const formData = reactive<IAViewWidgetsBody>({
  title: '',
  type: GROUP_TYPES.GROUP,
  widgets: [],
});

const types = computed(() => {
  const out = [{
    label: translateText('hubstr.editor.groups.types.group'),
    value: GROUP_TYPES.GROUP,
  }];

  return out;
});

/**
 * Принудительно с большой буквы
 * */
watch(() => formData.title, (newValue) => {
  formData.title = newValue.charAt(0).toUpperCase() + newValue.slice(1);
});

const addGroupHandler = () => {
  if (!formData.title) {
    magnerMessage({
      type: 'error',
      message: translateText('hubstr.editor.errors.fill_all'),
    });
    return;
  }

  emit('change', formData);
  emit('update:modelValue', false);
};

watch(() => props.modelValue, (newValue) => {
  isOpen.value = newValue;
  emit('update:modelValue', newValue);
});

</script>

<style scoped>

</style>
