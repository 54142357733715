import { cardPageController, translate } from 'magner';
import {
  interviewCreate, interviewGet, interviewDelete, interviewUpdate,
} from './requests';
import InterviewLayout from './components/layout/index.vue';
import { APP_GAZPROM, APP_ID, META_APP } from '~/constants';

const interviewConfig = cardPageController({
  getRequest: interviewGet,
  createRequest: interviewCreate,
  updateRequest: interviewUpdate,
  deleteRequest: interviewDelete,
  confirmDelete: true,

  header: {
    title: translate('gaz.interview.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('gaz.interview.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('gaz.interview.form.submit_button'),
          class: 'interview-submit',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('gaz.interview.form.remove_button'),
          hidden: ({ state }) => !state.interviewLayout.canDelete,
        },
      },
    ],
    layout: [
      {
        type: 'custom',
        name: 'interviewLayout',
        component: () => InterviewLayout,
        props: {
          moneySettingsHidden: APP_ID !== APP_GAZPROM,
        },
        validation: [{
          type: 'interview' as 'empty',
          trigger: 'blur',
        }],
      },
    ],
  },
});

export default interviewConfig;
