import { GenericComponent, translate } from 'magner';
import { ActionButton } from 'magner/dist/types/utils/actions';
import { Address } from 'features/addresses/types/hubstr';

export const actionsButtons = [
  {
    type: 'action',
    emits: 'cancel',
    props: {
      text: translate('hubstr.address.form.cancel_button'),
    },
  } as ActionButton<'cancel'>,
  {
    type: 'action',
    emits: 'submit',
    props: {
      text: translate('hubstr.address.form.submit_button'),
      type: 'primary',
    },
  } as ActionButton<'submit'>,
  {
    type: 'action',
    emits: 'remove',
    props: {
      text: translate('hubstr.address.form.remove_button'),
      type: 'danger',
    },
  } as ActionButton<'remove'>,
];

export const nameField: GenericComponent<Address> = {
  type: 'input',
  name: 'name',
  label: translate('hubstr.address.form.name.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.address.form.name.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const addressField: GenericComponent<Address> = {
  type: 'input',
  name: 'address',
  label: translate('hubstr.address.form.address.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.address.form.address.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const descriptionField: GenericComponent<Address> = {
  type: 'textarea',
  name: 'description',
  label: translate('hubstr.address.form.description.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.address.form.description.placeholder'),
    maxLength: 1000,
    showLetterLimit: true,
  },
};
