<template>
  <el-select
    :placeholder="customT(translate('hubstr.event.organizer.choose_organizer.placeholder'))"
    :loading="loading"
    :loading-text="'Загрузка'"
    :filterable="true"
    :remote="true"
    :remote-method="remoteMethod"
    @change="selectResident"
  >
    <el-option
      v-for="option in allUsers"
      :key="option.id"
      :value="option.id"
      :label="option.firstName + ' ' + option.lastName"
    />
  </el-select>

  <el-divider content-position="left">{{ customT(translate('hubstr.event.form.or_divider')) }}</el-divider>

  <el-row
    :class="['organizer-selector-row', {
      'organizer-selector-row__error': organizerError
    }]"
    justify="space-between"
  >
    <MagnerFormInput v-model="newPartner.title" :field="titleField" />
    <MagnerFormInput v-model="newPartner.link" :field="linkField" />
    <el-button type="primary" plain @click="addPartner">
      {{ customT(translate('hubstr.event.form.add_button')) }}
    </el-button>
  </el-row>

  <el-table :data="selectedOrganizers">
    <el-table-column label="Тип">
      <template #default="{row}">{{ row.type === 'partner' ? 'Партнер' : 'Резидент' }}</template>
    </el-table-column>
    <el-table-column label="Название" min-width="200" show-overflow-tooltip>
      <template #default="{row}">{{ row.name || row.title }}</template>
    </el-table-column>
    <el-table-column label="Ссылка">
      <template #default="{row}">
        <template v-if="!row.link">–</template>
        <a v-else :href="row.link" target="_blank">{{ getLink(row.link) }}</a>
      </template>
    </el-table-column>
    <el-table-column>
      <template #default="{row}">
        <el-button type="danger" plain @click="removeOrganizer(row)">
          {{ customT(translate('hubstr.event.form.remove_button')) }}
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import {
  defineProps, defineEmits, PropType, ref, reactive, computed,
} from 'vue';
import {
  MagnerFormInput, InputField, useTranslate, translate,
} from 'magner';
import { residentSearch } from 'features/residents/requests/hubstr';
import type { Resident } from 'features/residents/types/hubstr';
import type { Organizer, OrganizerPartner } from 'features/events/types/hubstr';
import './organizer-selector.css';

const { customT } = useTranslate();
const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Array as PropType<Organizer[]>,
    required: true,
  },
});

const emit = defineEmits(['blur', 'action', 'update:modelValue']);

const loading = ref(false);
const allUsers = ref<Resident[]>([]);
const selectedOrganizers = ref(props.modelValue);

const selectResident = (id: string) => {
  const selected = allUsers.value.find((user) => `${user.id}` === id);
  const alreadySelected = selectedOrganizers.value.some((user) => user.type === 'resident' && user.id === id);
  if (!selected || alreadySelected) return;

  selectedOrganizers.value = [
    { type: 'resident', id, name: `${selected.firstName} ${selected.lastName}` },
    ...selectedOrganizers.value,
  ];
  emit('update:modelValue', selectedOrganizers.value);
};

const titleField: InputField<any> = {
  type: 'input',
  name: 'title',
  props: {
    placeholder: customT(translate('hubstr.event.organizer.title.placeholder')),
  },
};
const linkField: InputField<any> = {
  type: 'input',
  name: 'link',
  props: {
    placeholder: customT(translate('hubstr.event.organizer.link.placeholder')),
    mask: {
      mask: 'https://T*',
      tokens: {
        T: { pattern: /./ as unknown as string },
      },
    },
  },
};

const newPartner = reactive<OrganizerPartner>({
  type: 'partner',
  title: '',
  link: '',
});
const organizerError = ref(false);
const addPartner = () => {
  if (newPartner.link === '' || newPartner.title === '') {
    organizerError.value = true;
    return;
  }
  organizerError.value = false;

  selectedOrganizers.value = [{ ...newPartner }, ...selectedOrganizers.value];
  newPartner.link = '';
  newPartner.title = '';
  emit('update:modelValue', selectedOrganizers.value);
};

const removeOrganizer = (organizer: Organizer) => {
  selectedOrganizers.value = selectedOrganizers.value.filter((org) => {
    if (organizer.type === 'partner') {
      return org.type !== 'partner' || org.title !== organizer.title;
    }
    return org.type !== 'resident' || org.id !== organizer.id;
  });
  emit('update:modelValue', selectedOrganizers.value);
};

const remoteMethod = async (search = '') => {
  loading.value = true;
  const newOptions = await residentSearch({ search });
  allUsers.value = newOptions.data?.rows || [];
  loading.value = false;
};

await remoteMethod();

const getLink = (link: string) => link.match(/https?:\/\/(www\.)?([^/]+)/)?.[2]
  || customT(translate('hubstr.event.organizer.link.placeholder'));
</script>
