<template>
  <el-input-number 
    :model-value="modelValue"
    :min="min" 
    :max="max"
    size="small"
    class="interview-preview-field-number" 
    @input="$emit('update:modelValue', $event)"
    @change="$emit('update:modelValue', $event)"
    @blur="$emit('update:modelValue', $event.target.value)"
  />
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IntreviewPreviewFieldNumber',

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 'Infinity',
    },
  },

  emits: ['update:modelValue'],
});
</script>

<style lang="scss">
.interview-preview-field-number {
  margin-top: 12px;

  span {
    position: absolute !important;
    display: flex !important;
    border: none;
  }

  .el-input__wrapper {
    background: transparent;
  }
}
</style>
