import {
  Resident as HubstrResident,
  ResidentList as HubstrResidentList,
} from './hubstr';

export interface Resident extends HubstrResident {
  authMethods?: string[];
  authMethod: string;
  oldAuthMethod: string;
  password: string;
  externalId: string;
}
export interface ResidentList extends HubstrResidentList {
  authMethods: string[];
  externalId: string;
}

export interface ResidentPassword {
  authMethods?: string[];
  authMethod: string;
  oldAuthMethod: string;
  password: string;
  externalId: string;
}

export const AUTH_METHODS = [{
  id: 'code',
  name: 'СМС',
}, {
  id: 'form',
  name: 'Постоянный пароль',
}];
