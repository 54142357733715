<template>
  <el-dialog
    v-model="isOpen"
    :width="isMobile && '95%'"
    style="padding: 10px"
    @close="$emit('update:modelValue', false)"
  >
    <template #title>
      <h2>{{ translateText('hubstr.editor.modal.select_field') }}</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item :label="translateText('hubstr.editor.modal.field_view')">
          <el-select v-model="fieldType" class="m-2" :placeholder="translateText('hubstr.editor.modal.select_field')">
            <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <component
          :is="TypeFields[fieldType]"
          @input="formData = $event"
          @input:create-data="createData = $event"
          @input:error="errorsData = $event"
          @update:autocomplete="autocompleteData = $event"
        />
      </el-form>
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            type="primary"
            :loading="isLoading"
            :disabled="errorsData"
            @click="addFieldHandler"
          >
            {{ translateText('hubstr.editor.add') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script lang="ts">
/**
 * Что бы включить добавление нового поля, необходимо:
 * Создать компонент в папке slots,
 * Импортировать сюда,
 * Добавить в Enum TypeFields (имя по type поля),
 * Добавить option в массив types
 * */
import {
  computed,
  defineComponent, PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  magnerMessage, translate, useTranslate, useMobile, 
} from 'magner';
import TextSection from 'features/settings/mobile-editor/components/left-side/modals/slots/text-section.vue';
import ListLine from 'features/settings/mobile-editor/components/left-side/modals/slots/list-line.vue';
import ListLineRawAction
  from 'features/settings/mobile-editor/components/left-side/modals/slots/list-line-raw-action.vue';
import Department from 'features/settings/mobile-editor/components/left-side/modals/slots/department.vue';
import Birthday from 'features/settings/mobile-editor/components/left-side/modals/slots/birthday.vue';
import { createAutocompleteField, createNewField } from 'features/settings/mobile-editor/requests';
import { CreateField, WidgetBody } from 'features/settings/mobile-editor/interfaces';

enum TypeFields {
  text_section_widget = 'text-section',
  list_line_widget = 'list-line',
  list_line_raw_action_widget = 'list-line-raw-action',
  department_widget = 'department',
  birthday_widget = 'birthday',
}

export default defineComponent({
  name: 'AddFieldModal',
  components: {
    TextSection,
    ListLine,
    ListLineRawAction,
    Department,
    Birthday,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    currentIndexGroup: {
      type: Number,
      required: true,
    },
    data: {
      type: Object as PropType<WidgetBody[]>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'change'],
  setup (props, { emit }) {
    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    const isOpen = ref(props.modelValue);
    const isMobile = useMobile();
    const isLoading = ref<Boolean>(false);

    const fieldType = ref('');
    const formData = ref();
    const errorsData = ref(true);
    const createData = ref<CreateField>();
    const autocompleteData = ref();

    const types = computed(() => {
      const out = [
        {
          label: translateText('hubstr.editor.modal.type.text'),
          value: 'text_section_widget',
        },
        {
          label: translateText('hubstr.editor.modal.type.list_line'),
          value: 'list_line_widget',
        },
        {
          label: translateText('hubstr.editor.modal.type.list_line_raw'),
          value: 'list_line_raw_action_widget',
        },
      ];

      const hasDepartments = props.data.flatMap((g) => g.widgets).some((w) => w.text === 'data.department');
      const hasBirthday = props.data.flatMap((g) => g.widgets).some((w) => w.text === 'data.formattedBirthday.value' || w.value === 'data.birthday');

      if (!hasBirthday) {
        out.push({
          label: 'День рождения',
          value: 'birthday_widget',
        });
      }

      if (!hasDepartments) {
        out.push({
          label: 'Отделение',
          value: 'department_widget',
        });
      }
      return out;
    });

    const addFieldHandler = async () => {
      isLoading.value = true;
      if (createData.value) {
        const res = await createNewField(createData.value); // создаем поле на бэке
        if (res.data?.res.error) {
          magnerMessage({
            type: 'error',
            message: translateText('hubstr.editor.errors.create_field'),
          });
          isLoading.value = false;
          return;
        }
      }

      if (autocompleteData.value?.values.length) {
        await createAutocompleteField({
          field: createData.value?.code,
          values: autocompleteData.value.values,
        });
      }

      emit('change', {
        indexGroup: props.currentIndexGroup,
        formData: {
          type: fieldType.value,
          ...formData.value,
        },
      });

      isLoading.value = false;
      emit('update:modelValue', false);
    };

    watch(() => props.modelValue, (newValue) => {
      isOpen.value = newValue;
      emit('update:modelValue', newValue);
    });

    watch(() => fieldType.value, () => {
      formData.value = null;
      createData.value = null;
    });

    return {
      TypeFields,
      isMobile,
      isOpen,
      isLoading,
      types,
      fieldType,
      formData,
      createData,
      errorsData,
      autocompleteData,
      addFieldHandler,
      translateText,
    };
  },
});
</script>

<style scoped>

</style>
