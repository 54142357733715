<template>
  <div class="phone-header">
    <div class="phone-header__steps">
      <div class="phone-header__step">{{ current }}/{{ length }}</div>

      <!-- <router-link 
        :to="{ name: 'interview', params: { id: $route.params.id } }" 
        class="phone-header__exit"
      >
        {{ translateText('gaz.interview.preview.form.exit_button') }}
      </router-link> -->
    </div>
  </div>
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';

import { translate, useTranslate } from 'magner';
    
export default defineComponent({
  name: 'IntreviewPreviewHeader',

  props: {
    current: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },

  setup () {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    return {
      translateText,
    };
  },
});
</script>
<style lang="scss" scoped>
.phone-header {
  margin: 22px 0px 24px 0;
  font-size: 17px;

  &__steps {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 14px;
  }

  &__step {
    color: rgba(151, 151, 151, 1);
  }

  &__exit {
    position: absolute;

    right: 0px;
    top: 50%;
    transform: translateY(-50%);

    color: rgba(0, 122, 255, 1);

    text-decoration: none;
  }
}
</style>
