<template>
  <el-row style="margin-top: 20px;">
    <el-col>
      <el-radio-group v-model="state.dataType" @update:modelValue="emitData">
        <el-radio
          v-for="option in DATE_DATA_TYPE_OPTIONS"
          :key="option.value"
          :label="option.value"
          :disabled="onlyMeta"
          border
        >
          {{ translateText(option.label) }}
        </el-radio>
      </el-radio-group>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive,
} from 'vue';
import { DATE_DATA_TYPE_OPTIONS } from 'features/settings/interviews/constants';
import { IDateQuestion } from 'features/settings/interviews/interfaces';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'DateTimeQuestion',
  props: {
    data: {
      type: Object as PropType<IDateQuestion>,
      required: true,
    },
    onlyMeta: Boolean,
  },
  emits: ['update:data'],
  setup (props, { emit }) {
    const { customT } = useTranslate();

    const state: Partial<IDateQuestion> = reactive({
      dataType: props.data.dataType ?? DATE_DATA_TYPE_OPTIONS[0].value,
    });

    const emitData = () => {
      emit('update:data', { data: state });
    };

    onMounted(() => emitData());

    return {
      state,
      DATE_DATA_TYPE_OPTIONS,
      emitData,
      translateText: (code: string) => customT(translate(code)),
    };
  },
});
</script>
