<template>
  <div class="entry">
    <p>Участник с {{ formatShortDate(data.text.date) }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { WidgetBody } from 'features/settings/mobile-editor/interfaces';
import { formatShortDate } from '~/utils/format-date';

interface WidgetEntry extends WidgetBody {
  text: {
    date: Date,
    timezone_type: number,
    timezone: string,
  }
}

export default defineComponent({
  name: 'Entry',
  props: {
    data: {
      type: Object as PropType<WidgetEntry>,
      required: true,
    },
  },
  setup () {
    return {
      formatShortDate,
    };
  },
});
</script>

<style scoped>
.entry {
  margin-bottom: 20px;
  text-align: center;
  color: var(--el-text-color-secondary);
}
</style>
