<template>
  <div class="phone-header">
    <el-row>
      <el-col :span="8">
        <el-avatar
          :size="70"
          :src="avatar"
        />
      </el-col>
      <el-col :span="16">
        <InputFlat
          :label="translateText('hubstr.association_editor.phone_preview.edit_content.name')"
          :placeholder="translateText('hubstr.association_editor.phone_preview.edit_content.name.placeholder')"
          required
        />
      </el-col>
    </el-row>
  </div>

  <div class="phone-body">
    <InputFlat
      v-if="badge"
      :label="data.header.find((i) => i.type === 'header_badge_widget').label || translateText('hubstr.editor.phone_preview.edit_content.badge.label')"
      :required="data.header.find((i) => i.type === 'header_badge_widget')?.required"
    />
    <br>
    <div
      v-for="view in state.body"
      :key="view"
      class="phone-body__group"
    >
      <h3
        class="phone-body__title"
      >
        {{ t[view.title.replace('lang.mobile.', '')] || view.title }}
      </h3>
      <div
        v-for="field in view.widgets"
        :key="field.title"
        class="phone-body__field"
      >
        <component
          :is="COMPONENTS[field.type] as never"
          :key="field.title"
          :data="field"
        />
      </div>
    </div>
  </div>
  <br>
  <br>
</template>

<script lang="ts" setup>
import {
  reactive,
} from 'vue';
import InputFlat from 'features/settings/mobile-editor/components/right-side/components/input-flat.vue';
import ListLine
  from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-edit-content/list-line.vue';
import TextSection
  from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-edit-content/text-section.vue';
import InfoLineAction
  from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-edit-content/info-line-action.vue';
import ListLineRawAction
  from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-edit-content/list-line-raw-action.vue';
import { translate, useTranslate } from 'magner';
import { TranslatesAssociationList as t } from 'features/settings/editor/association-editor/requests';

import {
  IAViewWidgets,
  IAViewWidgetsHeader,
} from 'features/settings/editor/association-editor/interfaces';
import {
  WIDGET_TYPES,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/constants';

interface Props {
  data: IAViewWidgets
}

const props = defineProps<Props>();

const state = reactive(JSON.parse(JSON.stringify(props.data))) as IAViewWidgets;

const COMPONENTS = {
  [WIDGET_TYPES.TEXT_SECTION]: TextSection,
  [WIDGET_TYPES.LIST_LINE]: ListLine,
  [WIDGET_TYPES.INFO_LINE_ACTION]: InfoLineAction,
  [WIDGET_TYPES.LIST_LINE_RAW]: ListLineRawAction,
} as const;

const avatar = props.data.header.find((i: IAViewWidgetsHeader) => i.type === 'header_avatar_widget')?.value || '';
const badge = props.data.header.find((i: IAViewWidgetsHeader) => i.type === 'header_badge_widget')?.value;

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

</script>

<style lang="scss" scoped>
.phone-header {
  margin-bottom: 20px;
}

.phone-body {
  &__title {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  &__group {
    margin-bottom: 30px;
  }
}
</style>
