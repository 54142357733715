import { cardPageController, translate } from 'magner';
import { ActionButton } from 'magner/dist/types/utils/actions';
import { getLoyaltyCategories, updateLoyaltyCategories } from 'features/profile/loyalty/categories/requests';
import LoyaltyLayout from 'features/profile/loyalty/categories/components/layout/index.vue';

const loyaltyCategoriesConfig = cardPageController<any>({
  header: {
    title: translate('gaz.loyalty.sidebar.card_title'),
    tabs: [
      {
        label: translate('gaz.loyalty.tabs.main'),
        link: { name: 'loyalty' },
        active: false,
      },
      {
        label: translate('gaz.loyalty.tabs.categories'),
        link: { name: 'loyalty-categories' },
        active: true,
      },
    ],
  },

  getRequest: getLoyaltyCategories,
  createRequest: null,
  updateRequest: updateLoyaltyCategories,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [],

    layout: {
      type: 'row',
      props: {
        justify: 'end',
      },
      fields: [
        {
          type: 'button',
          name: 'loyalty-submit-btn',
          props: {
            text: translate('gaz.loyalty.form.submit_button'),
            nativeType: 'submit',
          },
        },
        {
          type: 'custom',
          name: 'loyaltyLayout',
          component: () => LoyaltyLayout,
          props: {},
        },
      ],
    },
  },
});

export default loyaltyCategoriesConfig;
