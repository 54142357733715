<template>
  <el-dialog
    v-model="isOpen"
    top="5vh"
    @close="$emit('update:modelValue', false)"
  >
    <template #title>
      <h2>{{ translateText('hubstr.editor.modal.title.add') }}</h2>
    </template>
    <template #default>
      <el-form>
        <el-form-item :label="translateText('hubstr.editor.modal.field_view')">
          <el-select v-model="selectedWidgetType">
            <el-option
              v-for="item in widgetTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <component
        :is="setComponent(selectedWidgetType)"
        is-new
        @update:formData="formData = $event"
        @input:error="errorsData = $event"
        @update:autocomplete="autocompleteData = $event"
      />
    </template>
    <template #footer>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button @click="$emit('update:modelValue', false)">
            {{ translateText('hubstr.editor.cancel') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button
            type="primary"
            :disabled="errorsData"
            @click="addWidgetHandler"
          >
            {{ translateText('hubstr.editor.add') }}
          </el-button>
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import {
  type Component,
  computed, ref, watchEffect,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { WIDGET_TYPES } from 'features/settings/editor/residents-editor/ui/components/left-side/components/widgets/types/constants';
import TextSectionSlot from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/text-section-slot.vue';
import ListLineSlot from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/list-line-slot.vue';
import ListLineRawActionSlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/list-line-raw-action-slot.vue';
import DepartmentSlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/department-slot.vue';
import { IViewWidgetsBody } from 'features/settings/editor/residents-editor/interfaces';
import BirthdaySlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/birthday-slot.vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import BlockReasonSlot
  from 'features/settings/editor/residents-editor/ui/components/left-side/modals/slots/block-reason-slot.vue';

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

interface Props {
  modelValue: boolean;
  data: IViewWidgetsBody[];
  enableBlockReasonField: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  enableBlockReasonField: false,
});
const emit = defineEmits(['update:modelValue', 'update:formData', 'update:additionsData']);
const store = useEditorStore();

const isOpen = ref(props.modelValue);
const errorsData = ref(true);
const formData = ref({});
const autocompleteData = ref({});
const selectedWidgetType = ref();

const widgetTypes = computed(() => {
  const out = [
    {
      label: translateText('hubstr.editor.modal.type.text'),
      value: WIDGET_TYPES.TEXT_SECTION,
    },
    {
      label: translateText('hubstr.editor.modal.type.list_line'),
      value: WIDGET_TYPES.LIST_LINE,
    },
    {
      label: translateText('hubstr.editor.modal.type.list_line_raw'),
      value: WIDGET_TYPES.LIST_LINE_RAW,
    },
  ] as { label: string, value: typeof WIDGET_TYPES[keyof typeof WIDGET_TYPES]}[];

  const hasDepartments = props.data.flatMap((g) => g.widgets).some((w) => w?.text === 'data.department') as boolean;
  const hasBlockReason = props.data.flatMap((g) => g.widgets).some((w) => w?.text === 'data.additions.userBlockReason') as boolean;
  const hasBirthday = props.data.flatMap((g) => g.widgets).some((w) => w.text === 'data.formattedBirthday.value' || w.value === 'data.birthday') as boolean;

  if (!hasDepartments) {
    out.push({
      label: 'Отделение',
      value: WIDGET_TYPES.DEPARTMENT,
    });
  }

  if (!hasBirthday) {
    out.push({
      label: 'День рождения',
      value: WIDGET_TYPES.BIRTHDAY,
    });
  }

  if (!hasBlockReason && props.enableBlockReasonField) {
    out.push({
      label: 'Дополнительная информация',
      value: WIDGET_TYPES.BLOCK_REASON,
    });
  }

  return out;
});

const setComponent = (type: string): Component => {
  switch (type) {
    case WIDGET_TYPES.TEXT_SECTION:
      return TextSectionSlot;
    case WIDGET_TYPES.LIST_LINE:
      return ListLineSlot;
    case WIDGET_TYPES.LIST_LINE_RAW:
      return ListLineRawActionSlot;
    case WIDGET_TYPES.DEPARTMENT:
      return DepartmentSlot;
    case WIDGET_TYPES.BIRTHDAY:
      return BirthdaySlot;
    case WIDGET_TYPES.BLOCK_REASON:
      return BlockReasonSlot;
    default:
      return {};
  }
};

const addWidgetHandler = () => {
  store.pushToAutocompleteList(autocompleteData.value);
  emit('update:formData', formData.value);
};

watchEffect(() => {
  isOpen.value = props.modelValue;
});

</script>

<style scoped>

</style>
