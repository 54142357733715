import ChangeLog from 'features/change-log/components/change-log.vue';
import { log } from 'features/change-log/gaz/log';
import { cardPageController, translate } from 'magner';

const changeLogConfig = cardPageController({
  getRequest: () => ({ data: 'ok' }),

  header: {
    title: translate('hubstr.change_log.form.title'),
  },

  form: {
    fullDataOnUpdate: true,
    actions: [],
    layout: [
      {
        type: 'custom',
        name: 'ChangeLogLayout',
        component: () => ChangeLog,
        props: {
          row: log,
        },
      },
    ],
  },
});

export default changeLogConfig;
