import { filtersFormController, translate } from 'magner';
import { Resident } from 'features/residents/types/hubstr';
import { communityManagersListGet } from 'features/settings/community-managers/requests/hubstr';

const communityManagersFilters = filtersFormController<Resident>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersData: {
    communityManager: '',
    firstName: '',
  },
  sort: {},

  layout: [{
    type: 'select',
    name: 'communityManager',
    dataType: 'array',
    options: [],
    props: {
      multiple: false,
      filterable: true,
      remote: true,
      collapseTags: true,
      valueKey: 'id',
      labelKey: 'fio',
      remoteMethod: communityManagersListGet,
      placeholder: translate('hubstr.community_manager.filters.community_manager'),
    },
  }, {
    type: 'input',
    name: 'firstName',
    props: {
      placeholder: translate('hubstr.community_manager.filters.name'),
      inputDelay: 250,
    },
  }],
});

export default communityManagersFilters;
