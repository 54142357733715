<template>
  <div class="m-versions">
    <div class="m-versions__title">
      {{ translateText('hubstr.preferences.versions.title') }}
    </div>
    <div class="m-versions__content">
      <el-form>
        <el-form-item :label="translateText('hubstr.preferences.versions.version.label')">
          <MagnerFormInput v-model="state.version" :field="field" />
        </el-form-item>
        <el-form-item>
          <template #label>
            {{ translateText('hubstr.preferences.versions.type.label') }}
            <el-tooltip
              :content="translateText('hubstr.preferences.versions.type.hint')"
              effect="light"
              placement="bottom"
              popper-class="generic-form_item_tooltip"
            >
              <el-icon size="15" class="icon">
                <component :is="Question" />
              </el-icon>
            </el-tooltip>
          </template>
          <el-radio-group v-model="state.type">
            <el-radio label="HARD" />
            <el-radio label="SOFT" />
          </el-radio-group>
        </el-form-item>
        <el-form-item class="checkbox" :label="translateText('hubstr.preferences.versions.require_update.label')">
          <el-checkbox v-model="state.requireUpdate" />
        </el-form-item>
      </el-form>

      <el-divider />
      <div>
        <div class="m-versions__title">
          {{ translateText('hubstr.preferences.versions.appLinks.title') }}
        </div>
        <div class="m-versions__content">
          <el-row>
            <el-col
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
            >
              <el-form-item label="iOS">
                <div class="input-wrapper">
                  <MagnerFormInput v-model="state.iosLink" :field="iosLinkField" />
                  <el-button
                    class="input-wrapper__btn"
                    type="text"
                    :icon="TopRight"
                    :disabled="!state.iosLink"
                    @click="linkTo(state.iosLink)"
                  />
                </div>
              </el-form-item>

              <el-form-item label="Android">
                <div class="input-wrapper">
                  <MagnerFormInput v-model="state.androidLink" :field="androidLinkField" />
                  <el-button
                    class="input-wrapper__btn"
                    type="text"
                    :icon="TopRight"
                    :disabled="!state.androidLink"
                    @click="linkTo(state.androidLink)"
                  />
                </div>
              </el-form-item>

              <el-form-item label="Huawei">
                <div class="input-wrapper">
                  <MagnerFormInput v-model="state.huaweiLink" :field="huaweiLinkField" />
                  <el-button
                    v-if="state.huaweiLink"
                    class="input-wrapper__btn"
                    type="text"
                    :icon="TopRight"
                    @click="linkTo(state.huaweiLink)"
                  />
                </div>
              </el-form-item>

              <el-form-item label="RuStore">
                <div class="input-wrapper">
                  <MagnerFormInput v-model="state.ruStoreLink" :field="ruStoreLinkField" />
                  <el-button
                    v-if="state.ruStoreLink"
                    class="input-wrapper__btn"
                    type="text"
                    :icon="TopRight"
                    @click="linkTo(state.ruStoreLink)"
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import {
  InputField, MagnerFormInput, translate, useTranslate,
} from 'magner';
import Question from 'features/settings/mobile-editor/assets/icons/question.svg';
import { TopRight } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'MVersions',
  components: { MagnerFormInput },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  setup (props) {
    const state = ref(props.data);

    const field: InputField<any> = {
      type: 'input',
      name: 'version',
      props: {
        placeholder: '0.0.00',
        mask: {
          mask: '#*.#*.##',
        },
      },
    };
    const iosLinkField: InputField<any> = {
      type: 'input',
      name: 'iosLink',
      props: {
        placeholder: 'https://apps.apple.com/',
        mask: {
          mask: 'https://T*',
          tokens: {
            T: { pattern: /./ as unknown as string },
          },
        },
      },
    };
    const androidLinkField: InputField<any> = {
      type: 'input',
      name: 'androidLink',
      props: {
        placeholder: 'https://play.google.com/',
        mask: {
          mask: 'https://T*',
          tokens: {
            T: { pattern: /./ as unknown as string },
          },
        },
      },
    };
    const huaweiLinkField: InputField<any> = {
      type: 'input',
      name: 'huaweiLink',
      props: {
        placeholder: 'https://appgallery.huawei.com/',
        mask: {
          mask: 'https://T*',
          tokens: {
            T: { pattern: /./ as unknown as string },
          },
        },
      },
    };
    const ruStoreLinkField: InputField<any> = {
      type: 'input',
      name: 'ruStoreLink',
      props: {
        placeholder: 'https://www.rustore.ru/',
        mask: {
          mask: 'https://T*',
          tokens: {
            T: { pattern: /./ as unknown as string },
          },
        },
      },
    };

    const linkTo = (link: string) => {
      window.open(link, '_blank');
    };

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));
    return {
      state,
      Question,
      TopRight,
      field,
      iosLinkField,
      androidLinkField,
      huaweiLinkField,
      ruStoreLinkField,
      linkTo,
      translateText,
    };
  },
});
</script>

<style lang="scss" scoped>
.m-versions {
  &__title {
    font-size: var(--el-font-size-large);
  }
  &__content {
    margin-top: 20px;
    & .checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row-reverse;
      width: max-content;
    }
    & .el-icon {
      vertical-align: middle;
    }
  }

  .input-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;

    &__btn {
      width: auto;
    }
  }
}
</style>
