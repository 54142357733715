<template>
  <div class="phone-wrap">
    <div class="iphone-x">
      <div class="top-header">
        <i>Speaker</i>
        <b>Camera</b>
        <div class="brow">
          <s>{{ new Date().toLocaleTimeString([], {timeStyle: 'short'}) }}</s>
        </div>
      </div>
      <div class="content">
        <div class="content__switch">
          <el-button type="text" @click="switchContent = !switchContent">
            <div v-if="!switchContent" class="content__switch-icon">
              <ArrowLeft />
            </div>
          </el-button>
          <el-button type="text" @click="switchContent = !switchContent">
            <div v-if="switchContent" class="content__switch-icon">
              <EditPen />
            </div>
            <div v-else>
              {{ translateText('hubstr.editor.done') }}
            </div>
          </el-button>
        </div>
        <slot
          v-if="switchContent"
          :key="JSON.stringify(data)"
          name="view-content"
          :data="data"
        />
        <slot
          v-if="!switchContent"
          :key="JSON.stringify(data)"
          name="edit-content"
          :data="data"
        />
        <!--        <PhoneViewContent v-if="switchContent" :key="JSON.stringify(data)" :data="data" />-->
        <!--        <PhoneEditContent v-else :key="JSON.stringify(data)" :data="data" />-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { EditPen, ArrowLeft } from '@element-plus/icons-vue';
import { translate, useTranslate } from 'magner';
import { IAViewWidgets } from 'features/settings/editor/association-editor/interfaces';

interface Props {
  data: IAViewWidgets
}

defineProps<Props>();
const switchContent = ref<boolean>(true);

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

</script>

<style lang="scss">
@import "../../assets/scss/iphone";

.content {
  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: auto !important;
    }
    &-icon {
      width: 16px;
    }
  }
}

.content__switch {
  .el-button--text:not(.is-disabled):focus {
    color: var(--el-color-primary);
  }
}

</style>
