<template>
  <el-row class="mobile-editor">
    <el-col
      :xl="14"
      :lg="16"
      :md="15"
      :sm="24"
      :xs="24"
    >
      <CollapseWrap>
        <template #title>
          <div class="mobile-editor__title">
            {{ translateText('hubstr.editor.main.header.title') }}
          </div>
        </template>
        <template #default>
          <HeaderModule :data="state" :has-crown="field.crown" />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          <div class="mobile-editor__title">
            {{ translateText('hubstr.editor.main.body.title') }}
          </div>
        </template>
        <template #default>
          <BodyModule
            :data="state"
            :enable-block-reason-field="field.enableBlockReasonField"
          />
        </template>
      </CollapseWrap>
      <CollapseWrap>
        <template #title>
          <div class="mobile-editor__title">
            {{ translateText('hubstr.editor.main.footer.title') }}
          </div>
        </template>
        <template #default>
          <FooterModule :data="state" />
        </template>
      </CollapseWrap>
    </el-col>
    <el-col
      :xl="10"
      :lg="8"
      :md="9"
      :sm="24"
      :xs="24"
    >
      <div class="phone-wrap">
        <PhoneMock :data="state" />
      </div>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import CollapseWrap from 'features/settings/editor/residents-editor/ui/components/left-side/components/collapse-wrap.vue';
import {
  reactive, watchEffect,
} from 'vue';
import { translate, useTranslate } from 'magner';
import { IViewWidgets } from 'features/settings/editor/residents-editor/interfaces';
import BodyModule from 'features/settings/editor/residents-editor/ui/components/left-side/modules/body-module.vue';
import PhoneMock from 'features/settings/editor/residents-editor/ui/components/right-side/phone-mock.vue';
import FooterModule from 'features/settings/editor/residents-editor/ui/components/left-side/modules/footer-module.vue';
import HeaderModule from 'features/settings/editor/residents-editor/ui/components/left-side/modules/header-module.vue';
import useEditorStore from 'features/settings/editor/store/useEditorStore';
import { AdditionsList } from 'features/settings/editor/residents-editor/requests';

interface Props {
  modelValue: IViewWidgets
  field: any
}
const props = defineProps<Props>();
const state = reactive(props.modelValue);
const store = useEditorStore();

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

watchEffect(() => {
  store.setResidentAdditionalCodes([...new Set(props.modelValue.body
    .flatMap((g) => g.widgets.map((w) => w?.additions?.code))
    .concat(AdditionsList?.flatMap((a) => a.code))
    .filter(Boolean)),
  ]);
});

</script>

<style scoped>
.mobile-editor {
  width: 100%;
}

.mobile-editor__title {
  font-weight: 500;
  font-size: var(--el-font-size-base);
}

.phone-wrap {
  position: fixed;
  right: 40px;
  top: 15%;
  transform: translateY(calc(-5% + 24px));
}
@media (min-width: 768px) {
  .mobile-editor {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .phone-wrap {
    position: relative;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1199px) {
  .phone-wrap {
    position: relative;
    top: 0;
    margin-top: 40px;
    right: 0;
  }
}
</style>
