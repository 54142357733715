import {
  tablePageController, translate, actionCard, openModal, actionTable,
} from 'magner';
import { eventCategoryRead } from 'features/settings/event-categories/requests/hubstr';
import type { EventCategory } from 'features/settings/event-categories/types/hubstr';
import eventCategoryConfig from 'features/settings/event-categories/category/hubstr';

const eventCategoriesConfig = tablePageController<EventCategory>({
  request: eventCategoryRead,

  header: {
    title: translate('hubstr.event_category.table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          text: translate('hubstr.event_category.filters.create_new'),
        },
        emits: 'update-table',
        action: actionCard<EventCategory>(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: eventCategoryConfig,
              handleBeforeClose: true,
              customClass: 'modal-max-width modal-max-width__400',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          } catch (e) {
            return translate('hubstr.event_category.form.failed_creation');
          }
        }),
      },
    ],
  },

  filters: {
    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },
    filtersInSeparatePanel: true,
    filtersData: {
      name: '',
    },
    sort: {},

    actions: [],
    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: translate('hubstr.event_category.filters.name'),
        },
      },
    ],
  },

  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: translate('hubstr.event_category.table.not_found'),
    columns: [
      {
        prop: 'name',
        width: 'auto',
        label: translate('hubstr.event_category.table.name'),
      },
      {
        prop: 'createdAt',
        label: translate('hubstr.event_category.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<EventCategory>(async ({ data }) => {
                const category = (data as any).row as EventCategory;
                try {
                  await openModal<any>({
                    type: 'card',
                    config: eventCategoryConfig,
                    handleBeforeClose: true,
                    customClass: 'modal-max-width modal-max-width__500',
                    props: {
                      emptyCard: false,
                      entityId: category.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('hubstr.event_category.form.failed_edition');
                }
              }),
              props: {
                text: translate('hubstr.event_category.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default eventCategoriesConfig;
