import { AuthListItem } from 'features/settings/authorization/types';
import { request } from '~/utils/request';

export const listRead = request.table<AuthListItem[]>(async ({ api, data }) => {
  const res = await api.get<{ list: any[] }>(
    '/api/admin/auth/info/list',
  );
  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  return {
    data: {
      rows: res.data.list.map((i) => ({
        ...i,
        fio: `${i.firstName} ${i.lastName}`,
      })),
      pagination: null,
    },
  };
});
