import {
  actionTable, openModal, tablePageController, translate, 
} from 'magner';
import { linksRead } from 'features/settings/link-groups/requests/hubstr';
import { Link } from 'features/settings/link-groups/types/hubstr';
import linkGroupConfig from 'features/settings/link-groups/link/hubstr';

const linksConfig = tablePageController<Link>({
  request: linksRead,
  header: {
    title: ' ',
  },
  filters: {
    fieldsShowAmount: 5,
    pagination: {
      items: 25,
      page: 1,
    },
    filtersData: {},
    actions: [],
    layout: [],
  },
  filtersInSeparatePanel: true,
  table: {
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: '',
    columns: [
      {
        prop: 'title',
        width: 'auto',
        label: translate('hubstr.link_groups.table.name'),
      },
      {
        prop: 'id',
        label: translate('hubstr.link_groups.table.actions'),
        width: 200,
        view: {
          type: 'actions',
          actions: [
            {
              type: 'action',
              emits: 'update-table',
              action: actionTable<Link>(async ({ data }) => {
                const link = (data as any).row as Link;
                try {
                  await openModal<any>({
                    type: 'card',
                    config: {
                      ...linkGroupConfig,
                      header: {
                        title: translate('hubstr.link_groups.form.title_edit'),
                      },
                    },
                    handleBeforeClose: true,
                    props: {
                      emptyCard: false,
                      entityId: link.id,
                    },
                  });
                  return false;
                } catch (e) {
                  return translate('hubstr.link_groups.form.failed_edition');
                }
              }),
              props: {
                text: translate('hubstr.link_groups.table.edit_action'),
                type: 'primary',
                plain: true,
              },
            },
          ],
        },
      },
    ],
  },
});

export default linksConfig;
