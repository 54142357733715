<template>
  <el-form
    ref="formRef"
    :model="formData"
    @change="emitHandler(formRef)"
    @input="emitHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="title"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <el-input
        v-model="formData.title"
        :maxlength="40"
      />
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive, ref, 
} from 'vue';
import { FormInstance } from 'element-plus';
import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'Department',
  props: {
    widgetData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    additionsData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'input:createData', 'input:error', 'update:autocomplete'],
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const formData = reactive<any>(props.widgetData || {
      type: 'text_section_widget',
      title: '',
      text: 'data.department',
    });

    const emitHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);
          emit('input', formData);
          return;
        }

        emit('input:error', true);
      });
    };

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      formRef,
      formData,
      emitHandler,
      translateText,
    };
  },
});
</script>

<style scoped>

</style>
