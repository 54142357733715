<template>
  <el-input
    :model-value="modelValue"
    :maxlength="long ? 500 : 50"
    :placeholder="translateText('gaz.interview.preview.form.enter_text')"
    type="text"
    show-word-limit
    class="interview-preview-field-input"
    @input="$emit('update:modelValue', $event)"
  />
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';

import { translate, useTranslate } from 'magner';

export default defineComponent({
  name: 'IntreviewPreviewFieldInput',

  props: {
    modelValue: {
      type: String,
      required: true,
    },
    long: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup () {
    const { customT } = useTranslate();

    const translateText = (code: string) => customT(translate(code));

    return {
      translateText,
    };
  },
});
</script>

<style lang="scss">
.interview-preview-field-input {
  margin-top: 12px;
  border-bottom: 1px solid rgba(200, 199, 204, 1);

  .el-input__wrapper {
    padding: 0;
    background: transparent;
  }

  .el-input__count-inner {
    color: var(--el-color-info);
  }

  input {
    padding: 0;
    font-size: 15px;
  }
}
</style>
