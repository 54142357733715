<template>
  <el-form
    ref="formRef"
    :model="formData"
    @change="emitHandler(formRef)"
    @input="emitHandler(formRef)"
  >
    <el-form-item
      :label="translateText('hubstr.editor.modal.field_name')"
      prop="title"
      :rules="[
        { required: true, message: translateText('hubstr.editor.errors.required_field'), },
      ]"
    >
      <el-input
        v-model="titleProxy"
        :maxlength="40"
      />
    </el-form-item>
  </el-form>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, reactive, ref,
} from 'vue';
import { FormInstance } from 'element-plus';
import { translate, useTranslate } from 'magner';
import { TranslatesList as t } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'Birthday',
  props: {
    widgetData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    additionsData: {
      type: Object as PropType<any>,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'input:createData', 'input:error', 'update:autocomplete'],
  setup (props, { emit }) {
    const formRef = ref<FormInstance>();

    const formData = reactive<any>(props.widgetData || {
      name: null,
      type: 'text_section_widget',
      title: 'lang.birthday',
      text: 'data.formattedBirthday.value',
      required: true,
      hasPrivacy: true,
      value: null,
      dataProvider: null,
    });

    const titleProxy = ref(t[formData.title?.replace('lang.', '')] || formData.title);

    const formBuilder = () => {
      // если есть перевод оставляем код, если нет то свое значение
      if (titleProxy.value?.toLowerCase() !== t.birthday?.toLowerCase()) {
        formData.title = titleProxy.value.trim();
      } else {
        formData.title = 'lang.birthday';
      }
    };

    const emitHandler = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formBuilder();

      formEl.validate((valid) => {
        if (valid) {
          // submit
          emit('input:error', false);
          emit('input', formData);
          return;
        }

        emit('input:error', true);
      });
    };

    onMounted(() => emitHandler(formRef.value));

    const { customT } = useTranslate();
    const translateText = (code: string) => customT(translate(code));

    return {
      titleProxy,
      formRef,
      formData,
      emitHandler,
      translateText,
    };
  },
});
</script>

<style scoped>
.el-checkbox {
  font-weight: lighter;
}
</style>
