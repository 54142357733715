<template>
  <div class="text-slot">
    <el-form ref="formRef" :model="formData" @change="submitHandler(formRef)">
      <el-form-item
        label="Текст"
        prop="text"
        :rules="[{ required: true, message: 'Обязательное поле' }]"
      >
        <el-input v-model="formData.text" type="textarea" />
      </el-form-item>
      <el-row :gutter="20" style="margin-bottom: 0">
        <el-col :span="12">
          <el-form-item
            label="Размер шрифта"
            prop="fontSize"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.fontSize"
              :field="numberField"
              @update:model-value="maxIntMask($event,'fontSize')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Толщина шрифта"
            prop="fontWeight"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <el-select v-model.number="formData.fontWeight">
              <el-option
                v-for="item in fontWeight"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-bottom: 0">
        <el-col :span="12">
          <el-form-item
            label="Цвет текста"
            prop="color"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <ColorPicker v-model="formData.color" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Позиция"
          >
            <el-select v-model.number="formData.textAlign">
              <el-option
                v-for="item in textAlign"
                :key="item"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item
            label="Отступ сверху"
            prop="topPadding"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.topPadding"
              :field="numberField"
              @update:model-value="maxIntMask($event,'topPadding')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Отступ слева"
            prop="leftPadding"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.leftPadding"
              :field="numberField"
              @update:model-value="maxIntMask($event,'leftPadding')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="Отступ справа"
            prop="rightPadding"
            :rules="[{ required: true, message: 'Обязательное поле' }]"
          >
            <MagnerFormInput
              v-model.number="formData.rightPadding"
              :field="numberField"
              @update:model-value="maxIntMask($event,'rightPadding')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { IImageWidget, ITextWidget } from 'features/settings/onboarding-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/onboarding-editor/constants';
import { InputField, MagnerFormInput } from 'magner';
import { FormInstance } from 'element-plus';
import ColorPicker from 'features/settings/onboarding-editor/components/color-picker.vue';

interface Props {
  editData?: IImageWidget;
}

const props = withDefaults(defineProps<Props>(), {
  editData: undefined,
});
const emit = defineEmits(['update:formData', 'error']);

const state = props.editData && JSON.parse(JSON.stringify(props.editData));
const formRef = ref<FormInstance>();

const formData = reactive<ITextWidget>(
  state
  || {
    type: WIDGET_TYPES.TEXT,
    text: '',
    color: '#000000',
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'left',
    topPadding: 0,
    leftPadding: 0,
    rightPadding: 0,
  } as ITextWidget,
);

const fontWeight = ['100', '400', '500'];
const textAlign = [{ label: 'Слева', value: 'left' }, { label: 'По центру', value: 'center' }, { label: 'Справа', value: 'right' }];

const numberField: InputField<any> = {
  type: 'input',
  name: '',
  dataType: 'number',
  props: {
    mask: {
      mask: '###',
    },
  },
};

const maxIntMask = (value: number, field: keyof Omit<ITextWidget, 'type' | 'text'>) => {
  const regExp = /^0[0-9]/;

  if (value > 100) {
    formData[field] = 100;
  } else if (regExp.test(String(value))) {
    formData[field] = 1;
  }
};

const submitHandler = (formEl: FormInstance | undefined) => {
  if (!formEl) {
    return;
  }

  formEl.validate((valid) => {
    if (valid) {
      emit('error', false);
      emit('update:formData', formData);
      return true;
    }

    emit('error', true);
    return false;
  });
};

onMounted(() => submitHandler(formRef.value));
</script>

<style lang="scss" scoped>
.text-slot {
  :deep(.el-input-number .el-input__inner) {
    text-align: start !important;
  }
}
</style>
