import { PreferenceItem } from 'features/settings/preferences/interfaces';
import { request } from '~/utils/request';

interface GetRreference {
  data: {
    list: Array<PreferenceItem>
  }
}

const convertEmailValueToArrayValue = (item: PreferenceItem): Array<string> => {
  if (typeof item.value === 'string') {
    if (item.value.includes(',')) {
      item.value = item.value.split(', ');
    } else {
      item.value = [item.value];
    }
  }
};

export const preferenceGet = request.card(async ({ api }) => {
  try {
    const res = await api.get<GetRreference>('/api/admin/setting/list?page=1&count=150');

    if (res.data) {
      const responseList = res.data.data?.list ?? [];
      const defaultValue = { value: null };

      // const regex = /{(.*?)}/gi;
      // const shortCodes = Array.from(new Set(responseList
      //   .filter((item) => typeof item.value === 'string')
      //   .map((item) => (item?.value as string)?.match(regex)?.map((regItem) => regItem.slice(1, -1)))
      //   .filter((item) => item)
      //   .reduce((acc: string[], arr: string[]) => {
      //     acc.push(...arr);
      //     return acc;
      //   }, [])));

      const feedbackGazBreakingEmailTo = responseList
        .find((i: PreferenceItem) => i.code === 'feedbackGazBreakingEmailTo');
      if (feedbackGazBreakingEmailTo && feedbackGazBreakingEmailTo.value) {
        convertEmailValueToArrayValue(feedbackGazBreakingEmailTo);
      }

      const feedbackGazReceptionEmailTo = responseList
        .find((i: PreferenceItem) => i.code === 'feedbackGazReceptionEmailTo');
      if (feedbackGazReceptionEmailTo && feedbackGazReceptionEmailTo.value) {
        convertEmailValueToArrayValue(feedbackGazReceptionEmailTo);
      }

      const feedbackGazSuggestionsEmailTo = responseList
        .find((i: PreferenceItem) => i.code === 'feedbackGazSuggestionsEmailTo');
      if (feedbackGazSuggestionsEmailTo && feedbackGazSuggestionsEmailTo.value) {
        convertEmailValueToArrayValue(feedbackGazSuggestionsEmailTo);
      }

      return {
        data: {
          preferenceLayout: {
            copy: structuredClone(responseList),

            feedbackGazBreaking: {
              email: feedbackGazBreakingEmailTo ?? defaultValue,
              subject: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazBreakingSubject') ?? defaultValue,
              template: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazBreakingTemplate') ?? defaultValue,
            },
            feedbackGazReception: {
              email: feedbackGazReceptionEmailTo ?? defaultValue,
              subject: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazReceptionSubject') ?? defaultValue,
              template: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazReceptionTemplate') ?? defaultValue,
            },
            feedbackGazSuggestions: {
              email: feedbackGazSuggestionsEmailTo ?? defaultValue,
              subject: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazSuggestionsSubject') ?? defaultValue,
              template: responseList.find((i: PreferenceItem) => i.code === 'feedbackGazSuggestionsTemplate') ?? defaultValue,
            },

            sendNotificationResidentNew: responseList.find((i: PreferenceItem) => i.code === 'sendNotificationResidentNew') ?? defaultValue,

            maxUserDevicesCount: responseList.find((i: PreferenceItem) => i.code === 'maxUserDevicesCount') ?? defaultValue,
            postPinEnabled: responseList.find((i: PreferenceItem) => i.code === 'postPinEnabled') ?? defaultValue,
          },
        },
      };
    }
  } catch (e) {
    return {
      error: { message: '', fields: {} },
    };
  }
});

export const preferenceUpdate = request.custom(async ({ api, data, parseError }) => {
  const initData = data.data.preferenceLayout.copy;

  const updateData = {
    ...data.data.preferenceLayout,
    feedbackGazBreakingEmailTo: {
      ...data.data.preferenceLayout.feedbackGazBreaking.email,
      value: data.data.preferenceLayout.feedbackGazBreaking.email.value.join(', '),
    },
    feedbackGazBreakingSubject: data.data.preferenceLayout.feedbackGazBreaking.subject,
    feedbackGazBreakingTemplate: data.data.preferenceLayout.feedbackGazBreaking.template,

    feedbackGazReceptionEmailTo: {
      ...data.data.preferenceLayout.feedbackGazReception.email,
      value: data.data.preferenceLayout.feedbackGazReception.email.value.join(', '),
    },
    feedbackGazReceptionSubject: data.data.preferenceLayout.feedbackGazReception.subject,
    feedbackGazReceptionTemplate: data.data.preferenceLayout.feedbackGazReception.template,

    feedbackGazSuggestionsEmailTo: {
      ...data.data.preferenceLayout.feedbackGazSuggestions.email,
      value: data.data.preferenceLayout.feedbackGazSuggestions.email.value.join(', '),
    },
    feedbackGazSuggestionsSubject: data.data.preferenceLayout.feedbackGazSuggestions.subject,
    feedbackGazSuggestionsTemplate: data.data.preferenceLayout.feedbackGazSuggestions.template,
  };

  delete updateData.copy;
  delete updateData.feedbackGazBreaking;
  delete updateData.feedbackGazReception;
  delete updateData.feedbackGazSuggestions;

  const updateDataArray: Array<PreferenceItem> = Object.values(updateData);

  // Проверяем что изменилось
  // TODO проверка написана только для примитивных значений
  const settings = initData.reduce((
    result: Array<{ code: PreferenceItem['code'], value: PreferenceItem['value'] }>,
    initObject: PreferenceItem,
  ) => {
    const changeObject = updateDataArray
      .find((obj1: PreferenceItem) => obj1.category === initObject.category
        && obj1.code === initObject.code);

    if (changeObject && initObject.value !== changeObject.value) {
      result.push({
        code: initObject.code,
        value: changeObject.value,
      });
    }

    return result;
  }, []);

  const res = await api.put('/api/admin/setting/multi', { settings });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: 'ok' };
});
