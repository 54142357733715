const ruCustom = {
  dynamic: {} as any,
  hubstr: {
    system: {
      sidebar: {
        group_title: 'Системное',
      },
    },
    settings: {
      sidebar: {
        group_title: 'Настройки',
      },
    },
    moderation: {
      sidebar: {
        group_title: 'Модерация',
      },
    },
    address: {
      sidebar: {
        table_title: 'Адреса',
        card_title: 'Новый адрес',
        group_title: 'Адреса',
      },
      form: {
        title: 'Адрес',

        submit_button: 'Сохранить',
        cancel_button: 'Назад',
        remove_button: 'Удалить',

        name: { label: 'Название', placeholder: 'Офис' },
        address: { label: 'Адрес', placeholder: 'г. Москва, ул. Красноармейская, д. 4' },
        point: { label: 'Точка на карте', placeholder: '55.797397, 37.549390' },
        description: { label: 'Описание', placeholder: 'Вход находится с правого торца' },
      },
      table: {
        title: 'Адреса',
        not_found: 'Адреса не найдены',

        address: 'Адрес',
        name: 'Название',
        description: 'Описание',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'Название',
      },
    },
    event: {
      sidebar: {
        table_title: 'Мероприятия',
        card_title: 'Новое мероприятие',
        group_title: 'Мероприятия',
      },
      table: {
        title: 'Мероприятия',
        not_found: 'Мероприятия не найдены',

        id: 'ID',
        name: 'Название',
        category: 'Категория',
        date_start: 'Дата начала',
        date_end: 'Дата окончания',
        status: 'Статус',
        isPublished: 'Публикация',

        published: 'опубликовано',
        not_published: 'не опубликовано',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'Название',
        status: 'Статус',
        planned_only: 'Запланировано',
        is_canceled: 'Отменено',
        registration_closed: 'Регистрация закрыта',
        categories: 'Категории',
        date: 'Месяц',
        is_published: {
          title: 'Публикация',
          options: {
            published: 'Опубликовано',
            not_published: 'Не опубликовано',
          },
        },
      },
      tabs: {
        form: 'Карточка',
        guests: 'Участники',
      },
      alerts: {
        remove_with_interview: { title: 'Внимание!', message: 'Нельзя удалить, потому что мероприятие опубликовано и прикреплен опрос.' },
      },
      form: {
        title: 'Мероприятие',
        edit_button: 'Редактировать',
        add_button: 'Добавить',
        submit_button: 'Сохранить',
        submit_button_publish: 'Опубликовать',
        cancel_button: 'Назад',
        remove_button: 'Удалить',
        or_divider: 'или',

        name: {
          label: 'Название',
          placeholder: 'Бизнес тренинг',
          hint: 'Не более 128 знаков с пробелами',
        },
        description: {
          label: 'Описание',
          placeholder: 'На этом бизнес тренинге мы научимся эффективному управлению командой',
        },
        date: {
          label: 'Дата начала и окончания',
          start_placeholder: '20.01.2022 в 14:00',
          end_placeholder: '21.01.2022 в 15:30',
        },
        registration_date: {
          label: 'Дата начала и окончания регистрации',
        },
        image: {
          label: 'Обложка',
          hint: 'Рекомендуемый размер — не менее 800 пикселей по ширине,'
            + '<br/>видимая область в пропорциях 2:1. Формат JPG, JPEG, PNG. Вес не более 8 Мб',
        },
        department: { label: 'Пригласить резидентов из отделений', placeholder: '' },
        categories: { label: 'Категории', placeholder: 'Бизнес, управление персоналом' },
        organizer: { label: 'Организаторы', placeholder: 'Иван Иванов' },
        publish: { label: 'Публикация мероприятия' },
        address: {
          label: 'Адрес',
          placeholder: 'г. Москва',
          hint: {
            title: 'Чтобы выбрать адрес из списка его нужно создать в',
            link: 'справочнике',
          },
        },
        residentsLimit: {
          label: 'Максимальное количество участников',
          placeholder: '10',
          hint: 'Регистрация на&nbsp;мероприятие автоматически закроется, когда будет достигнуто заданное число участников. Если кто-то передумает, регистрация снова откроется; кроме случая, когда регистрация закрыта принудительно.',
        },
        eventEventCode: {
          label: 'Код мероприятия',
          placeholder: '7969981',
        },
        access: {
          label: 'Доступ',
          placeholder: 'Закрытое',
          opt_closed: 'Закрытое',
          opt_half_open: 'Полуоткрытое',
          opt_open: 'Открытое',
          opt_family: 'Семейное',
          opt_external: 'Внешнее',
        },
        type: {
          label: 'Тип',
          placeholder: 'Основное',
          opt_main: 'Основное',
          opt_additional: 'Дополнительное',
          opt_clubInClub: 'Клуб в клубе',
          opt_open: 'Открытое',
          opt_sports: 'Спортивно-оздоровительные',
          opt_culture: 'Культурно-массовые',
          opt_voluntary: 'Добровольческие',
          opt_education: 'Просветительские и образовательные',
        },
        isRegistrationOpen: {
          label: 'Состояние',
          placeholder: '',
          opt_main: 'Открыта',
          opt_additional: 'Принудительно закрыта',
          hint: '<i>Принудительно закрыта</i>&nbsp;&ndash; регистрация будет окончена даже при наличии свободных мест или если ещё не&nbsp;наступила дата окончания регистрации; она не&nbsp;откроется при освобождении лимитов и&nbsp;любых других условиях. На&nbsp;мероприятие нельзя будет записаться.',
        },
        registrationType: {
          label: 'Тип регистрации',
          options: {
            default: 'Простая',
            poll: 'Через опрос',
          },
        },
        status: {
          label: 'Статус',
          hint: '<em>Запланировано</em> – мероприятие будет проведено в указанные сроки. <br><em>Отменено</em> – мероприятие не состоится. <br><em>Регистрация закрыта</em> – мероприятие будет проведено, но регистрация новых участников прекращена.',
          placeholder: 'Запланировано',
          opt_planned: 'Запланировано',
          opt_canceled: 'Отменено',
          opt_closed: 'Регистрация закрыта',
        },
        report: { label: 'Отчет', placeholder: 'Мероприятие прошло успешно' },
        broadcast_link: { label: 'Ссылка на просмотр', placeholder: 'https://example.com' },
        materials_link: { label: 'Ссылка на материалы со встречи', placeholder: 'https://example.com' },
        rating_statistics: { label: 'Статистика по выставленным оценкам:', xls: 'XLS', csv: 'CSV' },
        poll: {
          label: 'Прикрепить опрос',
          placeholder: 'Выберите опрос из списка',
        },
        titles: {
          registrations: 'Управление регистрацией',
        },
      },
      guests: {
        sidebar: {
          table_title: 'Участники',
          card_title: 'Участники мероприятия',
        },
        statuses: {
          willGo: 'Пойдет',
          favorite: 'В избранном',
          noAnswer: 'Нет ответа',
          new: 'Приглашен',
          willNotGo: 'Не пойдет',
          read: 'Просмотрено',
        },
        table: {
          title: 'Участники мероприятия',
          not_found: 'Участники не найдены',
          id: 'ID',
          name: 'Имя',
          surname: 'Фамилия',
          email: 'Email',
          phone: 'Номер телефона',
          telegram: 'Телеграм',
          status: 'Статус',
          payment: 'Оплата',
          amount: 'К оплате, ₽',
          updatedAt: 'Время обновления',
        },
        filters: {
          name: 'Имя или фамилия',
          status: 'Статус',
        },
      },
      organizer: {
        title: {
          placeholder: 'Название организатора-партнера',
        },
        link: {
          placeholder: 'Ссылка',
        },
        choose_organizer: {
          placeholder: 'Выбрать организатора из резидентов',
        },
      },
    },
    login: {
      title: 'Вход',
      submitButton: 'Войти',
      fields: {
        phone: { placeholder: '7-900-123-45-67', label: 'Номер телефона' },
        password: { placeholder: '******', label: 'Пароль' },
      },
      sidebar: {
        group_title: 'Справочники',
      },
    },
    preferences: {
      sidebar: {
        title: 'Настройки',
      },
      tabs: {
        tab_1: 'Уведомления',
        tab_2: 'Общие настройки',
        tab_3: 'Уведомление резидентов о новых версиях\n',
      },
      versions: {
        title: 'Уведомление резидентов о новых версиях',
        version: { label: 'Установка минимальной рабочей версии моб. приложения в сторах' },
        type: { label: 'Тип сообщения окна в моб приложении', hint: 'Soft - раз в сутки, можно пропустить сообщение и пользоваться приложением дальше. Hard - нельзя пользоваться приложением, пока пользователь не обновит его.' },
        require_update: { label: 'Нужно ли показать сообщение о новой версии в сторе?' },
        appLinks: { title: 'Ссылки на приложение' },
      },
    },
    reports: {
      sidebar: {
        title: 'Отчёты',

        user_activity_title: 'Активность пользователя',
        events_title: 'Мероприятия',
        certificate_title: 'Сертификат',
        partners_title: 'Партнёры',
      },
      filters: {
        department: {
          label: 'Подразделения',
          placeholder: 'Выберите подразделение',
        },
        users: {
          label: 'Пользователи',
          placeholder: 'Введите 3 символа',
        },
        events: {
          label: 'Мероприятия',
          placeholder: 'Введите 3 символа',
        },
        dateStart: {
          label: 'Начало периода',
          placeholder: '',
        },
        dateEnd: {
          label: 'Конец периода',
          placeholder: '',
          hint: 'Если поле пустое, то фильтруется по текущую дату',
        },
      },
      table: {
        not_found: 'Нет данных',
        download: 'Выгрузить в XLSX',
        downloadList: 'Выгрузить список',

        user_activity: {
          title: 'Активность пользователя',

          userId: 'Пользователь',
          loginCount: 'Сессий в МП за период',
          lastActive: 'Последний вход',
          profilesView: 'Просмотренных профилей',
          postsView: 'Просмотренных постов',
          postsPublished: 'Созданных постов',
          postsLikes: 'Лайков на постах',
          postsComments: 'Комментариев под постами',
          eventsView: 'Просмотренных мероприятий',
          eventsWillGo: 'Записей на мероприятия',
          eventsRatingCount: 'Оценок мероприятий',
          userLikes: 'Отправил благодарностей',
          userThanks: 'Получил благодарностей',
        },
        events: {
          title: 'Мероприятия',

          name: 'Название мероприятия',
          dateStart: 'Дата мероприятия',
          type: 'Тип мероприятия',
          categories: 'Категория мероприятия',
          departments: 'Подразделения',
          eventWillGo: 'Подано заявок',
          eventWillNotGo: 'Отменено заявок',
          totalRegistered: 'Отправлено приглашений',
          hasPollPrice: 'Версия',

          free: 'Бесплатное',
          paid: 'Платное',
          alert: 'Пожалуйста, выберите мероприятия',
        },
        certificate: {
          title: 'Сертификат члена Профсоюза',

          userId: 'Пользователь',
          certificateView: 'Кол-во переходов на экран с сертификатов',
          certificateShared: 'Кол-во пересылок сертификата',
          alert: 'Пожалуйста, выберите подразделения',
        },
        partners: {
          title: 'Партнёры',

          userId: 'Партнер',
          loyaltyView: 'Количество переходов на страницу программы лояльности',
          loyaltyShared: 'Количество скачиваний/пересылок pdf-файла и страницы',
          alert: 'Пожалуйста, выберите подразделения',
        },
      },
    },
    resident: {
      sidebar: {
        table_title: 'Резиденты',
        card_title: 'Новый резидент',
        group_title: 'Резиденты',
        companies_title: 'Компании',
        company_title: 'Компания',
      },
      tabs: {
        resident: 'Резидент',
        companies: 'Компании',
        communities: 'Сообщества',
        create: 'Создать',
      },
      table: {
        title: 'Резиденты',
        not_found: 'Резиденты не найдены',
        id: 'ID',
        fio: 'ФИО',
        first_name: 'Имя',
        last_name: 'Фамилия',
        email: 'E-mail',
        phone: 'Номер телефона',
        birthday: 'Дата рождения',
        tags: 'Роль в клубе',
        avatar: 'Аватар',
        communityManager: 'КМ',
        status: 'Статус',
        authMethods: 'Тип авторизации',
        set_password: 'Задать пароль',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'Имя или фамилия',
        phone: 'Номер телефона',
        positionTags: 'Роль в клубе',
        status: 'Статус',
        community_manager: 'Комьюнити-менеджер',
      },
      form: {
        title: 'Резидент',
        cancel_button: 'Назад',
        edit_button: 'Редактировать',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        confirm_delete: 'Вы уверены, что хотите удалить данного резидента?',
        failed_password_change: 'Ошибка обновления пароля',

        about_me: 'О себе',
        about_business: 'О бизнесе',
        about_look: 'О том, что ищет',
        first_name: { label: 'Имя', placeholder: 'Иван' },
        last_name: { label: 'Фамилия', placeholder: 'Иванов' },
        phone: { label: 'Номер телефона', placeholder: '+998 (XX) XXX-XX-XX' },
        email: { label: 'Электронная почта', placeholder: 'example{\'@\'}domain.com' },
        password: { label: 'Пароль для панели', placeholder: '' },
        password_for_user: { label: 'Пароль', placeholder: '' },
        community_manager: 'Комьюнити-менеджер',
        roles: {
          label: 'Настройка доступов',
          placeholder: 'Администратор',
          hint: 'Поле влияет на доступ в административную панель. Также регулирует некоторые права внутри мобильного приложения. Например, роль "Администратор" дает право на удаление чужих комментариев',
          admin: 'Администратор',
          resident: 'Резидент',
          community_manager: 'Комьюнити менеджер',
          buddy_partner: 'Бади-партнер',
          moderator: 'Модератор',
          proforg: 'Профорг',
        },
        birthday: { label: 'Дата рождения', placeholder: '26.06.1980' },
        avatar: { label: 'Аватар' },
        badge: { label: 'Бейдж', placeholder: 'Гендиректор ООО "Атом"' },
        recommendation_user: { label: 'Вступил по рекомендации', placeholder: 'Петр Петров' },
        entry: { label: 'Участник с', placeholder: '15.10.2021' },
        tag: { label: 'Теги', placeholder: 'Тег' },
        forum_group: { label: 'Форум-группа', placeholder: 'Группа' },
        position_tag: { label: 'Роль в клубе', placeholder: 'Модератор' },
        department: { label: 'Отделение', placeholder: 'Выбрать' },
        atlas: { label: 'Атласы', placeholder: '10' },
        city: { label: 'Город', placeholder: 'Ульяновск' },
        business_start_year: { label: 'Стаж', placeholder: '01.03.2000' },
        business_start_year_display: { option: 'Скрыть раздел' },
        turnover_per_year: { label: 'Оборот в год, рубли', placeholder: '6 000 000 000' },
        turnover_per_year_dollar: { label: 'Оборот в год, доллары', placeholder: '6 000 000 000' },
        turnover_per_year_display: { option: 'Скрыть раздел' },
        number_of_employees: { label: 'Количество сотрудников', placeholder: '41' },
        number_of_employees_display: { option: 'Скрыть раздел' },
        business_sectors: { label: 'Отрасли бизнеса', placeholder: 'Айти, телеком' },
        gender: {
          label: 'Пол',
          option_m: 'Мужчина',
          option_f: 'Женщина',
        },
        visibility: {
          show: 'Показать',
          hide: 'Скрыть',
          range: 'Диапазон',
        },
        telegram: { label: 'Никнейм в Телеграм', placeholder: '{\'@\'}butterfly' },
        telegramId: { label: 'ID в Телеграм', placeholder: '' },
        vk: { label: 'Вконтакте', placeholder: 'https:/vk.com/butterfly' },
        facebook: { label: 'Фейсбук', placeholder: 'https://fb.com/butterfly' },
        instagram: { label: 'Инстаграм', placeholder: '{\'@\'}butterfly' },
        education: { label: 'Образование', placeholder: 'МГУ, 2014-2018' },
        family_status: { label: 'Семейное положение', placeholder: 'Свободен' },
        interests: { label: 'Интересы, хобби', placeholder: 'Плавание, верховая езда' },
        sports: { label: 'Виды спорта, которыми занимаюсь', placeholder: 'Айкидо, бег' },
        waste_types: { label: 'Виды перерабатываемых отходов', placeholder: 'Выберите значение' },
        facts_about_me: { label: 'Факты обо мне', placeholder: 'В 4 года продавал лимонад около дома' },
        personal_www: { label: 'Персональный сайт', placeholder: 'domain.com' },
        video_presentation: { label: 'Видеовизитка', placeholder: 'https://youtube.com/some-video' },
        accept_rules: { label: 'Принял соглашение' },
        profile_complete: { label: 'Полностью заполнил профиль', hint: 'После полностью заполненного профиля (обязательные поля) резидент попадает дальше в приложение. Видит других резидентов и события' },
        created_at: { label: 'Дата создания' },
        updated_at: { label: 'Дата обновления' },
        change_password_at: { label: 'Дата обновления пароля' },
        last_activity: { label: 'Дата последнего входа в приложение' },
        booleans: { yes: 'Да', no: 'Нет' },
        not_found: 'Нет данных',

        useful: 'Чем могу быть полезен',
        expertise: { label: 'Экспертиза', placeholder: 'Знаю химию' },
        achievements: { label: 'Достижения', placeholder: 'Сертификат "Медвежонок", первое место' },
        look_resources: { label: 'Ищет ресурсы', placeholder: 'Кальций' },

        searching: 'Что я ищу',
        provide_resources: { label: 'Какие могу предоставить ресурсы и возможности?', placeholder: 'Места в офисе в Калуге' },
        year_target: { label: 'Цель на год', placeholder: 'Добиться инвестиций в размере $100 млн' },
        request: { label: 'Запрос', placeholder: 'Ищу бизнес-партнера' },

        children: { label: 'Дети', placeholder: 'Мальчик 6 лет и девочка 8 лет' },
        contacts: { label: 'Контактная информация' },
        companies: {
          title: 'Компания',
          submit_button: 'Сохранить',
          remove_button: 'Удалить',
          edit_button: 'Редактировать',
          add_button: 'Добавить',

          label: 'Компании',
          user: { label: 'Резидент', placeholder: '' },
          name: { label: 'Название компании', placeholder: 'ООО "Атом"' },
          logo: { label: 'Логотип' },
          areas: { label: 'Сфера деятельности', placeholder: 'Телеком' },
          position: { label: 'Роль в компании', placeholder: 'CEO' },
          geography: { label: 'География', placeholder: 'Центральная Россия' },
          site: { label: 'Вебсайт', placeholder: 'example.com' },
          telegram: { label: 'Телеграм-канал компании', placeholder: '{\'@\'}company' },
          wasteTypes: { label: 'Виды перерабатываемых отходов', placeholder: '' },
        },
        status: {
          label: 'Статус',
          active: 'Активен',
          blocked: 'Заблокирован',
          left: 'Вышел из клуба',
          not_paid: 'Не оплачен',
        },
        newPassword: {
          label: 'Постоянный пароль',
          placeholder: 'Укажите пароль',
        },
        authMethods: {
          label: 'Тип авторизации',
          placeholder: 'Выберите хотя бы один тип авторизации',
        },
        externalId: {
          label: 'Идентификатор пользователя в GetCourse',
          placeholder: 'Введите идентификатор',
        },
        description: {
          label: 'Характеристика резидента как бади-партнера',
          placeholder: 'Описание',
        },
        userYearTarget: {
          label: 'Цель на год',
          placeholder: 'Введите информацию',
        },
        userLookResources: {
          label: 'Какие ищу ресурсы?',
          placeholder: 'Введите информацию',
        },
        userLookExpertise: {
          label: 'Зоны интереса и развития',
          placeholder: 'Выберите вариант',
        },
        userRequest: {
          label: 'Запрос',
          placeholder: 'Выберите вариант',
        },
      },
      companies: {
        table: {
          title: 'Компании резидента',
          not_found: 'Компании не найдены',
          name: 'Название',
          logo: 'Логотип',
          user_position: 'Должность в компании',
          areas: 'Сферы деятельности',
          error: 'Не удалось загрузить компанию',
        },
        filters: {
          name: 'Название',
        },
      },
    },
    link_groups: {
      sidebar: {
        table_title: 'Группы ссылок',
      },
      table: {
        title: 'Группы ссылок',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      form: {
        title_edit: 'Редактирование ссылки',
        submit_button: 'Сохранить',
        name: { label: 'Название', placeholder: 'Название ссылки' },
        position: { label: 'Позиция', placeholder: 'Номер позиции' },
        visible: 'Видимое',
        failed_edition: 'Не удалось отредактировать ссылку',
      },
      links: {
        table: {
          title: 'Cсылки',
        },
      },
    },
    club_role: {
      sidebar: {
        table_title: 'Роли в клубе',
      },
      table: {
        title: 'Роли в клубе',
        not_found: 'Роли не найдены',
        id: 'ID',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'По названию',
      },
      form: {
        title_create: 'Новая роль',
        title_edit: 'Редактирование роли',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать роль',
        failed_edition: 'Не удалось отредактировать роль',
        name: { label: 'Название', placeholder: 'Староста' },
        color: { label: 'Цвет роли', placeholder: '#FFFFFF' },
      },
    },
    onboarding_editor: {
      sidebar: {
        title: 'Welcome-экраны',
      },
      form: {
        title: 'Welcome-экраны',
      },
    },
    mobile_profile: {
      sidebar: {
        title: 'Редактор полей',
      },
      form: {
        title: 'Редактор полей',
      },
    },
    change_log: {
      form: {
        title: 'История версий',
      },
    },
    event_category: {
      sidebar: {
        group_title: 'Категории мероприятий',
        table_title: 'Категории мероприятий',
      },
      table: {
        title: 'Категории мероприятий',
        not_found: 'Категории не найдены',
        edit_action: 'Редактировать',
        id: 'ID',
        name: 'Название',
        remove: 'Удалить',
        actions: '',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'По названию',
      },
      form: {
        title: 'Новая категория',
        failed_creation: 'Не удалось создать категорию',
        failed_edition: 'Не удалось отредактировать категорию',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        name: { label: 'Название', placeholder: 'Собрание' },
      },
    },
    partner: {
      sidebar: {
        table_title: 'Партнеры',
      },
      table: {
        title: 'Партнеры',
        not_found: 'Партнеры не найдены',
        id: 'ID',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'По названию',
      },
      form: {
        title_create: 'Новый партнер',
        title_edit: 'Редактирование партнера',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать партнера',
        failed_edition: 'Не удалось отредактировать партнера',
        name: { label: 'Название', placeholder: 'ООО "Компания"' },
        description: { label: 'Описание', placeholder: '' },
        url: { label: 'Ссылка', placeholder: 'https://t.me/company' },
        group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
        image: { label: 'Изображение' },
      },
    },
    club_in_club: {
      sidebar: {
        table_title: 'Клубы в клубе',
      },
      table: {
        title: 'Клубы в клубе',
        not_found: 'Клубы не найдены',
        id: 'ID',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'По названию',
      },
      form: {
        title_create: 'Новый клуб в клубе',
        title_edit: 'Редактирование клуба',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать клуб',
        failed_edition: 'Не удалось отредактировать клуб',
        name: { label: 'Название', placeholder: 'Автолюбители' },
        description: { label: 'Описание', placeholder: '' },
        url: { label: 'Ссылка', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
        image: { label: 'Изображение' },
      },
    },
    complaints: {
      sidebar: {
        table_title: 'Жалобы',
      },
      table: {
        title: 'Жалобы',
        complaintAuthor: 'Кто пожаловался',
        complaintText: 'Текст жалобы',
        complaintObject: 'Объект жалобы',
        commentary: 'Автор комм./публ.',
        complaintTime: 'Время жалобы',
        complaintActions: 'Контроллеры',
        complaintObserve: 'Просмотр жалобы',
      },
      modal: {
        complaint_author: 'Кто пожаловался',
        complaint_text: 'Текст жалобы',
        complaint_object: 'Объект жалобы',
        commentary: 'Автор комм./публ.',
        complaint_time: 'Время жалобы',
        complaint_published_time: 'Время публикации',
        complaint_published_text: 'Текст публикации',
        complaint_published_author: 'Автор публикации',
        complaint_comment_time: 'Время комментария',
        complaint_comment_text: 'Текст комментария',
        complaint_comment_author: 'Автор комментария',
        complaint_event_time: 'Время мероприятия',
        complaint_event_name: 'Название мероприятия',
        cancel_button: 'Закрыть',
      },
      filters: {
        complaints: 'Жалобы',
        bans: 'Блокировки',
      },
      form: {
        title_create: 'Новый клуб в клубе',
        title_edit: 'Редактирование клуба',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать клуб',
        failed_edition: 'Не удалось отредактировать клуб',
        name: { label: 'Название', placeholder: 'Автолюбители' },
        description: { label: 'Описание', placeholder: '' },
        url: { label: 'Ссылка', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
        image: { label: 'Изображение' },
      },
    },
    bans: {
      sidebar: {
        table_title: 'Блокировки',
      },
      table: {
        ignoring: 'Присяжный',
        ignored: 'Подсудимый',
        title: 'Блокировки',
        company: 'Компания',
        author: 'ФИО Создателя',
        created: 'Дата создания',
        actions: 'Контроллеры',
        remove: 'Отменить блокировку',
      },
      filters: {
        complaints: 'Жалобы',
        bans: 'Блокировки',
      },
      form: {
        title_create: 'Новый клуб в клубе',
        title_edit: 'Редактирование клуба',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать клуб',
        failed_edition: 'Не удалось отредактировать клуб',
        name: { label: 'Название', placeholder: 'Автолюбители' },
        description: { label: 'Описание', placeholder: '' },
        url: { label: 'Ссылка', placeholder: 'https://t.me/avtolubitely' },
        group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
        image: { label: 'Изображение' },
      },
    },
    community_manager: {
      sidebar: {
        table_title: 'Комьюнити менеджеры',
      },
      table: {
        title: 'Комьюнити менеджеры',
        not_found: 'Комьюнити менеджеры не найдены',
        fio: 'ФИО',
        email: 'Е-мейл',
        phone: 'Номер телефона',
        birthday: 'Дата рождения',
        avatar: 'Аватар',
        community_manager: 'Комьюнити',
        is_community_manager: 'Является комьюнити',
        change_community_manager: 'Сменить менеджера',
        status: 'Статус',
      },
      form: {
        title: 'Привязать к комьюнити менеджеру',
        failed_action: 'Не удалось завершить привязку',
        submit_button: 'Сохранить',
      },
      filters: {
        community_manager: 'Комьюнити менеджер',
        name: 'Поиск по резидентам',
      },
      layout: {
        title: 'Привязать к комьюнити менеджеру',
        sub_title: 'Выбрано резидентов',
        placeholder: 'Выбрать менеджера',
        loading: 'Загрузка',
        link: 'Отвязать',
        unlink: 'Привязать',
      },
    },
    buddy_partner: {
      sidebar: {
        table_title: 'Бади партнёры',
      },
      table: {
        title: 'Бади партнёры',
        not_found: 'Бади партнёры не найдены',
        fio: 'ФИО',
        email: 'Е-мейл',
        phone: 'Номер телефона',
        birthday: 'Дата рождения',
        avatar: 'Аватар',
        buddy_partner: 'Бади',
        is_buddy_partner: 'Является бади',
        change_buddy_partner: 'Сменить бади партнера',
        status: 'Статус',
      },
      form: {
        title: 'Привязать к бади-партнеру',
        failed_action: 'Не удалось завершить привязку',
        submit_button: 'Сохранить',
      },
      filters: {
        buddy_partner: 'Бади-партнер',
        name: 'Поиск по резидентам',
      },
    },
    notification: {
      sidebar: {
        table_title: 'Рассылки',
      },
      table: {
        title: 'Рассылки',
        caption: 'Заголовок',
        actions: 'Действия',
        edit_action: 'Редактировать',
        createAt: 'Дата создания',
        notifyAt: 'Дата рассылки',
        published: 'Публикация',
      },
      filters: {
        create_new: 'Новая рассылка',
      },
      form: {
        title: 'Рассылка',
        cancel_button: 'Отменить',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        confirm_delete: 'Уверены что хотите удалить рассылку?',
        caption: {
          label: 'Заголовок сообщения',
          placeholder: 'Введите текст',
        },
        text: {
          label: 'Тело сообщения',
          placeholder: 'Введите текст',
        },
        date: {
          label: 'Дата и время отправки',
        },
        status: 'Статус рассылки',
        draft: 'Черновик',
        types: {
          all: 'Все',
          users: 'Список резидентов',
          group: 'Роль в клубе',
        },
        users_list_type: 'Получатели рассылки',
        users: {
          label: 'Выбрать резидентов',
          placeholder: 'Начните вводить имя...',
        },
        groupId: {
          label: 'Роль в клубе',
          placeholder: 'Выберите роль',
        },
        deeplink: {
          label: 'Диплинк',
          placeholder: 'Выбрать ссылку',
        },
        events: {
          label: 'Мероприятие',
          placeholder: 'Выберите мероприятие',
        },
        posts: {
          label: 'Посты',
          placeholder: 'Выберите пост',
        },
      },
      status: {
        draft: 'Черновик',
        planned: 'Запланировано',
        completed: 'Завершено',
        failed: 'Ошибка',
      },
    },
    auth: {
      sidebar: {
        table_title: 'Список кодов автор-й',
      },
      table: {
        title: 'Список последних кодов авторизаций',
        not_found: 'Список пуст',

        fio: 'ФИО',
        phone: 'Телефон',
        code: 'Код',
        validTill: 'Срок действия',
        activateAt: 'Статус входа',
      },
    },
    editor: {
      main: {
        header: {
          title: 'Шапка профиля резидента',
          widgets: {
            avatar: 'Аватарка',
            role: 'Роль в клубе',
            badge: 'Бэйдж',
            name: 'Имя Фамилия',
            title_with_icon: 'Виджет статуса',
            group_btns: 'Группа кнопок',
          },
          hints: {
            tags: 'В мобильном приложении выводиться максимум 3 значения, которые назначены через админ панель в детальной резидента',
            group_btns: 'Можно добавить максимум 4 кнопки',
          },
        },
        body: {
          title: 'Поля профиля резидента',
        },
        footer: {
          title: 'Футер',
          forum_group: 'Форум-группа',
          member_s: 'Участник с',
          member_since: 'Участник с 01 января 2000 года',
        },
      },
      modal: {
        title: { add: 'Добавить поле', edit: 'Редактировать поле' },
        select_field: 'Выберите тип поля',
        field_view: 'Вид поля',
        type: {
          text: 'Текст',
          list_line: 'Короткий текст в одну строку',
          list_line_raw: 'Значение без заголовка',
          phone: 'Телефон',
          company: 'Компании',
          block_reason: 'Дополнительная информация',
        },
        field_name: 'Название',
        data_type: {
          label: 'Тип данных',
          text: 'Строка',
          link: 'Ссылка',
          date_time: 'Дата',
          number: 'Число',
        },
        hint_list_line_raw: 'Название видно только в форме',
        hint_telegram: 'Единое поле для заполнения, но вывести его можно в разных местах',
        icon: 'Иконка',
        new_value: 'Введите новое значение',
        show: 'Показать все поля',
        hide: 'Скрыть все поля',
        long_text: 'Длинный текст',
        add_autocomplete: 'Добавить список выбора значений при заполнении',
        allow_search: 'Участвует в поиске',
      },
      fields: {
        field_type: 'Тип поля',
        label: 'Лейбл',
        required_admin: 'Обязательно для админ-панели',
        required_app: 'Обязательно для мобильного приложения',
        has_privacy: 'Имеет настройки приватности',
        departments: 'Отделения',
        text_section_widget: 'Текст',
        list_line_widget: 'Короткий текст в одну строку',
        list_line_raw_action_widget: 'Значение без заголовка',
      },
      groups: {
        select_group_type: 'Выберите тип группы',
        group_type: 'Тип группы',
        header: 'Заголовок',
        types: {
          group: 'Группа',
          companies: 'Группа сообществ/компаний',
          socials: 'Социальные сети',
          contacts: 'Контакты',
        },
      },
      add: 'Добавить',
      cancel: 'Отмена',
      remove: 'Удалить',
      apply: 'Применить',
      done: 'Готово',
      add_field: 'Добавить поле',
      add_group: 'Добавить группу',
      save_exit: 'Сохранить и выйти',
      exit_no_save: 'Выйти не сохраняя',
      saving: 'Сохранение...',
      errors: {
        create_field: 'Ошибка в создании поля',
        required_field: 'Обязательное поле',
        name_first: 'Сначала введите название поля',
        has_name: 'Такое значение уже есть в списке',
        fill_all: 'Заполните все поля',
        saving: 'Ошибка сохранения данных. Попробуйте еще раз.',
      },
      warnings: {
        remove_group_confirm: 'Уверены что хотите удалить группу?',
        remove_field_confirm: 'Уверены что хотите удалить поле?',
        save: 'Cохранить изменения?',
        attention: 'Внимание!',
      },
      success: {
        save: 'Успешно сохранено',
      },
      phone_preview: {
        text: 'Текст',
        privacy: 'Приватность',
        show: 'показывать',
        privacy_birthday: 'только месяц и день',
        action_btns: {
          thanks: 'Поблагодарить',
          to_contact: 'В контакты',
          link: 'Ссылка',
          call: 'Позвонить',
          radar: 'Радар',
        },
        view_content: {
          name: 'Имя Фамилия',
          badge: 'БЭЙДЖ',
          team: 'КОМАНДА КЛУБА',
          no_name: 'НЕТ ИМЕНИ',
        },
        edit_content: {
          name: { label: 'Ваше имя', placeholder: 'Иван Иванов' },
          surname: { label: 'Ваша фамилия' },
          badge: { label: 'Бейдж' },
          forum_group: 'Форум-группа',
        },
      },
    },
    association_editor: {
      main: {
        title: 'Редактор полей ассоциаций',
        header: {
          title: 'Шапка ассоциаций',
          widgets: {
            logo: 'Логотип',
            name: 'Название организаций',
            badge: 'Роль в компании',
          },
        },
        body: {
          title: 'Поля ассоциаций',
        },
      },
      phone_preview: {
        view_content: {
          name: 'Название организации',
        },
        edit_content: {
          name: 'Название организации',
          placeholder: 'Название',
        },
      },
    },
    appearance: {
      sidebar: {
        title: 'Внешний вид',
      },
      form: {
        title: 'Внешний вид',
        cancel_button: 'Назад',
        submit_button: 'Сохранить',
        tab1: 'Общее',
        tab2: 'Меню',
        name: { label: 'Название сообщества' },
        logo: { label: 'Загрузите логотип клуба', hint: 'Картинка должна иметь соотношение сторон 1:1. Если размер будет другим, то может отображаться некорректно.' },
        menu: {
          label: 'Анимация меню',
          default: 'Без анимации(по-умолчанию)',
        },
      },
    },
    cabinet_sections: {
      sidebar: {
        title: 'Разделы кабинета',
      },
      filters: {
        create_new: 'Создать',
      },
      table: {
        title: 'Разделы кабинета',
        actions: '',
        edit_action: 'Редактировать',

        type: 'Тип',
        name: 'Название',
        image: 'Картинка',
      },
      form: {
        title_create: 'Новая ссылка',
        title_edit: 'Редактирование ссылки',
        submit_button: 'Сохранить',
        failed_edition: 'Не удалось отредактировать ссылку',
        failed_creation: 'Не удалось создать ссылку',
        type: { label: 'Тип ссылки', placeholder: '' },
        name: { label: 'Название', placeholder: '' },
        description: { label: 'Описание', placeholder: '' },
        url: { label: 'Ссылка', placeholder: 'https://' },
        code: { label: 'Код', placeholder: '' },
        image: { label: 'Изображение', placeholder: '' },
      },
      deep_links: {
        sidebar: {
          title: 'Разделы кабинета',
        },
      },
    },
  },
  gaz: {
    // link_groups: {
    //   sidebar: {
    //     table_title: 'Группы ссылок',
    //   },
    // },
    import: {
      button: 'Импорт',
      title: 'Импортировать резидентов',
      error: 'Ошибка',
      label: 'Импорт',
      success_text: 'Успешно импортировано резидентов:',
    },
    interview: {
      sidebar: {
        table_title: 'Опросы',
        card_title: 'Опрос',
        preview_title: 'Предпросмотр',
      },
      table: {
        title: 'Опросы',
        not_found: 'Опросы не найдены',
        id: 'ID',
        name: 'Название',
        description: 'Описание',
        createdAt: 'Дата создания',
        actions: 'Действия',
        published: 'Публикация',
        is_published: 'Опубликован',
        is_not_published: 'Не опубликован',

        download: 'Результаты',
      },
      filters: {
        create_new: 'Создать',
        submit: 'Подтвердить',
        name: 'По названию',
      },
      form: {
        title: 'Опрос',
        preview_link: 'Предпросмотр',
        failed_creation: 'Не удалось создать опрос',
        submit_button: 'Сохранить',
        cancel_button: 'Отменить',
        remove_button: 'Удалить',
        edit_button: 'Редактировать',
        copy_button: 'Копировать',
        name: { label: 'Название', placeholder: 'Оценка мероприятия' },
      },
      preview: {
        title: 'Предпросмотр',

        form: {
          exit_button: 'Выйти',
          prev_button: 'Назад',
          next_button: 'Далее',
          final_button: 'Завершить',
          time: 'Время',
          select: 'Выбрать',
          date: 'Дата',
          enter_text: 'Введите текст',
          answer: 'Свой вариант',
          attach: 'Прикрепить',
          done: 'Опрос пройден',
        },
        error: 'Хотя бы один из ответов на вопросы с двумя звёздочками (**) должен быть ненулевым.',
      },
      components: {
        condition: {
          question_select: {
            placeholder: 'Выберите шаг и вопрос',
            step_label: 'Шаг',
            question_label: 'Добавить вопрос',
          },
          add_condition_btn: 'Добавить условие',
          add_block_btn: 'Добавить блок условия',
          confirm: {
            message: 'Действительно удалить это условие?',
            title: 'Внимание',
            confirm: 'Да, удалить',
            cancel: 'Отмена',
          },
        },
      },
      constants: {
        question_type: {
          stars: 'Поставить оценку',
          number: 'Ввести число',
          plain_answer: 'Ввести текст',
          list: 'Выбрать из списка',
          date: 'Выбрать дату, время',
          file: 'Загрузить фото или файл',
        },
        data_type: {
          string: 'Строка',
          number: 'Число',
        },
        length_options: {
          short: 'Короткий текст',
          long: 'Длинный текст',
        },
        date_type: {
          date_time: 'Дата',
          time: 'Время',
        },
        file_type: {
          photo: 'Выбрать фото в галерее',
          camera: 'Сделать фото',
          document: 'Загрузить из файлов',
        },
      },
      layout: {
        name: 'Наименование опроса',
        global_limit: 'Глобальный лимит',
        global_limit_hint: 'Лимит имеет более высокий приоритет, чем в Мероприятии. При его применении, лимит в Мероприятии будет игнорироваться.',
        description: 'Описание опроса',
        price: 'Доп. стоимость',
        step: 'Шаг',
        add_step: 'Добавить шаг',
        question: 'Вопрос',
        confirm_remove_all: 'Удалить все условия?',
        confirm_question: {
          message: 'Действительно удалить этот вопрос?',
          title: 'Внимание',
          confirm: 'Да, удалить',
          cancel: 'Отмена',
        },
        confirm_step: {
          message: 'Действительно удалить этот шаг?',
          title: 'Внимание',
          confirm: 'Да, удалить',
          cancel: 'Отмена',
        },
        confirm_move: {
          message: 'Если переместить этот шаг, будут удалены ВСЕ условия отображения в опросе. Продолжить?',
          title: 'Внимание',
          confirm: 'Да, переместить',
          cancel: 'Отмена',
        },
        confirm_move_question: {
          message: 'Если переместить этот вопрос, будут удалены ВСЕ условия отображения в опросе. Продолжить?',
          title: 'Внимание',
          confirm: 'Да, переместить',
          cancel: 'Отмена',
        },
        confirm_price: {
          message: 'Все настройки стоимости будут сброшены. Продолжить?',
          title: 'Внимание',
          confirm: 'Да',
          cancel: 'Отмена',
        },
        modal_question: {
          title: 'Как должен ответить резидент?',
          label: 'Тип вопроса',
          cancel: 'Отмена',
          ok: 'Выбрать',
        },
        modal_price: {
          title: 'Настройка стоимости',
          free: 'Бесплатное мероприятие',
          paid: 'Платное мероприятие',
          total_sum: 'Конечная стоимость',
          base_price: 'Базовая стоимость',
          question: 'Вопрос',
          term: 'Слагаемое',
          help_text: 'Для того, чтобы мероприятие стало платным - нужно добавить "Слагаемое" и выбрать вопрос к нему',
          question_name_label: 'Описание доп. стоимости',
          question_price_label: 'Прибавка к базовой цене',
          question_question_label: 'Вопрос',
          question_hint: 'Можно выбрать только вопрос с типом поля "Число" (подтип текстового поля). Например вопрос "Сколько хотите купить билетов?", стоимость будет рассчитываться, как число из ответа умножить на ("Базовая стоимость" + Доп. стоимость вопроса)',
        },
      },
      question: {
        title: 'Вопрос',
        placeholder_question: 'Сформулируйте вопрос',
        placeholder_description: 'Введите краткое описание',
        label: 'Обязательный вопрос',
        hint: {
          required_not_null: '<p>Резидент обязан ответить, иначе он&nbsp;не&nbsp;сможет пройти опрос.</p>\n'
            + '<p>Автоматически активируется, если выбрано &laquo;Нельзя ввести&nbsp;0&raquo;.</p>',
          required_null: '<p>Резидент должен ввести 1&nbsp;или больше. Полезно при оформлении билетов, если тип билета обязателен; например, при наличии детских билетов обязательно сопровождение взрослого.</p>'
            + '<p>Автоматически активирует &laquo;Обязательный вопрос&raquo;, так как иначе у&nbsp;резидента будет возможность оставить вопрос без ответа.</p> <p>Условия &laquo;Нельзя ввести&nbsp;0&raquo; и&nbsp;&laquo;Хотя&nbsp;бы один ответ в&nbsp;шаге должен быть больше&nbsp;0&raquo; являются взаимоисключающими. Активация одного из&nbsp;них блокирует возможность включения другого; для смены условия необходимо сначала отключить текущее.<p>',
          not_null_sum: 'Сумма ответов в группе вопросов с этим условием не может быть равна нулю',
        },
        price: {
          label: 'Cтоимость',
          hint: '<p>При наличии стоимости опрос будет заканчиваться переходом к&nbsp;оплате. Полезно для продажи билетов на&nbsp;мероприятие.</p>',
        },
        question_limit: {
          label: 'Лимит вопроса',
          hint: '<p>Максимальная сумма ответов всех резидентов на&nbsp;данный вопрос. Полезно при оформлении билетов, если количество участников ограничено. </p>\n'
            + '<p>Задавайте ограничение через лимит вопроса, если есть точное ограничение по&nbsp;количеству каждого типа билетов; иначе используйте лимит шага.</p>\n'
            + '<p>Лимит вопроса невозможно поменять, если уже задан лимит шага. Если нужно задать ограничение на&nbsp;уровне вопроса, сначала отключите лимит шага.</p>',
        },
        respondent_limit: {
          label: 'Лимит резидента',
          hint: '<p>Максимальное значение, которое может ввести резидент. Полезно при оформлении билетов, чтобы ограничить их&nbsp;количество в&nbsp;одни руки. Например, каждый сможет приобрести не&nbsp;более трех билетов.</p>',
        },
        not_taken: {
          label: 'Не учитывать в лимите шага',
          hint: '<p>Сумма ответов всех резидентов по&nbsp;данному вопросу не&nbsp;влияет и&nbsp;не&nbsp;суммируется к&nbsp;лимиту шага. Полезно при оформлении билетов, например для бронирования парковочных мест, ограничение по&nbsp;которым не&nbsp;связано с&nbsp;ограничением по&nbsp;входным билетам.</p>',
        },
      },
      steps: {
        step_limit: {
          label: 'Лимит шага',
          hint: '<p>Максимальная сумма ответов всех резидентов на&nbsp;вопросы типа &laquo;Ввести число&raquo; в&nbsp;рамках данного шага. Полезно при оформлении билетов, если количество участников ограничено.</p>'
            + '<p>Используйте лимит шага, если распределение общего лимита по&nbsp;типам билетов не&nbsp;имеет значения; в&nbsp;противном случае задавайте лимит для каждого вопроса.</p>'
            + '<p>Лимит шага нельзя включить, если для вопросов внутри него уже заданы лимиты. Здесь для справки отображается сумма текущих лимитов вопросов, кроме тех, для которых включено &laquo;Не&nbsp;учитывать в&nbsp;лимите шага&raquo;. Чтобы задать ограничение на&nbsp;уровне шага, сначала отключите лимиты всех вопросов.</p>',
        },
        must_be_greater_zero: {
          label: 'Хотя бы один ответ в шаге должен быть больше 0',
          hint: '<p>Резидент должен ввести 1&nbsp;или больше хотя&nbsp;бы по&nbsp;одному из&nbsp;вопросов типа &laquo;Ввести число&raquo;. Полезно при оформлении билетов, если есть несколько типов билетов и&nbsp;должен быть выбран хотя&nbsp;бы один любой билет.</p>\n'
            + '<p>Условия &laquo;Хотя&nbsp;бы один ответ в&nbsp;шаге должен быть больше&nbsp;0&raquo; и&nbsp;&laquo;Нельзя ввести&nbsp;0&raquo; являются взаимоисключающими. Активация одного из&nbsp;них блокирует возможность включения другого; для смены условия необходимо сначала отключить текущее.<p>',
        },
      },
      conditions: {
        title: {
          question: 'Условие для вопроса',
          step: 'Условие для шага',
        },
        hint: {
          question: '<p>Резидент увидит в&nbsp;своем опросе данный вопрос, только если будут выполнены заданные условия. Доступно начиная со&nbsp;второго вопроса.</p>',
          step: '<p>Резидент увидит в&nbsp;своем опросе данный шаг, только если будут выполнены заданные условия. Доступно начиная со&nbsp;второго шага.</p>',
        },
      },
      list_question: {
        confirm_delete: {
          message: 'Действительно удалить это вариант ответа?',
          title: 'Внимание',
          confirm: 'Да, удалить',
          cancel: 'Отмена',
        },
        title: 'Тип данных',
        self_answer: 'Резидент может ввести свой вариант ответа',
        multi_select: 'Резидент может выбрать несколько вариантов ответов',
        total_limit: 'Общий лимит',
        total_limit_hint: 'Общий лимит для всех участников мероприятия по отношению к определенному вопросу.',
        option_placeholder: 'Введите вариант ответа',
        add_button: 'Добавить вариант ответа',
      },
      plain_answer_question: {
        title: 'Тип данных',
        text_length: 'Длина текста',
        int_limit: 'Лимит',
        int_limit_hint: 'Лимит ограничивает максимальное количество выбранной опции (например, билетов) для одного пользователя, проходящего опрос.',
        total_limit: 'Общий лимит',
        total_limit_hint: 'Общий лимит ограничивает сумму вводимых всеми пользователями значений относительно выбранного вопроса.',
      },
      file_question: {
        max_size: 'Максимальный размер файла в МБ, лимит только для файлов',
        warning_1: 'К одному вопросу возможно прикрепить только один файл, выбранным выше способом',
        warning_2: 'Внимание! Файлы хранятся на сервере 1 год с момента добавления',
      },
    },
    profile: {
      sidebar: {
        title: 'Контент',
        table_title: 'Распр-е по отделениям',
        card_title: 'Новый подраздел',
        group_title: 'Контент',
      },
      table: {
        title: 'Распределение по отделениям',
        not_found: 'Подразделы не найдены',
        id: 'ID',
        name: 'Подраздел',
        departments: 'Отделения',
        actions: '',
        edit_action: 'Редактировать',
        nothing: 'Нет',
      },
      filters: {
        create_new: 'Создать',
      },
      form: {
        title_create: 'Новый подраздел',
        title_edit: 'Редактирование подраздела',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать подраздел',
        failed_edition: 'Не удалось отредактировать подраздел',
      },
    },
    feedback: {
      sidebar: {
        table_title: 'Электронная приемная',
      },
      table: {
        title: 'Электронная юридическая приемная',
        not_found: 'Записи не найдены',

        fio: 'ФИО',
        email: 'E-mail',
        text: 'Текст обращения резидента',
        createdAt: 'Дата создания',
        action_more: 'Подробнее',
      },
      tabs: {
        suggestions: 'Предложения по изменениям',
        breaking: 'Заявления о нарушениях',
      },
      filters: {
        text: 'Поиск по строке',
      },
    },
    boss_word: {
      sidebar: {
        card_title: 'Слово председателя',
      },
      form: {
        submit_button: 'Сохранить',
        cancel_button: 'Назад',

        image: {
          label: 'Изображение',
          hint: 'Размер до 100 Мб',
        },
        title: {
          label: 'Заголовок',
          placeholder: 'введите текст заголовка',
        },
        text: {
          label: 'Основной текст',
          placeholder: 'введите текст',
        },
      },
    },
    safety: {
      sidebar: {
        table_title: 'Охрана труда',
      },
      table: {
        title: 'Охрана труда и производственная безопасность',
        not_found: 'Предложения не найдены',

        fio: 'ФИО',
        email: 'E-mail',
        organization: 'Организация',
        regulation_act: 'Локальный нормативный акт',
        point: 'Номер пункта акта',
        text: 'Ваши предложения',
        text_breaking: 'Описание нарушения',
        createdAt: 'Дата создания',
        place: 'Объект, где выявлено нарушение',
        action_more: 'Подробнее',
      },
      tabs: {
        suggestions: 'Предложения по изменениям',
        breaking: 'Заявления о нарушениях',
      },
      filters: {
        text: 'Поиск по строке',
      },
    },
    sports: {
      sections: {
        tabs: {
          title: 'Секции',
        },
        sidebar: {
          title: 'Спорт',
        },
        table: {
          title: 'Спортивные секции',
          not_found: 'Спортивные секции не найдены',
          id: 'ID',
          name: 'Название',
          actions: '',
          edit_action: 'Редактировать',
        },
        filters: {
          create_new: 'Создать',
          submit: 'Подтвердить',
          name: 'По названию',
        },
        form: {
          title_create: 'Новая спортивная секция',
          title_edit: 'Редактирование спортивной секции',
          submit_button: 'Сохранить',
          remove_button: 'Удалить',
          failed_creation: 'Не удалось создать секцию',
          failed_edition: 'Не удалось отредактировать секцию',
          name: { label: 'Название', placeholder: 'Большой теннис' },
          description: { label: 'Описание', placeholder: '24 участника, олимпийская система' },
          url: { label: 'Ссылка', placeholder: 'https://t.me/tennis_bolshoy' },
          group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
          image: { label: 'Изображение' },
        },
      },
      tourney: {
        tabs: {
          title: 'Турниры',
        },
        table: {
          title: 'Спортивные секции',
          not_found: 'Спортивные секции не найдены',
          id: 'ID',
          name: 'Название',
          actions: '',
          edit_action: 'Редактировать',
        },
        filters: {
          create_new: 'Создать',
          submit: 'Подтвердить',
          name: 'По названию',
        },
        form: {
          title_create: 'Новая спортивная секция',
          title_edit: 'Редактирование спортивной секции',
          submit_button: 'Сохранить',
          remove_button: 'Удалить',
          failed_creation: 'Не удалось создать секцию',
          failed_edition: 'Не удалось отредактировать секцию',
          name: { label: 'Название', placeholder: 'Большой теннис' },
          description: { label: 'Описание', placeholder: '' },
          url: { label: 'Ссылка', placeholder: 'https://t.me/tennis_bolshoy' },
          group: { label: 'Группа ссылок', placeholder: 'Выбрать' },
          image: { label: 'Изображение' },
        },
      },
      gto: {
        tabs: {
          title: 'ВФСК ГТО',
        },
        card: {
          title: 'Нормативы ВФСК ГТО',
        },
        form: {
          submit_button: 'Сохранить',
          cancel_button: 'Назад',

          image: {
            label: 'Изображение',
            hint: 'Размер до 100 Мб',
          },
          title: {
            label: 'Заголовок',
            placeholder: 'введите текст заголовка',
          },
          text: {
            label: 'Основной текст',
            placeholder: 'введите текст',
          },
        },
      },
    },
    blanks: {
      sidebar: {
        table_title: 'Формы и бланки заявл.',
        card_title: 'Новый бланк',
      },
      table: {
        title: 'Формы и бланки заявлений',
        not_found: 'Формы и бланки не найдены',
        id: 'ID',
        name: 'Название',
        actions: '',
        edit_action: 'Редактировать',
      },
      filters: {
        create_new: 'Создать',
      },
      form: {
        title_create: 'Новый бланк',
        title_edit: 'Редактирование бланка',
        submit_button: 'Сохранить',
        remove_button: 'Удалить',
        failed_creation: 'Не удалось создать бланк',
        failed_edition: 'Не удалось отредактировать бланк',
        name: { label: 'Название', placeholder: '' },
        url: { label: 'Ссылка', placeholder: '' },
      },
    },
    blanks_admin: {
      sidebar: {
        table_title: 'Формы и бланки Адм.',
      },
      table: {
        title: 'Формы и бланки заявлений для администрации',
      },
    },
    loyalty: {
      sidebar: {
        card_title: 'Программа лояльности',
      },
      tabs: {
        main: 'Основной текст',
        categories: 'Категории и ссылки',
      },
      form: {
        submit_button: 'Сохранить',
        cancel_button: 'Назад',
        edit_button: 'Редактировать',

        image: { label: 'Изображение' },
        text: {
          label: 'Основной текст',
          placeholder: 'введите текст',
        },

        add_folder: 'Добавить подкаталог',
        add_file: 'Добавить файл',

        modals: {
          add_folder: {
            title: 'Добавить каталог',
            cancel: 'Отменить',
            ok: 'Сохранить',
          },
          edit_folder: {
            title: 'Редактировать каталог',
          },
          add_file: {
            title: 'Добавить файл',
            cancel: 'Отменить',
            ok: 'Сохранить',
          },
          edit_file: {
            title: 'Редактировать файл',
          },
        },

        confirm: {
          folder: {
            message: 'Вы уверены, что хотите удалить этот каталог? Все вложенные каталоги и файлы также будут удалены.',
            title: 'Внимание',
            confirm: 'Да, удалить',
            cancel: 'Отменить',
          },
          file: {
            message: 'Вы уверены, что хотите удалить этот файл?',
          },
        },
      },
    },
    faq: {
      sidebar: {
        menu_title: 'Вопросы и ответы',
        card_title: 'Вопросы и ответы о Профсоюзе',
      },
      form: {
        add_question_button: 'Добавить вопрос',
        add_file_button: 'Добавить файл',
        submit_button: 'Сохранить',
        cancel_button: 'Назад',
        edit_button: 'Редактировать',
        delete_button: 'Удалить',

        image: { label: 'Изображение' },
        name: 'Название файла',
        actions: '',
      },
      modal_text: {
        title: 'Добавить вопрос',
        name_placeholder: 'Введите вопрос',
        text_placeholder: 'Введите ответ',
        cancel: 'Отменить',
        ok: 'Сохранить',
      },
      modal_file: {
        name: 'Введите название файла',
        title: 'Добавить файл',
        cancel: 'Отменить',
        ok: 'Сохранить',
      },
      delete_question: {
        message: 'Действительно удалить вопрос?',
        title: 'Внимание',
        confirm: 'Да, удалить',
        cancel: 'Отмена',
      },
      delete_file: {
        message: 'Действительно удалить файл?',
        title: 'Внимание',
        confirm: 'Да, удалить',
        cancel: 'Отмена',
      },
    },
  },
  milliard: {
    resident: {
      form: {
        roles: {
          admin: 'Команда клуба',
          placeholder: 'Команда клуба',
        },
      },
    },
  },
  lc: {
    resident: {
      sidebar: {
        communities_title: 'Сообщества',
      },
      tabs: {
        communities: 'Сообщества',
      },
      communities: {
        table: {
          title: 'Сообщества',
        },
      },
      form: {
        about_look: 'Что я ищу',
        about_business: 'Информация об опыте',
        business_start_year: { label: 'В комьюнити менеджменте с', placeholder: '01.03.1999' },
        number_of_employees: { label: 'Количество сотрудников в команде', placeholder: '1' },
        turnover_per_year: { label: 'Годовой оборот, руб', placeholder: '6 000 000 000' },
        city: { label: 'Место нахождения', placeholder: 'Ульяновск' },
        facts_about_me: { label: 'Профессия/бизнес, помимо сообщества, мечта или проект мечты', placeholder: 'Объехать всю Монголию на коне' },

        communities: {
          title: 'Сообщество',
          logo: {
            label: 'Лого',
          },
          name: {
            label: 'Название сообщества',
            placeholder: 'Введите информацию',
          },
          roleInto: {
            label: 'Роль в сообществе',
            placeholder: 'Выберите вариант',
            placeholderText: 'Введите свой вариант',
          },
          description: {
            label: 'Краткое описание (видение, миссия, цели, ценности сообщества)',
            placeholder: 'Введите информацию',
          },
          communityIndustries: {
            label: 'Отраслевая принадлежность',
            placeholder: 'Выберите',
          },
          communityMembershipDescription: {
            label: 'Для кого сообщество',
            placeholder: 'Введите информацию',
          },
          communityTypes: {
            label: 'Тип сообщества ',
            placeholder: 'Выберите тип сообщества',
          },
          communityProvideResources: {
            label: 'Какие у вас есть ресурсы?',
            placeholder: 'Введите информацию',
          },
          communityYearTarget: {
            label: 'Цели на год вашего сообщества',
            placeholder: 'Введите информацию',
          },
          communityLookResources: {
            label: 'С какими запросами можно обращаться к вашему сообществу?',
            placeholder: 'Введите информацию',
          },
          site: {
            label: 'Сайт сообщества',
            placeholder: 'https://',
          },
          communityOtherSocials: {
            label: 'Социальные сети сообщества',
            placeholder: 'Введите информацию',
          },
          communityCity: {
            label: 'Город сообщества',
            placeholder: 'Выберите вариант',
          },
          communityContacts: {
            label: 'Контакт для связи',
            placeholder: 'Введите информацию',
          },
          membersCount: {
            label: 'Количество участников',
            placeholder: 'Введите количество участников',
          },
          communitySize: {
            label: 'Масштаб',
            placeholder: 'Выберите вариант',
            options: {
              local: 'Локальное',
              regional: 'Региональное',
              international: 'Международное',
              all_russian: 'Всероссийское',
            },
          },
          communityTelegram: {
            label: 'Открытый telegram-канал сообщества',
            placeholder: 'Введите информацию',
          },
          communityInstagram: {
            label: 'Инстаграм сообщества',
            placeholder: 'Введите информацию',
          },
          communityGeography: {
            label: 'География',
            placeholder: 'Введите информацию',
          },
        },
      },
    },
  },
};

export type CustomTranslationSchema = typeof ruCustom;
export default ruCustom;
