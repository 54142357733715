import { tablePageController, translate } from 'magner';
import residentsTable from 'features/residents/table/hubstr';
import residentsFilters from 'features/residents/filters/hubstr';
import { Resident } from 'features/residents/types/hubstr';
import { residentRead } from 'features/residents/requests/hubstr';

const residentsConfig = tablePageController<Resident>({
  header: {
    title: translate('hubstr.resident.table.title'),
    actions: [
      {
        type: 'link',
        to: { name: 'resident', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: translate('hubstr.resident.filters.create_new'),
        },
      },
    ],
  },
  request: residentRead,
  table: residentsTable as any,
  filters: residentsFilters,
});

export default residentsConfig;
