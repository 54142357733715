import { routerController } from 'magner';
import { routerConfig } from 'configs/routers/hubstr';
import { communityCenterConfig } from 'configs/routers/community_center';
import { gazRouterConfig } from 'configs/routers/gaz';
import { lcRouterConfig } from 'configs/routers/lc';
import { metaHubstrRouterConfig } from 'configs/routers/meta-hubstr';
import { commonRouterConfig } from 'configs/routers/common';
import { setAppTitle } from '~/utils/set-app-title';
import {
  APP_ID,
  APP_COMMUNITY_CENTER,
  ROLE,
  APP_GAZPROM,
  META_APP_LC,
  META_APP,
  SUB_DOMEN,
  APP_COMMON,
  COMMON_LIST,
} from '~/constants';

const router = routerController<ROLE>((() => {
  const faviconList = document.querySelectorAll('link[rel="icon"]');
  faviconList.forEach((element) => {
    element.setAttribute('href', `/logo/${APP_ID === APP_COMMON && COMMON_LIST.includes(SUB_DOMEN) 
      ? SUB_DOMEN 
      : APP_ID === APP_COMMON && !COMMON_LIST.includes(SUB_DOMEN) 
        ? 'hubstr' 
        : APP_ID}/favicon.ico`);
  });

  setAppTitle();

  switch (APP_ID) {
    case APP_COMMON:
      return commonRouterConfig;
    case APP_GAZPROM:
      return gazRouterConfig;
    case APP_COMMUNITY_CENTER:
      return communityCenterConfig;
    case META_APP_LC:
      return metaHubstrRouterConfig;
    case META_APP: {
      return metaHubstrRouterConfig;
    }
    default: {
      return routerConfig;
    }
  }
})() as any);

export default router;
