<template>
  <div class="phone-header">
    <el-avatar
      class="phone-header__avatar"
      :size="100"
      :src="avatar"
    />
    <h1
      class="phone-header__name_widget"
    >
      {{ translateText('hubstr.association_editor.phone_preview.view_content.name') }}
    </h1>
    <div
      v-if="data.header.find(i => i.type === 'header_badge_widget')"
      class="phone-header__badge"
    >
      {{ data.header.find((i) => i.type === 'header_badge_widget').label || translateText('hubstr.editor.phone_preview.view_content.badge') }}
    </div>
  </div>

  <div
    v-for="view in data.body"
    :key="view"
    class="phone-body"
  >
    <h3
      class="phone-body__title"
    >
      {{ t[view.title.replace('lang.mobile.', '')] || view.title }}
    </h3>
    <br>
    <div
      v-for="field in view.widgets"
      :key="field.title"
    >
      <component
        :is="setComponent(field.type)"
        :key="field.title"
        :data="field"
      />
    </div>
  </div>
  <div class="phone-footer">
    <div
      v-if="data.footer.some((i) => i.type === 'entry_widget')"
      class="phone-footer__member"
    >
      {{ translateText('hubstr.editor.main.footer.member_since') }}
    </div>
    <div
      v-if="data.footer.some((i) => i.type === 'form_group_widget')"
      class="phone-footer__form-group"
    >
      {{ translateText('hubstr.editor.main.footer.forum_group') }}
    </div>
  </div>
  <br>
  <br>
</template>

<script lang="ts" setup>
import TextSection from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-view-content/text-section.vue';
import ListLine from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-view-content/list-line.vue';
import ListLineRawAction from 'features/settings/editor/association-editor/ui/components/right-side/components/widgets/phone-view-content/list-line-raw-action.vue';
// import InfoLineAction
//   from 'features/settings/editor/components/right-side/components/fields/phone-view-content/info-line-action.vue';
import { TranslatesAssociationList as t } from 'features/settings/editor/association-editor/requests';
import { translate, useTranslate } from 'magner';
import { IAViewWidgets, IAViewWidgetsHeader, ValueOf } from 'features/settings/editor/association-editor/interfaces';
import { WIDGET_TYPES } from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/constants';

interface Props {
  data: IAViewWidgets
}

const props = defineProps<Props>();

const setComponent = (type: ValueOf<typeof WIDGET_TYPES>) => {
  switch (type) {
    case WIDGET_TYPES.TEXT_SECTION:
      return TextSection;
    case WIDGET_TYPES.LIST_LINE:
      return ListLine;
    case WIDGET_TYPES.LIST_LINE_RAW:
      return ListLineRawAction;
    // case WIDGET_TYPES.INFO_LINE_ACTION:
    //   return InfoLineAction;
    default:
      return {} as any;
  }
};

const { customT } = useTranslate();
const translateText = (code: string) => customT(translate(code));

const avatar = props.data.header.find((i: IAViewWidgetsHeader) => i.type === 'header_avatar_widget')?.value || '';
</script>

<style lang="scss" scoped>
.phone-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__name_widget {
    line-height: normal;
    text-align: center;
  }
  &__name {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 26px;
    text-align: center;
  }
  &__tags {
    font-size: 10px;
    margin-top: 15px;
    color: var(--el-color-warning);
  }
  &__avatar {
    position: relative;
  }
  &__badge {
    color: var(--el-text-color-secondary);
    font-size: 10px;
    margin-top: 5px;
  }
  &__action-buttons {
    padding: 0 10px;
  }
}

.phone-body {
  &__title {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
}

.phone-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  margin-top: 10px;

  &__member {
    color: var(--el-text-color-disabled);
  }
  &__form-group {
    color: var(--el-color-primary);
  }
}
</style>
