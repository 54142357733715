import { cardPageController, translate } from 'magner';
import {
  clubRoleCreate, clubRoleGet, clubRoleDelete, clubRoleUpdate,
} from 'features/settings/club-roles/requests/hubstr';

import ColorPicker from '../components/color-picker.vue';

const clubRoleConfig = cardPageController({
  getRequest: clubRoleGet,
  createRequest: clubRoleCreate,
  updateRequest: clubRoleUpdate,
  deleteRequest: clubRoleDelete,

  header: {
    title: translate('hubstr.club_role.form.title_create'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: translate('hubstr.club_role.form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: translate('hubstr.club_role.form.remove_button'),
        },
      },
    ],
    layout: {
      // @ts-ignore
      type: 'column',
      props: {},
      // @ts-ignore
      fields: [
        {
          type: 'input',
          name: 'name',
          label: translate('hubstr.club_role.form.name.label'),
          props: {
            required: true,
            placeholder: translate('hubstr.club_role.form.name.placeholder'),
          },
          validation: [{
            type: 'empty',
            trigger: 'blur',
          }],
        },
        {
          type: 'custom',
          name: 'color',
          label: translate('hubstr.club_role.form.color.label'),
          dataType: 'string',
          component: () => ColorPicker,
          props: {
            required: true,
            placeholder: translate('hubstr.club_role.form.color.placeholder'),
          },
          validation: {
            type: 'required-hex' as 'empty',
            trigger: 'change',
          },
        },
      ],
    },
  },
});

export default clubRoleConfig;
