import { IAdditions } from 'features/settings/editor/residents-editor/interfaces';

export default class MobilePrivacyEntity {
  additional: IAdditions;

  constructor (additional: IAdditions) {
    this.additional = additional;
  }

  setDefaultPrivacy () {
    return {
      type: 'privacyList',
      name: `fieldsVisibility[${this.additional.code}]`,
      value: `data.fieldsVisibility.${this.additional.code}`,
      title: 'Приватность',
      list: {
        1: 'lang.status_privacy_show',
        0: 'lang.status_privacy_hide',
      },
      default: '1',
    };
  }

  setRangePrivacy () {
    return {
      type: 'privacyList',
      name: `additions[${this.additional.code}Display]`,
      value: `data.additions.${this.additional.code}Display`,
      title: 'Приватность',
      list: {
        STRICT: 'lang.status_privacy_show',
        RANGE: 'lang.status_privacy_range',
        NONE: 'lang.status_privacy_hide',
      },
      default: 'STRICT',
    };
  }

  setPhonePrivacy (value?: boolean) {
    return {
      type: 'privacyBool',
      name: 'hidePhone',
      value: 'data.fieldsVisibility.phone',
      title: 'Приватность',
      list: {
        0: 'lang.status_privacy_show',
        1: 'lang.status_privacy_hide',
      },
      default: value ? '0' : '1',
    };
  }

  create (format: Pick<IAdditions, 'format'> | string) {
    switch (format) {
      case 'EXPERIENCE': case 'EMPLOYEES': case 'TURNOVER':
        return this.setRangePrivacy();
      case 'DEFAULT':
        return this.setDefaultPrivacy();
    }
  }
}
