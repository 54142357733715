<template>
  <el-alert
    title="Только просмотр."
    show-icon
    type="info"
    :closable="false"
  >
    <template #default>
      <div class="desc">
        Список объектов с произвольными полями.<br> Редактировать их поля можно во вкладке
        <el-link
          class="link"
          :underline="false"
          type="primary"
          @click="router.push({name: 'associations-editor'})"
        >
          {{ `«${t[data.title.replace('lang.', '')] || data.title}»` }}
        </el-link>
      </div>
    </template>
  </el-alert>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useRouter } from 'magner/vue-router';
import { Body } from 'features/settings/mobile-editor/interfaces';
import { TranslatesList as t } from 'features/settings/mobile-editor/requests';

export default defineComponent({
  name: 'GroupButton',
  methods: {
    replace () {
      return replace;
    },
  },
  props: {
    data: {
      type: Object as PropType<Body>,
      required: true,
    },
  },
  setup () {
    const router = useRouter();

    return {
      router,
      t,
    };
  },
});
</script>

<style scoped>
.link {
  vertical-align: baseline;
  opacity: .8;
  font-size: var(--el-font-size-extra-small);
  font-weight: 400;
}
</style>
