import { magnerConfirm, magnerMessage, translate } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import { BansList } from 'features/settings/bans/types/hubstr';
import ignoredLink from 'features/settings/bans/table/ignoredLink.vue';
import { banDelete } from 'features/settings/bans/requests/hubstr';
import ignoringLink from 'features/settings/bans/table/ignoringLink.vue';
import { formatDate } from '~/utils/format-date';

export const banIgnoring: TableColumn<BansList> = {
  prop: 'ignoring',
  label: translate('hubstr.bans.table.ignoring'),
  view: {
    type: 'custom',
    component: () => ignoringLink,
  },
  width: 350,
};

export const banIgnored: TableColumn<BansList> = {
  prop: 'ignored',
  label: translate('hubstr.bans.table.ignored'),
  view: {
    type: 'custom',
    component: () => ignoredLink,
  },
  width: 350,
};

export const banCreatedAt: TableColumn<BansList> = {
  prop: 'created_at',
  label: translate('hubstr.bans.table.created'),
  view: {
    type: 'text',
    formatter: (_, row) => formatDate(_ as Date),
  },
  width: 350,
};

export const banActions: TableColumn<BansList> = {
  prop: 'action',
  label: '',
  width: 230,
  view: {
    type: 'actions',
    actions: [
      {
        type: 'action',
        emits: 'remove',
        action: async ({ data } : any) => {
          await magnerConfirm({
            type: 'warning',
            title: 'Внимание!',
            message: 'Вы действительно хотите снять блокировку?',
            confirmButtonText: 'Да',
          })
            .then(async () => {
              await banDelete(data.row.id)
                .catch((e) => {
                  magnerMessage({
                    type: 'error',
                    message: 'Error',
                  });
                });
            });
        },
        props: {
          text: translate('hubstr.bans.table.remove'),
          type: 'primary',
          plain: true,
        },
      },
    ],
  },
};
