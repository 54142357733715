import { translate, openModal } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import ObserveComplaint from 'features/settings/complaints/layout/hubstr.vue';
import { ComplaintsList } from 'features/settings/complaints/types/hubstr';
import authorLink from 'features/settings/complaints/table/authorLink.vue';
import authorComplaintLink from 'features/settings/complaints/table/authorComplaintLink.vue';
import objectLink from 'features/settings/complaints/table/objectLink.vue';
import { formatDate } from '~/utils/format-date';

export const complaintAuthor: TableColumn<ComplaintsList> = {
  prop: 'author',
  label: translate('hubstr.complaints.table.complaintAuthor'),
  view: {
    type: 'custom',
    formatter: (_, row) => _,
    component: () => authorComplaintLink,
  },
  width: 220,
};

export const complaintText: TableColumn<ComplaintsList> = {
  prop: 'text',
  label: translate('hubstr.complaints.table.complaintText'),
  className: 'resident-fio-cell',
  width: 200,
};

export const complaintObject: TableColumn<ComplaintsList> = {
  prop: 'target',
  label: translate('hubstr.complaints.table.complaintObject'),
  view: {
    type: 'custom',
    component: () => objectLink,
  },
  width: 200,
};

export const complaintCommentary: TableColumn<ComplaintsList> = {
  prop: 'target',
  label: translate('hubstr.complaints.table.commentary'),
  width: 220,
  view: {
    type: 'custom',
    component: () => authorLink,
  },
};

export const complaintDate: TableColumn<ComplaintsList> = {
  prop: 'created_at',
  label: translate('hubstr.complaints.table.complaintTime'),
  view: {
    type: 'text',
    formatter: (_, row) => formatDate(_ as Date),
  },
  width: 220,
};

export const complaintActions: TableColumn<ComplaintsList> = {
  prop: 'action',
  label: '',
  width: 220,
  view: {
    type: 'actions',
    actions: [
      {
        type: 'action',
        emits: 'open-complaint',
        action: ({ data } : any) => (
          openModal<any>({
            type: 'custom',
            handleBeforeClose: true,
            customClass: 'change-community-manager-modal',
            component: () => ObserveComplaint,
            props: {
              residentsList: [],
              complaintId: data.row.id,
            },
          })),
        props: {
          text: translate('hubstr.complaints.table.complaintObserve'),
          type: 'primary',
          plain: true,
        },
      },
    ],
  },
};
