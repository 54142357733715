<template>
  <el-row gutter="30">
    <el-col :lg="5" :md="8" :xs="24">
      <el-form-item>
        <template #label>
          {{ translateText('hubstr.appearance.form.logo.label') }}
          <el-tooltip
            :content="translateText('hubstr.appearance.form.logo.hint')"
            effect="light"
            placement="bottom"
            popper-class="generic-form_item_tooltip"
          >
            <el-icon size="15" class="icon">
              <component :is="Question" />
            </el-icon>
          </el-tooltip>
        </template>
        <MagnerFormDropzone
          v-model="state.defaultLogo.big"
          :field="imageField"
          @update:modelValue="pushNewLogoFile"
        />
      </el-form-item>
    </el-col>
    <el-col :lg="19" :md="16" :xs="24">
      <el-form-item :label="translateText('hubstr.appearance.form.name.label')">
        <el-input v-model="state.title" :maxlength="20" />
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {
  MagnerFormDropzone, translate, useTranslate,
} from 'magner';
import {
  defineComponent, PropType, reactive,
} from 'vue';
import { AppInfo } from 'features/settings/appearance/interfaces';
import Question from 'features/settings/mobile-editor/assets/icons/question.svg';

export default defineComponent({
  name: 'GeneralTab',
  components: { MagnerFormDropzone },
  props: {
    data: {
      type: Object as PropType<AppInfo>,
      required: true,
    },
  },
  setup (props) {
    const { customT } = useTranslate();
    const state = reactive<AppInfo & { newLogo?: File[] }>(props.data);

    const imageField = {
      type: 'dropzone',
      props: {
        removable: true,
        formats: ['png', 'jpg', 'jpeg'],
      },
    };

    const pushNewLogoFile = async (event: File[]) => {
      if (event.length) {
        state.newLogo = event;
      }
    };

    const translateText = (code: string) => customT(translate(code));
    return {
      state,
      imageField,
      translateText,
      pushNewLogoFile,

      Question,
    };
  },
});
</script>

<style scoped>
.el-icon {
  vertical-align: middle;
}
</style>
