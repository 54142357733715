import MobileFieldEntity from 'features/settings/mobile-editor/factories/mobile-field-entity';
import MobilePrivacyEntity from 'features/settings/mobile-editor/factories/mobile-privacy-entity';
import { IAViewWidgets } from 'features/settings/editor/association-editor/interfaces';
import {
  IWidget,
} from 'features/settings/editor/association-editor/ui/components/left-side/components/widgets/types/interfaces';
import { ADMIN_GROUP_TYPES, GROUP_TYPES } from 'features/settings/mobile-editor/constants';
import { findMatchingField } from 'features/settings/mobile-editor/utils/find-matching-field';

/**
 * @param view
 * */
const parseForMobile = (view: IAViewWidgets) => {
  const mobileView = JSON.parse(JSON.stringify(view)) as IAViewWidgets;

  const newView = mobileView.body
    .map((group) => {
      const newWidgets = group.widgets.flatMap((widget) => {
        if (widget.additions) {
          const fieldFactoryInit = new MobileFieldEntity(widget, widget.additions);
          const privacyFactoryInit = new MobilePrivacyEntity(widget.additions);

          // Генерируем поле
          const out = [fieldFactoryInit.create()];

          // Добавляем приватность
          if (widget.hasPrivacy) {
            out.push(privacyFactoryInit.create(widget.additions.format));
          }

          return out;
        }
      }).filter((w) => w);

      return {
        ...group,
        fields: newWidgets.filter((w) => w),
      };
    }) as any;

  // Удаляем лишний объект widgets
  newView.forEach((group: any) => {
    delete group.widgets;
  });

  const out = {
    name: 'form_name',
    action: 'form_action',
    method: 'form_method',
    fields: [
      {
        type: 'inline',
        fields: [
          {
            type: 'image_upload',
            name: 'logo',
            imageType: 'company_logo',
            required: true,
            value: 'data.logo.big',
          },
          {
            type: 'text',
            name: 'name',
            value: 'data.name',
            title: 'Название организации',
            placeholder: 'Введите информацию',
            required: true,
            isMultiline: false,
            validator: {
              type: 'text',
              minLength: 1,
              maxLength: 24,
            },
          },
        ],
      },
      ...newView,
    ],
  };

  // const badge = mobileView.header.find((h) => h.type === 'header_badge_widget');
  // if (badge) {
  //   out.fields.splice(2, 0, {
  //     type: 'text',
  //     name: 'additions[userBadge]',
  //     value: 'data.additions.userBadge',
  //     title: badge?.label || 'Бейдж',
  //     placeholder: 'lang.badge_hint',
  //     required: badge?.required || false,
  //     multiline: false,
  //     validator: {
  //       type: 'text',
  //       maxLength: 150,
  //     },
  //   });
  // }

  return out;
};
export default parseForMobile;
